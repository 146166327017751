import { Maybe } from "src/core";

export const isInternalTransfer = (reason: Maybe<string>) =>
  reason === "v1:internal_transfer";

export const isDeposit = (reason: Maybe<string>) =>
  reason === "v1:staking" ||
  reason === "v1:lend" ||
  reason === "v1:lend_borrow" ||
  reason === "v1:deposit_liquidity";
