import { SingleValueData, Time } from "lightweight-charts";
import { noop } from "lodash";
import * as moment from "moment-timezone";
import { createContext } from "react";
import { BaseAssetFields, BaseClientFields } from "src/api/fragments";
import {
  AssetTypeEnum,
  GetAssetPositionsResponse,
  GetTokenInfoResponse,
  HistoricalBalanceIntervalEnum,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { AwakenChartValuePoint } from "./Charts/types";

export type TimeFrame = {
  label: string;
  type: HistoricalBalanceIntervalEnum;
};

// ex. h:mma not just date
export const SHOW_TIMESTAMP = new Set<string>([
  HistoricalBalanceIntervalEnum.Day,
  HistoricalBalanceIntervalEnum.Week,
  HistoricalBalanceIntervalEnum.Month,
]);

export const TIME_FRAMES = [
  {
    label: "1D",
    type: HistoricalBalanceIntervalEnum.Day,
  },
  {
    label: "1W",
    type: HistoricalBalanceIntervalEnum.Week,
  },
  {
    label: "1M",
    type: HistoricalBalanceIntervalEnum.Month,
  },
  // {
  //   label: "3M",
  //   type: HistoricalBalanceIntervalEnum.ThreeMonth,
  // },
  {
    label: "YTD",
    type: HistoricalBalanceIntervalEnum.YearToDate,
  },
  {
    label: "1Y",
    type: HistoricalBalanceIntervalEnum.Year,
  },
  {
    label: "ALL",
    type: HistoricalBalanceIntervalEnum.All,
  },
];

export const DEFAULT_TF = TIME_FRAMES.find(
  (d) => d.type === HistoricalBalanceIntervalEnum.Day
)!;

export const timeFormat = (timezone: string) => (time: Time) => {
  const timeFormatted = moment
    .tz(parseInt((time || 0).toString()) * 1000, timezone)
    .format("h:mma, MMM D");
  return timeFormatted;
};

export const getTimeFormat = (
  timezone: string,
  timeFrame: Maybe<TimeFrame>
) => {
  if (timeFrame?.type === HistoricalBalanceIntervalEnum.Day) {
    return {
      dateFormat: null,
      timeFormat: (time: Time) => {
        const timeFormatted = moment
          .tz(parseInt((time || 0)?.toString()) * 1000, timezone)
          .format("h:mma");
        return timeFormatted;
      },
    };
  }
  if (timeFrame?.type === HistoricalBalanceIntervalEnum.Week) {
    return {
      dateFormat: null,
      timeFormat: timeFormat(timezone),
    };
  }
  if (timeFrame?.type === HistoricalBalanceIntervalEnum.Month) {
    return {
      dateFormat: null,
      timeFormat: timeFormat(timezone),
    };
  }
  return { dateFormat: "MMM dd, yyyy", timeFormat: null };
};

export type AssetKeyInfoContext = {
  client: Maybe<BaseClientFields>;
  assets: BaseAssetFields[];
  provider: Maybe<string>;
  contractAddress: Maybe<string>;
  firstAsset: Maybe<BaseAssetFields>;
  points: AwakenChartValuePoint[];
  position: Maybe<GetAssetPositionsResponse>;
  assetKeyV2: Maybe<string>;
  name: string;
  symbol: string;
  iconImageUrl: string;
  coingeckoId: string;
  loadingPoints: boolean;
  type: Maybe<AssetTypeEnum>;
  timeFrame: Maybe<TimeFrame>;
  setTimeFrame: (timeFrame: Maybe<TimeFrame>) => void;
  startValueDollars: Maybe<number>;
  currentPoint: Maybe<SingleValueData>;
  currentValueDollars: Maybe<number>;
  pointValueDollars: Maybe<number>;
  gainLossDollars: Maybe<number>;
  gainLossPercentage: Maybe<number>;
  setPointValueDollars: (value: number) => void;
  setCurrentValueDollars: (value: number) => void;
  isNeg: boolean;
  isLoadingTokenInfo: boolean;
  tokenInfo: Maybe<GetTokenInfoResponse>;
  blockExplorerUrl: Maybe<string>;
};

export const AssetKeyInfoContext = createContext<AssetKeyInfoContext>({
  client: null,
  provider: null,
  contractAddress: null,
  isLoadingTokenInfo: false,
  tokenInfo: null,
  assets: [],
  points: [],
  assetKeyV2: null,
  position: null,
  firstAsset: null,
  gainLossDollars: null,
  loadingPoints: false,
  name: "",
  symbol: "",
  iconImageUrl: "",
  gainLossPercentage: 0,
  coingeckoId: "",
  isNeg: false,
  type: null,
  startValueDollars: null,
  setCurrentValueDollars: noop,
  setPointValueDollars: noop,
  currentValueDollars: null,
  currentPoint: null,
  pointValueDollars: null,
  timeFrame: DEFAULT_TF,
  setTimeFrame: noop,
  blockExplorerUrl: null,
});
