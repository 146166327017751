import { Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

export function LawyerLetter() {
  const { greenBg, text } = useTheme();
  const dispatch = useDispatch();

  return (
    <Text
      textAlign="center"
      marginBottom="1rem"
      fontSize="sm"
      padding="0.5rem"
      bg={greenBg}
      border={`1px solid ${colors.positive}`}
      borderRadius={10}
      marginTop="0.5rem"
      cursor="pointer"
      onClick={() => {
        dispatch(show("LawyerLetterModal"));
      }}
      color={text}
    >
      <i
        className="fa-sharp fa-piggy-bank"
        style={{ color: colors.positive }}
      />{" "}
      You may be able to reduce your tax liability by getting a legal letter.{" "}
      <span style={{ fontWeight: "bold" }}>
        Learn more <i className="fa-sharp fa-arrow-right" />
      </span>
    </Text>
  );
}
