import {
  Box,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import _, { truncate } from "lodash";
import { flatten, flow, groupBy, map, orderBy, partition } from "lodash/fp";
import { useMemo } from "react";
import { BaseLedgerEntryFullFields } from "src/api";
import { BaseLedgerEntryFields } from "src/api/fragments";
import {
  AssetTypeEnum,
  CurrencyCodeEnum,
  LedgerEntryDirectionEnum,
} from "src/api/generated/types";
import { useTheme } from "src/hooks/useTheme";
import { D } from "src/utils/helpers";
import { Copy } from "../styled";

export const LedgerEntriesTable = ({
  title = "Entries",
  ledgerEntries: _ledgerEntries,
  onClickEntry,
  showOnlyEssentials,
  showTransaction,
  showDate,
  showDuplicateButton = false,
}: {
  title?: string;
  ledgerEntries: BaseLedgerEntryFullFields[];
  onClickEntry: (e: BaseLedgerEntryFullFields) => void;
  showOnlyEssentials?: boolean; // FIXME: hack to hide tags + asset
  showTransaction?: boolean;
  showDate?: boolean;
  showDuplicateButton?: boolean;
}) => {
  const theme = useTheme();

  const { totalDebits, totalCredits } = useMemo(
    () => _checkEntries(_ledgerEntries),
    [_ledgerEntries]
  );

  const ledgerEntries = useMemo<BaseLedgerEntryFullFields[]>(
    () =>
      flow(
        groupBy("classification"),
        map(Object.values),
        map((values) =>
          // sort each classification group by fiat amount, descending
          _.sortBy(values, (entry) => Number(entry.fiatAmount)).reverse()
        ),
        flatten
      )(_ledgerEntries),
    [_ledgerEntries]
  );

  const entriesList = orderBy(
    ["fiatAmount", "direction"],
    "desc",
    ledgerEntries
  );

  return (
    <Box
      bg={theme.medBackground}
      w="100%"
      position="relative"
      borderRadius="0.5rem"
    >
      <Box w="100%" maxW="100%" overflowX="scroll" border="none">
        <Table w="100%" maxW="100%" border="none">
          <Thead bg={theme.medBackground} color={theme.text}>
            <Tr>
              <Th>Account</Th>
              <Th>Debit</Th>
              <Th>Credit</Th>
              <Th>Account</Th>
              {/* FIXME: hack to get this working */}
              {/* {!showOnlyEssentials && <Th>Tags</Th>} */}
              {showDate && <Th>Date</Th>}
              {showTransaction && <Th>Txn</Th>}
              {showDuplicateButton && <Th />}
            </Tr>
          </Thead>

          <Tbody border="none" w="100%">
            {entriesList.map((e) => (
              <LedgerEntryRow entry={e} key={e.id} />
            ))}
          </Tbody>

          <Tfoot paddingBottom="0">
            <Tr
              paddingBottom="0"
              paddingTop="0"
              color={theme.text}
              bg={theme.medBackground}
            >
              <Td borderBottom="0" fontSize="xs">
                SUM
              </Td>
              <Td borderBottom="0" fontSize="xs">
                {!totalDebits.isZero() && totalDebits.toFormat()}
              </Td>
              <Td borderBottom="0" fontSize="xs">
                {!totalCredits.isZero() && totalCredits.toFormat()}
              </Td>
              <Td />
              {showDate && <Td />}
              {showTransaction && <Td />}
              {showDuplicateButton && <Td />}
            </Tr>
          </Tfoot>
        </Table>
      </Box>
    </Box>
  );
};

const LedgerEntryRow = ({ entry }: { entry: BaseLedgerEntryFields }) => {
  const theme = useTheme();
  const assetName =
    entry.asset?.type === AssetTypeEnum.Nft
      ? entry.asset?.name
      : entry.asset?.symbol || "";

  const amount = new BigNumber(entry.amount);
  const isCredit = entry.direction === LedgerEntryDirectionEnum.Credit;

  return (
    <Tr borderBottom={`1px solid ${theme.border}`} bg={theme.background}>
      <Td color={theme.text} borderBottom="0" fontSize="xs">
        {entry.ledgerAccount?.name}
        {assetName ? ` (${assetName})` : ""}{" "}
        <Copy
          containerStyle={{ display: "inline-block" }}
          value={entry.ledgerAccount?.id || ""}
        />
      </Td>
      <Td color={theme.text} borderBottom="0" fontSize="xs">
        {!isCredit && D(entry.fiatAmount, entry.fiatCurrency).toFormat()}{" "}
        {!isCredit && amount.gt(0) ? `(${amount.toFormat(10)})` : ``}
      </Td>
      <Td color={theme.text} borderBottom="0" fontSize="xs">
        {isCredit && D(entry.fiatAmount, entry.fiatCurrency).toFormat()}{" "}
        {isCredit && amount.gt(0) ? `(${amount.toFormat(10)})` : ``}
      </Td>
      <Td
        color={theme.text}
        borderBottom="0"
        fontSize="xs"
        onClick={() => {
          console.log(entry?.account);
        }}
      >
        <Tooltip label={entry.account?.description || ""}>
          {truncate(entry.account?.description || "No Account", { length: 15 })}
        </Tooltip>
      </Td>
    </Tr>
  );
};

const _sum = (
  entries: BaseLedgerEntryFullFields[],
  currency: CurrencyCodeEnum
): Dinero.Dinero => {
  return entries.reduce(
    (acc, e) => acc.add(D(Math.abs(e.fiatAmount), currency)),
    D(0, currency)
  );
};

const _checkEntries = (ledgerEntries: BaseLedgerEntryFullFields[]) => {
  const currency = (ledgerEntries || [])[0]?.fiatCurrency ?? "USD";

  const [debits, credits] = partition(
    (e) => e.direction === LedgerEntryDirectionEnum.Debit,
    ledgerEntries
  );

  const totalDebits = _sum(debits, currency);
  const totalCredits = _sum(credits, currency);

  return {
    isValid: totalDebits.subtract(totalCredits).isZero(),
    totalDebits,
    totalCredits,
  };
};
