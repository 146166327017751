import { useMutation } from "@apollo/client";
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { BaseClientFields } from "src/api/fragments";
import { Modal } from "src/components/Modal";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  client?: Maybe<BaseClientFields>;
};

function _AffiliateModal({ handleHide, show: isVisible, client }: Props) {
  console.log(client);
  const [referredDiscountPercentage, setReferredDiscountPercentage] = useState(
    client?.referredDiscountPercentage ?? 0
  );
  const [referredCredit, setReferredCredit] = useState(
    !isNil(client?.referredCreditCents)
      ? (client?.referredCreditCents ?? 0) / 100
      : 25
  );
  const [referralPercentage, setReferralPercentage] = useState(
    client?.referralPercentage || 0
  );

  const [updateClientAffiliate, { loading }] = useMutation(
    api.clients.updateClientAffiliate
  );
  const toast = useMyToast();
  const theme = useTheme();

  const handleSubmit = async () => {
    try {
      if (!client) {
        return;
      }

      await updateClientAffiliate({
        variables: {
          clientId: client.id,
          referredDiscountPercentage: referredDiscountPercentage || null,
          referredCreditCents: (referredCredit ?? 0) * 100 || null,
          referralPercentage: referralPercentage || null,
        },
        refetchQueries: [api.clients.retrieve],
      });

      toast.show({
        message: "Affiliate settings updated successfully",
        status: "success",
      });
      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title="Affiliate Settings"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Stack spacing={4}>
          <FormControl>
            <FormLabel style={{ color: theme.text }}>
              Referred Discount Percentage
            </FormLabel>
            <Text marginBottom="10px" fontSize="xs" color={theme.text}>
              Ex. if someone signs up with their referral link, they get a 10%
              off.
            </Text>
            <NumberInput
              value={referredDiscountPercentage}
              onChange={(_, value) => setReferredDiscountPercentage(value)}
              min={0}
              max={100}
            >
              <NumberInputField style={{ color: theme.header }} />
            </NumberInput>
          </FormControl>

          <FormControl>
            <FormLabel style={{ color: theme.text }}>Referred Credit</FormLabel>
            <Text marginBottom="10px" fontSize="xs" color={theme.text}>
              Ex. if someone signs up with their referral link, they get $50
              credits. Note: if discount percentage is set above, then this is
              not used and the discount percentage is.
            </Text>
            <NumberInput
              value={referredCredit}
              onChange={(_, value) => setReferredCredit(value)}
              min={0}
            >
              <NumberInputField style={{ color: theme.header }} />
            </NumberInput>
          </FormControl>

          <FormControl>
            <FormLabel style={{ color: theme.text }}>
              Referral Percentage
            </FormLabel>
            <Text marginBottom="10px" fontSize="xs" color={theme.text}>
              Ex. if someone signs up with their referral link, you get 10% of
              their revshare.
            </Text>
            <NumberInput
              value={referralPercentage}
              onChange={(_, value) => setReferralPercentage(value)}
              min={0}
              max={100}
            >
              <NumberInputField style={{ color: theme.header }} />
            </NumberInput>
          </FormControl>
        </Stack>
      </Container>
    </Modal>
  );
}

export const AffiliateModal = connectModal({
  name: "AffiliateModal",
})(_AffiliateModal);
