import { Box, Button, Flex, Text, useClipboard } from "@chakra-ui/react";
import { connectModal, InjectedProps } from "redux-modal";
import { Modal } from "src/components/Modal";
import { useMyToast } from "src/hooks/useMyToast";
import { useTheme } from "src/hooks/useTheme";

interface RawTransactionModalProps extends InjectedProps {
  rawData: any;
  transactionId: string;
}

const _RawTransactionModal = ({
  handleHide,
  show: isVisible,
  rawData,
  transactionId,
}: RawTransactionModalProps) => {
  const { header, background, border, text } = useTheme();
  const toast = useMyToast();
  const formattedJson = JSON.stringify(rawData, null, 2);
  const { hasCopied, onCopy } = useClipboard(formattedJson);

  if (!rawData) {
    return null;
  }

  return (
    <Modal
      title={`Raw Transaction Data (${transactionId.substring(0, 8)}...)`}
      isVisible={isVisible}
      handleHide={handleHide}
      minW="85%"
    >
      <Box>
        <Flex justifyContent="flex-end" mb={3}>
          <Button size="sm" onClick={onCopy} colorScheme="blue">
            {hasCopied ? "Copied!" : "Copy to Clipboard"}
          </Button>
        </Flex>

        <Box
          overflowY="auto"
          maxHeight="70vh"
          p={4}
          borderRadius="md"
          border={`1px solid ${border}`}
          bg={background}
        >
          <pre
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              color: text,
              fontSize: "14px",
            }}
          >
            {formattedJson}
          </pre>
        </Box>

        <Text fontSize="sm" mt={4} color={text}>
          This is the raw transaction data as stored in the database. It may
          contain internal fields and structures.
        </Text>
      </Box>
    </Modal>
  );
};

export const RawTransactionModal = connectModal({
  name: "RawTransactionModal",
})(_RawTransactionModal);
