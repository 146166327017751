import { useMutation, useQuery } from "@apollo/client";
import { Box, Heading, Image, Text, useToast } from "@chakra-ui/react";
import _ from "lodash";
import { noop } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { compose } from "redux";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseClientFields } from "src/api/fragments";
import NoPic from "src/assets/awaken/icons/profile.png";
import PlusImage from "src/assets/awaken/plus.png";
import { NavBarPageTemplate } from "src/components/layouts/PageTemplate";
import { AddClientModal } from "src/components/modals/AddClientModal";
import { MyToast } from "src/components/MyToast";
import WhiteBox from "src/components/styled/WhiteBox";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  success,
  UnexpectedError,
} from "src/core";
import { useMe } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import Loading from "src/views/Loading";

function Dashboard() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const _onAddClient = () => _showModal("AddClientModal");

  const { me, loadingMe } = useMe();

  // API hooks
  // Mutations
  const [setActiveClient] = useMutation(api.users.clients.setActive);

  // Queries
  const {
    data: _clients,
    error,
    loading: loadingClients,
  } = useQuery<{
    getMyClients?: BaseClientFields[];
  }>(api.clients.list, {
    fetchPolicy: "cache-and-network",
  });

  const {
    data: activeClient,
    error: activeClientError,
    loading: loadingActiveClient,
  } = useQuery<{
    getMyActiveClient?: BaseClientFields;
  }>(api.users.clients.getActive, {
    fetchPolicy: "network-only",
  });

  // Functions
  const _setActiveClient = useCallback(
    async (
      clientId: string
    ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
      try {
        await setActiveClient({
          variables: {
            clientId,
          },
          refetchQueries: [api.users.clients.getActive],
        });
        return success(null);
      } catch (err) {
        return failure(new UnexpectedError(err));
      }
    },
    []
  );

  const _onChangeSelect = useCallback(
    async (client: BaseClientFields) => {
      const response = await _setActiveClient(client.id);

      if (response.isFailure()) {
        return toast({
          position: "top",
          render: () => (
            <MyToast message={response.error.message} status="error" />
          ),
        });
      }

      return navigate(`/clients/${client.id}/dashboard`);
    },
    [_setActiveClient]
  );

  const clients = useMemo(
    () => _.orderBy(_clients?.getMyClients || [], ["createdAt"], ["desc"]),
    [_clients]
  );

  const firstName = me?.name?.split(" ")[0];
  const isLoading = loadingMe || loadingClients;
  const theme = useTheme();

  if (loadingActiveClient) {
    return null;
  }

  const activeClientId = activeClient?.getMyActiveClient?.id;

  if (activeClientId) {
    return <Navigate to={`/clients/${activeClientId}`} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  // hacky solution to weird bug
  if (error && error.message === "Not logged in.") {
    return <Navigate to="/login" />;
  }

  return (
    <NavBarPageTemplate
      overflow="scroll"
      button="logout"
      bgColor={colors.white}
    >
      <AddClientModal />
      <Box maxW="60rem" margin="auto">
        <Heading size="xl">Welcome, {firstName} 👋!</Heading>
        {error ? (
          <Heading>Error: {error.message}</Heading>
        ) : (
          <>
            <Heading size="lg">Clients</Heading>
            <Box display="flex" flexWrap="wrap" marginBottom="10rem">
              <Client
                name="Add Client"
                src={PlusImage}
                onClick={isLoading ? undefined : _onAddClient}
              />
              {clients.map((c) => (
                <Client
                  isLoading={loadingClients}
                  onClick={() => _onChangeSelect(c)}
                  key={c.id}
                  name={c.name}
                  src={NoPic}
                />
              ))}
            </Box>
          </>
        )}
      </Box>
    </NavBarPageTemplate>
  );
}

type ClientProps = {
  name: string;
  src: string;
  onClick?: () => void;
  isLoading?: boolean;
};

function Client({ isLoading, name, src, onClick }: ClientProps) {
  return (
    <WhiteBox
      display="flex"
      w="15rem"
      alignItems="center"
      cursor="pointer"
      marginRight="1rem"
      marginBottom="1rem"
      onClick={isLoading ? noop : onClick}
      border={other.boxBorder}
    >
      <Image src={src} w="3rem" h="3rem" bgColor="red" borderRadius="100%" />
      <Text paddingLeft="1rem">{name}</Text>
    </WhiteBox>
  );
}

export default Dashboard;
