// ... existing imports ...

import { Box, HStack, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion"; // Add this import at the top
import moment from "moment";
import { Resizable } from "re-resizable";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { BaseClientFields } from "src/api/fragments";
import { useClientById } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { Touchable } from "../Touchable";
import { Button, Textarea } from "../styled";

// Add these new types
type MessageType = "user" | "assistant" | "action";
type ActionType = "label" | "code_import";

interface MessageStep {
  icon: string;
  title: string;
  subtitle?: string;
  status: "loading" | "complete" | "error";
}

interface Message {
  id: string;
  type: MessageType;
  actionType: string | ActionType;
  content: string | null;
  timestamp: Date;
  steps?: MessageStep[];
}

const MIN_WIDTH = 300;
const MAX_WIDTH = 600;
const DEFAULT_WIDTH = 400;

export const AgentSidebar = () => {
  const { clientId } = useParams();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const [messages, setMessages] = useState<Message[]>([
    {
      id: "2",
      type: "assistant",
      actionType: "assistant",
      content: "What are the top yield opportunities for my JITO?",
      timestamp: new Date(),
      steps: [
        {
          icon: "fa-magnifying-glass",
          title: "Finding top liquidity pools",
          subtitle: "Checking across 1,000 pools",
          status: "complete",
        },
        {
          icon: "fa-list",
          title: "Building transactions",
          status: "complete",
        },
      ],
    },
    {
      id: "2",
      type: "assistant",
      actionType: "assistant",
      content:
        "Looks like you may have a lot of spam on Base, let's help with that.",
      timestamp: new Date(),
      steps: [
        {
          icon: "fa-magnifying-glass",
          title: "Finding spam transactions",
          status: "complete",
        },
        {
          icon: "fa-list",
          title: "Found spam transactions",
          subtitle: "108 transactions identified as spam",
          status: "complete",
        },
        {
          icon: "fa-tag",
          title: "Labeling transactions",
          subtitle: "Labeled all 108 transactions",
          status: "loading",
        },
      ],
    },
    {
      id: "3",
      type: "user",
      actionType: "label",
      content:
        "I cannot figure out how to import my CoinEx transactions. Can you help me?",
      timestamp: new Date(),
    },
    {
      id: "3",
      type: "assistant",
      actionType: "assistant",
      content: null,
      steps: [
        {
          icon: "fa-magnifying-glass",
          title: "Researching CoinEx API",
          status: "complete",
        },
        {
          icon: "fa-code",
          title: "Writing code to import CoinEx transactions",
          status: "complete",
        },
        {
          icon: "fa-satellite-dish",
          title: "Importing CoinEx transactions",
          status: "complete",
        },
      ],
      timestamp: new Date(),
    },
  ]);

  const [showSidebar, setShowSidebar] = useState(true);
  const isLarge = useIsLargeScreen();
  const isOpen = true;
  const {
    background,
    medBackground,
    header,
    border,
    theme,
    text,
    secondaryBackground,
  } = useTheme();

  // Add width state
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const [isVisible, setIsVisible] = useState(false);

  const FloatingButton = (
    <Box
      position="fixed"
      bottom="30px"
      right="30px"
      width="75px"
      height="75px"
      borderRadius="full"
      background={`linear-gradient(to bottom right, ${colors.primary}, ${colors.lightBlue70})`}
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={() => setIsVisible(true)}
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
      _hover={{ transform: "scale(1.1)" }}
      _active={{ transform: "scale(0.95)" }}
      transition="all 0.2s"
    >
      <Image
        src={require("src/assets/awaken/logos/awake-white-rings.png")}
        alt="Awaken Logo"
        width="60px"
        height="60px"
      />
    </Box>
  );

  const SidebarComponent = (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: width }}
      exit={{ width: 0 }}
      transition={{ type: "spring", damping: 20, stiffness: 100 }}
      style={{
        overflow: "hidden",
        position: "fixed",
        right: 0,
        top: 0,
        height: "100%",
      }}
    >
      <Box width={width}>
        <Resizable
          enable={{ left: isLarge }}
          minWidth={MIN_WIDTH}
          maxWidth={MAX_WIDTH}
          size={{
            width: width,
            height: "100%",
          }}
          onResizeStop={(e, direction, ref, d) => {
            setWidth(Math.min(Math.max(width + d.width, MIN_WIDTH), MAX_WIDTH));
          }}
          handleStyles={{
            left: {
              width: "2px",
              left: "0px",
              cursor: "ew-resize",
              position: "absolute",
              height: "100%",
              backgroundColor: border,
            },
          }}
          handleClasses={{
            left: "resize-handle-left",
          }}
        >
          <Box
            display="flex"
            flexDir="column"
            justifyContent="space-between"
            w="100%"
            h="100vh"
            position="relative"
            style={{
              borderLeft: `1px solid ${border}`,
              backgroundColor: theme === "light" ? background : medBackground,
            }}
          >
            {/* Header */}
            <HStack
              justifyContent="space-between"
              borderBottom={`1px solid ${border}`}
              style={{
                padding: 15,
              }}
            >
              <i
                className="fa-sharp fa-microchip"
                style={{ color: header, fontSize: 18 }}
              />

              <Text
                style={{
                  color: header,
                  fontSize: 20,
                  flex: 1,
                  textAlign: "left",
                  fontWeight: 800,
                  fontStretch: "extra-expanded",
                }}
                marginBottom="0"
              >
                Awaken <span style={{ color: colors.primary }}>AI</span>
              </Text>

              {/* <Touchable
                iconName="fa-sharp fa-plus"
                iconStyle={{ fontSize: 18 }}
              /> */}

              <Touchable
                iconStyle={{ fontSize: 20 }}
                iconName="fa-sharp fa-times"
                onClick={() => setIsVisible(false)}
              />
            </HStack>

            <Waitlist />
            {/* <Messages
              messages={messages}
              client={client}
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            /> */}
          </Box>
        </Resizable>
      </Box>
    </motion.div>
  );

  if (!isLarge) {
    return null;
  }

  return null;

  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: width }}
          exit={{ width: 0 }}
          transition={{ type: "spring", damping: 20, stiffness: 100 }}
          style={{ overflow: "hidden" }}
        >
          {SidebarComponent}
        </motion.div>
      ) : (
        <motion.div
          animate={{ scale: 1 }}
          transition={{ type: "spring", damping: 20, stiffness: 100 }}
        >
          {FloatingButton}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Waitlist = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <VStack spacing={4} p={6} align="flex-start" h="100%">
      <Text fontSize="xl" fontWeight="bold" color={theme.header}>
        Coming soon...
      </Text>

      <Text color={theme.text}>
        Our mission is to help you manage and grow your onchain wealth.
      </Text>

      <VStack spacing={4} align="flex-start" pl={0}>
        <WaitlistItem
          icon="fa-calculator"
          title="Optimize your tax strategy and savings"
          subtitle=""
        />

        <WaitlistItem
          icon="fa-wallet"
          title="Find and execute opportunities across wallets and chains"
          subtitle=""
        />

        <WaitlistItem
          icon="fa-robot"
          title="AI bookkeeper to analyze and advise on your transactions"
          subtitle=""
        />
      </VStack>

      <Box mt={4}>
        <Text color={theme.text}>
          If you have any feedback, or things you'd like to see our AI help you
          with, just let us know.
        </Text>

        <Button
          style={{
            position: "relative",
            cursor: "pointer",
            display: "inline-block",
            width: "100%",
            padding: "0.5rem 1rem",
            color: colors.white,
            marginTop: 25,
          }}
          bg={colors.primary}
          _hover={{ bg: colors.primary }}
          onClick={() => dispatch(show("FeedbackModal"))}
        >
          <i style={{ marginRight: 10 }} className="fa-sharp fa-heart" />
          Make a suggestion
        </Button>
      </Box>
    </VStack>
  );
};

const WaitlistItem = ({
  icon,
  title,
  subtitle,
}: {
  icon: string;
  title: string;
  subtitle: string;
}) => {
  const theme = useTheme();

  return (
    <HStack spacing={3} align="center">
      <Box
        w="32px"
        h="32px"
        flexShrink={0}
        borderRadius="full"
        bg={`${theme.blueBg}`}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <i className={`fa-sharp ${icon}`} style={{ color: colors.primary }} />
      </Box>
      <Text
        color={theme.text}
        style={{
          fontWeight: "normal",
        }}
      >
        {title}
      </Text>
    </HStack>
  );
};

// New MessageBubble component
const MessageBubble = ({
  message,
  client,
}: {
  message: Message;
  client: BaseClientFields | null;
}) => {
  const theme = useTheme();

  const getBubbleStyle = (type: MessageType) => {
    return {
      bg: theme.background,
      color: theme.text,
      alignSelf: "flex-start",
    };
  };

  const style = getBubbleStyle(message.type);

  return (
    <div
      style={{
        width: "100%",
        borderRadius: 10,
        background: message.type === "user" ? "transparent" : theme.background,
        padding: message.type === "user" ? 0 : 10,
        border: message.type === "user" ? "none" : `1px solid ${theme.border}`,
        paddingBottom: "15px",
        marginBottom: message.type === "user" ? 0 : "15px",
      }}
    >
      <VStack w="100%" align="flex-start">
        {message.type === "user" && (
          <HStack spacing={2}>
            <Box
              w="26px"
              h="26px"
              borderRadius="5px"
              overflow="hidden"
              backgroundImage={`url(${
                client?.profilePicture ||
                "https://ik.imagekit.io/awaken//icons/hype.png?tr=w-80,h-80"
              })`}
              backgroundSize="cover"
            />
          </HStack>
        )}

        {message.type === "assistant" && (
          <HStack spacing={2}>
            <HStack
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                color={theme.header}
                fontSize="sm"
                marginBottom="0"
                fontWeight="bold"
              >
                Awaken AI{" "}
                <span
                  style={{
                    color: theme.text,
                    fontSize: "12px",
                    fontWeight: "normal",
                    marginLeft: "0.5rem",
                  }}
                >
                  {moment(message.timestamp).format("LT")}
                </span>
              </Text>
            </HStack>
          </HStack>
        )}
        {message.content && (
          <Box
            style={{
              width: "100%",
              color: theme.text,
              marginTop: 0,
              fontWeight: "normal",
            }}
          >
            <Text
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
              fontSize="md"
            >
              {message.content}
            </Text>
          </Box>
        )}
        {message.steps && (
          <VStack w="100%" spacing={2}>
            {message.steps.map((step) => (
              <MessageStep
                icon={step.icon}
                title={step.title}
                subtitle={step.subtitle}
                status={step.status}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </div>
  );
};

const Messages = ({
  messages,
  client,
  showSidebar,
  setShowSidebar,
}: {
  messages: Message[];
  client: BaseClientFields | null;
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
}) => {
  const isLarge = useIsLargeScreen();
  const { secondaryBackground, background, medBackground, border } = useTheme();

  return (
    <>
      {/* Collapse button */}
      {isLarge && (
        <Box
          w="1.4rem"
          h="3rem"
          bgColor={secondaryBackground}
          right="-1.4rem"
          top="0.4rem"
          position="absolute"
          border={`1px solid ${border}`}
          borderRightRadius={other.borderRadius}
          cursor="pointer"
          onClick={() => setShowSidebar(!showSidebar)}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <i
            className={`fa-sharp fa-chevron-${showSidebar ? "left" : "right"}`}
            style={{ color: colors.gray3 }}
          />
        </Box>
      )}

      {/* Messages container - note the modified height to accommodate header and input */}
      <Box w="100%" flex={1} overflow="scroll" whiteSpace="nowrap">
        <VStack h="100%" w="100%" spacing={2} p={4}>
          {messages.map((message) => (
            <MessageBubble key={message.id} message={message} client={client} />
          ))}
        </VStack>
      </Box>

      {/* Input area */}
      <Box w="100%" p={4} borderTop={`1px solid ${border}`} bg={background}>
        <HStack w="100%">
          <Textarea
            containerStyle={{
              flex: 1,
            }}
            style={{
              background: medBackground,
              borderRadius: 15,
              border: `1px solid ${border}`,
              boxShadow: "none",
            }}
            placeholder="Type your message..."
            size="md"
          />
          <IconButton
            aria-label="Send message"
            icon={
              <i className="fa-sharp fa-arrow-up" style={{ color: "white" }} />
            }
            size="md"
            backgroundColor={colors.primary}
            _hover={{ backgroundColor: colors.primary }}
          />
        </HStack>
      </Box>
    </>
  );
};

const MessageStep = ({ icon, title, subtitle, status }: MessageStep) => {
  const { text, medBackground, border } = useTheme();

  return (
    <HStack
      w="100%"
      p={2}
      bg={medBackground}
      border={`1px solid ${border}`}
      borderRadius="md"
      spacing={3}
    >
      <Box color={text} w="20px" textAlign="center">
        <i className={`fa-sharp ${icon}`} />
      </Box>

      <VStack flex={1} align="flex-start" spacing={0}>
        <Text fontSize="sm" color={text} fontWeight="medium">
          {title}
        </Text>
        {subtitle && (
          <Text fontSize="xs" color={text} opacity={0.8}>
            {subtitle}
          </Text>
        )}
      </VStack>

      {status === "complete" && (
        <Box color="green.500" ml="auto">
          <i className="fa-sharp fa-check-circle" />
        </Box>
      )}
      {status === "error" && (
        <Box color="red.500" ml="auto">
          <i className="fa-sharp fa-xmark" />
        </Box>
      )}
    </HStack>
  );
};
