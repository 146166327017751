import { useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import { Time } from "lightweight-charts";
import { isNil } from "lodash";
import numbro from "numbro";
import { useEffect, useMemo } from "react";
import { api } from "src/api";
import {
  ChartPoint,
  HistoricalBalanceIntervalEnum,
  Query,
} from "src/api/generated/types";
import { Maybe, hasValue } from "src/core";
import { D } from "src/utils/helpers";
import { AwakenChartValuePoint, ChartType, TimeFrame } from "./types";

type PricingChartParams = {
  clientId: string;
  assetKeyV2: Maybe<string>;
  timeFrame: Maybe<TimeFrame>;
  chartType: ChartType;
};

export const usePriceChartV2 = ({
  clientId,
  assetKeyV2,
  timeFrame,
  chartType,
}: PricingChartParams) => {
  const variables = useMemo(
    () => ({
      clientId,
      assetPricingKey: assetKeyV2,
      interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
    }),
    [clientId, assetKeyV2, timeFrame?.type]
  );

  const {
    data: chartData,
    loading: loadingChart,
    refetch: fetchChart,
    error: chartError,
    networkStatus,
  } = useQuery<{
    getAssetKeyChartV2: Query["getAssetKeyChartV2"];
  }>(api.assets.getChartV2, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: variables,
    skip: !variables.assetPricingKey || !variables.clientId,
  });

  const {
    data: assetPriceData,
    refetch: fetchCurrentAssetPrice,
    loading: loadingAssetPrice,
    networkStatus: assetPriceNetworkStatus,
  } = useQuery<{
    getCurrentAssetPriceV2: Query["getCurrentAssetPriceV2"];
  }>(api.assets.getCurrentPriceV2, {
    fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: variables,
    skip: !variables.assetPricingKey || !variables.clientId,
  });

  const currentValue = assetPriceData?.getCurrentAssetPriceV2;
  const _chartPoints = chartData?.getAssetKeyChartV2 || [];

  const fullPoints = useMemo(() => {
    if (!_chartPoints.length) return [];

    const currentValuePoint = _getCurrentValuePoint(
      currentValue?.valueCents ?? null,
      currentValue?.timestamp ?? null
    );

    const points = [..._chartPoints, currentValuePoint]
      .filter(hasValue)
      .filter((p) => !isNil(p.value) && p.value > 0)
      .sort(_sortAsc);

    return points;
  }, [_chartPoints, currentValue || ""]);

  const _points = useMemo<AwakenChartValuePoint[]>(() => {
    const result = fullPoints.map((p): AwakenChartValuePoint => {
      const isAfterNow = new Date(p.timestamp).getTime() > Date.now();
      return {
        // convert timestamp to seconds in the specific timezone. the dates are in UTC to start
        time: getTime(p.timestamp),
        value: (isAfterNow
          ? undefined
          : getValue((p.value || 0) * 100).value ?? 0) as number,
        timestamp: p.timestamp,
        data: p,
        // costBasis: !isNil(p.costBasis) ? p.costBasis / 100 : undefined,
      };
    });
    return result;
  }, [fullPoints]);

  const fetchFullChart = () => {
    if (!assetKeyV2) return;
    if (chartType !== ChartType.Price) return; // don't call unless we are fetching pricing data

    fetchChart({
      clientId,
      assetPricingKey: assetKeyV2,
      interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
    }).then(() =>
      fetchCurrentAssetPrice({
        clientId,
        assetPricingKey: assetKeyV2,
      })
    );
  };

  useEffect(
    () => fetchFullChart(),
    [clientId, timeFrame?.type, assetKeyV2, chartType]
  );
  const currentPrice = assetPriceData?.getCurrentAssetPriceV2;

  return {
    points: _points,
    currentValue: _getCurrentValuePoint(
      currentPrice?.valueCents ?? null,
      currentPrice?.timestamp ?? null
    ),
    loading:
      (loadingChart && !chartData) || (loadingAssetPrice && !assetPriceData),
    fetchFullChart, // chart + current point
    fetchChart,
    fetchCurrentAssetPrice,
  };
};

export const getValue = (valueCents: number) => {
  const isSmallNumber = new BigNumber(valueCents).lte(500);

  const value = isSmallNumber
    ? parseFloat(numbro(valueCents).divide(100).format("0,0.[0000000000]"))
    : D(valueCents).toUnit();

  return { value, isSmallNumber };
};

export const getValueFormatted = (valueCents: number) => {
  const { value, isSmallNumber } = getValue(valueCents);
  if (isSmallNumber) return `$${numbro(value).format("0,0.[0000000000]")}`;
  return D(value * 100).toFormat("$0,0.00");
};

const _getCurrentValuePoint = (
  currentValueCents: Maybe<number>,
  timestamp: Maybe<string | Date>
) => {
  if (isNil(currentValueCents)) return null;
  if (isNil(timestamp)) return null;

  const { isSmallNumber, value } = getValue(currentValueCents ?? 0);

  return {
    timestamp: new Date(timestamp),
    value: value,
  };
};

const _sortAsc = (
  a: Pick<ChartPoint, "timestamp">,
  b: Pick<ChartPoint, "timestamp">
) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();

export const getTime = (ts: Date) =>
  Math.floor(new Date(ts).getTime() / 1000) as Time;
