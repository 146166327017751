import { useMutation } from "@apollo/client";
import { Box, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { api } from "src/api";
import { MutationSubmitFeedbackArgs } from "src/api/generated/types";
import { useClientById, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

function Agents() {
  const theme = useTheme();
  const { clientId } = useParams();
  const dispatch = useDispatch();

  const toast = useMyToast();
  const [submitFeedback] = useMutation(api.contact.feedback);

  const { client } = useClientById(clientId || "", {
    onlyFetchClient: true,
    clientFetchPolicy: "cache-first",
  });

  const value = `https://awaken.tax?ref=${client?.referralCode}`;
  const isLarge = useIsLargeScreen();
  const _submitFeedback = async () => {
    const variables: MutationSubmitFeedbackArgs = {
      feedback: "I'm interested in crypto news.",
    };

    await submitFeedback({
      variables,
    });

    toast.show({
      message: "Joined waitlist! Thanks for your interest 🙏",
      status: "success",
    });
  };

  return (
    <Box
      maxWidth={"inherit"}
      bg={theme.background}
      // style={{
      //   padding: "1rem 2.5rem",
      // }}
    >
      <Box w="100%" paddingTop="1rem">
        {/* <FreeCreditBadge /> */}

        <HStack alignItems="center" padding="0.5rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading
              style={{
                fontStretch: "extra-expanded",
                fontWeight: "700",
              }}
              color={theme.header}
              margin="0"
              size="lg"
            >
              The agents are coming... <i className="fa-solid fa-robot" />
            </Heading>
          </Flex>
        </HStack>

        <div style={{ marginTop: 10 }}>
          <Text
            style={{
              color: theme.text,
              maxWidth: 700,
              fontSize: 18,
              fontWeight: "medium",
              marginBottom: 15,
            }}
          >
            Awaken will build agents to help you make the most of your crypto.
            Tax help, financial planning, crypto research, and more. AI to help
            you grow your crypto and stay compliant at the same time.
          </Text>
          <br />
          <Text
            style={{
              color: theme.text,
              maxWidth: 700,
              fontWeight: "medium",
              fontSize: 18,
              marginBottom: 15,
            }}
          >
            If you're excited about this,{" "}
            <span
              onClick={() => {
                const referralLink = `https://twitter.com/intent/tweet?text=@AwakenTax is building AI agents to do your crypto taxes. It's coming soon, so make an account now. \n\nUse my link ${value} to get $25 of free credit!`;

                // open twitter and tweet about it tagging @AwakenTax
                window.open(referralLink, "_blank");
              }}
              style={{
                color: colors.primary,
                cursor: "pointer",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              tweet about it
            </span>{" "}
            and tag us! We also give Awaken away for free to one person a month
            who shouts us out on X, so that could be you!
          </Text>
        </div>

        <br />

        <Link
          to={`/clients/${clientId}/awaiken`}
          style={{
            width: isLarge ? "auto" : "100%",
          }}
        >
          <Box
            border={`1px solid ${theme.border}`}
            borderRadius="md"
            padding="0.5rem 1rem"
            cursor="pointer"
            bg={theme.medBackground}
            color={theme.header}
            style={{
              marginTop: isLarge ? 0 : 15,
              display: "flex",
              border: `1px solid ${theme.border}`,
              alignItems: "center",
              justifyContent: isLarge ? "flex-start" : "center",
            }}
          >
            <i
              style={{
                color: colors.primary,
                fontSize: 24,
                marginRight: 10,
              }}
              className="fa-sharp fa-microchip-ai"
            />

            <VStack style={{ flex: 1, alignItems: "flex-start" }}>
              <Text
                style={{ textAlign: "left", flex: 1 }}
                color={theme.header}
                fontWeight="bold"
              >
                Try Awaken AI
              </Text>
              <Text marginTop={"0 !important"} color={theme.text} fontSize="sm">
                Have AI help you format CSVs or answer questions.
              </Text>
            </VStack>

            <i
              style={{
                marginLeft: 15,
              }}
              className="fa-sharp fa-chevron-right"
            />
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default Agents;
