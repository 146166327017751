import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { connectModal, InjectedProps } from "redux-modal";
import { Modal } from "src/components/Modal";
import { Button } from "src/components/styled/Button";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

const _TelegramModal = ({ handleHide, show: isVisible }: InjectedProps) => {
  const { text, header, background } = useTheme();

  const telegramUrl = "https://t.me/awakenpro";

  return (
    <Modal
      title="Join Our Telegram"
      isVisible={isVisible}
      handleHide={handleHide}
    >
      <VStack spacing={4} align="stretch">
        <Text fontSize="md" color={text}>
          Join our Telegram channel to stay up to date with the latest
          announcements for Awaken's portfolio and tax products!
        </Text>

        <Text fontSize="md" color={text}>
          We regularly share:
        </Text>

        <VStack align="start" pl={0} spacing={2}>
          <HStack align="start" spacing={2}>
            <Box color={text} pt="3px">
              <i
                className="fas fa-check-circle"
                style={{ fontSize: "14px" }}
              ></i>
            </Box>
            <Text fontSize="md" color={text}>
              Product updates and new features
            </Text>
          </HStack>
          <HStack align="start" spacing={2}>
            <Box color={text} pt="3px">
              <i
                className="fas fa-check-circle"
                style={{ fontSize: "14px" }}
              ></i>
            </Box>
            <Text fontSize="md" color={text}>
              Monthly rewards for engaging with us on X
            </Text>
          </HStack>
          <HStack align="start" spacing={2}>
            <Box color={text} pt="3px">
              <i
                className="fas fa-check-circle"
                style={{ fontSize: "14px" }}
              ></i>
            </Box>
            <Text fontSize="md" color={text}>
              Early access to new features
            </Text>
          </HStack>
        </VStack>

        <Box pt={2}>
          <a
            href={telegramUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: "100%" }}
          >
            <Button
              w="100%"
              leftIcon={
                <i
                  className="fab fa-telegram-plane"
                  style={{ color: colors.white }}
                ></i>
              }
              style={{
                background: `linear-gradient(45deg, #00a1ff 0%, #00e5ff 100%)`,
              }}
              color={colors.white}
              fontSize="16px"
              height="55px"
              padding="20px"
              borderRadius="8px"
              margin="5px 0"
            >
              Join Telegram Channel
            </Button>
          </a>
        </Box>
      </VStack>
    </Modal>
  );
};

export const TelegramModal = connectModal({
  name: "TelegramModal",
})(_TelegramModal);
