import { FetchPolicy, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  ActiveSubscriptionDiscountCalculation,
  DiscountInfo,
  Query,
  QueryGetEligibleDiscountsForTaxYearArgs,
  QueryGetMyActiveSubscriptionArgs,
  Subscription,
} from "src/api/generated/types";
import { CURRENT_TAX_YEAR } from "src/config";
import { Maybe } from "src/core";

export type UseActiveSubscriptionResponse = {
  totalAvailable: number;
  totalUsed: Maybe<number>;
  canDownload: boolean;
  hasSubscription: boolean;
  hasUnlimitedCX: boolean;
  startPeriod: Maybe<Date>;
  endPeriod: Maybe<Date>;
  subscription: Maybe<Subscription>;
  availableDiscounts: DiscountInfo[];
  loading: boolean;
  discountCalculation: Maybe<ActiveSubscriptionDiscountCalculation>;
};

export const useActiveSubscription = (
  clientId: string,
  cachePolicy: FetchPolicy = "no-cache"
): UseActiveSubscriptionResponse => {
  const { data, loading: loadingSubscription } = useQuery<
    Pick<Query, "getMyActiveSubscription">,
    QueryGetMyActiveSubscriptionArgs
  >(api.subscriptions.active, {
    variables: { clientId },
    fetchPolicy: cachePolicy,
    notifyOnNetworkStatusChange: true,
    skip: !clientId,
  });

  const { data: discountsData, loading: loadingDiscounts } = useQuery<
    Pick<Query, "getEligibleDiscountsForTaxYear">,
    QueryGetEligibleDiscountsForTaxYearArgs
  >(api.checkouts.discounts, {
    variables: { clientId, taxYear: CURRENT_TAX_YEAR },
    fetchPolicy: cachePolicy,
    skip: !clientId,
  });

  const hasPaidForUnlimitedCX =
    data?.getMyActiveSubscription?.hasPaidForUnlimitedCX ?? false;

  if (!data?.getMyActiveSubscription) {
    return {
      totalAvailable: 0,
      loading: loadingSubscription,
      hasUnlimitedCX: false,
      totalUsed: null,
      canDownload: false,
      hasSubscription: false,
      subscription: null,
      startPeriod: null,
      endPeriod: null,
      availableDiscounts: discountsData?.getEligibleDiscountsForTaxYear ?? [],
      discountCalculation: null,
    };
  }

  const {
    maxAllowedForTaxYear,
    currentCountForTaxYear,
    hasSubscription,
    subscription,
  } = data.getMyActiveSubscription;

  const hasEnough =
    (currentCountForTaxYear || 0) <= (maxAllowedForTaxYear || 0);

  return {
    totalAvailable: maxAllowedForTaxYear || 0,
    totalUsed: currentCountForTaxYear,
    loading: loadingSubscription && !data,
    hasUnlimitedCX: hasPaidForUnlimitedCX,
    canDownload:
      currentCountForTaxYear >= 0 && hasEnough && hasSubscription === true,
    hasSubscription,
    subscription: subscription || null,
    startPeriod: subscription?.startPeriod || null,
    endPeriod: subscription?.endPeriod || null,
    availableDiscounts: discountsData?.getEligibleDiscountsForTaxYear ?? [],
    discountCalculation: data.getMyActiveSubscription.discountCalculation,
  };
};
