import { useMutation } from "@apollo/client";
import { Box, Divider, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api, apolloClient } from "src/api";
import {
  MutationClaimPartnershipDiscountArgs,
  PartnershipTypeEnum,
} from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Button } from "src/components/styled/Button";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

declare global {
  interface Window {
    phantom?: {
      solana?: {
        connect(): Promise<void>;
        signMessage(message: Uint8Array, encoding: string): Promise<any>;
      };
    };
  }
}

type PartnershipInfo = {
  type: PartnershipTypeEnum;
  name: string;
  iconImageUrl: string;
  body?: string;
  backgroundImageUrl: string;
  hasClaimed?: boolean;
};

const _PartnershipDiscountModal = ({
  handleHide,
  show: isVisible,
  partnership,
  title,
}: InjectedProps & { partnership?: PartnershipInfo; title?: string }) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { text, header, background, border } = useTheme();
  const dispatch = useDispatch();
  const toast = useMyToast();
  const [isLoading, setIsLoading] = useState(false);
  const [claimPartnershipDiscount] = useMutation(
    api.users.clients.discounts.partnership.claim
  );
  const isPhantom = partnership?.type === PartnershipTypeEnum.Phantom;

  const handleClaimDiscount = async () => {
    try {
      if (!partnership) {
        return;
      }

      setIsLoading(true);

      const variables: MutationClaimPartnershipDiscountArgs = {
        clientId: clientId || "",
        partnershipType: partnership.type,
      };

      await claimPartnershipDiscount({
        variables,
      });

      apolloClient.refetchQueries({
        include: [
          api.clients.retrieve,
          api.partnershipDiscounts.list,
          api.subscriptions.active,
        ],
      });

      toast.show({
        status: "success",
        message: "Discount successfully claimed!",
      });
      handleHide();
    } catch (error) {
      console.error("Error claiming partnership discount:", error);
      toast.show({
        status: "error",
        message:
          (error as any)?.message ||
          "Failed to claim discount. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhantomSignAndClaim = async () => {
    try {
      if (!partnership || !window.phantom?.solana) {
        toast.show({
          status: "error",
          message: "Phantom wallet not found. Please install Phantom wallet.",
        });
        return;
      }

      setIsLoading(true);

      // Request Phantom wallet connection if not connected
      const { solana } = window.phantom;
      await solana.connect();

      // Create message to sign
      const message = new TextEncoder().encode(
        `Claiming Awaken x Phantom partnership discount for client: ${clientId}`
      );

      // Request signature
      const signedMessage = await solana.signMessage(message, "utf8");

      if (!signedMessage) {
        throw new Error("Failed to sign message with Phantom wallet");
      }

      const signatureString = Buffer.from(signedMessage.signature).toString(
        "base64"
      );

      // If signature successful, proceed with claiming discount
      const variables: MutationClaimPartnershipDiscountArgs = {
        clientId: clientId || "",
        partnershipType: partnership.type,
        signature: signatureString,
      };

      await claimPartnershipDiscount({
        variables,
      });

      void apolloClient.refetchQueries({
        include: [
          api.clients.retrieve,
          api.subscriptions.active,
          api.partnershipDiscounts.list,
        ],
      });

      toast.show({
        status: "success",
        message: "Phantom discount successfully claimed!",
      });
      handleHide();
    } catch (error) {
      console.error("Error claiming Phantom partnership discount:", error);
      toast.show({
        status: "error",
        message:
          (error as any)?.message ||
          "Failed to claim discount. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={title || "Partnership Offer"}
      isVisible={isVisible}
      handleHide={handleHide}
    >
      <VStack spacing={4} align="stretch">
        <Box
          borderRadius="lg"
          overflow="hidden"
          position="relative"
          height="120px"
          mb={2}
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            backgroundImage={`url(${partnership?.backgroundImageUrl})`}
            backgroundSize="cover"
            backgroundPosition="center"
            zIndex={0}
          />

          <Box
            position="absolute"
            top={0}
            zIndex={1}
            left={0}
            right={0}
            bottom={0}
            bg={`rgba(0,0,0,0.6)`}
          />

          <HStack
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            justify="center"
            align="center"
            spacing={4}
            px={4}
          >
            <Image
              style={{
                borderRadius: 20,
                transform: "rotate(-10deg)",
                position: "relative",
                left: 10,
                zIndex: 2,
                border: `2px solid white`,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              }}
              src={require("src/assets/awaken/logos/black-icon.png")}
              alt="Awaken Logo"
              height="60px"
            />
            {partnership?.iconImageUrl ? (
              <Image
                style={{
                  borderRadius: 20,
                  transform: "rotate(10deg)",
                  position: "relative",
                  right: 10,
                  zIndex: 1,
                  border: `2px solid white`,
                }}
                src={partnership.iconImageUrl}
                alt={`${partnership.name} Logo`}
                height="60px"
              />
            ) : (
              <Box
                height="60px"
                width="60px"
                bg={colors.primary}
                color={colors.white}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  borderRadius: 20,
                  transform: "rotate(10deg)",
                  position: "relative",
                  right: 10,
                  zIndex: 1,
                  fontSize: 28,
                  fontWeight: "bold",
                  border: `2px solid white`,
                }}
              >
                {partnership?.name.charAt(0).toUpperCase()}
              </Box>
            )}
          </HStack>
        </Box>

        <Text fontSize="md" color={text}>
          {partnership?.body ||
            `As part of our special partnership with ${partnership?.name}, you're eligible for an exclusive discount`}{" "}
          <i
            className="fa-sharp fa-crown"
            style={{
              color: "#FFD700",
            }}
          ></i>
        </Text>

        {partnership?.type === PartnershipTypeEnum.Phantom && (
          <Text fontSize="md" color={text}>
            To celebrate, we are{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              making all Solana wallets + up to 1k transactions on other chains
              free
            </span>
            . So claim it and tell a friend{" "}
            <i
              className="fa-sharp fa-heart"
              style={{
                color: colors.pink50,
              }}
            ></i>
          </Text>
        )}
        {partnership?.type === PartnershipTypeEnum.Phantom && (
          <Text fontSize="md" color={text}>
            We'll have you sign a message just to prove you have a Phantom
            wallet. This will not transfer any assets nor cost you any gas.
          </Text>
        )}

        {!partnership?.hasClaimed && (
          <Box pt={4}>
            <Button
              w="100%"
              style={{
                background: isPhantom
                  ? "#A699EA"
                  : `linear-gradient(45deg, ${colors.primary} 0%, ${colors.lightBlue80} 100%)`,
              }}
              color={colors.white}
              fontSize="16px"
              height="55px"
              padding="20px"
              borderRadius="8px"
              margin="5px 0"
              isLoading={isLoading}
              onClick={
                partnership?.type === PartnershipTypeEnum.Phantom
                  ? handlePhantomSignAndClaim
                  : handleClaimDiscount
              }
            >
              Claim Your Discount{" "}
              <i
                style={{ marginLeft: 10, position: "relative", top: -2 }}
                className="fa-solid fa-gift"
              ></i>
            </Button>
          </Box>
        )}

        {partnership?.hasClaimed && (
          <>
            <Divider
              style={{ marginTop: 25, borderColor: border, borderWidth: 1 }}
            />
            <Text style={{ marginTop: 25 }} fontSize="md" color={text}>
              You've already claimed your discount. Make sure you tell a friend
              so they can get it too{" "}
              <i
                className="fa-sharp fa-check-circle"
                style={{ marginLeft: 2, color: colors.positive, zIndex: 2 }}
              ></i>
            </Text>
          </>
        )}

        <br />
      </VStack>
    </Modal>
  );
};

export const PartnershipDiscountModal = connectModal({
  name: "PartnershipDiscountModal",
})(_PartnershipDiscountModal);
