import {
  Box,
  Button,
  Center,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  HStack,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { Dictionary, isBoolean, isNil, orderBy, truncate, uniq } from "lodash";
import { compose, keyBy } from "lodash/fp";
import { DateTime } from "luxon";
import moment from "moment";
import numbro from "numbro";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  BaseAccountFields,
  BaseAssetFields,
  BaseSimpleTransactionFields,
  PartialAssetFields,
} from "src/api/fragments";
import {
  AccountTypeEnum,
  AssetTypeEnum,
  NumTxnsResponse,
  Query,
  TaxYearBracket,
} from "src/api/generated/types";
import {
  ActionSheet,
  AwakenTooltip,
  Input,
  Option,
  Select,
} from "src/components";
import { INTEGRATIONS } from "src/components/modals/AccountModal/constants";
import { FilterTxnsModal } from "src/components/modals/FilterModal";
import { AssetIcon } from "src/components/styled/Assets";
import { RecalculateButton } from "src/components/styled/RecalculateButton";
import Paginate from "src/components/styled/Table/Paginate";
import WhiteBox from "src/components/styled/WhiteBox";
import { Touchable } from "src/components/Touchable";
import { hasValue, Maybe } from "src/core";
import { useClientById, useMyToast } from "src/hooks";
import { useDownloadFile, useInterval } from "src/hooks/common";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import {
  AccountFilterType,
  LabelFilterType,
  TransactionSearchFilters,
  useTransactionSearch,
} from "src/hooks/useTransactionSearch";
import { getAssetSymbolOrName } from "src/modules/ledger/assets";
import { getGainsLossesLink } from "src/modules/ledger/transactions";
import { colors, other } from "src/theme";
import Helpers from "src/utils/helpers";
import truncateMiddle from "truncate-middle";
import { BatchTransactionsEdit } from "./BatchTransactionsEdit";
import { SelectTransactionsContext } from "./context";
import { Transaction } from "./Transaction";

const MAX_SYNC_BUFFER_MINUTES = 5;

const PAGE_SIZE_OPTIONS: Option[] = [
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 250,
    label: "250",
  },
];

function Transactions() {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const toast = useMyToast();

  const { filters, hasFilters, clearFilters, updateFilters } =
    useTransactionSearch();

  const [selectedTxnIds, setSelectedTxnIds] = useState<string[]>([]);
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState((filters.page || 0) + 1);

  // Modals
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  const _onClickFilter = useCallback(
    () => _showModal("FilterTxnsModal", { clientId }),
    [clientId]
  );

  const _onClickHideSpam = (_includeSpam: boolean) => {
    updateFilters({
      includeSpam: _includeSpam,
    });
  };

  const _onClickIncome = (_includeIncome: boolean) => {
    if (_includeIncome) {
      updateFilters({
        ascending: false,
        hasIncome: _includeIncome,
        sortBy: "incomeSum",
      });
    } else {
      updateFilters({
        ascending: null,
        hasIncome: null,
        sortBy: null,
      });
    }
  };

  // Queries
  const [getNumTxns, { data: numTxnsData, networkStatus: numTxnsStatus }] =
    useLazyQuery<{
      getNumTxns?: NumTxnsResponse;
    }>(api.transactions.countTransactions, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  const {
    transactions,
    totalTransactions,
    totalCapGainCents,
    totalIncomeCents,
    totalCapGainsFormatted,
    totalIncomeFormatted,
    costBasisPercentFormatted,
    costBasisPercent,
    getTransactions,
    preFetchTransactions,
    isLoadingTransactions,
    errorGetTransactions,
    client,
  } = useClientById(clientId, {
    skipFetchAssetsOnLoad: true,
    txnFetchPolicy: "cache-and-network",
  });

  const totalPages = useMemo(
    () =>
      filters.limit
        ? Math.ceil(totalTransactions / filters.limit)
        : Math.ceil(totalTransactions / 20),
    [totalTransactions]
  );

  // console.log(transactions);

  useEffect(() => {
    getNumTxns({
      variables: {
        clientId,
      },
    });
  }, []);

  // const isSortByIncome = filters.sortBy === "incomeSum";
  const filterHasIncome = filters.hasIncome === true;

  const handlePageInput = (e: any) => {
    const selectedPage = e.target.value;
    setCurrentPage(selectedPage);
  };

  const _onFetchTransactions = useCallback(async () => {
    // console.log(`[fetching txns]`);
    const query = _getTransactionQuery(filters);
    // console.log(query);

    await getTransactions(query);

    // pre fetch the next page
    void preFetchTransactions({
      ...query,
      page: filters.page ? filters.page + 1 : 1,
    });
  }, [JSON.stringify(filters)]);

  // Note: the first page is 0 (just like an array)
  const onPageChange = (p: { selected: number }) => {
    updateFilters({ ...filters, page: p.selected });
    // scroll to the top of awaken__transactions-container
    // scroll body to the top
    // scroll awaken__transactions-container to the top
    // get the parent of this container
    const container = document.querySelector(".awaken__transactions-container");
    if (container) {
      container.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onSort = (sortBy: Maybe<string>) => {
    if (sortBy === "capGainsSum" && filters.sortBy !== "capGainsSum") {
      updateFilters({
        sortBy,
        ascending: false,
        hasIncome: null,
        hasNotes: null,
      });
    } else if (sortBy === "incomeSum" && filters.sortBy !== "incomeSum") {
      updateFilters({
        sortBy,
        hasIncome: true,
        hasNotes: null,
        ascending: false,
      });
    } else {
      updateFilters({
        ascending: filters.ascending === "true" ? "" : "true",
        sortBy,
        hasIncome: null,
        hasNotes: null,
      });
    }
  };

  // looks like this is a duplicate of above function, with slight differences
  const _onSelectSort = (sortBy: Maybe<string>) => {
    if (sortBy === "capGainsSum" && filters.sortBy !== "capGainsSum") {
      updateFilters({
        sortBy,
        ascending: false,
        hasIncome: null,
        hasNotes: null,
      });
    } else if (sortBy === "incomeSum" && filters.sortBy !== "incomeSum") {
      // we want descending
      updateFilters({
        sortBy,
        hasIncome: true,
        hasNotes: null,
        ascending: false,
      });
    } else {
      updateFilters({
        ascending: null,
        sortBy: null,
        hasIncome: null,
        hasNotes: null,
      });
    }
  };

  const addOrRemoveTxnIds = (txnId: string) => {
    const txnIds = [...selectedTxnIds];
    if (txnIds.includes(txnId)) {
      setSelectedTxnIds(() => txnIds.filter((id) => id !== txnId));
    } else {
      setSelectedTxnIds([...txnIds, txnId]);
    }
  };

  const _toggleSelectAllTxns = (e?: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (selectedTxnIds.length === transactions.length) {
      console.log(`[wiping txns]`);
      setSelectedTxnIds(() => []);
    } else {
      console.log(`[setting txns]`);
      setSelectedTxnIds(() => transactions.map((t) => t.id));
    }
  };

  useEffect(() => void _onFetchTransactions(), [_onFetchTransactions]);

  useEffect(() => setCurrentPage((filters.page || 0) + 1), [filters?.page]);

  const [getTransactionHistory] = useLazyQuery<
    Pick<Query, "getTransactionHistoryReport">
  >(api.reports.transactionHistory);

  const [getTransactionHistoryV2, { loading }] = useLazyQuery<
    Pick<Query, "getTransactionHistoryReportV2">
  >(api.reports.transactionHistoryV2);

  const { download } = useDownloadFile();

  const _exportTransactions = async () => {
    try {
      const query = _getTransactionQuery(filters);

      const response = await getTransactionHistoryV2({
        variables: {
          ...query,
          clientId,
        },
        fetchPolicy: "network-only",
      });

      if (response.data?.getTransactionHistoryReportV2?.downloadUrl) {
        download(response.data.getTransactionHistoryReportV2.downloadUrl);

        toast.show({
          message: "Exported transactions",
          status: "success",
        });
      }
    } catch (err) {
      // alert error
      alert("Error exporting transactions");
    }
  };

  const {
    background,
    medBackground,
    secondaryBackground,
    secondaryBackground2,
    ternaryBackground,
    border,
    theme,
    text,
    header,
  } = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <SelectTransactionsContext.Provider
      value={{
        selectedTransactionIds: selectedTxnIds,
        wipeTxnIds: () => setSelectedTxnIds([]),
        addOrRemoveTxnIds,
        activeLabel,
        setActiveLabel,
      }}
    >
      <FilterTxnsModal />
      <BatchTransactionsEdit />

      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          // WebKit (Chrome, Safari, newer versions of Edge)
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: border,
            borderRadius: "5px",
          },
          // Firefox
          scrollbarWidth: "thin",
          scrollbarColor: `${text} transparent`,
        }}
        padding={isLarge ? "1.5rem 2rem" : "4rem 0.75rem"}
        className="awaken__transactions-container"
      >
        <HStack
          margin={isLarge ? 0 : "25px 0"}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: isLarge ? "row" : "column",
              alignItems: isLarge ? "center" : "flex-start",
            }}
            flex={1}
          >
            <Heading
              color={header}
              padding="0"
              margin={0}
              size="lg"
              marginRight="0.5rem"
            >
              Transactions
            </Heading>
            {isLarge && !isNil(costBasisPercent) && (
              <AwakenTooltip
                placement="bottom"
                message={`Awaken is industry leading for being able to calculate your cost basis. This is the percent of your transactions we've found the cost basis for based on your current filter. Click to see the missing basis transactions.`}
              >
                <div
                  style={{
                    textAlign: "right",
                    fontWeight: "500",
                    color: header,
                    padding: "5px 10px",
                    border: `1px solid ${border}`,
                    background: medBackground,
                    borderRadius: 10,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "4px",
                  }}
                  onClick={() => {
                    // navigate and add a isMissingBasis query param
                    updateFilters({
                      isMissingBasis: true,
                    });
                  }}
                >
                  <Text
                    fontSize="sm"
                    style={{
                      fontWeight: "bold",
                      color: text,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Cost Basis:
                  </Text>
                  <Text
                    fontSize="sm"
                    style={{
                      fontWeight: "normal",
                      color: text,
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {costBasisPercentFormatted || "-"}
                    <span
                      style={{
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        flexShrink: 0,
                        borderRadius: "50%",
                        backgroundColor:
                          costBasisPercent > 0.98
                            ? colors.positive
                            : costBasisPercent > 0.9
                            ? colors.yellow50
                            : colors.negative,
                      }}
                    ></span>
                  </Text>
                </div>
              </AwakenTooltip>
            )}
            <HStack w="100%">
              {/* <SyncAllRecent /> */}
              {!isLarge && (
                <>
                  <Touchable
                    padding="0.5rem 1rem"
                    borderRadius={other.borderRadius}
                    onClick={() => dispatch(show("CreateTxnModal"))}
                  >
                    <i style={{ color: text }} className="fa-sharp fa-plus" />
                    <Text
                      color={text}
                      fontSize="sm"
                      padding="0"
                      fontWeight="semibold"
                    >
                      Create
                    </Text>
                  </Touchable>
                  <RecalculateButton />
                </>
              )}
            </HStack>
          </Box>
          {isLarge && (
            <TransactionStats getNumTxnsData={numTxnsData?.getNumTxns} />
          )}
        </HStack>

        {/* <Divider style={{ marginTop: "1rem" }} /> */}

        {/* don't show this because it makes us feel like we shouldn't generate the reports till 100% */}
        <HStack
          flex={1}
          alignItems="center"
          margin="1rem 0 1.25rem 0"
          paddingTop="1rem"
        >
          <Box
            flex={1}
            style={{
              display: "flex",
              flexDirection: isLarge ? "row" : "column",
              alignItems: isLarge ? "center" : "flex-start",
            }}
          >
            <HStack
              cursor="text"
              padding="0.6rem 1rem"
              transition="0.5s ease-in-out"
              w="100%"
              marginBottom={isLarge ? "0" : "1rem"}
              marginRight="0.25rem"
              maxW={isLarge ? "15rem" : "inherit"}
              bg={secondaryBackground}
              borderRadius={other.borderRadius}
              // border={"1px solid " + colors.gray3}
              onClick={_onClickFilter}
            >
              <i
                className={"fa-sharp fa-search"}
                style={{
                  color: text,
                }}
              />
              <Text
                fontSize="sm"
                padding="0"
                color={text}
                fontWeight="semibold"
              >
                Search
              </Text>
            </HStack>

            <SelectYear />
          </Box>

          {isLarge && (
            <HStack flex={1} alignItems="center" justifyContent="flex-end">
              <Touchable
                padding="0.5rem 1rem"
                borderRadius={other.borderRadius}
                onClick={() => dispatch(show("CreateTxnModal"))}
              >
                <i style={{ color: text }} className="fa-sharp fa-plus" />
                <Text
                  color={text}
                  fontSize="sm"
                  padding="0"
                  fontWeight="semibold"
                >
                  New
                </Text>
              </Touchable>

              <ActionSheet
                content={{
                  maxW: "225px",
                }}
                popover={{ placement: "bottom" }}
                commands={[
                  {
                    label: "Sort by date",
                    iconName: "fa-sharp fa-calendar",
                    onClick: () => _onSelectSort(null),
                  },
                  {
                    label: "Sort by gains/loss",
                    iconName: "fa-sharp fa-chart-line",
                    onClick: () => _onSelectSort("capGainsSum"),
                    infoMessage:
                      "You should be labeling a $50,000 gain transaction before a $10 gain transaction.",
                  },
                  {
                    label: "Sort by income",
                    iconName: "fa-sharp fa-coins",
                    onClick: () => _onSelectSort("incomeSum"),
                    infoMessage:
                      "See which transactions you earned interest or staking/lending income from.",
                  },
                  filters.sortBy
                    ? {
                        label: "Remove sort",
                        iconName: "fa-sharp fa-undo",
                        hasDivider: true,
                        onClick: () => _onSelectSort(null),
                      }
                    : null,
                ].filter(hasValue)}
              >
                <Touchable
                  bg={secondaryBackground}
                  _hover={{ bg: secondaryBackground }}
                  padding="0.5rem 1rem"
                  borderRadius={other.borderRadius}
                >
                  <i style={{ color: text }} className="fa-sharp fa-sort" />
                  <Text
                    color={text}
                    fontSize="sm"
                    padding="0"
                    fontWeight="semibold"
                  >
                    Sort
                  </Text>
                </Touchable>
              </ActionSheet>

              <RecalculateButton
                message=""
                buttonProps={{
                  bg: colors.primary,
                  // color: colors.white,
                }}
                showReplay={true}
                iconStyle={{ color: colors.white }}
                textProps={{ color: colors.white }}
              />

              {/* do not add this button back people are crashing our system with it somehow */}
            </HStack>
          )}

          {/* {hasFilters && (
          <HStack
            cursor="pointer"
            padding="0.5rem 1rem"
            background={`linear-gradient(90deg, #ff5e4d 32%, #ffa763 100%)`}
            transition="0.5s ease-in-out"
            borderRadius={other.borderRadius}
            onClick={() => navigate(getTransactionsLink(clientId || ""))}
            bgColor={colors.gray2}
          >
            <i
              className={"fa-sharp fa-times"}
              style={{
                color: white,
              }}
            ></i>
            <Text
              fontSize="sm"
              padding="0"
              color={colors.white}
              fontWeight="semibold"
            >
              Reset Filters
            </Text>
          </HStack>
        )} */}
        </HStack>

        <HStack
          style={{ width: "100%", marginTop: isLarge ? undefined : "1rem" }}
        >
          <div
            style={{
              // marginLeft: "0.5rem",
              marginRight: "15px",
              display: "inline-block",
            }}
          >
            <AwakenTooltip message="Show small transactions like spam or negligible interest payments / staking rewards, etc.">
              <HStack>
                <Text
                  color={text}
                  fontSize="sm"
                  padding="0"
                  fontWeight="medium"
                >
                  Show small
                </Text>
                <Switch
                  className={theme === "dark" ? "awaken__switch_dark" : ""}
                  isChecked={filters.includeSpam === true}
                  onChange={(e) => _onClickHideSpam(e.target.checked)}
                  size="sm"
                />
              </HStack>
            </AwakenTooltip>
          </div>

          <div
            style={{
              // marginLeft: "0.5rem",
              display: "inline-block",
            }}
          >
            <AwakenTooltip message="Show income transactions">
              <HStack>
                <Text
                  color={text}
                  fontSize="sm"
                  padding="0"
                  fontWeight="medium"
                >
                  Show income
                </Text>
                <Switch
                  className={theme === "dark" ? "awaken__switch_dark" : ""}
                  isChecked={filters.hasIncome === true}
                  onChange={(e) => _onClickIncome(e.target.checked)}
                  // smaller
                  size="sm"
                />
              </HStack>
            </AwakenTooltip>
          </div>

          <div style={{ flex: 1 }} />

          <TransactionsInformation
            totalTransactions={totalTransactions}
            totalCapGainCents={totalCapGainCents}
            totalIncomeCents={totalIncomeCents}
            totalCapGainCentsFormatted={totalCapGainsFormatted}
            totalIncomeCentsFormatted={totalIncomeFormatted}
            costBasisPercentFormatted={costBasisPercentFormatted}
            costBasisPercent={costBasisPercent}
            loading={loading}
            _exportTransactions={_exportTransactions}
          />
        </HStack>

        <FiltersList />

        <WhiteBox border="none" padding="0" marginTop="20px">
          <TableContainer>
            <Table border={`1px solid ${border}`} variant="simple">
              <Thead bg={background} border={`1px solid ${border}`}>
                <Tr>
                  <Th borderColor={border} w="20px">
                    <div onClick={_toggleSelectAllTxns}>
                      <Checkbox
                        borderColor={border}
                        isChecked={
                          selectedTxnIds.length === transactions.length
                        }
                      />
                    </div>
                  </Th>
                  <Th
                    borderColor={border}
                    paddingLeft="0rem"
                    cursor="pointer"
                    color={text}
                    onClick={() => onSort("title")}
                  >
                    Name
                    <SortTriangle
                      ascending={
                        filters.sortBy === "title" ? filters.ascending : "false"
                      }
                      sortBy={filters.sortBy}
                      expectedSortBy={"title"}
                    />
                  </Th>
                  <Th borderColor={border} cursor="pointer">
                    <Center color={text}>
                      <Tooltip
                        zIndex={1401}
                        trigger={isMobile ? "hover" : "hover"} // we already have a click handler
                        bg={colors.black}
                        placement="top"
                        borderRadius="0.25rem"
                        padding={{ base: "0.5rem 1rem" }}
                      >
                        Status
                      </Tooltip>
                    </Center>
                  </Th>
                  <Th
                    borderColor={border}
                    cursor="pointer"
                    onClick={() => {
                      // hack for now
                      if (filterHasIncome) {
                        updateFilters({
                          sortBy: "incomeSum",
                          hasIncome: true,
                          ascending:
                            (filters.ascending || "") === "false"
                              ? "true"
                              : "false",
                        });
                      } else {
                        onSort("capGainsSum");
                      }
                    }}
                  >
                    <Center color={text}>
                      <Tooltip
                        zIndex={1401}
                        trigger={isMobile ? "hover" : "hover"} // we already have a click handler
                        bg={colors.black}
                        placement="top"
                        borderRadius="0.25rem"
                        label={
                          filterHasIncome
                            ? "Click here to sort by income. You should be reviewing transactions with $1,000 of income before a $1 of income."
                            : "Click here to sort by gains/losses. You should be reviewing a $50,000 gain before a $100 gain transaction."
                        }
                        padding={{ base: "0.5rem 1rem" }}
                      >
                        <>Value</>
                      </Tooltip>

                      {filterHasIncome ? (
                        <SortTriangle
                          ascending={
                            filters.sortBy === "incomeSum"
                              ? filters.ascending === "true"
                                ? "true"
                                : "false"
                              : "true"
                          }
                          sortBy={filters.sortBy}
                          expectedSortBy={"incomeSum"}
                        />
                      ) : (
                        <SortTriangle
                          ascending={
                            filters.sortBy === "capGainsSum"
                              ? filters.ascending === "true"
                                ? "false"
                                : "true"
                              : "false"
                          }
                          sortBy={filters.sortBy}
                          expectedSortBy={"capGainsSum"}
                        />
                      )}
                    </Center>
                  </Th>
                  {/* <Th cursor="pointer" onClick={() => onSort("capGainsSum")}>
                  <Center>
                    <Tooltip
                      zIndex={1401}
                      trigger={isMobile ? "hover" : "hover"} // we already have a click handler
                      bg={colors.black}
                      placement="top"
                      borderRadius="0.25rem"
                      label="Click here to sort by your biggest gains/losses. You may want to see which transactions led you to your cap gains total on the Tax Reports page"
                      padding={{ base: "0.5rem 1rem" }}
                    >
                      Gains/Losses
                    </Tooltip>
                    <SortTriangle
                      ascending={
                        filters.sortBy === "capGainsSum"
                          ? filters.ascending
                          : "false"
                      }
                      sortBy={filters.sortBy}
                      expectedSortBy={"capGainsSum"}
                    />
                  </Center>
                </Th> */}
                  <Th borderColor={border}>
                    <Center color={text}>Account</Center>
                  </Th>
                  <Th
                    borderColor={border}
                    isNumeric
                    cursor="pointer"
                    color={text}
                    onClick={() => onSort("createdAt")}
                  >
                    &nbsp;&nbsp;Date &#38; Time{" "}
                    <SortTriangle
                      ascending={
                        filters.sortBy === "createdAt"
                          ? filters.ascending
                          : "false"
                      }
                      sortBy={filters.sortBy}
                      expectedSortBy={"createdAt"}
                    />
                  </Th>
                </Tr>
              </Thead>
              <Tbody border={`1px solid ${border} !important`}>
                {transactions.map((t) => (
                  <Transaction
                    timezone={client?.timezone || "UTC"}
                    transaction={t}
                    key={t.id}
                    // handleContextMenu={handleContextMenu}
                  />
                ))}
              </Tbody>
            </Table>

            <div
              style={{
                border: `1px solid ${border}`,
                borderTop: "none",
              }}
            >
              <NoTransactionsComponent
                transactions={transactions}
                filters={filters}
                hasFilters={hasFilters}
                errorGetTransactions={errorGetTransactions}
                _onFetchTransactions={_onFetchTransactions}
                isLoadingTransactions={isLoadingTransactions}
                clearFilters={clearFilters}
              />
            </div>
          </TableContainer>
        </WhiteBox>

        <HStack alignItems="flex-start" marginTop="2rem">
          <Paginate
            onPageChange={onPageChange}
            filters={filters}
            currentPage={filters?.page || 0}
            totalPages={totalPages}
            totalTransactions={totalTransactions}
            numPageTransactions={transactions?.length}
          />
        </HStack>

        <HStack
          style={{ marginTop: 0, width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Input
            // label="Page"
            containerStyle={{ width: 60, marginBottom: 0 }}
            value={currentPage}
            textAlign="center"
            paddingLeft="0.6rem"
            onChange={handlePageInput}
            placeholder="Page"
            fontSize={14}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onPageChange({ selected: currentPage - 1 });
              }
            }}
          />
          <Select
            // label="Per page"
            selectProps={{
              onChange: (o: any) => {
                updateFilters({ ...filters, limit: o?.value || 20 });
              },
              components: {
                IndicatorSeparator: () => null,
              },
            }}
            containerStyle={{ width: 100, marginBottom: 0 }}
            placeholder=""
            value={
              PAGE_SIZE_OPTIONS.find((o) => o.value === filters.limit) || null
            }
            options={PAGE_SIZE_OPTIONS}
          />
        </HStack>
      </Box>
    </SelectTransactionsContext.Provider>
  );
}

const EXCLUDE_FILTERS = ["page", "limit"];

const FiltersList = () => {
  const theme = useTheme();
  const { clientId } = useParams<{ clientId: string }>();
  const { filters, updateFilters } = useTransactionSearch();
  const { assets, accounts, getAssets } = useClientById(clientId);
  const assetById = useMemo(() => keyBy((a) => a.id, assets), [assets]);

  const assetByKey = useMemo(
    (): Dictionary<PartialAssetFields> =>
      keyBy((a: PartialAssetFields): string => {
        if (a.type === AssetTypeEnum.FiatCurrency) return a.symbol ?? a.id;
        if (a.type === AssetTypeEnum.FungibleToken) {
          return a.coinGeckoTokenId ?? a.contractAddress ?? a.id;
        }
        if (a.type === AssetTypeEnum.Nft) {
          return a.contractAddress ?? a.id;
        }
        return a.id;
      }, assets),
    [assets]
  );
  const assetByName = useMemo(
    () => keyBy((a) => getAssetSymbolOrName(a), assets),
    [assets]
  );
  const accountById = useMemo(() => keyBy((a) => a.id, accounts), [accounts]);

  useEffect(() => {
    void getAssets();
  }, [clientId]);

  function convertF0(f0?: any, f1?: any) {
    if (!f0) return "";
    if (f0 === "includeSpam") return "";
    if (f0 === "gainLossFilter") {
      return {
        title: "Gain/loss",
        iconName: "fa-sharp fa-balance-scale-left",
      };
    }
    if (f0 === "labeledPriority") {
      return {
        title: "Priority",
        iconName: "fa-sharp fa-exclamation-triangle",
      };
    }
    if (f0 === "accountIds") {
      const accounts = (f1 ?? [])
        .map((id: string): BaseAccountFields => accountById[id])
        .filter(hasValue);
      const allSameProvider =
        new Set(accounts.map((a: BaseAccountFields) => a.provider)).size === 1;
      const imgSrc = allSameProvider ? accounts[0]?.iconImageUrl : null;

      return {
        title: "Account",
        iconName: allSameProvider ? null : "fa-sharp fa-wallet",
        iconComponent: allSameProvider ? (
          <img
            src={imgSrc}
            alt="Icon"
            style={{
              marginRight: "0.5rem",
              width: 17,
              height: 17,
              borderRadius: 10,
              objectFit: "cover",
            }}
          />
        ) : null,
      };
    }
    if (
      f0 === "assetId" ||
      f0 === "assetSymbolOrName" ||
      f0 === "assetKey" ||
      f0 === "assetKeyV2"
    ) {
      const asset = assetByName[f1] || assetById[f1] || assetByKey[f1];
      return {
        title: "Asset",
        iconName: asset ? null : "fa-sharp fa-coins",
        iconComponent: asset ? (
          <AssetIcon
            style={{ marginRight: "0.5rem" }}
            size={18}
            asset={asset}
          />
        ) : null,
      };
    }
    if (f0 === "assetIds") {
      return {
        title: "Assets",
        iconName: "fa-sharp fa-coins",
      };
    }
    if (f0 === "assetType") {
      return {
        title: "Asset Type",
        iconName: "fa-sharp fa-filter",
      };
    }
    if (f0 === "labels") {
      return {
        title:
          "Labels" +
          (filters.labelFilterMode === LabelFilterType.Exclude ? " (not)" : ""),
        iconName: "fa-sharp fa-tags",
      };
    }
    if (f0 === "providerFilterMode") {
      return null;
    }
    if (f0 === "labelFilterMode") {
      return null;
    }
    if (f0 === "onlyShowDeleted") {
      return {
        title: "Deleted",
        iconName: "fa-sharp fa-trash",
      };
    }
    if (f0 === "showOnlyEditedTransactions") {
      return {
        title: "Edited",
        iconName: "fa-sharp fa-pencil",
      };
    }
    if (f0 === "providers") {
      const providerSet = new Set(f1);
      const providers = INTEGRATIONS.filter((i) => providerSet.has(i.provider));

      const providerFilterMode = filters.providerFilterMode;
      const isOneProvider = providers.length === 1;
      const imgSrc = isOneProvider ? providers[0].logoUrl : null;
      const allSameType = new Set(providers.map((p) => p.type));

      const name =
        allSameType.size === 1
          ? providers[0].type === AccountTypeEnum.Exchange
            ? "Exchanges"
            : "Wallets"
          : "Provider";

      return {
        title:
          name +
          (providerFilterMode === AccountFilterType.Exclude ? " (not)" : ""),
        iconComponent: imgSrc ? (
          <img
            src={imgSrc}
            alt="Icon"
            style={{
              marginRight: "0.5rem",
              width: 17,
              height: 17,
              borderRadius: 10,
              objectFit: "cover",
            }}
          />
        ) : null,
        iconName: imgSrc ? null : "fa-sharp fa-wallet",
      };
    }
    if (f0 === "startDate")
      return { title: "Start Date", iconName: "fa-sharp fa-calendar" };
    if (f0 === "endDate")
      return { title: "End Date", iconName: "fa-sharp fa-calendar" };
    if (f0 === "ascending")
      return { title: "Ascending", iconName: "fa-sharp fa-sort-amount-up" };
    if (f0 === "hasIncome")
      return { title: "Income", iconName: "fa-sharp fa-dollar-sign" };
    if (f0 === "hasNotes")
      return { title: "Notes", iconName: "fa-sharp fa-notes" };
    if (f0 === "reviewed")
      return { title: "Status", iconName: "fa-sharp fa-check" };
    if (f0 === "search")
      return { title: "Search", iconName: "fa-sharp fa-search" };
    else if (f0 === "sortBy")
      return { title: "Sort By", iconName: "fa-sharp fa-sort" };
    else if (f0 === "isMissingBasis")
      return {
        title: "Missing Basis",
        iconName: "fa-sharp fa-exclamation-triangle",
      };
    else if (f0 === "isNegativeBalance")
      return {
        title: "Negative Balance",
        iconName: "fa-sharp fa-balance-scale-left",
      };
    return f0;
  }

  function convertF1(f0?: any, f1?: any) {
    if (!f1) return "";
    if (f1 === "incomeSum") return "Income Ranking";
    else if (f1 === "capGainsSum") return "Gains/Losses";
    if (f0 === "onlyShowDeleted") {
      return "True";
    }
    if (f0 === "showOnlyEditedTransactions") {
      return "True";
    }

    if (f0 === "startDate" || f0 === "endDate")
      return DateTime.fromJSDate(new Date(f1))
        .toUTC()
        .startOf("day")
        .toFormat("LLL dd, yyyy");

    if (f0 === "assetId") {
      const name = getAssetSymbolOrName(assetById[f1]);
      return truncateMiddle(name || f1, 10, 10, "...");
    }
    if (f0 === "assetType") {
      if (f1?.toUpperCase() === "NFT") return "NFT";
      if (f1?.toLowerCase() === "fungible_token") return "Tokens";
      return "None";
    }
    if (f0 === "labeled") {
      if (f1?.toLowerCase() === "true") return "Reviewed";
      if (f1?.toLowerCase() === "false") return "Unreviewed";
    }
    if (f0 === "assetIds") {
      const assets = (f1 ?? [])
        .map((id: string) => assetById[id])
        .filter(hasValue);

      return uniq(
        assets.map((a: BaseAssetFields) =>
          truncateMiddle(getAssetSymbolOrName(a) || f1, 10, 10, "...")
        )
      ).join(", ");
    }
    if (f0 === "providers") {
      const providerSet = new Set(f1);
      const providers = INTEGRATIONS.filter((i) => providerSet.has(i.provider));

      return (providers ?? []).map((p) => p.name).join(", ");
    }
    if (f0 === "labels") {
      const labels = (f1 ?? [])
        .map((f: string) =>
          f.replace("v1:", "").split("_").map(Helpers.capitalize).join(" ")
        )
        .join(", ");

      return labels;
    }
    if (f0 === "assetKey") {
      const name = getAssetSymbolOrName(assetByKey[f1]);
      return truncateMiddle(name || f1, 10, 10, "...");
    }
    if (f0 === "assetKeyV2") {
      const [type, _, symbol, ...rest] = f1.split(":");
      if (type === "onchain_token") {
        // slice 2 onwards and replace : with " "
        return `${symbol} (${rest.join(":")})`;
      }

      return truncateMiddle(symbol || f1, 10, 10, "...");
    }

    if (f0 === "accountIds") {
      const accounts = (f1 ?? [])
        .map((id: string) => accountById[id])
        .filter(hasValue);

      return accounts
        .map((a: BaseAccountFields) =>
          truncate(a.description || "", { length: 15 })
        )
        .join(", ");
    }

    if (f0 === "labeledPriority") {
      return f1 == "1" ? "Low" : f1 == "2" ? "Medium" : f1 == "3" ? "High" : "";
    }

    if (isBoolean(f1) || f1 === "true" || f1 === "false") {
      if (f1 === true || f1 === "true") return "Yes";
      if (f1 === false || f1 === "false") return "No";
    }

    return f1;
  }

  const activeFiltersList = Object.entries(filters).filter(
    (f) =>
      hasValue(f[1]) &&
      f[1] !== "" &&
      !EXCLUDE_FILTERS.includes(f[0]) &&
      !!convertF0(f[0], f[1])
  );

  if (!activeFiltersList.length) {
    return null;
  }

  return (
    <Box marginTop="1.5rem" marginBottom="1rem" display="flex" flexWrap="wrap">
      {activeFiltersList.map((f) => {
        const info = convertF0(f[0], f[1]);

        if (!info) {
          return null;
        }

        return (
          <Box
            bgColor={theme.secondaryBackground}
            // border={`1px solid ${colors.gray70}`}
            borderRadius={other.borderRadius}
            color={theme.text}
            display="flex"
            flexDirection="row"
            alignItems="center"
            padding="0.25rem 0.75rem"
            fontWeight="semibold"
            whiteSpace={"nowrap"}
            marginBottom="0.5rem"
            marginRight="0.5rem"
            fontSize={14}
          >
            {info.iconName && (
              <i
                className={info.iconName}
                style={{ marginRight: "0.5rem", fontSize: 14 }}
              />
            )}
            {info.iconComponent && info.iconComponent}
            {`${info.title}: ${convertF1(f[0], f[1])}`}
            <i
              style={{
                borderRadius: "50%",
                marginLeft: "1rem",
                cursor: "pointer",
              }}
              className="fa-sharp fa-times"
              onClick={() => updateFilters({ ...filters, [f[0]]: null })}
            ></i>
          </Box>
        );
      })}
    </Box>
  );
};

const TransactionStats = ({
  getNumTxnsData,
}: {
  getNumTxnsData: NumTxnsResponse | undefined;
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const {
    background,
    ternaryBackground,
    medBackground,
    secondaryBackground,
    secondaryBackground2,
    header,
    text,
  } = useTheme();

  const unreviewedImportant = getNumTxnsData ? getNumTxnsData.high : null;

  const timeLeft = useMemo(() => {
    if (unreviewedImportant === null) return "";
    let totalMins = 0;
    if (unreviewedImportant === 0) totalMins = 0;
    else if (unreviewedImportant < 100)
      totalMins = Math.round(unreviewedImportant * 0.2);
    else if (unreviewedImportant < 1000)
      // intersects with above line at y = 100
      totalMins = Math.round(unreviewedImportant * 0.1 + 10);
    // intersects with above line at y = 1000
    else totalMins = Math.round(unreviewedImportant * 0.05 + 60);

    if (unreviewedImportant && totalMins === 0 && unreviewedImportant > 0)
      totalMins = 1;

    const hours = Math.floor(totalMins / 60);
    const mins = totalMins % 60;
    if (hours > 0) return `${hours} hrs${mins > 0 ? ` ${mins} mins` : ""}`;
    else return `${mins} min${mins === 1 ? "" : "s"}`;
  }, [unreviewedImportant]);

  const taxDeadline2023 = new Date("4/18/2023");
  const daysLeft = Math.floor(
    (taxDeadline2023.getTime() - Date.now()) / (1000 * 3600 * 24)
  );
  const circularProgressValue =
    unreviewedImportant === null || getNumTxnsData?.total === undefined
      ? undefined
      : (100 * (getNumTxnsData.total - (unreviewedImportant || 0))) /
        getNumTxnsData.total;
  const cheerText =
    circularProgressValue !== undefined &&
    (circularProgressValue === 100
      ? null
      : circularProgressValue >= 60
      ? null
      : null);
  const highPriorityUnlabeledLink = getGainsLossesLink({
    clientId: clientId || "",
    reviewed: false,
    labeledPriority: 3, // highest priority
  });

  return (
    <WhiteBox
      // border={other.boxBorder}
      minW="auto"
      padding="0.4rem"
      marginBottom="1rem"
      display="inline-block"
      style={{ boxShadow: "none" }}
      bg={medBackground}
    >
      <HStack>
        <Box paddingRight="0.5rem">
          <CircularProgress
            value={circularProgressValue}
            isIndeterminate={!getNumTxnsData}
            color={
              (unreviewedImportant ?? 0) === 0
                ? colors.green50
                : colors.yellow50
            }
            trackColor={ternaryBackground}
            capIsRound
            size="4rem"
          >
            <CircularProgressLabel
              color={header}
              fontSize="14px"
              fontWeight="bold"
            >
              {!circularProgressValue
                ? ""
                : `${Math.floor(circularProgressValue)}%`}
            </CircularProgressLabel>
          </CircularProgress>
          {cheerText && (
            <Text color={header} w="100%" textAlign={"center"}>
              {cheerText}
            </Text>
          )}
        </Box>
        <VStack
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Link
            to={highPriorityUnlabeledLink}
            style={{ color: header, width: "100%" }}
          >
            <HStack>
              <i
                className="fa-sharp fa-circle"
                style={{
                  color: colors.red50,
                  marginRight: 5,
                }}
              />
              <Tooltip
                zIndex={1401}
                trigger={isMobile ? "click" : "hover"}
                bg={colors.black}
                placement="top"
                borderRadius="0.25rem"
                label={
                  unreviewedImportant
                    ? `We highly encourage you to review these ${unreviewedImportant} unlabeled transactions to get accurate tax reports. If you want to optimize your taxes, you can review more of the unlabeled transactions as well.`
                    : ""
                }
                padding={{ base: "0.5rem 1rem" }}
              >
                <Text
                  fontSize="sm"
                  cursor="pointer"
                  style={{ textAlign: "left", flex: 1 }}
                  _hover={{
                    color: header,
                    textDecoration: "underline",
                  }}
                  color={header}
                >
                  <strong>{unreviewedImportant}</strong> high priority
                </Text>
              </Tooltip>
            </HStack>
          </Link>

          <Link
            to={getGainsLossesLink({
              clientId: clientId || "",
              reviewed: true,
            })}
            style={{ color: colors.black, width: "100%" }}
          >
            <HStack>
              <i
                className="fa-sharp fa-check-circle"
                style={{
                  color: colors.green50,
                  marginRight: 5,
                }}
              />
              <Text
                color={header}
                fontSize="sm"
                cursor="pointer"
                style={{ textAlign: "left", flex: 1 }}
                _hover={{
                  color: header,
                  textDecoration: "underline",
                }}
              >
                <strong>{getNumTxnsData?.reviewed}</strong> labeled
              </Text>
            </HStack>
          </Link>

          {/* <Text color={text} padding="0 0.5rem" fontSize="sm">
              <i
                className="fa-sharp fa-clock"
                style={{
                  color: text,
                  marginRight: 5,
                }}
              />{" "}
              <strong>{timeLeft}</strong> of work
            </Text> */}
          {/* <Text margin="0.75rem 0">
              🗓 <strong>{daysLeft}</strong> days left
            </Text> */}
        </VStack>
        {/* {unreviewedImportant === 0 ? (
            <SecondaryText
              color={text}
              text={
                <span>
                  To optimize your taxes, you can label more transactions.
                </span>
              }
            />
          ) : (
            <SecondaryText
              onClick={() => navigate(highPriorityUnlabeledLink)}
              cursor="pointer"
              color={text}
              text={
                <span>
                  Label{" "}
                  <SecondaryText
                    text={`${unreviewedImportant} high priority transactions`}
                    color={header}
                    textDecor="underline"
                    fontWeight="semibold"
                    display="inline"
                    margin="0"
                  />
                </span>
              }
            />
          )} */}
      </HStack>
    </WhiteBox>
  );

  // return (
  //   <Box padding="0.5rem 0">
  //     <Text>
  //       Please review as many transactions as you can so Awaken can generate the
  //       most accurate tax reports.{" "}
  //       {/*When you review one transaction, Awaken
  //       automatically reviews similar transactions. */}
  //       {getNumTxnsData && getNumTxnsData.total && (
  //         <>
  //           <br />
  //           You have&nbsp;
  //           <Link to={highestPriorityLink}>
  //             <Text
  //               display="inline"
  //               fontWeight="semibold"
  //               color={colors.primary}
  //               textDecoration="underline"
  //             >
  //               {getNumTxnsData.unreviewed} transactions
  //             </Text>
  //           </Link>{" "}
  //           that need review.{" "}
  //           {timeLeft && (
  //             <span>
  //               (<strong>~{timeLeft}</strong>)
  //             </span>
  //           )}
  //         </>
  //       )}
  //     </Text>
  //     {getNumTxnsData && getNumTxnsData.unreviewed > 0 && (
  //       <Progress
  //         margin="1rem 0"
  //         value={(100 * getNumTxnsData.reviewed) / getNumTxnsData.total}
  //         width="27.25rem"
  //         bgColor={colors.gray2}
  //       />
  //     )}
  //   </Box>
  // );
};

const SortTriangle = ({
  ascending,
  sortBy,
  expectedSortBy,
}: {
  ascending?: Maybe<string>;
  sortBy?: Maybe<string>;
  expectedSortBy: string;
}) => {
  const isBeingSorted = sortBy === expectedSortBy;

  return (
    <i
      className={`fa-sharp fa-angle-${ascending === "true" ? "up" : "down"}`}
      style={{
        marginLeft: "0.3rem",
        fontSize: 16,
        position: "relative",
        top: 0,
        color: isBeingSorted ? colors.black : colors.gray70,
      }}
    />
  );
};

const NoTransactionsComponent = ({
  transactions,
  filters,
  hasFilters,
  errorGetTransactions,
  _onFetchTransactions,
  isLoadingTransactions,
  clearFilters,
}: {
  transactions: BaseSimpleTransactionFields[];
  filters: TransactionSearchFilters;
  hasFilters: boolean;
  errorGetTransactions: ApolloError | undefined;
  _onFetchTransactions: any;
  isLoadingTransactions: boolean;
  clearFilters: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const _onAddWallet = () => {
    _showModal("AccountModal", {
      location: "no_transactions_page",
    });
  };

  if (isLoadingTransactions && !transactions.length) {
    return (
      <Box w="100%" padding="5rem 1rem">
        <Center>
          <Spinner color={theme.header} />
        </Center>
      </Box>
    );
  }

  if (errorGetTransactions) {
    return (
      <Box width="100%" padding="3rem 1rem" textAlign="center">
        <Text color={theme.header}>Something went wrong 🙁</Text>
        <Button
          variant="primary"
          marginTop="1rem"
          onClick={_onFetchTransactions}
        >
          Try again
        </Button>
      </Box>
    );
  }

  // No transactions matched
  if (transactions.length === 0 && hasFilters) {
    return (
      <Box width="100%" padding="3rem 1rem" textAlign="center">
        <Box width="100%" padding="3rem 1rem" textAlign="center">
          <Text color={theme.header}>No transactions matched your filter</Text>
          <Button variant="primary" marginTop="1rem" onClick={clearFilters}>
            Clear filters
          </Button>
        </Box>
      </Box>
    );
  }

  // User has no transactions
  if (transactions.length === 0) {
    return (
      <Box width="100%" padding="3rem 1rem" textAlign="center">
        <Text color={theme.header}>
          No transactions yet. Add an account to import your transactions 👇
        </Text>
        <Button variant="primary" marginTop="1rem" onClick={_onAddWallet}>
          Add Account
        </Button>
      </Box>
    );
  }

  return null;
};

const SelectYear = () => {
  const { clientId } = useParams();
  const { client } = useClientById(clientId, { onlyFetchClient: true });

  const timezone = client?.timezone || "UTC";
  const { filters, updateFilters } = useTransactionSearch();
  const { secondaryBackground, medBackground, background, text } = useTheme();

  const [_getTaxYears, { data: taxYearsData, loading: loadingTaxYears }] =
    useLazyQuery<{
      getTaxYearBrackets: Query["getTaxYearBrackets"];
    }>(api.reports.getTaxYearBrackets, {
      fetchPolicy: "cache-and-network",
    });

  const yearOptions = useMemo(() => {
    if (!taxYearsData?.getTaxYearBrackets) return [];
    return orderBy(
      taxYearsData.getTaxYearBrackets.map((bracket) => ({
        label: bracket.taxYear,
        value: bracket,
      })),
      (v) => parseFloat(v.value.taxYear),
      "desc"
    );
  }, [taxYearsData]);

  const _onSelect = (o: any) => {
    const value = o?.value as TaxYearBracket;

    if (!o || !value) {
      updateFilters({
        startDate: null,
        endDate: null,
      });
      return;
    }

    updateFilters({
      startDate: moment(value.start).toISOString(),
      endDate: moment(value.end).toISOString(),
    });
  };

  useEffect(() => {
    if (!clientId) return;
    _getTaxYears({
      variables: {
        clientId,
      },
    });
  }, [clientId]);

  return (
    <AwakenTooltip message="Filter for transactions in a specific year">
      <div style={{ width: 90 }}>
        <Select
          containerStyle={{ marginBottom: 0, width: "100%" }}
          options={yearOptions}
          selectProps={{
            isClearable: false,
            placeholder: "All",
            onChange: (o: any) => _onSelect(o),
            styles: {
              container: (base) => ({
                ...base,
                border: "none",
                boxShadow: "none",
              }),
              control: (base) => ({
                ...base,
                backgroundColor: secondaryBackground,
                border: "none",
                boxShadow: "none",
                borderRadius: 8,
                height: 40,
                fontWeight: "600",
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: medBackground,
              }),
              option: (base) => ({
                ...base,
                backgroundColor: medBackground,
                color: text,
                "&:hover": {
                  backgroundColor: colors.primary,
                  color: colors.white,
                },
              }),
              singleValue: (base) => ({
                ...base,
                color: text,
              }),
            },
          }}
        />
      </div>
    </AwakenTooltip>
  );
};

const _getTransactionQuery = (filters: TransactionSearchFilters) => {
  const strToBool = (str?: Maybe<string | boolean>) => {
    if (typeof str === "boolean") return str;
    if (str === "true") return true;
    else if (str === "false") return false;
    else return null;
  };
  const reviewed = strToBool(filters.reviewed);
  const hasIncome = strToBool(filters.hasIncome);
  const hasNotes = strToBool(filters.hasNotes);
  const search = (filters.search || "")?.replace(/['"]/g, "");

  const query = {
    page: filters.page || 0,
    limit: filters.limit,
    // review status could be an empty string which does not satisfy the enum type,
    // so we default to null for the empty case so graphQL doesn't complain
    accountIds: filters.accountIds || null,
    providers: filters.providers || null,
    providerFilterMode: filters.providerFilterMode || null,
    labelFilterMode: filters.labelFilterMode || null,
    includeSpam: filters.includeSpam ?? null,
    startDate: filters.startDate ? new Date(filters.startDate) : null,
    endDate: filters.endDate ? new Date(filters.endDate) : null,
    search: search || null,
    gainLossFilter: filters.gainLossFilter ?? null,
    reviewed,
    labeledPriority: filters.labeledPriority || null,
    hasIncome,
    hasNotes,
    sortBy: filters.sortBy,
    ascending: filters.ascending === "true",
    // FIXME: this is rlly asset keys. it isn't a specific match on ID atm it is by the coingecko or identifier
    assetIds: filters.assetIds || null,
    labels: filters.labels || null,
    assetSymbolOrName: filters.assetSymbolOrName || null,
    isMissingBasis: filters.isMissingBasis || null,
    isNegativeBalance: filters.isNegativeBalance || null,
    assetKey: filters.assetKey || null,
    assetKeyV2: filters.assetKeyV2 || null,
    assetType: filters.assetType || null,
    onlyShowDeleted: filters.onlyShowDeleted ?? null,
    showOnlyEditedTransactions: filters.showOnlyEditedTransactions ?? null,
  };
  return query;
};

export const SyncAllRecent = () => {
  const { clientId } = useParams();
  const { client } = useClientById(clientId, { onlyFetchClient: true });
  const { medBackground, secondaryBackground, border, text } = useTheme();
  const toast = useMyToast();
  const [syncAll] = useMutation(api.accounts.syncAll);
  const [lastSyncedAt, setLastSyncedAt] = useState<string | null>(null);

  const _onClickSyncTxns = useCallback(async () => {
    try {
      await syncAll({
        variables: {
          clientId,
          isContinuousSync: true,
        },
        refetchQueries: [api.clients.retrieve],
      });
      toast.show({
        message: "We've started to sync your transactions.",
        status: "success",
      });
    } catch (e) {
      toast.show({
        message: (e as ApolloError)?.message || "Error syncing transactions",
        status: "info",
      });
    }
  }, [clientId]);

  // set the last synced at message
  useInterval(async () => {
    if (!client) return;
    if (!client.lastSyncedAt) return setLastSyncedAt(null);

    const lastSyncedAt = DateTime.fromJSDate(new Date(client.lastSyncedAt));

    const lastSyncedAtMessage = lastSyncedAt
      ? lastSyncedAt.toRelative({
          style: "short",
          round: true, // Enable rounding
        })
      : null;

    const displayTime =
      lastSyncedAt.diffNow().as("minutes") < -1
        ? lastSyncedAtMessage
        : "just now";

    setLastSyncedAt(displayTime);
  }, 1 * 1000);

  const isLarge = useIsLargeScreen();

  return (
    <div
      style={{
        marginLeft: isLarge ? 10 : 0,
        flex: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <AwakenTooltip
        openDelay={0}
        message={
          lastSyncedAt
            ? "Last synced " +
              lastSyncedAt +
              ". Note: this can only be called once every 24h 🕐"
            : "Refresh transactions"
        }
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Touchable
            cursor="pointer"
            padding={"0.25rem 0.25rem"}
            onClick={_onClickSyncTxns}
            bg={medBackground}
            _hover={{ bg: secondaryBackground }}
            border={"1px solid " + border}
            borderRadius={other.borderRadius}
          >
            {/* <StatusTag
                  type="beta"
                  label="Beta"
                  infoMessage="Continuous syncing where we pull in all your transactions when you load Awaken is currently in Beta. If you have any feedback, shoot us a message to andrew@awaken.tax :)."
                /> */}

            <Text fontSize={"xs"} padding="0" color={text} fontWeight="normal">
              {"Sync all"}
            </Text>
            <i
              className="fa-sharp fa-sync-alt"
              style={{
                color: text,
              }}
            />
          </Touchable>
        </div>
      </AwakenTooltip>
    </div>
  );
};

const TransactionsInformation = ({
  totalTransactions,
  totalCapGainCentsFormatted,
  totalIncomeCentsFormatted,
  totalCapGainCents,
  totalIncomeCents,
  loading,
  costBasisPercent,
  costBasisPercentFormatted,
  _exportTransactions,
}: {
  totalTransactions: number;
  totalCapGainCentsFormatted: string;
  totalIncomeCentsFormatted: string;
  totalCapGainCents: number;
  totalIncomeCents: number;
  loading: boolean;
  costBasisPercentFormatted: string | null;
  costBasisPercent: number | null;
  _exportTransactions: () => void;
}) => {
  const { medBackground, border, header, text } = useTheme();

  const formatCurrency = (cents: number) => {
    return numbro(cents / 100).formatCurrency({
      mantissa: 2,
      thousandSeparated: true,
    });
  };

  const { updateFilters } = useTransactionSearch();
  const isLarge = useIsLargeScreen();

  const boxStyle = {
    textAlign: "right" as const,
    fontWeight: "500" as const,
    color: header,
    padding: "5px 10px",
    border: `1px solid ${border}`,
    background: medBackground,
    borderRadius: 10,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  };

  return (
    <HStack spacing={1}>
      {isLarge && totalCapGainCents !== 0 && (
        <AwakenTooltip
          placement="bottom"
          message={`Total capital ${
            totalCapGainCents > 0 ? "gains" : "losses"
          } from these transactions`}
        >
          <div
            style={boxStyle}
            onClick={() => {
              updateFilters({
                sortBy: "capGainsSum",
                ascending: false,
                hasIncome: null,
                hasNotes: null,
              });
            }}
          >
            <i
              className={
                totalCapGainCents > 0
                  ? "fa-sharp fa-caret-up"
                  : "fa-sharp fa-caret-down"
              }
              style={{
                fontSize: 14,
                color:
                  totalCapGainCents > 0 ? colors.positive : colors.negative,
              }}
            />
            <span>{totalCapGainCentsFormatted}</span>
          </div>
        </AwakenTooltip>
      )}

      {isLarge && totalIncomeCents > 0 && (
        <AwakenTooltip
          placement="bottom"
          message="Total income from these transactions"
        >
          <div
            style={boxStyle}
            onClick={() => {
              updateFilters({
                sortBy: "incomeSum",
                ascending: false,
                hasIncome: null,
                hasNotes: null,
              });
            }}
          >
            <i
              className="fa-sharp fa-sack-dollar"
              style={{ color: text, fontSize: 10 }}
            />
            <span>{totalIncomeCentsFormatted}</span>
          </div>
        </AwakenTooltip>
      )}

      <AwakenTooltip
        placement="bottom-end"
        message="Click to export these transactions"
      >
        <div onClick={_exportTransactions} style={boxStyle}>
          <i
            className="fa-sharp fa-exchange"
            style={{ color: text, fontSize: 10 }}
          />
          <span>{numbro(totalTransactions).format("0,0")}</span>{" "}
          <i
            className={
              loading ? "fa-sharp fa-spinner fa-spin" : "fa-sharp fa-download"
            }
            style={{ color: text, marginLeft: 10 }}
          />
        </div>
      </AwakenTooltip>
    </HStack>
  );
};

export default Transactions;
