import {
  getAssociatedTokenAddress,
  TOKEN_2022_PROGRAM_ID,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";

interface UseTokenAccountProps {
  mint?: string | null;
  address?: string | null;
  enabled?: boolean;
}

export const useTokenAccount = ({
  mint,
  address,
  enabled = false,
}: UseTokenAccountProps) => {
  const [tokenAddress, setTokenAddress] = useState<string>("");

  useEffect(() => {
    if (!mint || !address || !enabled) {
      return;
    }

    const fetchTokenAccount = async () => {
      try {
        // Special cases for pyUSD and CRT tokens that use Token-2022 program
        const pyUSD = "2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo";
        const crt = "CRTx1JouZhzSU6XytsE42UQraoGqiHgxabocVfARTy2s";
        const isToken22 = mint === pyUSD || mint === crt;

        const account = await getAssociatedTokenAddress(
          new PublicKey(mint),
          new PublicKey(address),
          undefined,
          isToken22 ? TOKEN_2022_PROGRAM_ID : undefined
        );

        setTokenAddress(account.toBase58());
      } catch (error) {
        console.error("Error fetching token account:", error);
        setTokenAddress("");
      }
    };

    fetchTokenAccount();
  }, [mint, address, enabled]);

  return tokenAddress;
};
