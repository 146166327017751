import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Fuse from "fuse.js";
import { noop } from "lodash";
import debounce from "lodash/debounce";
import { compose } from "lodash/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isBrowser } from "react-device-detect";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  AccountTypeEnum,
  ImportTypeEnum,
  MutationCreateAccountArgs,
  Query,
} from "src/api/generated/types";
import { AwakenTooltip, Info, Input } from "src/components/styled";
import StatusTag from "src/components/styled/StatusTag";
import { config } from "src/config";
import { Maybe } from "src/core";
import { useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import {
  ALLOWED_EVM_PROVIDERS,
  EVM_PROVIDERS,
  isValidEVMAddress,
  isValidSolanaAddress,
} from "src/modules/ledger/accounts";
import { colors, other } from "src/theme";
import { trackEvent } from "src/utils/analytics";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { ManualAccountModal } from "../ManualAccountModal";
import { Wallets } from "./Wallets";
import {
  APPLICABLE_INTEGRATIONS,
  ExtraordinaryChain,
  GENERAL_BLOCKCHAIN_INTEGRATION,
  GENERAL_EXCHANGE_INTEGRATION,
} from "./constants";
import {
  IntegrationAction,
  IntegrationOption,
  IntegrationOptionProps,
  IntegrationProviderInfo,
} from "./types";

const HATCHFI_CLIENT_ID = config.hatchfi.clientId;

const useHandleHatchfiLink = (clientId?: Maybe<string>) => {
  const [getLink] = useMutation(api.accounts.getHatchfiLink);
  const toast = useMyToast();

  const _navigateToLink = async (
    info: IntegrationProviderInfo,
    usedIntegration: IntegrationOption
  ) => {
    if (!clientId) {
      return toast.show({
        status: "error",
        message: "Missing client.",
      });
    }

    const response = await getLink({
      variables: {
        clientId,
      },
    });

    const token = response.data.getHatchfiLink;

    const link = `https://link.hatchfi.co/?clientId=${HATCHFI_CLIENT_ID}&token=${token}&redirect=true&provider=${usedIntegration.hatchfiProvider}`;

    localStorage.setItem(
      "v1:account_link:redirect_url",
      window.location.pathname
    );
    localStorage.setItem("v1:account_link:integration_name", info.name);
    localStorage.setItem("v1:account_link:client_id", clientId);

    window.location.href = link;
  };

  return _navigateToLink;
};

export const useHandleVezgoLink = (clientId?: Maybe<string>) => {
  const [getLink] = useMutation(api.accounts.getVezgoLink);
  const toast = useMyToast();

  const _navigateToLink = async (
    info: Pick<IntegrationProviderInfo, "name">,
    usedIntegration: Pick<IntegrationOption, "vezgoProvider">
  ) => {
    if (!clientId) {
      return toast.show({
        status: "error",
        message: "Missing client. Please contact support.",
      });
    }

    if (!usedIntegration.vezgoProvider) {
      return toast.show({
        status: "error",
        message: "No vezgo provider. Please contact support.",
      });
    }

    const response = await getLink({
      variables: {
        clientId,
        provider: usedIntegration.vezgoProvider,
      },
    });

    const connectUrl = response.data.getVezgoLink?.connectUrl;

    localStorage.setItem(
      "v1:account_link:redirect_url",
      window.location.pathname
    );
    localStorage.setItem("v1:account_link:integration_name", info.name);
    localStorage.setItem("v1:account_link:client_id", clientId);

    window.location.href = connectUrl;
  };

  return _navigateToLink;
};

export const useHandleGetOAuthLink = (clientId?: Maybe<string>) => {
  const [getLink] = useMutation(api.accounts.getOAuthLink);
  const toast = useMyToast();

  const _getOAuthLink = async (
    info: Pick<IntegrationProviderInfo, "provider" | "name">
  ): Promise<Maybe<string>> => {
    if (!clientId) {
      toast.show({
        status: "error",
        message: "Missing client.",
      });
      return null;
    }

    const response = await getLink({
      variables: {
        clientId,
        provider: info.provider,
      },
    });

    const url = response.data.getOAuthLink?.url;

    localStorage.setItem("v1:oauth_link:integration_name", info.name);
    localStorage.setItem("v1:oauth_link:client_id", clientId);
    localStorage.removeItem("v1:oauth_link:account_id"); // remove the accountId just in case it is set

    return url;
  };

  return _getOAuthLink;
};

const options: Fuse.IFuseOptions<IntegrationProviderInfo> = {
  includeScore: true,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.4, // 0.2 means it is pretty strict match
  keys: ["name", "infoMessage", "keywords", "keywordsV2"],
};

const fuse = new Fuse(APPLICABLE_INTEGRATIONS, options);

function AccountsWorkflow({
  onSuccess,
  isReadOnly,
}: {
  onSuccess: () => void;
  isReadOnly?: boolean;
}) {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const _showModal = compose(dispatch, show);
  const [loadingIntegration, setLoadingIntegration] =
    useState<Maybe<string>>(null);
  const _handleHatchfiLink = useHandleHatchfiLink(clientId);
  const _handleVezgoLink = useHandleVezgoLink(clientId);
  const _handleGetOAuthLink = useHandleGetOAuthLink(clientId);

  const _handleOauth = async (info: IntegrationProviderInfo) => {
    const link = await _handleGetOAuthLink(info);

    if (link) window.location.href = link;
  };

  const _onAddIntegration = async (
    info: IntegrationProviderInfo,
    usedIntegration: IntegrationOption
  ) => {
    setLoadingIntegration(info.name);

    try {
      switch (usedIntegration.action) {
        case IntegrationAction.Oauth:
          await _handleOauth(info);
          break;
        case IntegrationAction.HatchfiLink:
          await _handleHatchfiLink(info, usedIntegration);
          break;
        case IntegrationAction.VezgoLink:
          await _handleVezgoLink(info, usedIntegration);
          break;

        case IntegrationAction.FileUpload:
          _showModal("AccountFileUploadModal", {
            integration: info,
            mode: "new_account",
            provider: usedIntegration.provider,
            accountId: null,
            option: usedIntegration,
            onSuccess: () => onSuccess(),
            ...usedIntegration.modalProps,
          });
          break;
        case IntegrationAction.AwakenCsvFileUpload:
          _showModal("AwakenCSVUploadModal", {
            integration: info,
            mode: "new_account",
            provider: usedIntegration.provider,
            accountId: null,
            option: usedIntegration,
            message: info.awakenCsvModalDescription,
            onSuccess: () => onSuccess(),
          });
          break;
        case IntegrationAction.TriggerModal:
          if (!usedIntegration.modalName) {
            break;
          }
          _showModal(usedIntegration.modalName, {
            integration: info,
            provider: usedIntegration.provider,
            option: usedIntegration,
            onSuccess: () => onSuccess(),
            ...usedIntegration.modalProps,
          });
          break;
        default:
          break;
      }
    } catch (err) {
      // TODO: alert the error
    } finally {
      setLoadingIntegration(null);
    }
  };

  // Debounce user input and send to Segment
  const debouncedSearch = React.useRef(
    debounce((term) => {
      trackEvent("Search Integration", { term });
    }, 250)
  ).current;

  useEffect(() => {
    if (search && search.length >= 2) debouncedSearch(search);
  }, [search, debouncedSearch]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const validIntegrations = useMemo(() => {
    if (isValidEVMAddress(search)) {
      return APPLICABLE_INTEGRATIONS.filter((v) =>
        EVM_PROVIDERS.has(v.provider)
      );
    }
    // HSxD9ytq1QCYSPgqUkkFAYzFq9W7PPL5K5qepfDFDtBp
    if (isValidSolanaAddress(search)) {
      return APPLICABLE_INTEGRATIONS.filter((v) => v.provider === "solana");
    }

    const fuseItems = fuse.search(search).map((v) => v.item);

    return fuseItems;
  }, [search]);
  const integrations = search ? validIntegrations : APPLICABLE_INTEGRATIONS;
  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  return (
    <>
      {/* other referenced modals */}
      <ManualAccountModal />

      <Container padding="0" paddingBottom="1rem" width="100%" margin="0">
        <Box>
          <Input
            autoFocus={true}
            flex={1}
            containerStyle={{ marginBottom: 0 }}
            value={search}
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            focusBorderColor={colors.primary}
            iconLeft={
              <i
                style={{ color: colors.gray50, fontSize: 14 }}
                className="fa-sharp fa-search"
              />
            }
          />

          <Divider style={{ margin: "1.5rem 0" }} />

          {/* {!integrations.length && (
            <Text fontSize="md" marginTop="1rem">
              Don't see the exchange/blockchain you are looking for?{" "}
              <a
                style={{
                  color: colors.primary,
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Send us a message.
              </a>
            </Text>
          )} */}
        </Box>

        <Wallets
          onSuccess={onSuccess}
          isReadOnly={isReadOnly}
          search={search || ""}
        />

        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={2}
          marginTop="15px"
        >
          {/* {!search && <ManualAccount />} <- users are getting confused by this */}

          {integrations.map((integration) => (
            <IntegrationOptionComponent
              key={integration.name}
              loadingIntegration={loadingIntegration}
              onAddIntegration={_onAddIntegration}
              integration={integration}
              onSuccess={onSuccess}
              isReadOnly={isReadOnly}
            />
          ))}
          {integrations.length === 0 && GENERAL_EXCHANGE_INTEGRATION && (
            <IntegrationOptionComponent
              key={GENERAL_EXCHANGE_INTEGRATION.name}
              loadingIntegration={loadingIntegration}
              onAddIntegration={_onAddIntegration}
              integration={GENERAL_EXCHANGE_INTEGRATION}
              onSuccess={onSuccess}
            />
          )}
          {integrations.length === 0 && GENERAL_BLOCKCHAIN_INTEGRATION && (
            <IntegrationOptionComponent
              key={GENERAL_BLOCKCHAIN_INTEGRATION.name}
              loadingIntegration={loadingIntegration}
              onAddIntegration={_onAddIntegration}
              integration={GENERAL_BLOCKCHAIN_INTEGRATION}
              onSuccess={onSuccess}
            />
          )}

          {/* if there is a search, put manual at the bottom */}
          {/* {search && <ManualAccount />} <- users are getting confused by this */}
        </Grid>
      </Container>
    </>
  );
}

const IntegrationOptionComponent = ({
  integration,
  loadingIntegration,
  onAddIntegration,
  onSuccess,
  isReadOnly,
}: IntegrationOptionProps) => {
  const [url, setUrl] = useState<string | null>(integration.logoUrl);
  const theme = useTheme();

  return (
    <GridItem gap={1} colSpan={1}>
      <Box
        h="100%"
        border={`1px solid ${theme.border}`}
        margin="0"
        marginBottom="1rem"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bg={theme.background}
        borderRadius={15}
        padding="0.75rem 0.5rem"
      >
        <HStack>
          {url ? (
            <img
              style={{
                width: "2.5rem",
                height: "2.5rem",
                backgroundColor: theme.medBackground,
                borderRadius: integration.name.toLowerCase().includes("general")
                  ? 0
                  : 10,
                objectFit: "contain",
              }}
              src={url}
              alt={integration.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "";
                setUrl(null);
              }}
            />
          ) : (
            <Box
              style={{
                width: "2.5rem",
                height: "2.5rem",
                justifyContent: "center",
                display: "flex",
                flexDirection: "revert",
                color: theme.background,
                alignItems: "center",
                borderRadius: 5,
                fontSize: 14,
                fontWeight: "bold",
              }}
              bg={theme.header}
            >
              {integration.name?.charAt(0).toUpperCase()}{" "}
            </Box>
          )}

          <VStack
            marginLeft="0.5rem !important"
            w="100%"
            flex={1}
            alignItems="start"
          >
            <HStack w="100%" flex={1}>
              <VStack alignItems="flex-start" flex={1}>
                <Text color={theme.header} style={{ fontSize: 16 }}>
                  {integration.name}{" "}
                  {integration.infoMessage && (
                    <Info message={integration.infoMessage} />
                  )}{" "}
                  {integration.isNew && isBrowser && (
                    <AwakenTooltip label="If you have any problems, just send us a message and we'll be right on it!">
                      <span style={{ marginLeft: "5px" }}>
                        <motion.div
                          whileHover={{ scale: 1.07 }}
                          whileTap={{ scale: 0.9 }}
                          style={{ display: "inline-block" }}
                        >
                          <StatusTag
                            type="info"
                            label="New"
                            iconName="fa-sharp fa-badge-check"
                            iconStyle={{ fontSize: 13 }}
                          />
                        </motion.div>
                      </span>
                    </AwakenTooltip>
                  )}
                  {integration.isFree && isBrowser && (
                    <AwakenTooltip
                      label={`For the tax year ${
                        integration.name
                      } is free! This means the transactions don't count towards the number of transactions you have to pay for. ${
                        integration.provider === "base"
                          ? "You have to add the wallet to Awaken before Jan 1st 2025 though, otherwise it's not free! So add a wallet and tell a friend."
                          : ""
                      }`}
                    >
                      <span style={{ marginLeft: "5px" }}>
                        <motion.div
                          whileHover={{ scale: 1.07 }}
                          whileTap={{ scale: 0.9 }}
                          style={{ display: "inline-block" }}
                        >
                          <StatusTag
                            type="success"
                            label="Free"
                            iconName="fa-sharp fa-party-horn"
                            iconStyle={{ fontSize: 13 }}
                          />
                        </motion.div>
                      </span>
                    </AwakenTooltip>
                  )}
                  {integration.isBeta && isBrowser && (
                    <AwakenTooltip label="This integration is in beta, so if you have any problems just send us a message and we'll be right on it!">
                      <span style={{ marginLeft: "5px" }}>
                        <motion.div
                          whileHover={{ scale: 1.07 }}
                          whileTap={{ scale: 0.9 }}
                          style={{ display: "inline-block" }}
                        >
                          <StatusTag
                            type="beta"
                            label="Beta"
                            iconName="fa-sharp fa-flask-vial"
                            iconStyle={{ fontSize: 13 }}
                          />
                        </motion.div>
                      </span>
                    </AwakenTooltip>
                  )}
                  {ALLOWED_EVM_PROVIDERS.has(integration.provider) &&
                    isBrowser && (
                      <AwakenTooltip
                        label={`When you add this wallet, we'll automatically pull in transactions for other EVM chains including ${Array.from(
                          ALLOWED_EVM_PROVIDERS
                        ).join(", ")}.`}
                      >
                        <span style={{ marginLeft: "5px" }}>
                          <motion.div
                            whileHover={{ scale: 1.07 }}
                            whileTap={{ scale: 0.9 }}
                            style={{ display: "inline-block" }}
                          >
                            <StatusTag
                              type="info"
                              label="All EVM"
                              iconName="fa-sharp fa-check-circle"
                              iconStyle={{ fontSize: 13 }}
                            />
                          </motion.div>
                        </span>
                      </AwakenTooltip>
                    )}
                </Text>

                {integration.isExtraordinary && !isWhiteLabeledDomain() && (
                  <ExtraordinaryChain />
                )}
              </VStack>

              <HStack>
                {isReadOnly ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      // gap in between each
                      gap: "0.5rem",
                    }}
                  >
                    {!!integration.options.find(
                      (o) =>
                        o.action === IntegrationAction.TriggerModal ||
                        o.action === IntegrationAction.VezgoLink ||
                        o.action === IntegrationAction.Oauth ||
                        o.action === IntegrationAction.HatchfiLink
                    ) && (
                      <StatusTag
                        infoMessage="Just add your public keys and we'll pull in all your transactions!"
                        type="success"
                        label="Automated"
                        iconName="fa-sharp fa-check-circle"
                      />
                    )}

                    {!!integration.options.find(
                      (o) => o.action === IntegrationAction.FileUpload
                    ) && (
                      <StatusTag
                        infoMessage="Just download your CSV from the exchange and you can upload it as is to Awaken. We do this for exchanges that sometimes have unreliable data streams."
                        type="info"
                        label="Exchange CSV"
                        iconName="fa-sharp fa-check-circle"
                      />
                    )}

                    <StatusTag
                      infoMessage="Upload a CSV file in Awaken's easy to use format (you can use our AI tool which may be able to help)!"
                      type="none"
                      label="Awaken CSV"
                      iconName="fa-sharp fa-file-csv"
                    />
                  </div>
                ) : (
                  <>
                    {integration.options.map((option) => (
                      <IntegrationButton
                        key={option.action}
                        integration={integration}
                        option={option}
                        onSuccess={onSuccess}
                        loadingIntegration={loadingIntegration}
                        onAddIntegration={onAddIntegration}
                      />
                    ))}
                  </>
                )}
              </HStack>
            </HStack>
          </VStack>
        </HStack>

        {!!integration.description && (
          <div style={{ marginTop: "0px" }}>
            {typeof integration.description === "string" ? (
              <Text color={theme.text} fontSize="xs" marginTop="5px !important">
                {integration.description ||
                  "Automated support coming in 1-2 months"}
              </Text>
            ) : (
              integration.description
            )}
          </div>
        )}
      </Box>
    </GridItem>
  );
};

const IntegrationButton = (
  props: IntegrationOptionProps & {
    option: IntegrationOption;
  }
) => {
  const { me } = useMe("cache-only");
  const { loadingIntegration, option, onAddIntegration, integration } = props;
  const isSuperUser = me?.isSuperuser || false;
  const theme = useTheme();

  if (option.onlySuperUser && !isSuperUser) {
    return null;
  }

  if (option.action === IntegrationAction.PlaidLink) {
    return <PlaidLinkButton onSuccessAddAccount={props.onSuccess} {...props} />;
  }

  if (option.action === IntegrationAction.FileUpload) {
    return (
      <AwakenTooltip
        label={`Upload the CSV file you got from ${integration.name}.`}
      >
        <span>
          <Button
            key={option.action}
            isLoading={loadingIntegration === integration.name}
            disabled={!!loadingIntegration}
            size="sm"
            borderRadius="7px !important"
            style={{ fontSize: 12 }}
            onClick={() => onAddIntegration(integration, option)}
            bg={theme.secondaryBackground}
            color={theme.header}
            _hover={{ bg: theme.ternaryBackground }}
          >
            {option.buttonText || "Connect"}{" "}
            <i
              style={{ marginLeft: 7 }}
              className="fa-sharp fa-file-arrow-up"
            />
          </Button>
        </span>
      </AwakenTooltip>
    );
  }

  if (option.action === IntegrationAction.AwakenCsvFileUpload) {
    return (
      <AwakenTooltip message="Upload a CSV file in the Awaken format">
        <span>
          <Button
            key={option.action}
            isLoading={loadingIntegration === integration.name}
            disabled={!!loadingIntegration}
            size="sm"
            borderRadius="7px !important"
            style={{ fontSize: 12 }}
            onClick={() => onAddIntegration(integration, option)}
            bg={theme.secondaryBackground}
            color={theme.header}
            _hover={{ bg: theme.ternaryBackground }}
          >
            <i className="fa-sharp fa-upload" />
          </Button>
        </span>
      </AwakenTooltip>
    );
  }

  return (
    <Button
      key={option.action}
      isLoading={loadingIntegration === integration.name}
      disabled={!!loadingIntegration}
      size="sm"
      borderRadius="7px !important"
      style={{ fontSize: 12 }}
      onClick={() => onAddIntegration(integration, option)}
      bg={theme.secondaryBackground}
      color={theme.header}
      _hover={{ bg: theme.ternaryBackground }}
    >
      {option.buttonText || "Connect"}
    </Button>
  );
};

const Free2024Tag = ({ label }: { label: string }) => {
  return (
    <AwakenTooltip
      openDelay={0}
      message={`All wallets for ${label} are free for the 2024 tax year! Make sure to tell your friends 🫡.`}
    >
      <div>
        <Button
          style={{
            height: 30,
            fontSize: 12,
            background: `linear-gradient(to right, rgb(31, 162, 255), rgb(77 246 147))`,
            color: "#fff",
            borderRadius: 0,
            fontWeight: "black",
          }}
          borderRadius={"5px !important"}
        >
          Free
          <i style={{ marginLeft: 5 }} className={"fa-sharp fa-party-horn"} />
        </Button>
      </div>
    </AwakenTooltip>
  );
};

const PlaidLinkButton = ({
  loadingIntegration,
  option,
  integration,
  onSuccessAddAccount,
}: Omit<IntegrationOptionProps, "onAddIntegration"> & {
  option: IntegrationOption;
  onSuccessAddAccount: () => void;
}) => {
  const myToast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const [createAccount] = useMutation(api.accounts.create);
  const { data } = useQuery<Pick<Query, "getPlaidLinkToken">>(
    api.accounts.getPlaidLinkToken,
    {
      fetchPolicy: "no-cache",
    }
  );

  const linkToken = data?.getPlaidLinkToken;

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string) => {
      if (!clientId) {
        return;
      }

      // create the account here
      const createAccountArgs: MutationCreateAccountArgs = {
        code: null,
        provider: integration.provider,
        type: AccountTypeEnum.Exchange,
        redirectUrl: null,
        address: null,
        plaidPublicToken: public_token,
        importType: ImportTypeEnum.Plaid,
        label: null,
        clientId: clientId || null,
      };

      try {
        await createAccount({
          variables: createAccountArgs,
          refetchQueries: [api.clients.accounts, api.portfolio.get],
        });

        myToast.show({
          message: `Importing transactions 🤖 This may take a while.`,
          status: "success",
        });

        onSuccessAddAccount();
      } catch (err) {
        myToast.show({
          message: `Error importing transactions. ${(err as Error)?.message}`,
          status: "error",
        });
      }
    },
    []
  );

  const config: PlaidLinkOptions = {
    onSuccess,
    onExit: noop,
    onEvent: noop,
    token: linkToken || "",
  };

  const { open, exit, ready } = usePlaidLink(config);

  return (
    <Button
      key={option.action}
      isLoading={loadingIntegration === integration.name}
      disabled={!!loadingIntegration}
      size="sm"
      borderRadius="7px !important"
      style={{ fontSize: 12 }}
      onClick={() => open()}
      bg={colors.gray90}
    >
      {option.buttonText || "Connect"}
    </Button>
  );
};

const ManualAccount = () => {
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  const _onAddManualAccount = () => {
    _showModal("ManualAccountModal");
  };

  return (
    <HStack
      border={other.boxBorder}
      margin="0"
      h="100%"
      marginBottom="0.5rem"
      bg={colors.white}
      borderRadius="0.25rem"
      padding="1rem"
    >
      <div
        style={{
          width: "2.25rem",
          height: "2.25rem",
          borderRadius: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colors.gray90,
        }}
      >
        <i
          className="fa-sharp fa-university"
          style={{ fontSize: 18, color: colors.gray30 }}
        />
      </div>

      <Text marginLeft="1rem !important" flex={1}>
        Manual Account{" "}
        <Info message="This is an account that you can manually add transactions for." />
      </Text>

      <Button
        isLoading={false}
        bg={colors.gray90}
        size="sm"
        onClick={_onAddManualAccount}
      >
        Create
      </Button>
    </HStack>
  );
};

export { AccountsWorkflow };
