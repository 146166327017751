import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { api } from "src/api";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import { QueryGetClientTransactionsArgs } from "src/api/generated/types";
import { Maybe } from "src/core";

export const useTransactions = (
  clientId: Maybe<string>,
  assetKeyV2: Maybe<string>,
  page: number,
  limit = 10
): {
  transactions: BaseSimpleTransactionFields[];
  total: number;
  loading: boolean;
} => {
  const variables = useMemo(
    (): QueryGetClientTransactionsArgs => ({
      clientId: clientId || "",
      assetKeyV2,
      page,
      limit: limit,
      includeSpam: false,
    }),
    [clientId, assetKeyV2, page]
  );

  const { data, loading, fetchMore, networkStatus } = useQuery<{
    getClientTransactions: {
      transactions: BaseSimpleTransactionFields[];
      total: number;
    };
  }>(api.clients.transactions, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !variables.clientId || !variables.assetKeyV2,
    fetchPolicy: "cache-and-network",
  });

  const transactions = useMemo(() => {
    if (data?.getClientTransactions?.transactions) {
      return data.getClientTransactions.transactions;
    }
    return [];
  }, [data?.getClientTransactions?.transactions]);

  return {
    loading,
    transactions: transactions,
    total: data?.getClientTransactions?.total || 0,
  };
};
