import { useMutation, useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import { Rule } from "src/api/generated/types";
import { Button } from "src/components";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import StatusTag from "src/components/styled/StatusTag";
import WhiteBox from "src/components/styled/WhiteBox";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import Helpers from "src/utils/helpers";
import Loading from "src/views/Loading";
import { Transaction } from "../Transactions/Transaction";

function Rules() {
  const { clientId } = useParams();
  const toast = useMyToast();

  const { data: rulesData, loading: rulesLoading } = useQuery<{
    getRulesForClient: Rule[];
  }>(api.clients.rules, {
    variables: { clientId },
    fetchPolicy: "cache-and-network",
  });
  const [removeRule] = useMutation(api.rules.removeRule);

  const _removeRule = async (ruleId: string, rule: Rule) => {
    try {
      const confirm = window.confirm(
        `Are you sure you want to delete this rule? It will remove it from ${rule.label} and all ${rule?.txnCount} transactions that match it.`
      );

      if (!confirm) {
        return;
      }

      await removeRule({
        variables: { ruleId },
        refetchQueries: [api.clients.rules],
      });
      toast.show({
        message: "Rule deleted successfully",
        status: "success",
      });
    } catch (error) {
      console.error("Error deleting rule:", error);
      toast.show({
        message: "Failed to delete rule",
        status: "error",
      });
    }
  };

  // Color mode values
  const theme = useTheme();

  if (rulesLoading && !rulesData) {
    return <Loading />;
  }

  const existingRules = rulesData?.getRulesForClient || [];

  console.log(existingRules);

  return (
    <div
      style={{
        backgroundColor: theme.background,
      }}
    >
      <HStack spacing={4}>
        <Heading flex={1} size="lg" color={theme.header}>
          Rules ({existingRules.length})
        </Heading>
      </HStack>

      <Text color={theme.text} fontSize="md" mb={8}>
        Rules are created automatically as you label transactions (only for EVM
        at the moment).
      </Text>

      {/* Existing Rules Section */}
      <WhiteBox padding="0" mb={8} bg={theme.background}>
        <Accordion allowMultiple>
          {existingRules.map((rule) => {
            const txns = (rule.transactions ??
              []) as BaseSimpleTransactionFields[];
            const providerLogoUrl = PROVIDER_TO_LOGO_URL[rule.provider];
            const name =
              rule.label || rule.type.split(":")[1]?.replace(/_/g, " ").trim();
            return (
              <AccordionItem
                borderRadius={"0 !important"}
                key={rule.id}
                borderColor={"transparent"}
              >
                <AccordionButton
                  borderBottom={"1px solid"}
                  borderColor={theme.border}
                  borderRadius={"0 !important"}
                  _hover={{ bg: theme.medBackground }}
                >
                  <HStack padding="10px 5px" flex="1" spacing={4}>
                    <HStack spacing={4} alignItems="center">
                      {providerLogoUrl ? (
                        <Box w="25px" h="25px">
                          <img
                            src={providerLogoUrl}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          w="25px"
                          h="25px"
                          bg={theme.medBackground}
                          borderRadius="md"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text
                            color={theme.text}
                            fontSize="sm"
                            fontWeight="medium"
                          >
                            {rule.provider.charAt(0).toUpperCase()}
                          </Text>
                        </Box>
                      )}
                      <VStack style={{ alignItems: "flex-start" }}>
                        <Text
                          color={theme.text}
                          fontSize="md"
                          fontWeight="medium"
                        >
                          {Helpers.capitalizeAllWords(name)}
                        </Text>
                      </VStack>

                      <StatusTag
                        boxProps={{
                          style: { padding: "2px 5px", borderRadius: 100 },
                        }}
                        label={rule.type.trim()}
                        type="info"
                        infoMessage={`Any transaction that matches the following constraints will match against this rule. Constraints: ${JSON.stringify(
                          JSON.parse(rule.constraints)
                        )}`}
                      />
                    </HStack>

                    <Text color={theme.text} fontSize="sm">
                      Show {rule.txnCount} transactions{" "}
                      <AccordionIcon color={theme.text} />
                    </Text>
                  </HStack>

                  <HStack>
                    <Button
                      size="sm"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation(); // Prevent accordion from toggling
                        await _removeRule(rule.id, rule);
                      }}
                      style={{
                        background: colors.red50,
                        color: colors.white,
                        marginRight: 15,
                      }}
                    >
                      Delete{" "}
                      <i
                        style={{ marginLeft: 5 }}
                        className="fa-solid fa-trash-can"
                      ></i>
                    </Button>
                  </HStack>
                </AccordionButton>
                <AccordionPanel py={0} px={0} borderColor={"transparent"}>
                  <TableContainer borderColor={"transparent"}>
                    <Table variant="simple">
                      <Tbody>
                        {txns.map((transaction) => (
                          <Transaction
                            style={{
                              backgroundColor: theme.medBackground,
                            }}
                            key={transaction.id}
                            transaction={transaction}
                            timezone="UTC"
                            limitedView
                          />
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </WhiteBox>
    </div>
  );
}

export default Rules;
