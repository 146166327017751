import {
  Box,
  Center,
  Divider,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BlackIcon from "src/assets/awaken/logos/black-icon.png";
import Logo from "src/assets/awaken/logos/logo-name.png";
import WhiteLogo from "src/assets/awaken/logos/white-logo.png";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";

import { useLazyQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInWithCustomToken } from "firebase/auth";
import { isNil } from "lodash/fp";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { api } from "src/api";
import { BaseUserFields } from "src/api/fragments";
import {
  MutationCreateUserWithEmailArgs,
  Query,
} from "src/api/generated/types";
import { Button, Input } from "src/components";
import SecondaryText from "src/components/styled/SecondaryText";
import { config } from "src/config";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  success,
  UnexpectedError,
} from "src/core";
import { useMyToast } from "src/hooks";
import { useReferralCode } from "src/hooks/useReferralCode";
import { useTheme } from "src/hooks/useTheme";
import useRecaptchaV3 from "src/navigation/useRecaptcha";
import { colors, other } from "src/theme";
import { auth } from "src/utils/firebase";
import { D } from "src/utils/helpers";
import { getMetadataInfo, isWhiteLabeledDomain } from "src/utils/whitelabel";
import * as yup from "yup";
import { AppleButton } from "../components/Apple";
import { GoogleButton } from "../components/Google";
import { getRedirectPath } from "../utils";

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is required.").nullable(),
  email: yup.string().email().required("Email is required.").nullable(),
  password: yup.string().min(6).required("Password is required.").nullable(),
  referralCode: yup.string().nullable(),
});

type FormValues = {
  email: string;
  password: string;
  fullName: string;
  referralCode: string;
};

const DEFAULT_VALUES: FormValues = {
  email: "",
  password: "",
  fullName: "",
  referralCode: "",
};

export function Signup() {
  const [search] = useSearchParams();
  const { ref, setRefCodeLocalStorage } = useReferralCode();
  const toast = useMyToast();
  const navigate = useNavigate();

  const email = search.get("email") || null;
  const fullName = search.get("fullName") || null;

  const [getSpecialCodeInfo, { data }] = useLazyQuery<
    Pick<Query, "getSpecialCodeInfo">
  >(api.referrals.getSpecialCodeInfo);

  // API hooks
  const [createUser] = useMutation<{
    createUserWithEmail?: { user: BaseUserFields; customToken: string };
  }>(api.users.createWithEmail);
  const [getMe] = useLazyQuery<{ me?: BaseUserFields }>(api.users.me, {
    fetchPolicy: "no-cache",
  });

  // Form hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...DEFAULT_VALUES,
      email: email || "",
      fullName: fullName || "",
      referralCode: ref || "",
    },
  });

  const _logAuthError = ({ message }: { message: string }) => {
    toast.show({ message, status: "error" });
  };

  const _logTwitter = (email: string | null) => {
    try {
      (window as any).twq("event", "tw-p5zwo-p60d8", {
        email_address: email || null,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const _navigateForUser = (
    user?: BaseUserFields,
    partialPath = "accounts"
  ) => {
    setRefCodeLocalStorage(null);
    window.localStorage.removeItem("first_referral_code");

    const path = getRedirectPath(user, partialPath);
    const hasCredit =
      !isNil(user?.referredCreditCents) && (user?.referredCreditCents || 0) > 0;
    const hasDiscount =
      !isNil(user?.referredDiscountPercentage) &&
      (user?.referredDiscountPercentage || 0) > 0;

    if (user) {
      _logTwitter(user?.email || null);
    }

    // if the user has a valid referred by code -> we want to redirect them to a welcome page
    if (hasCredit || hasDiscount) {
      const amountFreeCreditCents = user?.referredCreditCents ?? 0;
      const referredDiscountPercentage = user?.referredDiscountPercentage ?? 0;

      navigate(
        `/welcome?redirect=${path}&referralCode=${
          user?.referredByCode || ""
        }&amountFreeCreditCents=${amountFreeCreditCents}&referredDiscountPercentage=${referredDiscountPercentage}`
      );
      return;
    }

    return navigate(`/welcome?redirect=${path}`);
  };

  // Functions
  const _createUser = useCallback(
    async (
      values: FormValues
    ): Promise<FailureOrSuccess<DefaultErrors, BaseUserFields>> => {
      try {
        const referredDomainUrl = window.location.hostname;

        const params: MutationCreateUserWithEmailArgs = {
          email: values.email,
          name: values.fullName,
          phoneNumber: null,
          password: values.password,
          referralCode: values.referralCode,
          referredDomainUrl,
        };

        const userResponse = await createUser({
          variables: params,
        });

        if (!userResponse.data?.createUserWithEmail) {
          return failure(new Error("No user returned."));
        }

        const { customToken, user } = userResponse.data.createUserWithEmail;

        await signInWithCustomToken(auth, customToken);

        return success(user);
      } catch (err) {
        return failure(new UnexpectedError(err));
      }
    },
    [createUser, ref]
  );

  const [requiresCheckbox, setRequiresCheckbox] = useState(false);
  const { recaptchaRef, verifyV2, verifyV3 } = useRecaptchaV3(
    config.recaptcha.v3Token,
    "signup"
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        // Add verification
        if (requiresCheckbox) {
          const verificationResponse = await verifyV2();

          if (verificationResponse.isFailure()) {
            toast.show({
              status: "error",
              message:
                verificationResponse.error.message ||
                "Failed to get reCAPTCHA v2 token.",
            });
            return;
          }
        } else {
          const verificationResponse = await verifyV3();

          if (verificationResponse.isFailure()) {
            toast.show({
              status: "error",
              message:
                verificationResponse.error.message ||
                "Failed to get reCAPTCHA v3 token.",
            });
            return;
          }

          const needToVerifyCheckbox =
            verificationResponse.value.requiresCheckbox;

          if (needToVerifyCheckbox) {
            setRequiresCheckbox(true);
            recaptchaRef.current?.reset();
            toast.show({
              status: "error",
              message: "Please fill out the reCAPTCHA.",
            });
            return;
          }
        }

        // Rest of the existing onSubmit logic
        const currentUser = auth.currentUser;

        // if there is already a user locally and they have the same email,
        // check to see if they already exist in our db. and if not create the user otherwise can return
        if (currentUser && currentUser.email === values.email) {
          const res = await getMe();

          // if the user is trying to create an account for an email they are already signed in as,
          // just move them to the dashboard because they are good
          if (res.data?.me) {
            return _navigateForUser(res.data.me, "dashboard");
          }

          // if there is already a user for this email and they are already logged in with the firebase
          // user we just need to create their user in our system and send them to the dashboard
          // Note: this is safe because the authentication header will be set with a jwt that verified legitimacy
          // of the firebase account
          if (res.error || !res.data?.me) {
            const userResponse = await _createUser(values);

            if (userResponse.isSuccess()) {
              return _navigateForUser(userResponse.value);
            }
          }
        }

        const userResponse = await _createUser(values);

        if (userResponse.isFailure()) {
          return toast.show({
            status: "error",
            message: userResponse.error.message,
          });
        }

        return _navigateForUser(userResponse.value);
      } catch (err) {
        return toast.show({
          status: "error",
          message: (err as Error)?.message || "An unknown error occurred.",
        });
      }
    },
    [_createUser, requiresCheckbox]
  );

  useEffect(() => {
    if (ref) {
      getSpecialCodeInfo({ variables: { referralCode: ref } });
    }
  }, [ref]);

  const isWhiteLabel = isWhiteLabeledDomain();
  const metadata = getMetadataInfo();
  const theme = useTheme();

  return (
    <NoNavBarPageTemplate>
      <VStack
        padding="2rem 0"
        flexDir="column"
        minH="100vh"
        display="flex"
        justifyContent="center"
      >
        {/* <FreeTransactions refCode={ref} /> */}
        <Box
          display="flex"
          flexDir="column"
          maxWidth="35rem"
          margin="auto"
          padding="2rem"
          w="100%"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <form
            onSubmit={handleSubmit(onSubmit, (e) =>
              toast.show({
                status: "error",
                message:
                  e.email?.message ||
                  e.fullName?.message ||
                  e.password?.message ||
                  e.referralCode?.message ||
                  "",
              })
            )}
          >
            <Logos data={data?.getSpecialCodeInfo} />

            <GoogleButton
              label="Sign up"
              onError={_logAuthError}
              onSuccess={(u, fb, isNew) =>
                _navigateForUser(u, isNew ? "accounts" : "dashboard")
              }
              referralCode={ref}
            />
            <div style={{ height: 10 }} />
            <AppleButton
              label="Sign up"
              onError={_logAuthError}
              onSuccess={(u, fb, isNew) =>
                _navigateForUser(u, isNew ? "accounts" : "dashboard")
              }
              referralCode={ref}
            />
            <HStack margin="1rem 0">
              <Divider />
              <SecondaryText text="or" margin="0 1rem" />
              <Divider />
            </HStack>

            <Box width="100%">
              <Input
                label="Full Name"
                isRequired
                control={control}
                autoComplete="name"
                infoMessage="This name is added to tax reports you generate (it is required by the government). We don't use it for anything else. If you want to stay fully anonymous, you can put your name as 'Anonymous' and always edit it in the PDFs we generate for you outside of Awaken."
                name="fullName"
              />
              <Input
                label="Email"
                isRequired
                autoComplete="email"
                control={control}
                name="email"
              />
              <Input
                label="Password"
                isRequired
                control={control}
                type="password"
                name="password"
              />

              {/* {ref && (
                <Input
                  label="Your code"
                  control={control}
                  bg={colors.green100}
                  borderColor={colors.green50}
                  focusBorderColor={colors.green50}
                  infoMessage="This will automatically be applied when you signup."
                  name="referralCode"
                />
              )} */}

              {/* <Checkbox>
                <Text fontSize="xs">
                  I opt-in to receiving notification emails regarding my
                  account.
                </Text>
              </Checkbox> */}
            </Box>

            <Text textAlign="center" fontSize="xs" color={theme.text}>
              By signing up, you agree to our{" "}
              <Link
                style={{
                  color: colors.primary,
                  fontWeight: "bold",
                }}
                to="/legal/terms-of-service"
              >
                Terms of Service
              </Link>
              .
            </Text>

            <br />

            {/* Add reCAPTCHA before the submit button */}
            {requiresCheckbox && (
              <Box mb={4}>
                <ReCAPTCHA
                  sitekey={config.recaptcha.v2Token}
                  size="normal"
                  ref={recaptchaRef}
                />
              </Box>
            )}

            <Button
              isLoading={isSubmitting}
              width="100%"
              variant="primary"
              type="submit"
              padding="1rem 2rem"
            >
              Sign up
            </Button>

            <Divider margin="2rem auto" />

            <Text margin="auto" color={theme.text}>
              Already have an account?&nbsp;
              <Box display="inline-block" textDecor="underline" color="black">
                <Link to="/login" style={{ color: colors.primary }}>
                  <Text color={colors.primary}>Log in</Text>
                </Link>
              </Box>
            </Text>

            <Text color={theme.text} marginTop="0.5rem">
              Having trouble signing in?&nbsp;
              <Box
                display="inline-block"
                textDecor="underline"
                color={colors.primary}
                cursor="pointer"
                onClick={() => {
                  console.log((window as any).Intercom);

                  (window as any).Intercom("show");
                }}
              >
                <Text color={colors.primary}>Contact us</Text>
              </Box>
            </Text>
          </form>
        </Box>
      </VStack>
    </NoNavBarPageTemplate>
  );
}

const FreeTransactions = ({ refCode }: { refCode: string }) => {
  const [search] = useSearchParams();

  if (!refCode || refCode === "undefined" || refCode === "null") {
    return null;
    // return (
    //   <Box
    //     w="100%"
    //     maxWidth="30rem"
    //     style={{
    //       color: colors.white,
    //       background: `linear-gradient(30deg, ${colors.green60} 0%, ${colors.green50} 50%, ${colors.green70} 100%)`,
    //       padding: "0.75rem 0.25rem",
    //       borderRadius: 5,
    //       fontWeight: "600",
    //       margin: "0.5rem auto",
    //       fontSize: 15,
    //       textAlign: "center",
    //     }}
    //   >
    //     <i
    //       className="fa-sharp fa-gift"
    //       style={{ marginRight: 5, color: colors.white }}
    //     />{" "}
    //     Sign up for Awaken now and get $25 credit!{" "}
    //     <Info
    //       style={{ color: colors.white }}
    //       message="Awaken is free if you have less than 500 transactions a year. If you have more, you'll get a free $25 of credit. Note: this credit may expire within 30 days, so make sure to use it."
    //     />
    //   </Box>
    // );
  }

  return (
    <Box
      w="100%"
      maxWidth="30rem"
      style={{
        color: colors.white,
        background: `linear-gradient(30deg, ${colors.green60} 0%, ${colors.green50} 50%, ${colors.green70} 100%)`,
        padding: "0.75rem 0.5rem",
        borderRadius: 5,
        fontWeight: "600",
        margin: "0.5rem auto",
        fontSize: 15,
        textAlign: "center",
      }}
    >
      You are unlocking <b>FREE</b> credit with code {refCode} 🔑
    </Box>
  );
};

const Logos = ({ data }: { data: Query["getSpecialCodeInfo"] }) => {
  const theme = useTheme();

  const mainLogo = theme.theme === "light" ? Logo : WhiteLogo;
  const hasSecondary = data?.imageUrl && data?.imageUrl?.includes("https://");
  const secondaryLogoUrl = hasSecondary ? data?.imageUrl : "";

  if (data) {
    return (
      <Box>
        <Center>
          <HStack paddingTop="1rem">
            <Image
              style={{
                borderRadius: 20,
                // rotate this -20 degrees
                transform: "rotate(-10deg)",
                position: "relative",
                left: 10,
                zIndex: 2,
                border: `2px solid ${theme.border}`,
                // add some shadow
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              }}
              src={BlackIcon}
              cursor="pointer"
              w={75}
              alt="Main logo"
            />

            {secondaryLogoUrl ? (
              <Image
                style={{
                  borderRadius: 20,
                  transform: "rotate(10deg)",
                  position: "relative",
                  right: 10,
                  zIndex: 1,
                  border: `2px solid ${theme.border}`,
                }}
                src={secondaryLogoUrl || ""}
                cursor="pointer"
                w={75}
                alt="Secondary logo"
              />
            ) : (
              <Box
                w={75}
                h={75}
                bg={colors.primary}
                color={colors.white}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  borderRadius: 20,
                  transform: "rotate(10deg)",
                  position: "relative",
                  right: 10,
                  zIndex: 1,
                  fontSize: 28,
                  fontWeight: "bold",
                }}
              >
                {(data?.description || data.code || "")
                  ?.charAt(0)
                  .toUpperCase()}
              </Box>
            )}
          </HStack>
        </Center>

        <HStack
          style={{
            padding: "2rem 0",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Text
            style={{ textAlign: "center" }}
            fontSize="md"
            color={theme.text}
          >
            Join {data?.description || data?.code || "us"} on Awaken to make
            crypto taxes easy!
            <br />
            You'll get{" "}
            <span style={{ fontWeight: "800", color: colors.primary }}>
              {data?.type === "credit"
                ? `${D(data?.creditCents || 0).toFormat()} of credit`
                : `${data?.discountPercentage}% off your first purchase`}
            </span>{" "}
            + a bunch of other perks <i className="fa-solid fa-gift" />
          </Text>
        </HStack>
      </Box>
    );
  }

  return (
    <Link to="/">
      <Center>
        <HStack spacing={4} paddingY="2rem">
          <Image
            style={{
              borderRadius: 20,
              position: "relative",
              zIndex: 2,
              // add some shadow
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            }}
            src={BlackIcon}
            cursor="pointer"
            w={75}
            alt="Main logo"
          />
        </HStack>
      </Center>
    </Link>
  );
};
