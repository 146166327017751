import { Box, HStack, Link, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { BaseFeeFields } from "src/api/fragments";
import { CurrencyCodeEnum } from "src/api/generated/types";
import { ActionSheet } from "src/components/styled";
import { AssetIcon } from "src/components/styled/Assets";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { ActiveTransactionContext } from "src/context";
import { hasValue } from "src/core";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { CreditFinancialInfo } from "../components/CreditFinancialInfo";
import { formatCryptoAmount, getTransformedRawAsset } from "../utils";

export const Fee = ({ fee }: { fee: BaseFeeFields }) => {
  const { transaction, client } = useContext(ActiveTransactionContext);
  const asset = fee.fullAsset || getTransformedRawAsset(fee.rawAsset);
  const transferValue = formatCryptoAmount(fee.amount, asset);
  const color = colors.negative;

  return (
    <HStack alignItems="start" w="21rem" padding="0.25rem 0">
      <AssetIcon asset={asset} />

      <Box flex={1}>
        <HStack alignItems="center">
          <Text flex={1} fontWeight="bold" fontSize="md" color={color}>
            -{transferValue}{" "}
            <Link
              target="_blank"
              fontWeight="normal"
              href={
                asset?.coinGeckoTokenId
                  ? `https://www.coingecko.com/en/coins/${asset?.coinGeckoTokenId}`
                  : "#"
              }
              color={color}
            >
              {asset?.symbol}
            </Link>
          </Text>
        </HStack>

        <CreditFinancialInfo
          transfer={null}
          fiatCurrency={transaction?.fiatCurrency || CurrencyCodeEnum.Usd}
          proceeds={fee.proceeds ?? null}
          basis={fee.basis ?? null}
          transaction={transaction}
          profit={fee.profit ?? null}
          assetCreditId={fee.assetCreditId ?? null}
          timezone={client?.timezone || "UTC"}
        />
        <HStack marginTop="0.5rem">
          <FeePayer fee={fee} />
        </HStack>
      </Box>
    </HStack>
  );
};

const FeePayer = ({ fee }: { fee: BaseFeeFields }) => {
  const { clientId } = useParams();
  const toast = useMyToast();
  const { secondaryBackground, header } = useTheme();

  if (!fee || !fee.payerAccount) return null;

  return (
    <HStack>
      <StatusTag
        label={fee?.payerAccount?.description || ""}
        rightIcon={
          <ActionSheet
            popover={{ trigger: "hover" }}
            content={{ width: 250 }}
            commands={[
              {
                label: "View transactions",
                link: `/clients/${clientId}/transactions?accountIds=${fee.payerAccount?.id}`,
                iconName: "fa-sharp fa-exchange-alt",
              },
              {
                label: "Copy account ID",
                onClick: () => {
                  navigator.clipboard.writeText(fee.payerAccount?.id || "");
                  toast.show({
                    status: "info",
                    message: "Copied to clipboard!",
                  });
                },
                iconName: "fa-sharp fa-copy",
              },
              fee.payerAccount.walletAddress
                ? {
                    label: "Copy wallet address",
                    onClick: () => {
                      navigator.clipboard.writeText(
                        fee.payerAccount?.walletAddress || ""
                      );
                      toast.show({
                        status: "info",
                        message: "Copied to clipboard!",
                      });
                    },
                    iconName: "fa-sharp fa-copy",
                  }
                : null,
            ].filter(hasValue)}
          >
            <Touchable iconName="fa-sharp fa-ellipsis-v" />
          </ActionSheet>
        }
        type="none"
        boxProps={{ style: { maxWidth: "12rem" } }}
      />
    </HStack>
  );
};
