import { Button, Container, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { Modal } from "src/components/Modal";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { trackEvent } from "src/utils/analytics";

type Props = InjectedProps & {
  title: string;
  onContinue: () => void;
};

function _FreeTaxModal({
  handleHide,
  title,
  show: isVisible,
  onContinue,
}: Props) {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const theme = useTheme();

  const _copyLink = () => {
    if (!client) return;

    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message:
        "Copied sharable link to clipboard! You can paste it in discord/imessage/twitter or wherever you want.",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  const onHide = () => {
    handleHide();
  };

  const _submit = () => {
    onContinue();
    handleHide();
  };

  return (
    <Modal
      title="You'll get Awaken for free, but first..."
      titleHeaderProps={{
        fontSize: 24,
        marginRight: 10,
        color: theme.header,
      }}
      marginTop="1.5rem"
      isVisible={isVisible}
      handleHide={onHide}
      Footer={
        // fade in motion dive
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 5, duration: 1 } }}
          exit={{ opacity: 0 }}
          style={{ width: "100%" }}
        >
          <Button
            width="100%"
            bg={colors.green50}
            color={colors.white}
            marginBottom="0.5rem"
            marginTop="1.5rem"
            _hover={{ bg: colors.green40 }}
            onClick={_submit}
          >
            Unlock report{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-arrow-right"></i>
          </Button>
        </motion.div>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text color={theme.text} fontSize="md">
          Please share Awaken with at least one person.
          <br />
          <br />
          In order for us to bring more people onchain, we have to make it
          easier to understand your onchain finances. Tracking and taxes are
          table stakes in TradFi, and for along time they were lacking in
          crypto. Until Awaken.
          <br />
          <br />
          We work extremely hard to continue to improve Awaken every single day
          (and we are always receptive to feedback). Out of every 4 users, 3 of
          you hear about us from a friend. We rely on you to help us grow, so if
          you're a part of a TG group, discord, or X, please share us.
          <br />
          <br />
          Thank you for being an Awaken user 🤝
        </Text>

        {/* <Textarea 
          value={notes}
          noOfLines={5}
          onChange={(e) => setNotes(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        /> */}
      </Container>
    </Modal>
  );
}

export const FreeTaxModal = connectModal({
  name: "FreeTaxModal",
})(_FreeTaxModal);
