import { useQuery } from "@apollo/client";
import { Text } from "@chakra-ui/react";
import { useEffect } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { Query } from "src/api/generated/types";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getHasBanner, setHasBanner } from "src/redux/reducers/globalState";
import { colors } from "src/theme";
import { trackEvent } from "src/utils/analytics";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import Banner from "../styled/Banner";

import FireworksAnimation from "src/assets/animations/fireworks-3.json";

const freeBannerDismissKey = "v1:free_transaction_code";

// Add a prefix to create unique storage keys for each banner type
const BANNER_DISMISS_PREFIX = "banner_dismissed:";

export function FreeBanner() {
  const dispatch = useDispatch();
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const hasBanner = useSelector(getHasBanner);
  const isLarge = useIsLargeScreen();
  const isWhiteLabel = isWhiteLabeledDomain();
  const { me } = useMe();
  const has2FA = me?.hasTwoFactorAuth ?? false;

  // Current banner type - can be changed to show different banners
  const bannerType = "x";
  const isEnterprise = client?.isEnterprise ?? false;

  // Check if this banner type has been dismissed
  const isDismissed = () => {
    const key = `${BANNER_DISMISS_PREFIX}${bannerType}:${clientId}`;
    return localStorage.getItem(key) === "true";
  };

  useEffect(() => {
    if (isWhiteLabel || !isLarge || isDismissed()) {
      dispatch(setHasBanner(false));
      return;
    }

    dispatch(setHasBanner(true));
  }, [client, isLarge, isWhiteLabel, bannerType, clientId]);

  if (!hasBanner) return null;

  if (isEnterprise) {
    return (
      <BedrockBanner
        client={client}
        bannerType={bannerType}
        clientId={clientId || ""}
      />
    );
  }

  return (
    <XBanner
      client={client}
      bannerType={bannerType}
      clientId={clientId ?? ""}
    />
  );
  // return <ShareAwakenBanner client={client} bannerType={bannerType} clientId={clientId} />;
  // return <ReferralBanner client={client} bannerType={bannerType} clientId={clientId} />;

  // return (
  //   <Banner
  //     padding="0 0.5rem"
  //     // borderBottom={`1px solid ${colors.gray80}`}
  //     // dismissKey={freeBannerDismissKey + ":" + clientId}
  //     message={
  //       <Text
  //         fontSize={{ base: "xs", sm: "sm" }}
  //         style={{ fontWeight: "500", color: header }}
  //       >
  //         {/* 🥁 Awaken's portfolio is now in beta 🥁 */}
  //         {/* Check out our new{" "} */}
  //         {/* <span style={{ fontWeight: "bold", color: colors.lightBlue100 }}>
  //           ➡️ $25 of credit ⬅️
  //         </span>{" "}
  //         when you share{" "} */}
  //         We launched the easiest way to trade memecoins. Check it out:{" "}
  //         <a target="_blank" href="https://movement.market" rel="noreferrer">
  //           <span
  //             style={{
  //               fontWeight: "bold",
  //               color: header,
  //               cursor: "pointer",
  //               textDecoration: "underline",
  //             }}
  //           >
  //             ➡️ Movement ⬅️
  //           </span>
  //         </a>
  //         .
  //       </Text>
  //     }
  //     // bg={`linear-gradient(45deg, ${colors.orange60} 0%, ${colors.yellow60} 50%, ${colors.orange60} 100%)`}
  //     bg={
  //       theme === "light"
  //         ? `linear-gradient(45deg, ${colors.lightBlue60} 0%, ${colors.lightBlue80} 50%, ${colors.lightBlue60} 100%)`
  //         : `linear-gradient(45deg, ${colors.primary} 0%, ${colors.lightBlue50} 50%, ${colors.primary} 100%)`
  //     }
  //   />
  // );
}

const BedrockBanner = ({
  client,
  bannerType,
  clientId,
}: {
  client: any;
  bannerType: string;
  clientId: string;
}) => {
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const handleDismiss = (e: React.MouseEvent) => {
    e.stopPropagation();
    dismissBanner(bannerType, clientId);
    dispatch(setHasBanner(false));
  };

  return (
    <Banner
      padding="0 0.5rem"
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "600", color: colors.white }}
        >
          <i className="fa-solid fa-shield-halved" /> Awaken Bedrock: Enterprise
          accounting built for blockchains
        </Text>
      }
      cursor="pointer"
      onClick={() => dispatch(show("BedrockModal"))}
      borderBottom={`1px solid ${colors.gray20}`}
      bg={`linear-gradient(45deg, #1a1a2e 0%, #16213e 50%, #1a1a2e 100%)`}
    />
  );
};

const TwoFactorAuthBanner = ({ client }: { client: any }) => {
  const toast = useMyToast();

  return (
    <Link to={`/clients/${client?.id}/team`}>
      <Banner
        padding="0 0.5rem"
        // dismissKey={freeBannerDismissKey + ":" + clientId}
        message={
          <Text
            fontSize={{ base: "xs", sm: "sm" }}
            style={{
              fontWeight: "600",
              color: colors.black,
              animation: "pulse 2s infinite",
            }}
          >
            <i className="fa-solid fa-shield-halved" /> Please enable 2FA in the{" "}
            <span style={{ cursor: "pointer", textDecoration: "underline" }}>
              Settings
            </span>{" "}
            page to keep your account secure. Click to enable{" "}
            <i className="fa-solid fa-shield-halved" />
          </Text>
        }
        // style={{
        //   position: "fixed",
        //   top: 0,
        // }}
        bg={`linear-gradient(45deg, ${colors.blue80} 0%, ${colors.green70} 50%, ${colors.blue80} 100%)`}
      />
    </Link>
  );
};

// Helper function to dismiss a banner by type
const dismissBanner = (bannerType: string, clientId: string) => {
  const key = `${BANNER_DISMISS_PREFIX}${bannerType}:${clientId}`;
  localStorage.setItem(key, "true");
};

// Update the banner components to accept and use the banner type
const TelegramBanner = ({
  client,
  bannerType,
  clientId,
}: {
  client: any;
  bannerType: string;
  clientId: string;
}) => {
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const handleDismiss = (e: React.MouseEvent) => {
    e.stopPropagation();
    dismissBanner(bannerType, clientId);
    dispatch(setHasBanner(false));
  };

  return (
    <Banner
      padding="0 0.5rem"
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "600", color: colors.white, cursor: "pointer" }}
        >
          <i
            className="fab fa-telegram-plane"
            style={{
              fontSize: "16px",
              marginRight: "8px",
            }}
          />
          Join our Telegram for the latest updates and support
          <span
            onClick={handleDismiss}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              float: "right",
              position: "absolute",
              right: 15,
            }}
          >
            <i className="fa-solid fa-times" />
          </span>
        </Text>
      }
      cursor="pointer"
      onClick={() => dispatch(show("TelegramModal"))}
      bg={`linear-gradient(45deg, #00a1ff 0%, #00e5ff 100%)`}
    />
  );
};

const XBanner = ({
  client,
  bannerType,
  clientId,
}: {
  client: any;
  bannerType: string;
  clientId: string;
}) => {
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const handleDismiss = (e: React.MouseEvent) => {
    e.stopPropagation();
    dismissBanner(bannerType, clientId);
    dispatch(setHasBanner(false));
  };

  return (
    <a href="https://x.com/awakentax" target="_blank" rel="noreferrer">
      <Banner
        padding="0 0.5rem"
        message={
          <Text
            fontSize={{ base: "xs", sm: "sm" }}
            style={{
              fontWeight: "600",
              color: colors.white,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Lottie
              options={{
                animationData: FireworksAnimation,
                loop: true,
                autoplay: true,
              }}
              style={{ width: 24, height: 24 }}
            />{" "}
            Follow us on{" "}
            <i
              className="fab fa-x-twitter"
              style={{
                fontSize: "14px",
              }}
            />{" "}
            for the latest announcements (+ some giveaways){" "}
            <Lottie
              options={{
                animationData: FireworksAnimation,
                loop: true,
                autoplay: true,
              }}
              style={{ width: 24, height: 24 }}
            />
            <span
              onClick={handleDismiss}
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                float: "right",
                position: "absolute",
                right: 15,
              }}
            >
              <i className="fa-solid fa-times" />
            </span>
          </Text>
        }
        cursor="pointer"
        bg={`linear-gradient(45deg, #15202b 0%, #1a1f23 100%)`}
      />
    </a>
  );
};

const ShareAwakenBanner = ({
  client,
  bannerType,
  clientId,
}: {
  client: any;
  bannerType: string;
  clientId: string;
}) => {
  const toast = useMyToast();
  const dispatch = useDispatch();

  const handleDismiss = (e: React.MouseEvent) => {
    e.stopPropagation();
    dismissBanner(bannerType, clientId);
    dispatch(setHasBanner(false));
  };

  const _copyLink = () => {
    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  return (
    <Banner
      padding="0 0.5rem"
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "600", color: colors.black }}
        >
          Awaken's mobile app is being sunset so we can focus on our tax product
          🛠️.{" "}
          <a
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: colors.black,
              fontWeight: "700",
            }}
            target="_blank"
            href="https://x.com/AwakenTax/status/1864372556528333259"
          >
            Read more.
          </a>
          <span
            onClick={handleDismiss}
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              float: "right",
            }}
          >
            <i className="fa-solid fa-times" />
          </span>
        </Text>
      }
      bg={`linear-gradient(45deg, #FFD89B 0%, #FF9A8B 50%, #FFD89B 100%)`}
    />
  );
};

const ReferralBanner = ({ client }: { client: any }) => {
  const toast = useMyToast();
  const { data, loading } = useQuery<Pick<Query, "getSubscriptions">>(
    api.subscriptions.list,
    {
      variables: { clientId: client?.id },
      skip: !client?.id,
    }
  );

  const subscriptions = data?.getSubscriptions ?? [];
  const showBadge = !!data && subscriptions.length === 0 && !loading;
  const theme = useTheme();

  const _copyLink = () => {
    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };
  const dispatch = useDispatch();

  // if (showBadge) {
  //   return (
  //     <Banner
  //       padding="0.25rem 0.5rem"
  //       cursor="pointer"
  //       onClick={() => {
  //         // dispatch(show("ReferralModal"));
  //       }}
  //       // dismissKey={freeBannerDismissKey + ":" + clientId}
  //       message={
  //         <Text
  //           fontSize={{ base: "xs", sm: "sm" }}
  //           style={{ fontWeight: "500", color: colors.black }}
  //         >
  //           <b>Get $25</b> off next year if you buy a 2023 tax plan by August
  //           31st.{" "}
  //         </Text>
  //       }
  //       bg={`linear-gradient(45deg, ${colors.green80} 0%, ${colors.green60} 50%, ${colors.green80} 100%)`}
  //     />
  //   );
  // }

  return (
    <Banner
      padding="0.25rem 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{
            fontWeight: "500",
            color: theme.header,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              gap: "-10px",
              marginRight: "4px",
            }}
          >
            <img
              src="https://assets.awaken.tax/icons/hype.png"
              alt="Hyperliquid"
              style={{
                width: "20px",
                borderRadius: 100,
                height: "20px",
                zIndex: 3,
                border: `1px solid ${theme.text}`,
              }}
            />
            <img
              src="https://assets.awaken.tax/icons/sui.png"
              alt="Sui"
              style={{
                width: "20px",
                height: "20px",
                borderRadius: 100,
                marginLeft: "-6px",
                zIndex: 2,
                border: `1px solid ${theme.text}`,
              }}
            />
            <img
              src="https://assets.awaken.tax/icons/btc.png"
              alt="Bitcoin"
              style={{
                width: "20px",
                borderRadius: 100,
                height: "20px",
                marginLeft: "-6px",
                zIndex: 1,
                border: `1px solid ${theme.text}`,
              }}
            />
          </span>
          <b>Hyperliquid, Bitcoin, and Sui</b>&nbsp;are 100% free.&nbsp;
          <span
            onClick={() =>
              dispatch(
                show("PleaseShareModal", {
                  title:
                    "Awaken's #1 goal is to make crypto taxes as stress-free as possible. And to get there, we need your help.",
                })
              )
            }
            style={{
              fontWeight: "bold",
              color: theme.header,
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Help us spread the word <i className="fa-solid fa-heart" />
          </span>
        </Text>
      }
      borderBottom={`1px solid ${theme.border}`}
      // make a bitcoin orange gradient
      // bg={`linear-gradient(45deg, #FFD89B 0%, #FF9A8B 50%, #FFD89B 100%)`}
      bg={`linear-gradient(45deg, ${theme.background} 0%, ${theme.medBackground} 50%, ${theme.background} 100%)`}
    />
  );
};

const Maintenance = () => {
  return (
    <Banner
      padding="0 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "500", color: colors.black }}
        >
          Wallet imports for Base are experiencing high failure rates. We are
          working with <a href="https://basescan.org">basescan</a> to resolve
          this.
        </Text>
      }
      bg={`linear-gradient(45deg, ${colors.yellow50} 0%, ${colors.yellow40} 50%, ${colors.yellow50} 100%)`}
    />
  );
};

const ChangingThings = () => {
  return (
    <Banner
      padding="0 0.5rem"
      // dismissKey={freeBannerDismissKey + ":" + clientId}
      message={
        <Text
          fontSize={{ base: "xs", sm: "sm" }}
          style={{ fontWeight: "500", color: colors.black }}
        >
          We are currently undergoing large changes to our infrastructure, so
          please bear with us. Your data is safe.
        </Text>
      }
      bg={`linear-gradient(45deg, ${colors.yellow50} 0%, ${colors.yellow40} 50%, ${colors.yellow50} 100%)`}
    />
  );
};
