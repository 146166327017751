import { useMutation } from "@apollo/client";
import { Button, Container, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Mutation, MutationCreateClientArgs } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Input } from "src/components/styled/Form/Input";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = {
  name?: string;
} & InjectedProps;

function _AddClientModal({ handleHide, show: isVisible, name: _name }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const toast = useMyToast();
  const [name, setName] = useState(_name || "");
  const [createClient, { loading }] = useMutation<{
    createClient: Mutation["createClient"];
  }>(api.clients.create);

  const _onAddWallet = useCallback(async () => {
    try {
      if (!name) {
        return toast.show({
          status: "error",
          message: "Please enter a name for the client.",
        });
      }

      const domain = window.location.hostname;

      const createAccountArgs: MutationCreateClientArgs = {
        name,
        email: null,
        referredDomainUrl: domain,
      };

      const result = await createClient({
        variables: createAccountArgs,
        refetchQueries: [api.users.clients.getActive, api.clients.list],
      });

      const clientId = result.data?.createClient.id;

      handleHide();

      if (clientId) {
        navigate(`/clients/${clientId}/accounts`);
      }
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message || "Something went wrong. Please try again.",
      });
    }
  }, [name]);

  return (
    <Modal
      title="Add Client"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          // type="submit"
          isLoading={loading}
          onClick={_onAddWallet}
        >
          Add Client
        </Button>
      }
    >
      {/* <form onSubmit={_onAddWallet}> */}
      <Container padding="0px" marginTop="0px !important">
        <Text fontSize="md" color={theme.text}>
          Once you create this client, you'll be able to invite them in the
          settings page.
        </Text>

        <br />

        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Client Full Name"
          placeholder="John Legend"
        />
      </Container>
      {/* </form> */}
    </Modal>
  );
}

export const AddClientModal = connectModal({
  name: "AddClientModal",
})(_AddClientModal);
