import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { api, apolloClient } from "src/api";
import { useMyToast } from "./useMyToast";

export const useAssetMutations = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [updateKey] = useMutation(api.assets.updateKey);
  const [markWorthless] = useMutation(api.assets.markWorthless);
  const toast = useMyToast();

  const handleMarkAsSpam = async (assetKey: string) => {
    try {
      // confirm with them if they want to undo this they'll have to contact support
      const confirm = window.confirm(
        "This asset will be set as spam. If you want to undo this, you'll have to contact support."
      );

      if (!confirm) return;

      await updateKey({
        variables: {
          assetKey,
          isSpam: true,
          clientId,
        },
      });

      apolloClient.refetchQueries({
        include: [api.portfolio.getPortfolioV2CoinsAndDefi],
      });

      toast.show({
        status: "success",
        message: "Successfully marked asset as spam",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          "Failed to mark asset as spam. This usually means the token is represented in a DeFi position. Contact support and we can recommend a work around.",
      });
    }
  };

  const handleMarkAsWorthless = async (clientId: string, assetKey: string) => {
    try {
      // confirm with them if they want to undo this they'll have to contact support
      const confirm = window.confirm(
        "This asset will be be marked as worthless and hidden from your portfolio. You can undo this by contacting support."
      );

      if (!confirm) return;

      await markWorthless({
        variables: {
          clientId,
          assetKey,
          isWorthless: true,
        },
      });

      apolloClient.refetchQueries({
        include: [api.portfolio.getPortfolioV2CoinsAndDefi],
      });

      toast.show({
        status: "success",
        message: "Successfully marked asset as worthless",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: "Failed to mark asset as worthless",
      });
    }
  };

  return { handleMarkAsSpam, handleMarkAsWorthless };
};
