import { useMutation } from "@apollo/client";
import {
  Box,
  Center,
  Checkbox,
  HStack,
  Image,
  Menu,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { GraphQLError } from "graphql";
import { truncate } from "lodash";
import { isNil, uniq } from "lodash/fp";
import moment from "moment-timezone";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { api, apolloClient } from "src/api";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import {
  CurrencyCodeEnum,
  MutationHideTransactionArgs,
  Transaction as TransactionT,
} from "src/api/generated/types";
import { CapGainsTag } from "src/components";
import { ReviewStatusTag } from "src/components/Labels/ReviewStatusTag";
import {
  INTEGRATIONS,
  PROVIDER_TO_LOGO_URL,
} from "src/components/modals/AccountModal/constants";
import { AwakenTooltip } from "src/components/styled/AwakenTooltip";
import StatusTag from "src/components/styled/StatusTag";
import { IncomeTag } from "src/components/styled/Transaction/IncomeTag";
import { Touchable } from "src/components/Touchable";
import { Maybe, hasValue } from "src/core";
import { useMe, useMyToast, useTransactionSearch } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { isLabeled, isReviewed } from "src/modules/ledger/transactions";
import {
  getActiveHighlightedTransactionId,
  getPrepopulatedTxns,
} from "src/redux/reducers/active";
import { colors } from "src/theme";
import { toTitleCase } from "src/utils";
import { SelectTransactionsContext } from "./context";

type TransactionProps = {
  transaction: BaseSimpleTransactionFields;
  limitedView?: boolean; // show a few columns
  onClick?: () => void;
  timezone: string;
  handleContextMenu?: (e: any, id: string) => void;
  style?: React.CSSProperties;
};

const MAX_WIDTH = 35;

export function Transaction({
  transaction,
  limitedView,
  onClick,
  // UTC
  timezone = "UTC",
  style,
}: TransactionProps) {
  const { clientId } = useParams();
  const txnMapping = useSelector(getPrepopulatedTxns);
  const fullTxn = txnMapping[transaction.id];
  const defaultLabels = (fullTxn as unknown as TransactionT)?.options ?? null;
  const navigate = useNavigate();

  const { selectedTransactionIds, addOrRemoveTxnIds } = useContext(
    SelectTransactionsContext
  );
  const [updateTransaction, { loading: isLoadingUpdateTransaction }] =
    useMutation(api.transactions.update);
  const highlightedTxnId = useSelector(getActiveHighlightedTransactionId);

  const [search, setSearchParams] = useSearchParams();
  const searchTransactionId = search.get("transactionId");

  const selected = searchTransactionId === transaction.id;
  const [isHighlighted, setHighlighted] = useState(
    highlightedTxnId === transaction.id
  );
  const toast = useMyToast();
  const [hideTransaction, { loading: isLoadingHideTransaction }] = useMutation(
    api.transactions.hide
  );

  const { me } = useMe("cache-first");
  const isSuperUser = me?.isSuperuser || false;
  const { filters } = useTransactionSearch();
  const hasIncomeSort = filters.sortBy === "incomeSum";

  // right-click menu
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState<Maybe<string>>(null);

  const handleContextMenu = (e: any, id: string) => {
    e.preventDefault();
    setMenuPosition({ x: e.pageX, y: e.pageY });
    setIsMenuOpen(id);
  };

  const handleClose = () => {
    setIsMenuOpen(null);
  };

  const _onClickTxn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    search.delete("transactionId"); // delete the current txn id
    setSearchParams(search);
    search.append("transactionId", transaction.id);
    setSearchParams(search);
    setHighlighted(true);
  };

  const title = transaction.title || "";

  const [accountLabelTruncated, accountLabel] = useMemo(
    () => [
      _getAccountLabel(transaction, 10),
      _getAccountLabel(transaction, 1_000),
    ], // arbitraryily large
    [transaction]
  );

  const needsRecalculate = transaction.needsReview?.needsRecalculate || false;
  const needsSpamReview = useMemo(() => {
    const relevantTransfers = transaction.transfers.filter(
      (t) => !!t.fromAccount || !!t.toAccount
    );

    return (
      relevantTransfers.length > 0 &&
      !!relevantTransfers.find(
        (t) => t.fullAsset && t.fullAsset?.needsSpamReview
      )
    );
  }, [transaction.transfers]);

  const showMissingBasis = transaction.isMissingBasis;

  const _toggle = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    addOrRemoveTxnIds(transaction.id);
  };

  const isSelected = useMemo(
    () => selectedTransactionIds.includes(transaction.id),
    [selectedTransactionIds]
  );

  const _updateSpam = async () => {
    try {
      if (isLabeled(transaction)) {
        const confirmed = await confirm(
          "Are you sure you want to mark this transaction as spam?"
        );
        if (!confirmed) return;
      }

      const variables = {
        updates: {
          notes: transaction.notes,
          title: transaction.title,
          label: "v1:spam",
          overrideLabel: true,
          globalRuleName: "",
        },
        transactionId: transaction.id,
        createDefaultRule: false,
      };

      const result = await updateTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: `Successfully labeled transaction as spam${
          filters.includeSpam ? "" : " and hid it from view"
        }`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as GraphQLError).message,
        status: "error",
      });
    }
  };

  const markAsSpam = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const id = isMenuOpen;
    if (!id) return;
    if (!transaction) return;

    await _updateSpam();
  };

  const _deleteTransaction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      const variables: MutationHideTransactionArgs = {
        transactionId: transaction.id,
        isHidden: true,
      };

      await hideTransaction({
        variables,
      });

      void apolloClient.refetchQueries({
        include: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
          api.graph.getReplayInfo,
        ],
      });

      toast.show({
        message: "Deleted Transaction!",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error deleting transaction!",
      });
    }
  };

  useEffect(() => {
    if (isHighlighted) {
      setTimeout(() => setHighlighted(false), 5000);
    }
  }, [isHighlighted]);

  const fullTheme = useTheme();
  const {
    background,
    medBackground,
    secondaryBackground,
    header,
    text,
    border,
  } = fullTheme;

  // if has a sum and that sum is greater than $100, it i red and should be looked at
  const isHighGainLoss =
    !isNil(transaction.capGainsSum) &&
    new BigNumber(transaction.capGainsSum || 0).isGreaterThan(10000);
  const isGeneral =
    transaction.provider === "general_blockchain" ||
    transaction.provider === "general_exchange";

  return (
    <Tr
      cursor="pointer"
      className="awaken__transaction-row"
      borderBottom={`1px solid ${border} !important`}
      _hover={{
        backgroundColor: medBackground,
      }}
      py="50px"
      // onMouseOver={_preloadTxnDebounced}
      onClick={onClick || _onClickTxn}
      onContextMenu={
        handleContextMenu
          ? (e: any) => handleContextMenu(e, transaction.id)
          : undefined
      }
      bg={selected || isHighlighted ? secondaryBackground : background}
      style={style}
    >
      {isMenuOpen !== null && (
        <Portal>
          <Menu isOpen={true} onClose={handleClose}>
            <MenuList
              position="absolute"
              left={`${menuPosition.x}px`}
              top={`${menuPosition.y}px`}
              style={{
                opacity: isMenuOpen ? 1 : 0,
                transition: "opacity 0.2s ease-in-out",
                position: "absolute",
                left: `${menuPosition.x}px`,
                top: `${menuPosition.y}px`,
                backgroundColor: background,
                border: `1px solid ${border}`,
              }}
            >
              <MenuItem
                onClick={(e) =>
                  isLoadingUpdateTransaction ? undefined : markAsSpam(e)
                }
                _hover={{ backgroundColor: medBackground }}
                color={text}
              >
                {isLoadingUpdateTransaction ? (
                  <Spinner size="sm" margin="auto" />
                ) : (
                  <>
                    <i className="fa-sharp fa-empty-set" />
                    &nbsp;&nbsp;Mark as spam
                  </>
                )}
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(
                    `/clients/${clientId}/transactions?transactionId=${transaction.id}`
                  );
                }}
                _hover={{ backgroundColor: medBackground }}
                color={text}
              >
                <i className="fa-sharp fa-external-link" />
                &nbsp;&nbsp;Open transaction
              </MenuItem>
              <MenuItem
                onClick={(e) =>
                  isLoadingHideTransaction ? undefined : _deleteTransaction(e)
                }
                _hover={{ backgroundColor: medBackground }}
                color={text}
              >
                {isLoadingHideTransaction ? (
                  <Spinner size="sm" margin="auto" />
                ) : (
                  <>
                    <i className="fa-sharp fa-trash" />
                    &nbsp;&nbsp;Delete transaction
                  </>
                )}
              </MenuItem>
            </MenuList>
          </Menu>
        </Portal>
      )}
      <Td borderColor={border} w="30px" onClick={(e) => e.stopPropagation()}>
        <div>
          <Checkbox
            borderColor={border}
            onChange={_toggle}
            isChecked={isSelected}
          />
        </div>
      </Td>
      <Td
        py={4}
        borderColor={border}
        paddingLeft="0rem !important"
        maxWidth="20rem"
      >
        <HStack>
          <AwakenTooltip
            placement="top"
            message={
              INTEGRATIONS.find((i) => i.provider === transaction.provider)
                ?.name || "Provider"
            }
          >
            {isGeneral ? (
              <div
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  background: secondaryBackground,
                  borderRadius: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  style={{ color: header, fontSize: 10 }}
                  className="fa-sharp fa-wallet"
                />
              </div>
            ) : (
              <Image
                src={PROVIDER_TO_LOGO_URL[transaction.provider || ""] || ""}
                marginRight="0.5rem"
                width="1.25rem"
                height="1.25rem"
                display="inline"
                border={`1px solid ${border}`}
                style={{ borderRadius: 3 }}
              />
            )}
          </AwakenTooltip>
          <HStack alignItems="center" style={{ width: "100%" }}>
            <Box
              style={{
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AwakenTooltip
                  openDelay={250}
                  placement="bottom-start"
                  label={title}
                >
                  <Text
                    isTruncated
                    textAlign="left"
                    fontSize={14}
                    maxW="100%"
                    marginRight={"5px"}
                    color={header}
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {title}
                  </Text>
                </AwakenTooltip>

                <ProtocolIcon transaction={transaction} />

                {transaction.notes && (
                  <AwakenTooltip
                    trigger="hover"
                    bg={colors.black}
                    placement="top-start"
                    borderRadius="0.25rem"
                    label={transaction.notes}
                    hasArrow
                  >
                    <i
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        color: text,
                      }}
                      className="fa-sharp fa-comment-alt-lines"
                    />
                  </AwakenTooltip>
                )}

                {needsSpamReview && !isLabeled(transaction) && (
                  <AwakenTooltip label="This asset is potentially spam. Click to mark as spam. You can label it as any other label to mark the asset as not spam.">
                    <div style={{ display: "flex" }}>
                      <Touchable
                        onClick={async (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          await _updateSpam();
                        }}
                        style={{
                          background: fullTheme.yellowBg,
                          color: colors.yellow30,
                        }}
                        type="warning"
                        labelStyle={{ color: colors.yellow30 }}
                        iconName="fa-sharp fa-trash-can"
                        iconStyle={{ color: colors.yellow30 }}
                      />
                    </div>
                  </AwakenTooltip>
                )}
              </div>

              {transaction.description && (
                <AwakenTooltip
                  openDelay={250}
                  placement="bottom-start"
                  label={transaction.description || ""}
                >
                  <Box
                    isTruncated
                    marginTop="7px"
                    textAlign="left"
                    fontSize={14}
                    color={text}
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {transaction.description}
                  </Box>
                </AwakenTooltip>
              )}
            </Box>

            {showMissingBasis ? (
              <StatusTag
                boxProps={{
                  style: {
                    marginLeft: 5,
                    display: "block",
                    padding: "3px 10px",
                  },
                }}
                infoMessage={`We are missing the purchase price for an asset in this transaction.${
                  isHighGainLoss
                    ? ""
                    : " The gain/loss is low for this transaction."
                }`}
                type={isHighGainLoss ? "error" : "none"}
                label=""
                iconStyle={{ fontSize: 14 }}
                iconName="fa-sharp fa-circle-exclamation"
              />
            ) : null}
          </HStack>
        </HStack>
      </Td>
      <Td borderColor={border}>
        <Center>
          <div>
            <ReviewStatusTag
              isReviewed={isReviewed(transaction)}
              capPriority={transaction.capPriority}
              type={transaction.processingType || null}
              transactionId={transaction.id}
              currentLabelUsed={transaction.labelUsed}
              needsRecalculate={
                transaction.needsReview?.needsRecalculate || false
              }
              defaultLabels={defaultLabels}
            />
          </div>

          {/* )} */}
        </Center>
      </Td>
      <Td borderColor={border}>
        <Center>
          {hasIncomeSort ? (
            <IncomeTag
              transaction={transaction}
              isDirty={transaction.isDirty}
            />
          ) : transaction.isImporting ? (
            <Box />
          ) : (
            <VStack
              style={{
                width: "100%",
                textAlign: "left",
                alignItems: "center",
              }}
            >
              <IncomeTag
                transaction={transaction}
                isDirty={transaction.isDirty}
              />
              <CapGainsTag
                capPriority={transaction.capPriority ?? null}
                currency={transaction.fiatCurrency || CurrencyCodeEnum.Usd}
                capGainsSum={
                  transaction.capGainsSumSigned ??
                  transaction.capGainsSum ??
                  null
                }
                isDirty={transaction.isDirty}
              />
            </VStack>
          )}
        </Center>
      </Td>
      {!limitedView && (
        <Td borderColor={border}>
          <AwakenTooltip message={accountLabel}>
            <Center color={header}>{accountLabelTruncated}</Center>
          </AwakenTooltip>
        </Td>
      )}

      <Td
        borderColor={border}
        isNumeric
        style={{
          fontSize: 14,
        }}
        color={header}
      >
        <>
          {moment.tz(new Date(transaction.createdAt), timezone).format("h:mma")}
        </>
        <div style={{ marginTop: 5 }}>
          {moment
            .tz(new Date(transaction.createdAt), timezone)
            .format("MMM Do, yyyy")}
        </div>
      </Td>

      {/* <Td borderColor={border} isNumeric>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "none",
            justifyContent: "center",
            height: "100%",
          }}
        >

          {!!transaction?.blockExplorerUrl && (
            <AwakenTooltip message="View on block explorer">
              <div style={{ display: "flex" }}>
                <Touchable
                  iconName="fa-sharp fa-external-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    window.open(transaction.blockExplorerUrl || "", "_blank");
                  }}
                />
              </div>
            </AwakenTooltip>
          )}
        </div>
      </Td> */}
    </Tr>
  );
}

const _getDescription = (d: Maybe<string>) => {
  if (!d) return null;

  // split by | and then go through and if it starts with + make it green, if <> make it grey, if - make it red
  const parts = d.split("|");
  const partsWithColors = parts.map((_p) => {
    const p = _p.trim();
    //
    const [num, ...other] = p.split(" ");
    const asset = other.join(" ");
    let color: string = colors.black;

    if (p.startsWith("+")) {
      color = colors.positive;
    } else if (p.startsWith("<>")) {
      color = colors.primary;
    } else if (p.startsWith("-")) {
      color = colors.negative;
    }

    return (
      <Text
        key={p}
        style={{
          display: "inline-block",
          fontWeight: "medium",
          fontSize: 14,
        }}
      >
        <span style={{ display: "inline-block", color: color }}>{num}</span>{" "}
        {asset}
      </Text>
    );
  });

  return (
    <div>
      {partsWithColors.map((p, i) => (
        <span key={i}>
          {p}
          {i !== parts.length - 1 ? " | " : ""}
        </span>
      ))}
    </div>
  );
};

// ex. ETH -> coinbase etc... with the wallet label. will leave that to a future version tho
const _getAccountLabel = (txn: BaseSimpleTransactionFields, length: number) => {
  const accounts = uniq([
    ...txn.transfers
      .map((t) => [
        t.fromAccount
          ? toTitleCase(truncate(t.fromAccount.description, { length: length }))
          : null,
        t.toAccount
          ? toTitleCase(truncate(t.toAccount.description, { length: length }))
          : null,
      ])
      .flat()
      .filter(hasValue),
    ...txn.fees
      .map((t) =>
        t.payerAccount
          ? toTitleCase(
              truncate(t.payerAccount.description, { length: length })
            )
          : null
      )
      .filter(hasValue),
  ]);

  if (accounts.length >= 1) {
    return accounts.join(", ");
  }

  return "--";
};

const _getImageUrl = (txn: BaseSimpleTransactionFields) => {
  const protocol = txn.protocol;

  if (!protocol || !protocol.imageUrl) {
    return null;
  }

  if (protocol) {
    return {
      name: protocol.name,
      imageUrl: protocol.imageUrl,
    };
  }

  return null;
};

const ProtocolIcon = React.memo(
  ({ transaction }: { transaction: BaseSimpleTransactionFields }) => {
    const title = transaction.title;
    const theme = useTheme();
    const protocol = _getImageUrl(transaction);

    if (!protocol || !protocol.imageUrl) {
      return null;
    }

    return (
      <AwakenTooltip message={protocol.name}>
        <img
          style={{
            width: 18,
            height: 18,
            margin: "0 5px",
            border: `1px solid ${theme.secondaryBackground2}`,
            borderRadius: 6,
            objectFit: "cover",
          }}
          src={protocol.imageUrl}
          alt={protocol.name}
        />
      </AwakenTooltip>
    );
  },
  (prev, next) => {
    return (prev.transaction.title || "") === (next.transaction.title || "");
  }
);
