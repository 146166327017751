import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { Box, useInterval } from "@chakra-ui/react";
import { SingleValueData } from "lightweight-charts";
import { first, isNil, last } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { api } from "src/api";
import {
  Query,
  QueryGetClientTransactionsArgs,
  QueryGetTokenInfoArgs,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { useClientById } from "src/hooks";
import { isLoadingGQL } from "src/utils/helpers";
import { AssetDetails } from "./AssetDetails";
import { AwakenChartValuePoint } from "./Charts/types";
import { getTime, usePriceChartV2 } from "./Charts/usePriceChartV2";
import { ChartType } from "./types";
import {
  AssetKeyInfoContext,
  DEFAULT_TF,
  TIME_FRAMES,
  TimeFrame,
} from "./utils";

function AssetDetailsPage() {
  const { clientId, assetKey: assetKeyV2 } = useParams<{
    clientId: string;
    assetKey: string;
  }>();

  const [search] = useSearchParams();
  const { client } = useClientById(clientId);

  const variables = useMemo(
    () => ({
      assetPricingKeyV2: assetKeyV2,
      clientId,
    }),
    [clientId, assetKeyV2]
  );

  const tokenInfoVariables = useMemo(
    (): QueryGetTokenInfoArgs => ({
      assetKey: assetKeyV2 || "",
    }),
    [assetKeyV2]
  );

  const {
    data: assetKeyInfoData,
    networkStatus,
    refetch: refetchAssetKeyInfo,
  } = useQuery<{
    getAssetKeyInfo: Query["getAssetKeyInfo"];
  }>(api.assets.getAssetKeyInfo, {
    skip: !variables.assetPricingKeyV2 || !variables.clientId,
    variables: variables,
  });

  const {
    data: tokenInfoData,
    networkStatus: tokenInfoNetworkStatus,
    refetch: refetchTokenInfo,
  } = useQuery<{
    getTokenInfo: Query["getTokenInfo"];
  }>(api.portfolio.getTokenInfo, {
    skip: !tokenInfoVariables.assetKey,
    variables: tokenInfoVariables,
  });

  const balance = {} as any; // FIXME:
  const assetData = assetKeyInfoData?.getAssetKeyInfo;
  const assets = assetData?.assets || []; // NOTE: this is just empty right now FIXME: i just dont want the api to return all the assets cause can be a lil slow
  const firstAsset = assetData?.firstAsset || null;
  const name = assetData?.name || balance?.name || "";
  const symbol = assetData?.symbol || balance?.symbol || "";
  const iconImageUrl = assetData?.iconImageUrl || balance?.iconImageUrl || "";
  const coingeckoId = assetData?.coingeckoId || balance?.coinGeckoId || "";
  const type = assetData?.type || balance?.type || null;
  const provider = assetData?.provider || balance?.provider || null;
  const contractAddress =
    assetData?.contractAddress || balance?.contractAddress || null;
  const blockExplorerUrl = assetData?.blockExplorerUrl || null;

  const [getTransactions, { data: transactionsData }] = useLazyQuery(
    api.clients.transactions
  );

  const tf = search.get("tf") as unknown as Maybe<TimeFrame["type"]>;
  const defaultTf = TIME_FRAMES.find((d) => d.type === tf) || DEFAULT_TF;

  const [timeFrame, setActiveTimeFrame] = useState<Maybe<TimeFrame>>(defaultTf);

  const apolloClient = useApolloClient();

  const positionVariables = useMemo(
    () => ({
      clientId,
      assetPricingKey: assetKeyV2,
    }),
    [clientId, assetKeyV2]
  );

  // if (__DEV__) {
  //   console.log("Positions", variables);
  // }

  const {
    data: positionsData,
    loading: positionsLoading,
    error: positionsErrors,
    refetch: positionsRefetch,
  } = useQuery<Pick<Query, "getAssetPositions">>(
    api.portfolio.getAssetPositions,
    {
      variables: positionVariables,
      skip: !positionVariables.clientId || !positionVariables.assetPricingKey,
    }
  );

  const {
    points,
    loading: loadingPoints,
    fetchFullChart,
    currentValue,
  } = usePriceChartV2({
    clientId: clientId || "",
    assetKeyV2: assetKeyV2 || "",
    chartType: ChartType.Price,
    timeFrame,
  });

  const [currentValueDollars, _setCurrentValueDollars] = useState<
    Maybe<number>
  >(last(points)?.value ?? null);

  const [currentPoint, _setCurrentPoint] = useState<SingleValueData | null>(
    null
  );

  const [pointValueDollars, _setPointValueDollars] = useState<Maybe<number>>(
    last(points)?.value ?? null
  );

  const _fetchTransactions = async () => {
    if (!clientId) return;

    const variables: QueryGetClientTransactionsArgs = {
      clientId: clientId || "",
    };

    getTransactions({
      variables,
    });
  };

  useEffect(() => {
    console.log("[refetching positions]");
    positionsRefetch();
  }, []);

  useEffect(() => {
    _fetchTransactions();
  }, []);

  useEffect(() => {
    if (!currentValue) return;
    const val = currentValue?.value ?? null;
    _setCurrentValueDollars(val);
    _setPointValueDollars(val);
    _setCurrentPoint({
      time: getTime(currentValue.timestamp),
      value: val,
    });
  }, [JSON.stringify(currentValue)]);

  const { gainLossDollars, gainLossPercentage, isNeg } = useGainLoss({
    points,
    pointValueDollars,
  });

  const startingValueDollars = useMemo(
    () => first(points)?.value ?? null,
    [JSON.stringify(first(points) ?? {})]
  );

  const onRefresh = useCallback(async () => {
    console.log(`[refetching asset info for ${clientId} asset ${assetKeyV2}]`);
    const response = await refetchAssetKeyInfo();
    fetchFullChart();
    apolloClient.refetchQueries({
      include: [api.portfolio.getAssetPositions, api.clients.transactions],
    });
  }, [clientId, assetKeyV2, timeFrame]);

  const isOverallNeg =
    !isNil(startingValueDollars) &&
    !isNil(currentValueDollars) &&
    startingValueDollars > currentValueDollars;

  const position = positionsData?.getAssetPositions ?? null;
  const isLoadingTokenInfo = isLoadingGQL(tokenInfoNetworkStatus);

  useInterval(() => onRefresh(), 15_000);

  const providerValue: AssetKeyInfoContext = useMemo(
    () => ({
      client: client,
      position: position,
      assetKeyV2: assetKeyV2 ?? null,
      isNeg,
      loadingPoints: loadingPoints,
      gainLossDollars: gainLossDollars,
      gainLossPercentage: gainLossPercentage,
      points,
      assets,
      firstAsset,
      name,
      symbol,
      iconImageUrl,
      coingeckoId,
      type,
      currentValueDollars,
      currentPoint,
      startValueDollars: startingValueDollars,
      pointValueDollars: pointValueDollars,
      setPointValueDollars: _setPointValueDollars,
      setCurrentValueDollars: _setCurrentValueDollars,
      timeFrame,
      txns: [],
      setTimeFrame: setActiveTimeFrame,
      isLoadingTokenInfo,
      tokenInfo: tokenInfoData?.getTokenInfo || null,
      provider,
      contractAddress,
      blockExplorerUrl,
    }),
    [
      client,
      isNeg,
      currentPoint,
      loadingPoints,
      gainLossDollars,
      gainLossPercentage,
      points,
      assets,
      firstAsset,
      name,
      symbol,
      iconImageUrl,
      coingeckoId,
      type,
      currentValueDollars,
      startingValueDollars,
      pointValueDollars,
      _setPointValueDollars,
      _setCurrentValueDollars,
      timeFrame,
      setActiveTimeFrame,
      position,
      assetKeyV2,
      isLoadingTokenInfo,
      tokenInfoData,
      provider,
      contractAddress,
      blockExplorerUrl,
    ]
  );

  return (
    <AssetKeyInfoContext.Provider value={providerValue}>
      <Box style={{ width: "100%" }}>
        <Box padding="1rem 0" style={{ width: "100%" }}>
          <AssetDetails clientId={clientId!} />
        </Box>
      </Box>
    </AssetKeyInfoContext.Provider>
  );
}

const useGainLoss = ({
  points,
  pointValueDollars,
}: {
  points: AwakenChartValuePoint[];
  pointValueDollars: Maybe<number>;
}): {
  gainLossDollars: Maybe<number>;
  gainLossPercentage: Maybe<number>;
  isNeg: boolean;
} => {
  const firstPoint = first(points);

  const gainLoss =
    !isNil(pointValueDollars) && !isNil(firstPoint)
      ? pointValueDollars - firstPoint.value
      : null;

  const gainLossPercentage =
    !isNil(gainLoss) && !isNil(firstPoint)
      ? (gainLoss / firstPoint.value) * 100
      : null;

  return {
    gainLossDollars: gainLoss,
    gainLossPercentage,
    isNeg: !isNil(gainLoss) && gainLoss < 0,
  };
};

export default AssetDetailsPage;
