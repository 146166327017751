import { useMutation, useQuery } from "@apollo/client";
import { Box, Container, HStack, Text, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Mutation } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Button } from "src/components/styled";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  //
};

function _RefundModal({ handleHide, show: isVisible }: Props) {
  const { clientId } = useParams();

  const [generatePaymentLink, { loading }] = useMutation<
    Pick<Mutation, "getPaymentLink">
  >(api.checkouts.generatePaymentLink);

  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();

  // Get payments
  const { data: paymentsData, loading: paymentsLoading } = useQuery(
    api.checkouts.getStripePayments,
    {
      variables: { clientId },
      skip: !clientId,
    }
  );

  // Refund mutation
  const [refundPayment, { loading: refundLoading }] = useMutation(
    api.checkouts.refundStripe
  );

  const handleRefund = async (payment: any) => {
    if (!clientId) return;

    try {
      // ask them to put in the amount via confirm
      const amount = await window.prompt(
        `Enter the amount to refund in USD. Ex. 69.00 means $69.`,
        (payment.amount / 100).toFixed(2)
      );

      if (!amount) return;

      const parsedAmount = parseFloat(amount) * 100;

      const variables = {
        clientId,
        amountCents: parsedAmount,
        checkoutSessionId: payment.type === "checkout" ? payment.id : null,
        paymentIntentId: payment.type === "paymentIntent" ? payment.id : null,
      };

      await refundPayment({
        variables,
        refetchQueries: [api.checkouts.getStripePayments],
      });

      toast.show({
        message: "Payment refunded successfully!",
        status: "success",
      });
    } catch (e) {
      console.error(e);
      toast.show({
        message: "Error refunding payment.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      maxW="50rem"
      title="Refund Payment"
      isVisible={isVisible}
      handleHide={handleHide}
    >
      <Container padding="0px" marginTop="0px !important">
        <Text fontSize="md" color={theme.text} marginBottom="1rem">
          Select a payment to refund. This action cannot be undone.
        </Text>

        {paymentsLoading ? (
          <Text color={theme.text}>Loading payments...</Text>
        ) : (
          <VStack spacing={4} align="stretch" width="100%">
            {paymentsData?.getStripePayments.map((payment: any) => (
              <Box
                key={payment.id}
                spacing={4}
                padding="15px"
                borderRadius={15}
                border="1px"
                bg={theme.medBackground}
                borderColor={theme.border}
              >
                <HStack>
                  <Text flex="1" color={theme.header}>
                    {new Date(payment.created).toLocaleDateString()}
                  </Text>
                  <Text flex="1" color={theme.text}>
                    {(payment.amount / 100).toLocaleString("en-US", {
                      style: "currency",
                      currency: payment.currency.toUpperCase(),
                    })}
                  </Text>
                  <Text flex="1" color={theme.text}>
                    {payment.status}
                  </Text>
                  <Box flex="1">
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="red"
                      isDisabled={
                        refundLoading || payment.status !== "succeeded"
                      }
                      onClick={() => handleRefund(payment)}
                    >
                      Refund
                    </Button>
                  </Box>
                </HStack>

                <Text color={theme.text}>{payment.metadata}</Text>
              </Box>
            ))}
          </VStack>
        )}

        <br />
        <br />
      </Container>
    </Modal>
  );
}

export const RefundModal = connectModal({
  name: "RefundModal",
})(_RefundModal);
