import { Box, Flex, HStack, Heading } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AccountsWorkflow } from "src/components/modals/AccountModal/AccountsWorkflow";
import { useTheme } from "src/hooks/useTheme";
import { setTheme } from "src/redux/reducers/globalState";

function AddAccountScreen() {
  const [search] = useSearchParams();
  const theme = search.get("theme");
  const dispatch = useDispatch();
  const { header } = useTheme();

  const onSuccess = () => {
    const RN = (window as any).ReactNativeWebView;

    if (!RN) return;

    const params = JSON.stringify({ type: "success" });
    RN.postMessage(params);
  };

  useEffect(() => {
    if (!theme) {
      return;
    }

    if (theme !== "light" && theme !== "dark") return;

    dispatch(setTheme(theme as "light" | "dark"));
  }, [theme]);

  // Note: this screen is only used by the app so the negative pixel is hacky to get rid of ugly padding
  return (
    <Box top="-30px" position="relative" maxWidth={"inherit"}>
      <Box w="100%">
        <HStack alignItems="center" padding="0rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading color={header} marginTop="0" marginBottom="2rem" size="lg">
              Add an account or wallet
            </Heading>
          </Flex>
        </HStack>

        <AccountsWorkflow onSuccess={onSuccess} />
      </Box>
    </Box>
  );
}

export default AddAccountScreen;
