import { useMutation } from "@apollo/client";
import { Button, Checkbox, Container, Text } from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Modal } from "src/components/Modal";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  startingFeedback?: string;
  hiddenDescription?: string;
};

function _LawyerLetterModal({
  handleHide,
  show: isVisible,
  hiddenDescription,
  startingFeedback,
}: Props) {
  const [feedback, setFeedback] = useState(
    startingFeedback || "I am interested in a legal letter."
  );
  const [consent, setConsent] = useState(false);
  const [submitAffiliate, { loading }] = useMutation(api.contact.affiliate);
  const toast = useMyToast();
  const theme = useTheme();

  const _onSubmit = async function () {
    if (!feedback || !consent)
      return toast.show({
        message: "Please fill out all fields and accept the consent checkbox.",
        status: "error",
      });

    try {
      await submitAffiliate({
        variables: {
          feedback: `${feedback}\n\nOther: ${hiddenDescription || "-"}\nURL: ${
            window.location.href
          }\nSource: website 🌐`,
        },
      });

      toast.show({
        message: "Thank you for your submission",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }

    handleHide();
  };

  return (
    <Modal
      title="Request Legal Letter"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={_onSubmit}
        >
          Apply for Legal Letter
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text style={{ color: theme.text }} fontSize="md" fontWeight="500">
          We partner with a leading crypto law firm to help optimize your tax
          position.
          <br />
          <br />
          For transactions like airdrops, aggressive trading, derivative swaps
          (ex. SOL to mSOL), and bridging activities, a legal opinion letter may
          help reduce your tax liability.
          <br />
          <br />
          By applying below, the law firm will contact you to discuss options
          and provide a quote. Legal letters can cost $15k+ and takes ~2 weeks,
          but potential tax savings can be substantial depending on your
          circumstances.
        </Text>
        <br />
        <Checkbox
          isChecked={consent}
          onChange={(e) => setConsent(e.target.checked)}
          style={{ color: theme.text }}
        >
          I consent to sharing my information with a law firm.
        </Checkbox>
      </Container>
    </Modal>
  );
}

export const LawyerLetterModal = connectModal({
  name: "LawyerLetterModal",
})(_LawyerLetterModal);
