import { Box, BoxProps, Spinner, useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { compose } from "lodash/fp";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { show } from "redux-modal";
import NavBar from "src/components/NavBar";
import { Sidebar } from "src/components/Sidebar";
import { useClientById, useMe } from "src/hooks";
import { useClientListener } from "src/hooks/useClientListener";
import { useClientPoller } from "src/hooks/useClientPoller";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { setHighlightedTransactionId } from "src/redux/reducers/active";
import {
  getHasBanner,
  getIsGlobalRuleMode,
  setHasBanner,
} from "src/redux/reducers/globalState";
import { AgentSidebar } from "../Agent";
import { GlobalRuleBanner } from "../banners";
import { FreeBanner } from "../banners/FreeBanner";
import { MobileHeader } from "../MobileHeader";
import {
  AccountFileUploadModal,
  AssetModal,
  CreateAccountModal,
  UnstakingWarningModal,
  WalletTransferWarningModal,
} from "../modals";
import { LoginModal } from "../modals/2FA/LoginModal";
import { PhoneVerificationModal } from "../modals/2FA/PhoneVerificationModal";
import { TOTPModal } from "../modals/2FA/TOTPModal";
import { VerifyEmailModal } from "../modals/2FA/VerifyEmailModal";
import { AccountModal } from "../modals/AccountModal";
import { AddClientModal } from "../modals/AddClientModal";
import { AdminModal } from "../modals/AdminModal";
import { AffiliateModal } from "../modals/AffiliateModal";
import { AssetPriceModal } from "../modals/AssetPriceModal";
import { AwakenCSVUploadModal } from "../modals/AwakenCSVUploadModal";
import { AwakenProModal } from "../modals/AwakenProModal";
import { BatchWalletModal } from "../modals/BatchWalletModal";
import { BuilderDiscountModal } from "../modals/BuilderDiscountModal/BuilderDiscountModal";
import { BuyLinkModal } from "../modals/BuyLinkModal";
import { CheckoutModal } from "../modals/CheckoutModal";
import { ClientNameModal } from "../modals/ClientNameModal";
import { CommunityCodeModal } from "../modals/CommunityCodeModal";
import { CompetitorCreditModal } from "../modals/CompetitorCreditModal";
import { CreateGlobalRuleModal } from "../modals/CreateGlobalRuleModal";
import { CreateTxnModal } from "../modals/CreateTxnModal";
import { CXModal } from "../modals/CXModal";
import { DeleteUserModal } from "../modals/DeleteUserModal";
import { EditClientModal } from "../modals/EditClientModal";
import { FeedbackModal } from "../modals/FeedbackModal";
import { FreeSubscriptionModal } from "../modals/FreeSubscriptionModal";
import { FreeTaxModal } from "../modals/FreeTaxModal";
import { LabelsModal } from "../modals/LabelsModal";
import { LawyerLetterModal } from "../modals/LawyerLetterModal/LawyerLetterModal";
import { LockTaxesModal } from "../modals/LockTaxesModal";
import { OverrideLabelModal } from "../modals/OverrideLabelModal";
import { PartnershipDiscountModal } from "../modals/PartnershipDiscountModal";
import { PaymentModal } from "../modals/PaymentModal";
import { PayWithCryptoModal } from "../modals/PayWithCryptoModal";
import { PleaseShareModal } from "../modals/PleaseShareModal";
import { PurchaseUpgradeModal } from "../modals/PurchaseUpgradeModal/PurchaseUpgradeModal";
import { RawTransactionModal } from "../modals/RawTransactionModal";
import { ReferralCodeModal } from "../modals/ReferralCodeModal";
import { ReferralModal } from "../modals/ReferralModal";
import { RefundModal } from "../modals/RefundModal/RefundModal";
import { ReportExportWarningModal } from "../modals/ReportExportWarningModal";
import { SearchAssetModal } from "../modals/SearchAssetsModal";
import { ShareModal } from "../modals/ShareModal";
import { StakingExactWarningModal } from "../modals/StakingExactWarningModal";
import { TelegramModal } from "../modals/TelegramModal";
import { TransferModal } from "../modals/TransferModal";
import { TweetModal } from "../modals/TweetModal/TweetModal";
import { TxnDetailModal } from "../modals/TxnDetailModal";
import { UpgradeInfoModal } from "../modals/UpgradeInfoModal";
import { UpgradeModal } from "../modals/UpgradeModal/UpgradeModal";
import { VIPQuoteRequestModal } from "../modals/VIPQuoteRequestModal";
import { WalletAccountModal } from "../modals/WalletAccountModal/WalletAccountModal";
import { WalletModal } from "../modals/WalletModal";
import Recalculating from "../Recalculating";
import { BANNER_HEIGHT_PX } from "../styled/Banner";

const CONTENT_MARGIN_LEFT = 2.5;
const CONTENT_MARGIN_TOP = "2rem";

const Content = styled.div`
  width: ${100 - CONTENT_MARGIN_LEFT - CONTENT_MARGIN_LEFT}%;
  margin-left: ${CONTENT_MARGIN_LEFT}%;
  margin-top: ${CONTENT_MARGIN_TOP}%;
  overflow-y: scroll;
`;

function PageTemplateContainer(props: BoxProps) {
  const theme = useTheme();

  return (
    <Box
      w="100%"
      bgColor={theme.medBackground}
      style={{
        overflow: "hidden",
      }}
      {...props}
    >
      <PhoneVerificationModal />
      <TOTPModal />
      {props.children}
    </Box>
  );
}

type NavBarPageTemplateProps = BoxProps & {
  children: ReactNode;
  position?: string;
  button?: string;
  wrapInMainScreen?: boolean;
};

function NavBarPageTemplate({ button, ...props }: NavBarPageTemplateProps) {
  return (
    <PageTemplateContainer {...props}>
      <NavBar position={props.position} button={button} />
      <Content>{props.children}</Content>
    </PageTemplateContainer>
  );
}

const MainScreen = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 1.5rem 3rem;
  padding-bottom: 5rem;
  display: block;
  overflow-y: scroll;
  @media (max-width: 800px) {
    padding: 4rem 0.75rem;
  }
`;

function SideBarPageTemplate(props: NavBarPageTemplateProps) {
  const { clientId } = useParams<{ clientId: string }>();
  const params = useParams();
  const navigate = useNavigate();
  const [search, setSearchParams] = useSearchParams();
  const transactionId = search.get("transactionId");
  const queryHighlightTransactionId = search.get("highlightTransactionId");
  const showUpgradeModal = search.get("showUpgradeModal") === "true";
  const location = useLocation();

  const showBanner = useSelector(getHasBanner);
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const wrapInMainScreen = props.wrapInMainScreen ?? true;
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const { background } = useTheme();

  const { me } = useMe();

  // listens
  useClientListener(clientId || null);
  // fallback clears the client cache so we have to refetch it every 60 seconds
  useClientPoller();
  const isLarge = useIsLargeScreen();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hasBanner = useSelector(getHasBanner);

  const contentHeight = !isLarge
    ? "100%"
    : `calc(100vh - ${hasBanner ? BANNER_HEIGHT_PX : 0}px)`; // - NAVBAR_HEIGHT

  useEffect(() => {
    if (!client) return;

    if (!client?.name) {
      _showModal("ClientNameModal");
    }
  }, [client]);

  useEffect(() => {
    if (!transactionId) {
      return;
    }

    // // delete the txn id
    // search.delete("transactionId");
    // setSearchParams(search);

    // show modal if there is a transactionId
    if (transactionId) {
      _showModal("TxnDetailModal", {
        transactionId,
        startingTxn: null,
      });
    }
  }, [transactionId]);

  useEffect(() => {
    if (!showUpgradeModal) return;
    dispatch(show("UpgradeInfoModal"));
  }, [showUpgradeModal]);

  useEffect(() => {
    dispatch(setHighlightedTransactionId(queryHighlightTransactionId));
  }, [queryHighlightTransactionId]);
  const theme = useTheme();

  // portfolio redirect
  if (!me || !clientId) {
    return (
      <Box
        w="100%"
        display="flex"
        bg={theme.background}
        flexDir="row"
        minHeight="100vh"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Spinner color={theme.header} />
      </Box>
    );
  }
  // if (
  //   me &&
  //   me.hasPortfolioEnabled &&
  //   clientId &&
  //   // only if they're on the base link, not if they actually clicked the portfolio tab
  //   (window.location.pathname === getBaseLink(params.clientId || "") ||
  //     window.location.pathname === `${getBaseLink(params.clientId || "")}/`)
  // ) {
  //   return <Navigate to={getAccountsLink(clientId)} />;
  // }

  const isPortfolio =
    location.pathname === `/clients/${clientId}/portfolio` ||
    location.pathname === `/clients/${clientId}/portfolio/` ||
    location.pathname === `/clients/${clientId}/dashboard` ||
    location.pathname === `/clients/${clientId}` ||
    location.pathname.includes(`/clients/${clientId}/portfolio`);

  const isTxns =
    location.pathname === `/clients/${clientId}/transactions` ||
    location.pathname === `/clients/${clientId}/transactions/`;

  return (
    <PageTemplateContainer {...props}>
      {/* modals */}

      <BuyLinkModal />
      <RefundModal />
      {/* <PhishingAlertModal /> */}
      <VIPQuoteRequestModal />
      <CompetitorCreditModal />
      <VerifyEmailModal />
      <LoginModal />
      <CXModal />
      <TxnDetailModal />
      <AssetModal />
      <AssetPriceModal />
      <SearchAssetModal />
      <CreateTxnModal />
      <TransferModal />
      <ReferralCodeModal />
      <WalletTransferWarningModal />
      <UnstakingWarningModal />
      <StakingExactWarningModal />
      <ReportExportWarningModal />
      <AccountModal />
      <WalletAccountModal />
      <AwakenCSVUploadModal />
      <AccountFileUploadModal />
      <OverrideLabelModal />
      <CreateAccountModal />
      <CreateGlobalRuleModal />
      <CheckoutModal />
      <PaymentModal />
      <UpgradeInfoModal />
      <PurchaseUpgradeModal />
      <LabelsModal />
      {/* <PricedAssetModal /> */}
      <ClientNameModal />
      <DeleteUserModal />
      <WalletModal />
      <BatchWalletModal />
      <ReferralModal />
      <PayWithCryptoModal />
      <CommunityCodeModal />
      <AddClientModal />
      <EditClientModal />
      <FeedbackModal />
      <FreeSubscriptionModal />
      <ShareModal />
      <PleaseShareModal />
      <TweetModal />
      <FreeTaxModal />
      <UpgradeModal />
      <BuilderDiscountModal />

      {/* components */}
      <Recalculating />
      <LawyerLetterModal />
      <AdminModal />
      <TelegramModal />
      <AffiliateModal />
      <RawTransactionModal />
      <PartnershipDiscountModal />
      <AwakenProModal />
      <LockTaxesModal />

      {/* page content */}

      <MobileHeader />

      <div style={{ height: "100%" }}>
        <PageBanner />

        <Box
          bg={theme.background}
          height={contentHeight}
          // minH="100vh"
          w="100%"
          display="flex"
        >
          <Sidebar />
          {wrapInMainScreen ? (
            <MainScreen
              style={{
                background,
                paddingTop:
                  isPortfolio || isTxns ? 0 : isLarge ? undefined : "4rem",
                padding: isPortfolio || isTxns ? 0 : undefined,
              }}
            >
              {props.children}
            </MainScreen>
          ) : (
            props.children
          )}
          <AgentSidebar />
        </Box>
      </div>
    </PageTemplateContainer>
  ); // do <NavBarSideBarPageTemplate> <Sidebar /> ... </NavBarSideBarPageTemplate>
}

const PageBanner = () => {
  const dispatch = useDispatch();
  const isGlobalRuleMode = useSelector(getIsGlobalRuleMode);
  const _setHasBanner = compose(dispatch, setHasBanner);

  useEffect(() => {
    if (isGlobalRuleMode) _setHasBanner(true);
  }, [_setHasBanner]);

  const banner = isGlobalRuleMode ? <GlobalRuleBanner /> : <FreeBanner />;

  return banner;
  // <div style={{ position: "fixed", zIndex: 100, top: 0, left: 0, right: 0 }}>
  //   {banner}
  // </div>
};

function NoNavBarPageTemplate(props: BoxProps) {
  return (
    <PageTemplateContainer {...props}>
      {/* this isn't used atm bc email verification is only done on the settings page. may change tho so adding here for now */}
      <VerifyEmailModal />
      <Content>{props.children}</Content>
    </PageTemplateContainer>
  );
}

export {
  // Content,
  NavBarPageTemplate,
  NoNavBarPageTemplate,
  PageTemplateContainer,
  SideBarPageTemplate,
};
