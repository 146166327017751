import { useMutation } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { api } from "src/api";
import { Mutation, MutationUnhideTransfersArgs } from "src/api/generated/types";
import { Touchable } from "src/components/Touchable";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";

type Props = {
  transactionId: string;
  hasHiddenTransfers?: Maybe<boolean>;
};

export const UnhideTransfers = ({
  transactionId,
  hasHiddenTransfers,
}: Props) => {
  const toast = useMyToast();

  const [unhideTransfers, { loading }] = useMutation<
    {
      unhideTransfers?: Mutation["unhideTransfers"];
    },
    MutationUnhideTransfersArgs
  >(api.transactions.transfers.unhideTransfers, {});

  const onClickUnhideTransfers = async () => {
    // if (loading) return;
    // if (
    //   window.confirm(
    //     "Are you sure you want to unhide the transfers on this transaction?"
    //   )
    // ) {
    try {
      await unhideTransfers({
        variables: {
          transactionId,
        },
        refetchQueries: [
          {
            query: api.transactions.retrieve,
            variables: { transactionId },
          },
          {
            query: api.transactions.typeOptions,
            variables: { transactionId },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: (err as Error).message || "Could not unhide transfers",
      });
    }
    // }
  };

  if (!hasHiddenTransfers) return <Box />;

  return (
    <Box>
      <Touchable
        onClick={onClickUnhideTransfers}
        label="Unhide transfers"
        padding="0.5rem 0.75rem"
        // iconName="fa-sharp fa-list-timeline"
      />
    </Box>
  );
};
