import { useLazyQuery } from "@apollo/client";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import numbro from "numbro";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { AssetTypeEnum } from "src/api/generated/types";
import WhiteBox from "src/components/styled/WhiteBox";
import { Touchable } from "src/components/Touchable";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getAssetOnMarketplaceOrCoingecko } from "src/modules/getAssetUrl";
import { formatNum } from "src/utils/helpers";
import { AssetKeyInfoContext } from "./utils";

const TokenDetails = () => {
  const { assetKey } = useParams<{ assetKey: string }>();
  const theme = useTheme();
  const {
    tokenInfo,
    blockExplorerUrl,
    provider,
    contractAddress,
    firstAsset,
    coingeckoId,
  } = useContext(AssetKeyInfoContext);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const [fetchTokenInfo, { data: tokenData }] = useLazyQuery(
    api.portfolio.getTokenInfo,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (!assetKey) return;
    fetchTokenInfo({
      variables: {
        assetKey,
      },
    });
  }, [assetKey]);

  const description = tokenInfo?.about?.description;

  const data = {
    coinGeckoTokenId: firstAsset?.coinGeckoTokenId || coingeckoId,
    type: firstAsset?.type || AssetTypeEnum.FungibleToken,
    contractAddress: firstAsset?.contractAddress || contractAddress,
    provider: firstAsset?.provider || provider,
    tokenId: firstAsset?.tokenId,
  };

  const assetInfo = getAssetOnMarketplaceOrCoingecko(data);

  const isLarge = useIsLargeScreen();

  if (!tokenInfo) {
    return null;
  }

  return (
    <Box paddingBottom="0" width="100%" border="none" marginBottom={"1.5rem"}>
      <HStack flex={1} marginBottom="0.75rem" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text
            color={theme.header}
            fontSize={18}
            fontWeight="semibold"
            marginRight="5px"
            style={{ fontStretch: "extra-expanded" }}
          >
            Details
          </Text>
        </Flex>

        {blockExplorerUrl && (
          <>
            <a target="_blank" href={blockExplorerUrl}>
              <Touchable
                iconName="fa-solid fa-globe"
                label={isLarge ? "Explorer" : ""}
              />
            </a>
          </>
        )}

        {assetInfo && (
          <a href={assetInfo.url} target="_blank">
            <Touchable
              icon={
                <img
                  src={assetInfo.imageUrl}
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 100,
                  }}
                />
              }
              label={isLarge ? assetInfo.name : ""}
            />
          </a>
        )}
      </HStack>

      <WhiteBox
        style={{ boxShadow: "none" }}
        border={`1px solid ${theme.border}`}
        padding="1rem"
        marginTop="0"
        h="100%"
        minW="none"
      >
        <HStack spacing={8} justify="space-between">
          <VStack flex={1} align="start" spacing={1}>
            <Text color={theme.text} fontSize="sm">
              Market cap
            </Text>
            <Text color={theme.header} fontSize="md" fontWeight="medium">
              {tokenInfo?.stats?.marketCap
                ? numbro(Math.floor(tokenInfo.stats.marketCap)).format("$0,0")
                : "-"}
            </Text>
          </VStack>

          <VStack flex={1} alignItems="flex-start" spacing={1}>
            <Text color={theme.text} fontSize="sm">
              Volume (24h)
            </Text>
            <Text color={theme.header} fontSize="md" fontWeight="medium">
              {tokenInfo?.stats?.v24hUSD
                ? formatNum(tokenInfo.stats.v24hUSD, true, "$0,0.00")
                : "-"}
            </Text>
          </VStack>
        </HStack>
      </WhiteBox>
    </Box>
  );
};

export default TokenDetails;
