import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { truncate } from "lodash";
import { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import { api } from "src/api";
import { Asset, AssetTypeEnum, Query } from "src/api/generated/types";
import { AwakenTooltip, Button } from "src/components";
import {
  INTEGRATIONS,
  PROVIDER_TO_LOGO_URL,
} from "src/components/modals/AccountModal/constants";
import { AssetIcon } from "src/components/styled/Assets";
import WhiteBox from "src/components/styled/WhiteBox";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

import { Select as AwakenSelect } from "src/components/styled/Form/Select";

const LIST_SIZE = 15;
const NEEDS_REVIEW_SIZE = 15;
const NEEDS_REVIEW_PAGE_OPTIONS = [15, 25, 50, 100];

function Spam() {
  const { clientId } = useParams<{ clientId: string }>();

  const toast = useMyToast();
  const [unsetSpam, { loading: isUnsettingSpam }] = useMutation(
    api.assets.unsetSpam
  );
  const [bulkMarkSpam, { loading: isBulkMarkingSpam }] = useMutation(
    api.assets.bulkMarkSpam
  );
  const [checkForSpamReview] = useMutation(api.assets.checkForSpamReview);

  const theme = useTheme();

  const {
    data: spamData,
    networkStatus: jobsNetworkStatus,
    error,
    refetch: fetchSpamAssets,
  } = useQuery<Pick<Query, "getClientSpamAssets">>(api.assets.spam, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      clientId,
    },
  });

  useEffect(() => void fetchSpamAssets(), [clientId]);

  const _checkForSpamReview = async () => {
    try {
      toast.show({
        message:
          "Started checking for assets that need review. This may take a bit, so you can check back in a few minutes. You can close this tab.",
        status: "success",
      });

      const response = await checkForSpamReview({
        variables: { clientId },
        refetchQueries: [api.assets.spam],
        awaitRefetchQueries: true,
      });

      void fetchSpamAssets();
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Failed to check for spam review.",
        status: "error",
      });
    }
  };

  const _unsetSpam = async (assetId: string) => {
    try {
      const unsetResponse = await unsetSpam({
        variables: { assetId },
        refetchQueries: [api.assets.spam, api.clients.transactions],
        awaitRefetchQueries: true,
      });

      const message = unsetResponse?.data?.unsetSpam || "";

      void fetchSpamAssets();

      toast.show({
        message,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Failed to unset spam",
        status: "error",
      });
    }
  };

  const _markNotSpam = async (assetId: string) => {
    try {
      const unsetResponse = await unsetSpam({
        variables: { assetId },
        refetchQueries: [api.assets.spam, api.clients.transactions],
        awaitRefetchQueries: true,
      });

      const message = unsetResponse?.data?.unsetSpam || "";

      void fetchSpamAssets();

      toast.show({
        message,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Failed to unset spam",
        status: "error",
      });
    }
  };

  const _markAsSpam = async (assetId: string) => {
    try {
      const response = await bulkMarkSpam({
        variables: { clientId, assetIds: [assetId] },
        refetchQueries: [api.assets.spam],
        awaitRefetchQueries: true,
      });

      const message = response?.data?.bulkMarkSpam || "";

      toast.show({
        message: message || "Successfully marked assets as spam.",
        status: "success",
      });

      void fetchSpamAssets();

      setSelectedAssets([]);
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Failed to mark as spam.",
        status: "error",
      });
    }
  };

  const bulkMarkAsSpam = async () => {
    try {
      const response = await bulkMarkSpam({
        variables: { clientId, assetIds: selectedAssets },
        refetchQueries: [api.assets.spam],
        awaitRefetchQueries: true,
      });

      const message = response?.data?.bulkMarkSpam || "";

      toast.show({
        message: message || "Successfully marked assets as spam.",
        status: "success",
      });

      void fetchSpamAssets();

      setSelectedAssets([]);
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Failed to mark as spam.",
        status: "error",
      });
    }
  };

  const bulkMarkAsNotSpam = async () => {
    try {
      for (const assetId of selectedAssets) {
        await unsetSpam({
          variables: { assetId },
          refetchQueries: [api.assets.spam, api.clients.transactions],
          awaitRefetchQueries: true,
        });
      }

      void fetchSpamAssets();

      toast.show({
        message: `Successfully marked ${selectedAssets.length} assets as not spam.`,
        status: "success",
      });

      setSelectedAssets([]);
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Failed to mark as not spam.",
        status: "error",
      });
    }
  };

  const spamAssets = (spamData?.getClientSpamAssets?.assets || []) as Asset[];
  const needsReviewAssets = (spamData?.getClientSpamAssets?.needsReview ||
    []) as Asset[];

  const [searchTerm, setSearchTerm] = useState("");
  const [needsReviewSearchTerm, setNeedsReviewSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const filteredSpamAssets = spamAssets.filter(
    (asset) =>
      asset.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (asset.symbol || "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSpamAssets.length / LIST_SIZE);
  const paginatedAssets = filteredSpamAssets.slice(
    page * LIST_SIZE,
    (page + 1) * LIST_SIZE
  );

  const _setPage = (newPage: number) => {
    setPage(newPage);
  };

  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);

  const [needsReviewPage, setNeedsReviewPage] = useState(0);

  const [needsReviewSize, setNeedsReviewSize] = useState(NEEDS_REVIEW_SIZE);

  const [selectedProvider, setSelectedProvider] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [selectedAssetType, setSelectedAssetType] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const providerOptions = useMemo(
    () => [
      { value: "", label: "All chains" },
      ...INTEGRATIONS.map((i) => ({
        value: i.provider,
        label: i.name,
      })),
    ],
    []
  );

  const assetTypeOptions = useMemo(
    () => [
      { value: "", label: "All types" },
      { value: AssetTypeEnum.FungibleToken, label: "Tokens" },
      { value: AssetTypeEnum.Nft, label: "NFTs" },
    ],
    []
  );

  const filteredNeedsReviewAssets = needsReviewAssets.filter((asset) => {
    const matchesSearch =
      asset.name.toLowerCase().includes(needsReviewSearchTerm.toLowerCase()) ||
      (asset.symbol || "")
        .toLowerCase()
        .includes(needsReviewSearchTerm.toLowerCase()) ||
      asset.provider
        ?.toLowerCase()
        .includes(needsReviewSearchTerm.toLowerCase());

    const matchesProvider =
      !selectedProvider?.value ||
      asset.provider?.toLowerCase() === selectedProvider.value.toLowerCase();

    const matchesType =
      !selectedAssetType?.value ||
      asset.type?.toLowerCase() === selectedAssetType.value.toLowerCase();

    return matchesSearch && matchesProvider && matchesType;
  });

  const paginatedNeedsReview = filteredNeedsReviewAssets.slice(
    needsReviewPage * needsReviewSize,
    (needsReviewPage + 1) * needsReviewSize
  );
  const totalNeedsReviewPages = Math.ceil(
    filteredNeedsReviewAssets.length / needsReviewSize
  );

  const selectAllDisabled = isBulkMarkingSpam || isUnsettingSpam;

  return (
    <div>
      <HStack style={{ width: "100%", margin: "1.5rem 0 0.75rem 0" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Heading size="lg" margin="0" mr={4} color={theme.header}>
            Review Assets{" "}
          </Heading>
          {/* <StatusTag
            type="beta"
            label="Beta"
            iconName="fa-sharp fa-vial"
            infoMessage="This feature is in Beta. If you have problems or feedback, just let us know."
          /> */}
        </div>

        <AwakenTooltip
          placement="bottom-end"
          message="We recommend only using this once you've added all your wallets and have recalculated your transactions."
        >
          <div>
            <Button
              style={{
                background: theme.secondaryBackground2,
                color: theme.text,
              }}
              fontSize="sm"
              onClick={_checkForSpamReview}
            >
              Check for Spam{" "}
              <i
                style={{ marginLeft: 10, fontSize: 14 }}
                className="fa-sharp fa-binoculars"
              />
            </Button>
          </div>
        </AwakenTooltip>
      </HStack>

      <a
        href="https://help.awaken.tax/en/articles/10419498-i-have-a-lot-of-spam-transactions-how-do-i-handle-them"
        target="_blank"
      >
        <Text
          style={{
            color: theme.text,
          }}
        >
          We have a helpful guide on spam{" "}
          <span
            style={{
              fontWeight: "bold",
              color: colors.primary,
              textDecoration: "underline",
            }}
          >
            here
          </span>
          !
        </Text>
      </a>

      {needsReviewAssets.length > 0 && (
        <>
          <WhiteBox
            style={{
              background:
                theme.theme === "light"
                  ? theme.background
                  : theme.medBackground,
            }}
            padding="1rem"
          >
            <HStack mb={1}>
              <Heading size="md" margin="1rem 0" mr={10} color={theme.header}>
                Needs Review ({needsReviewAssets.length})
              </Heading>

              <Flex flex={1} />

              {selectedAssets.length >= 2 && (
                <Button
                  colorScheme="green"
                  rightIcon={<i className="fa-sharp fa-check" />}
                  isDisabled={selectedAssets.length === 0}
                  onClick={bulkMarkAsNotSpam}
                >
                  Mark {selectedAssets.length || "-"} as Not Spam
                </Button>
              )}

              {selectedAssets.length >= 2 && (
                <Button
                  colorScheme="red"
                  rightIcon={<i className="fa-sharp fa-ban" />}
                  isDisabled={selectedAssets.length === 0}
                  onClick={bulkMarkAsSpam}
                >
                  Mark {selectedAssets.length || "-"} as Spam
                </Button>
              )}
            </HStack>

            <HStack
              style={{
                alignItems: "flex-start",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <InputGroup maxWidth="300px">
                  <InputLeftElement pointerEvents="none">
                    <i
                      className="fa-sharp fa-search"
                      style={{ color: theme.text }}
                    />
                  </InputLeftElement>
                  <Input
                    placeholder="Search needs review..."
                    value={needsReviewSearchTerm}
                    onChange={(e) => setNeedsReviewSearchTerm(e.target.value)}
                    bg={theme.secondaryBackground}
                    color={theme.text}
                    borderColor={theme.border}
                    _hover={{
                      borderColor: theme.border,
                    }}
                    style={{
                      fontSize: 16,
                      width: 275,
                    }}
                    _focus={{
                      borderColor: colors.primary,
                      boxShadow: `0 0 0 1px ${colors.primary}`,
                    }}
                  />
                </InputGroup>

                <Checkbox
                  isChecked={
                    paginatedNeedsReview.length > 0 &&
                    paginatedNeedsReview.every((asset) =>
                      selectedAssets.includes(asset.id)
                    )
                  }
                  style={{ marginTop: 15 }}
                  disabled={selectAllDisabled}
                  onChange={(e) => {
                    if (selectAllDisabled) {
                      return;
                    }

                    e.stopPropagation();
                    e.preventDefault();

                    if (e.target.checked) {
                      setSelectedAssets([
                        ...selectedAssets,
                        ...paginatedNeedsReview
                          .map((a) => a.id)
                          .filter((id) => !selectedAssets.includes(id)),
                      ]);
                    } else {
                      setSelectedAssets(
                        selectedAssets.filter(
                          (id) => !paginatedNeedsReview.find((a) => a.id === id)
                        )
                      );
                    }
                  }}
                >
                  <Text color={theme.text}>
                    Select All{" "}
                    {selectedAssets.length > 0
                      ? `(${selectedAssets.length})`
                      : ""}
                  </Text>
                </Checkbox>
              </Box>

              <div
                style={{
                  width: 150,
                }}
              >
                <AwakenSelect
                  placeholder="Filter chain"
                  options={providerOptions}
                  value={selectedProvider}
                  containerStyle={{ marginBottom: 0 }}
                  selectProps={{
                    isClearable: true,
                    onChange: (option) => setSelectedProvider(option as any),
                  }}
                />
              </div>

              <div
                style={{
                  width: 150,
                }}
              >
                <AwakenSelect
                  placeholder="Filter type"
                  options={assetTypeOptions}
                  value={selectedAssetType}
                  containerStyle={{ marginBottom: 0 }}
                  selectProps={{
                    isClearable: true,
                    onChange: (option) => setSelectedAssetType(option as any),
                  }}
                />
              </div>
            </HStack>

            <Box mt={8}>
              {paginatedNeedsReview.map((asset) => (
                <NeedsReviewAsset
                  key={asset.id}
                  asset={asset}
                  selectedAssets={selectedAssets}
                  setSelectedAssets={setSelectedAssets}
                  clientId={clientId || ""}
                  markNotSpam={_markNotSpam}
                  markAsSpam={_markAsSpam}
                />
              ))}
            </Box>

            {totalNeedsReviewPages > 1 && (
              <HStack marginTop="1rem" justify="space-between" align="center">
                <HStack spacing={2}>
                  <Text color={theme.text}>Items per page:</Text>
                  <Select
                    value={needsReviewSize}
                    onChange={(e) => {
                      setNeedsReviewSize(Number(e.target.value));
                      setNeedsReviewPage(0);
                    }}
                    width="100px"
                    bg={theme.secondaryBackground}
                    color={theme.text}
                    borderColor={theme.border}
                  >
                    {NEEDS_REVIEW_PAGE_OPTIONS.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </Select>
                </HStack>

                <ReactPaginate
                  breakLabel=".."
                  nextLabel={<i className="fa-sharp fa-chevron-right" />}
                  onPageChange={({ selected }) => setNeedsReviewPage(selected)}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                  forcePage={needsReviewPage}
                  initialPage={needsReviewPage || 0}
                  pageCount={totalNeedsReviewPages}
                  previousLabel={<i className="fa-sharp fa-chevron-left" />}
                  pageClassName={
                    theme.theme === "light" ? "page-item" : "dark-page-item"
                  }
                  pageLinkClassName={
                    theme.theme === "light" ? "page-link" : "dark-page-link"
                  }
                  previousClassName={
                    theme.theme === "light" ? "page-item" : "dark-page-item"
                  }
                  previousLinkClassName={
                    theme.theme === "light" ? "page-link" : "dark-page-link"
                  }
                  nextClassName={
                    theme.theme === "light" ? "page-item" : "dark-page-item"
                  }
                  nextLinkClassName={
                    theme.theme === "light" ? "page-link" : "dark-page-link"
                  }
                  breakClassName={
                    theme.theme === "light" ? "page-item" : "dark-page-item"
                  }
                  breakLinkClassName={
                    theme.theme === "light" ? "page-link" : "dark-page-link"
                  }
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </HStack>
            )}
          </WhiteBox>
        </>
      )}

      <Box mb={4} mt={"50px"}>
        <InputGroup maxWidth="300px">
          <InputLeftElement pointerEvents="none">
            <i className="fa-sharp fa-search" style={{ color: theme.text }} />
          </InputLeftElement>
          <Input
            placeholder="Search assets..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            bg={theme.secondaryBackground}
            color={theme.text}
            borderColor={theme.border}
            _hover={{
              borderColor: theme.border,
            }}
            _focus={{
              borderColor: colors.primary,
              boxShadow: `0 0 0 1px ${colors.primary}`,
            }}
          />
        </InputGroup>
      </Box>

      <WhiteBox padding="0">
        <TableContainer style={{ border: "none" }}>
          {filteredSpamAssets.length === 0 ? (
            <Text p={10} textAlign="center" color={theme.text}>
              {searchTerm ? "No matching assets found" : "No spam assets found"}
            </Text>
          ) : (
            <>
              <Table variant="simple" sx={{ border: "none" }}>
                <Thead>
                  <Tr>
                    <Th
                      color={theme.text}
                      borderBottom={`1px solid ${theme.border}`}
                    >
                      Asset
                    </Th>
                    <Th
                      color={theme.text}
                      borderBottom={`1px solid ${theme.border}`}
                    >
                      Transactions
                    </Th>
                    <Th
                      color={theme.text}
                      borderBottom={`1px solid ${theme.border}`}
                    >
                      Spam
                    </Th>
                    <Th
                      style={{
                        maxWidth: 100,
                      }}
                      color={theme.text}
                      borderBottom={`1px solid ${theme.border}`}
                    >
                      Actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedAssets.map((asset) => (
                    <SpamAssetRow
                      key={asset.id}
                      asset={asset}
                      clientId={clientId || ""}
                      onUnsetSpam={_unsetSpam}
                      theme={theme}
                    />
                  ))}
                </Tbody>
              </Table>
            </>
          )}
        </TableContainer>
      </WhiteBox>

      <HStack marginTop={"1rem"} padding="0.25rem 0">
        <HStack
          flex={1}
          marginLeft={5}
          alignItems="center"
          justifyContent="flex-end"
        >
          <ReactPaginate
            breakLabel=".."
            nextLabel={<i className="fa-sharp fa-chevron-right" />}
            onPageChange={({ selected }) => _setPage(selected)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            forcePage={page}
            initialPage={page || 0}
            pageCount={totalPages}
            previousLabel={<i className="fa-sharp fa-chevron-left" />}
            pageClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            pageLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            previousClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            previousLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            nextClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            nextLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            breakClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            breakLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            containerClassName="pagination"
            activeClassName="active"
          />
        </HStack>
      </HStack>
    </div>
  );
}

const SpamAssetRow = ({
  asset,
  clientId,
  onUnsetSpam,
  theme,
}: {
  asset: Asset;
  clientId: string;
  onUnsetSpam: (assetId: string) => void;
  theme: any;
}) => {
  const providerLogoUrl =
    PROVIDER_TO_LOGO_URL[asset.provider?.toLowerCase() || ""];

  return (
    <Tr key={asset.id} borderBottom={`1px solid ${theme.border}`}>
      <Td color={theme.text} borderColor={theme.border}>
        <HStack spacing={2}>
          <div style={{ marginRight: 15, position: "relative", flexShrink: 0 }}>
            <AssetIcon
              asset={{
                iconImageUrl: asset.iconImageUrl,
                symbol: asset.symbol,
                type: asset.type,
              }}
              size={35}
            />

            {providerLogoUrl && (
              <img
                src={providerLogoUrl}
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  borderRadius: "100%",
                  bottom: -5,
                  border: `1px solid ${theme.border}`,
                  backgroundColor: theme.border,
                  right: -5,
                  borderRight: "100%",
                  position: "absolute",
                }}
              />
            )}
          </div>
          <Box>
            <AwakenTooltip message={asset.name || "None"}>
              <Text fontWeight="medium" fontSize="md" color={theme.text}>
                {truncate(asset.name || "None", { length: 40 })}
              </Text>
            </AwakenTooltip>
            <Text fontWeight="normal" fontSize="sm" color={theme.text}>
              {truncate(asset.symbol || "-", { length: 20 })}
            </Text>
          </Box>
        </HStack>
      </Td>

      <Td borderColor={theme.border} color={colors.primary}>
        <Link
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            color: colors.primary,
          }}
          to={`/clients/${clientId}/transactions?assetIds=${asset.id}&includeSpam=true`}
        >
          View Transactions
        </Link>{" "}
        <i
          className="fa-sharp fa-arrow-up-right-from-square"
          style={{ fontSize: 12 }}
        />
      </Td>

      <Td color={theme.text} borderColor={theme.border}>
        {asset.isSpamUserSet && (
          <AwakenTooltip message="You labeled a transaction with this asset as spam.">
            <Text fontSize="sm" style={{ marginBottom: "0.25rem" }}>
              User Marked <i className="fa-sharp fa-check-circle" />
            </Text>
          </AwakenTooltip>
        )}
        {asset.isSpamAuto && !asset.isSpamUserSet && (
          <AwakenTooltip message="This asset was found in our database of over 1M spam assets and auto-tagged.">
            <Text fontSize="sm">
              Auto Detected <i className="fa-sharp fa-robot" />
            </Text>
          </AwakenTooltip>
        )}
      </Td>
      <Td style={{ width: 100 }} borderColor={theme.border}>
        <AwakenTooltip
          placement="bottom-end"
          message="This will remove the spam label from the asset and also remove the spam tag for any transactions associated with the asset."
        >
          <div>
            <Button
              size="sm"
              colorScheme="green"
              onClick={() => onUnsetSpam(asset.id)}
            >
              Not Spam{" "}
              <i
                style={{ marginLeft: 5 }}
                className="fa-sharp fa-check-circle"
              />
            </Button>
          </div>
        </AwakenTooltip>
      </Td>
    </Tr>
  );
};

const NeedsReviewAsset = ({
  asset,
  selectedAssets,
  setSelectedAssets,
  markNotSpam,
  markAsSpam,
  clientId,
}: {
  asset: Asset;
  selectedAssets: string[];
  setSelectedAssets: (assets: string[]) => void;
  markNotSpam: (assetId: string) => void;
  markAsSpam: (assetId: string) => void;
  clientId: string;
}) => {
  const theme = useTheme();
  const name = /\\u[0-9a-fA-F]{4}/.test(asset.name) ? null : asset.name;
  const symbol = /\\u[0-9a-fA-F]{4}/.test(asset.symbol || "")
    ? null
    : asset.symbol;
  const providerLogoUrl =
    PROVIDER_TO_LOGO_URL[asset.provider?.toLowerCase() || ""];

  return (
    <HStack
      key={asset.id}
      p={2}
      mb={1}
      borderBottom={`1px solid ${theme.secondaryBackground2}`}
      // borderRadius={15}
      // bg={theme.background}
      spacing={4}
      align="center"
    >
      <Checkbox
        isChecked={selectedAssets.includes(asset.id)}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setSelectedAssets(
            e.target.checked
              ? [...selectedAssets, asset.id]
              : selectedAssets.filter((id) => id !== asset.id)
          );
        }}
      />

      <HStack spacing={2} flex={1}>
        <div style={{ marginRight: 15, position: "relative", flexShrink: 0 }}>
          <AssetIcon
            asset={{
              iconImageUrl: asset.iconImageUrl,
              symbol: asset.symbol,
              type: asset.type,
            }}
          />

          {providerLogoUrl && (
            <img
              src={providerLogoUrl}
              style={{
                width: "1.25rem",
                height: "1.25rem",
                borderRadius: "100%",
                bottom: -5,
                border: `1px solid ${theme.border}`,
                backgroundColor: theme.border,
                right: -5,
                borderRight: "100%",
                position: "absolute",
              }}
            />
          )}
        </div>

        <Box flex={1}>
          <Link
            to={`/clients/${clientId}/transactions?assetIds=${asset.id}&includeSpam=true`}
          >
            <Text
              fontWeight="medium"
              color={theme.text}
              _hover={{
                textDecoration: "underline",
              }}
            >
              {truncate(name || "None", { length: 40 })}
            </Text>
          </Link>
          <Text mt={1} fontSize="xs" color={theme.text}>
            {truncate(symbol || "-", { length: 20 })}
          </Text>
        </Box>

        {/* {asset.blockExplorerUrl && (
          <HStack
            spacing={1}
            style={{
              color: theme.header,
            }}
          >
            <a
              href={asset.blockExplorerUrl}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                color: theme.header,
              }}
            >
              View in Explorer
            </a>
            <i
              style={{ fontSize: 12 }}
              className="fa-sharp fa-arrow-up-right-from-square"
            />
          </HStack>
        )} */}
      </HStack>

      <HStack marginLeft="35px">
        <Button
          size="sm"
          colorScheme="green"
          onClick={async (e) => {
            await markNotSpam(asset.id);
          }}
        >
          Not Spam{" "}
          <i style={{ marginLeft: 5 }} className="fa-sharp fa-check-circle" />
        </Button>

        <Button
          marginLeft="10px"
          size="sm"
          colorScheme="red"
          onClick={async (e) => {
            await markAsSpam(asset.id);
          }}
        >
          Spam <i style={{ marginLeft: 5 }} className="fa-sharp fa-ban" />
        </Button>
      </HStack>
    </HStack>
  );
};

export default Spam;
