import { useMutation } from "@apollo/client";
import {
  Button,
  Container,
  HStack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps, show } from "redux-modal";
import { api, apolloClient } from "src/api";
import { MutationClaimFreeChainsArgs } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { ProfilePicture } from "src/components/Sidebar/ClientSelector";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

type Props = InjectedProps & {
  title: string;
  subtitle?: string;
};

function _TweetModal({ handleHide, subtitle, title, show: isVisible }: Props) {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const theme = useTheme();
  const [claimFreeChains] = useMutation(api.clients.claimFreeChains);

  // Add tweet options and state
  const tweetOptions = [
    `I'm using @AwakenTax for my crypto taxes 📢\n\nThey are by far the best crypto tax platform for crypto natives, and Solana + a bunch of other chains are free.`,
  ];

  const [currentTweetIndex, setCurrentTweetIndex] = useState(0);

  const [tweetText, setTweetText] = useState(tweetOptions[currentTweetIndex]);

  const refreshTweet = () => {
    const newIndex = (currentTweetIndex + 1) % tweetOptions.length;
    setCurrentTweetIndex(newIndex);
    setTweetText(tweetOptions[newIndex]);
  };

  const onSubmit = async () => {
    const refLink = `https://awaken.tax?ref=${client?.referralCode}`;
    const text = tweetText;

    window.open(`https://x.com/intent/tweet?text=${encodeURIComponent(text)}`);

    const variables: MutationClaimFreeChainsArgs = {
      clientId: clientId || "",
    };

    try {
      await claimFreeChains({
        variables,
      });
    } catch (err) {
      console.log(err);
    }

    void apolloClient.refetchQueries({
      include: [
        api.clients.retrieve,
        api.clients.accounts,
        api.subscriptions.active,
      ],
    });

    handleHide();

    dispatch(
      show("PleaseShareModal", {
        title:
          "The more you help us grow, the better we can make Awaken (and the more discounts we can give you too)!",
      })
    );
  };

  const onHide = () => {
    handleHide();
  };

  return (
    <Modal
      title="Tweet to unlock free transactions"
      titleHeaderProps={{
        fontSize: 24,
        color: theme.header,
      }}
      marginTop="1.5rem"
      isVisible={isVisible}
      handleHide={onHide}
      Footer={
        <VStack style={{ width: "100%" }}>
          <Button
            width="100%"
            bg={colors.green50}
            color={colors.white}
            marginBottom="0.5rem"
            marginTop="1.5rem"
            _hover={{ bg: colors.green40 }}
            onClick={onSubmit}
            style={{
              padding: "1.5rem 1rem",
            }}
          >
            Share to X{" "}
            <i
              style={{
                marginLeft: 15,
                position: "absolute",
                right: 20,
                top: 15,
                fontSize: 20,
              }}
              className="fa-sharp fa-arrow-right"
            />
          </Button>
        </VStack>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text style={{ color: theme.text }}>
          Instead of spending a ton of money on marketing this year, we are
          giving that back to you!{" "}
          <span
            style={{
              color: colors.positive,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            SOL, BTC, HYPE, & SUI
          </span>{" "}
          wallets are FREE.
        </Text>

        {/* <HStack justify="space-between" marginBottom="4">
          <Text color={theme.text} fontSize="md" flex={1} />
          <Touchable
            label="Try another"
            iconName="fa-sharp fa-rotate"
            onClick={refreshTweet}
          />
        </HStack> */}

        <Container
          border="1px solid"
          borderColor={theme.border}
          borderRadius="16px"
          padding="4"
          background={theme.background}
          maxW="100%"
        >
          <VStack align="start" spacing={3}>
            <HStack spacing={3}>
              <ProfilePicture client={client} />
              <VStack align="start" spacing={0}>
                <Text fontWeight="bold" color={theme.text}>
                  You
                </Text>
                <Text color={theme.text} fontSize="sm">
                  @you
                </Text>
              </VStack>
            </HStack>

            <Textarea
              value={tweetText}
              onChange={(e) => setTweetText(e.target.value)}
              color={theme.text}
              border="none"
              padding="5px 0 !important"
              _focus={{ border: "none", boxShadow: "none" }}
              resize="none"
              minH="100px"
            />
          </VStack>
        </Container>

        <Text color={theme.text} fontSize="md" marginTop="4">
          You can get bonus credit if:
        </Text>

        <VStack align="start" spacing={2} marginTop={2}>
          <HStack spacing={3}>
            <i style={{ color: colors.pink50 }} className="fa-solid fa-heart" />
            <Text color={theme.text}>Your tweet gets 20+ likes</Text>
          </HStack>
          <HStack spacing={3}>
            <i
              style={{ color: colors.positive }}
              className="fa-solid fa-check"
            />
            <Text color={theme.text}>You tweet about us in your own words</Text>
          </HStack>
        </VStack>

        {/* <Textarea 
          value={notes}
          noOfLines={5}
          onChange={(e) => setNotes(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        /> */}
      </Container>
    </Modal>
  );
}

export const TweetModal = connectModal({
  name: "TweetModal",
})(_TweetModal);
