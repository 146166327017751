import { Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { ActiveTransactionContext } from "src/context";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

export function EchoMessage() {
  const { blueBg, header } = useTheme();
  const dispatch = useDispatch();
  const { transaction } = useContext(ActiveTransactionContext);

  if (
    !transaction ||
    !transaction.autoReviewReason ||
    !transaction?.autoReviewReason?.toLowerCase()?.includes("echo") ||
    transaction.provider !== "base"
  ) {
    return null;
  }

  return (
    <a
      href="https://help.awaken.tax/en/articles/11004519-echo-investments"
      target="_blank"
      rel="noreferrer"
    >
      <Text
        textAlign="center"
        marginBottom="1rem"
        fontSize="sm"
        padding="0.5rem"
        bg={blueBg}
        border={`1px solid ${colors.primary}`}
        borderRadius={10}
        marginTop="0.5rem"
        cursor="pointer"
        color={header}
      >
        <i
          className="fa-sharp fa-piggy-bank"
          style={{ color: colors.primary }}
        />{" "}
        Looks like this was an <b>Echo</b> investment. Read more here to see how
        it works <i className="fa-sharp fa-arrow-right" />
      </Text>
    </a>
  );
}
