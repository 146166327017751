import { useQuery } from "@apollo/client";
import { Box, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { PartnershipTypeEnum, Query } from "src/api/generated/types";
import PenguIcon from "src/assets/accrue/images/pudgy-2.gif"; // Adjust path if needed
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { AwakenTooltip } from "../styled";

const SIZE = 40;

export const Partnerships = ({ canDownload }: { canDownload: boolean }) => {
  const { clientId } = useParams();
  const theme = useTheme();

  const { data, loading } = useQuery<Pick<Query, "getPartnershipDiscounts">>(
    api.partnershipDiscounts.list,
    {
      variables: { clientId },
      skip: !clientId,
    }
  );

  if (!data || !data?.getPartnershipDiscounts?.length) {
    return null;
  }

  const hasPartnershipsToShow = data?.getPartnershipDiscounts?.some(
    (partnership) => partnership.hasClaimed
  );

  if (canDownload && !hasPartnershipsToShow) {
    return null;
  }

  return (
    <>
      <Text
        style={{
          padding: "0 5px",
          fontWeight: "bold",
          fontStretch: "extra-expanded",
          textTransform: "uppercase",
        }}
        fontSize="12px"
        color={theme.text}
      >
        Eligible Partnerships{" "}
        <i className="fa-duotone fa-handshake" style={{ color: theme.text }} />
      </Text>

      <PudgyDiscount
        canDownload={canDownload}
        data={data ?? null}
        loading={loading}
      />
      <PhantomDiscount
        canDownload={canDownload}
        data={data ?? null}
        loading={loading}
      />
    </>
  );
};

const PudgyDiscount = ({
  data,
  loading,
  canDownload,
}: {
  loading: boolean;
  data: Pick<Query, "getPartnershipDiscounts"> | null;
  canDownload: boolean;
}) => {
  const dispatch = useDispatch();

  // Check if Pudgy Penguins partnership exists in the data
  const pudgyPartnership = (data?.getPartnershipDiscounts || [])?.find(
    (partnership) => partnership.type === PartnershipTypeEnum.PudgyPenguins
  );

  // If loading or no Pudgy partnership found, don't render anything
  if (loading || !pudgyPartnership) {
    return null;
  }

  if (!pudgyPartnership?.isEligible && !pudgyPartnership.hasClaimed) {
    return null;
  }

  // if they have a valid subscription but no discount. don't show it. bc we don't want them to know they could have claimed it
  if (canDownload && !pudgyPartnership.hasClaimed) {
    return null;
  }

  return (
    <Box
      onClick={() => {
        if (!pudgyPartnership) {
          return;
        }

        dispatch(
          show("PartnershipDiscountModal", {
            partnership: {
              type: PartnershipTypeEnum.PudgyPenguins,
              name: pudgyPartnership.name || "Pudgy Penguins",
              iconImageUrl: require("src/assets/accrue/images/pengu.png"),
              backgroundImageUrl: require("src/assets/accrue/images/pudgy.jpeg"),
              description: pudgyPartnership.description,
              isEligible: pudgyPartnership.isEligible,
              hasClaimed: pudgyPartnership.hasClaimed,
            },
          })
        );
      }}
      style={{
        marginTop: 10,
        textDecoration: "none",
        display: "block",
        padding: "0 5px",
        cursor: "pointer",
      }}
    >
      <Box
        style={{
          borderRadius: "8px",
          width: "100%",
          padding: 5,
          display: "flex",
          alignItems: "center",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          transition: "all 0.2s ease-in-out",
          position: "relative",
          overflow: "hidden",
        }}
        _hover={{
          transform: "scale(1.02)",
        }}
      >
        {/* Background image with overlay */}
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${require("src/assets/accrue/images/pudgy.jpeg")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 0,
          }}
        />
        {/* Black overlay with 0.4 opacity */}
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1,
          }}
        />
        {/* Content */}
        <img
          src={PenguIcon}
          alt="Pudgy Penguin"
          style={{
            width: SIZE,
            height: SIZE,
            borderRadius: 10,
            objectFit: "cover",
            zIndex: 2,
          }}
        />
        <Text
          flex={1}
          color="white"
          fontWeight="700"
          fontSize="16px"
          style={{
            zIndex: 2,
            fontStretch: "expanded",
            marginLeft: 5,
            position: "relative",
          }}
        >
          Pudgy{" "}
          {pudgyPartnership?.hasClaimed && (
            <AwakenTooltip message="Claimed discount.">
              <i
                className="fa-sharp fa-check-circle"
                style={{ marginLeft: 2, color: colors.white, zIndex: 2 }}
              />
            </AwakenTooltip>
          )}
        </Text>
      </Box>
    </Box>
  );
};

export const PhantomDiscount = ({
  data,
  loading,
  canDownload,
}: {
  loading: boolean;
  data: Pick<Query, "getPartnershipDiscounts"> | null;
  canDownload: boolean;
}) => {
  const dispatch = useDispatch();

  const discounts = data?.getPartnershipDiscounts || [];
  const phantomDiscount = discounts.find(
    (discount) => discount.type === PartnershipTypeEnum.Phantom
  );

  if (loading || !phantomDiscount) {
    return null;
  }

  // if they have a valid subscription but no discount. don't show it. bc we don't want them to know they could have claimed it
  if (canDownload && !phantomDiscount.hasClaimed) {
    return null;
  }

  return (
    <Box
      onClick={() => {
        if (!phantomDiscount) {
          return;
        }

        dispatch(
          show("PartnershipDiscountModal", {
            title: "Awaken x Phantom",
            partnership: {
              type: PartnershipTypeEnum.Phantom,
              name: "Phantom",
              iconImageUrl: "https://assets.awaken.tax/icons/phantom.png",
              backgroundImageUrl: require("src/assets/accrue/images/phantom-bg-2.jpeg"),
              description: "Phantom Discount",
              body: "2024 was the year of Solana, and we are excited to be an official partner of Phantom!",
              isEligible: phantomDiscount.isEligible,
              hasClaimed: phantomDiscount.hasClaimed,
            },
          })
        );
      }}
      style={{
        marginTop: 10,
        textDecoration: "none",
        display: "block",
        padding: "0 5px",
        cursor: "pointer",
      }}
    >
      <Box
        style={{
          borderRadius: "8px",
          width: "100%",
          padding: 5,
          display: "flex",
          alignItems: "center",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          transition: "all 0.2s ease-in-out",
          position: "relative",
          overflow: "hidden",
          border: "2px solid #A699EA",
          background: "#A699EA",
        }}
        _hover={{
          transform: "scale(1.02)",
        }}
      >
        {/* Background image with overlay */}
        {/* <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${require("src/assets/accrue/images/phantom-bg.jpg")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 0,
          }}
        /> */}
        {/* Black overlay with 0.4 opacity */}
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#A699EA", // "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
          }}
        />
        {/* Content */}
        <img
          src={require("src/assets/accrue/images/2024.gif")}
          alt="Phantom"
          style={{
            width: SIZE,
            height: SIZE,
            borderRadius: 10,
            objectFit: "cover",
            zIndex: 2,
          }}
        />
        <Text
          flex={1}
          color="white"
          fontWeight="700"
          fontSize="16px"
          style={{
            zIndex: 2,
            fontStretch: "expanded",
            marginLeft: 5,
            position: "relative",
          }}
        >
          Phantom{" "}
          {phantomDiscount?.hasClaimed && (
            <AwakenTooltip message="Claimed discount.">
              <i
                className="fa-sharp fa-check-circle"
                style={{ marginLeft: 2, color: colors.white, zIndex: 2 }}
              />
            </AwakenTooltip>
          )}
        </Text>
      </Box>
    </Box>
  );
};

export default PudgyDiscount;
