/* eslint-disable no-constant-condition */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Container,
  FormLabel,
  HStack,
  Image,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import BigNumber from "bignumber.js";
import { Currency } from "dinero.js";
import { GraphQLError } from "graphql";
import { isEmpty, isNil, sum, truncate } from "lodash";
import { compose } from "lodash/fp";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  FormProvider,
  SubmitErrorHandler,
  useController,
  useForm,
  useFormContext,
  UseFormHandleSubmit,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { connectModal, InjectedProps, show } from "redux-modal";
import { api, apolloClient, BaseTransactionFullFields } from "src/api";
import {
  BaseAccountFields,
  BaseFullTransactionFields,
  BaseTransferFields,
} from "src/api/fragments";
import {
  CheckRuleUsedResponse,
  CountryEnum,
  DefaultRule,
  GetOtherUserLabelsResp,
  IsDirtyEnum,
  LedgerAccountSubTypeEnum,
  LedgerAccountTypeEnum,
  LedgerTransactionReviewStatusEnum,
  LedgerTransactionStatusEnum,
  Mutation,
  MutationHideTransactionArgs,
  MutationRuleToDefaultRuleArgs,
  MutationSendSlackNotificationArgs,
  MutationUpdateTransactionArgs,
  Query,
  QueryCheckRuleUsedArgs,
  QueryGetOtherUserLabelsArgs,
  QueryGetTransactionPageArgs,
  RuleTypeEnum,
  TransactionPageResponse,
  TransactionTypeOption,
} from "src/api/generated/types";
import { LabelSelect } from "src/components/Labels/LabelSelect";
import { Modal } from "src/components/Modal";
import {
  ActionSheet,
  AwakenTooltip,
  Button,
  Copy,
  Info,
  RecalculateButton,
  Textarea,
} from "src/components/styled";
import { Input } from "src/components/styled/Form/Input";
import SecondaryText from "src/components/styled/SecondaryText";
import StatusTag, { StatusTagType } from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { MIN_HIGH_CAP_GAINS, MIN_HIGH_INCOME } from "src/config";
import { ActiveTransactionContext } from "src/context";
import { hasValue, Maybe } from "src/core";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { GetTxn, useTransactionById } from "src/hooks/useTransactionById";
import {
  formatAutoReviewReason,
  formatRuleUsed,
  getLink,
  isLabeled,
} from "src/modules/ledger/transactions";
import { overridePrepopulatedTxn } from "src/redux/reducers/active";
import { getIsGlobalRuleMode } from "src/redux/reducers/globalState";
import { colors } from "src/theme";
import { D, timeAgo } from "src/utils/helpers";
import { INTEGRATIONS, PROVIDER_TO_LOGO_URL } from "../AccountModal/constants";
import { UnhideTransfers } from "./components";
import { BalancesPopover } from "./components/BalancesPopover/BalancesPopover";
import { EchoMessage } from "./components/EchoMessage";
import { EntriesPopover } from "./components/EntriesPopover";
import { LawyerLetter } from "./components/LawyerLetter";
import { TransactionWarning } from "./components/TransactionWarning";
import { Fees } from "./Fees";
import { FormValues, getDefaultValues, schema } from "./form";
import { TransferOverview } from "./Transfers";
import { Transfers } from "./Transfers/TransferForm";
import { useTransfers } from "./Transfers/TransferForm/utils";
import { useCreatedAtUTC } from "./utils";

type Props = InjectedProps & {
  transactionId: string;
  showAssetTransfersBuilder: boolean;
  onSuccess: (acct?: BaseAccountFields) => void;
};

export const GET_TXN_STATUS: Record<
  LedgerTransactionStatusEnum,
  StatusTagType
> = {
  [LedgerTransactionStatusEnum.Canceled]: "error",
  [LedgerTransactionStatusEnum.Failed]: "error",
  [LedgerTransactionStatusEnum.Pending]: "info",
  [LedgerTransactionStatusEnum.Completed]: "success",
  [LedgerTransactionStatusEnum.Unknown]: "none",
};

function _TxnDetailModal({
  transactionId,
  handleHide,
  show,
  showAssetTransfersBuilder = false,
}: Props) {
  const { clientId } = useParams();
  // const startingTxn = useSelector(getModalTransaction);
  const { client } = useClientById(clientId || "", {
    onlyFetchClient: true,
    clientFetchPolicy: "cache-only",
  });

  const [usedAi, setUsedAi] = useState(false);

  // just used for the cache reset on close
  const [fetchFullTransaction] = useLazyQuery<
    GetTxn["resolver"],
    GetTxn["variables"]
  >(api.transactions.retrieve);

  const {
    transaction: _transaction,
    isUsingFreshTransaction,
    defaultLabels,
    ledgerEntries,
    isLoadingTransaction,
    loadedType,
  } = useTransactionById(
    transactionId || null,
    // fetch policy that ALWAYS calls network but does use cache field
    "network-only"
  );

  const transaction = _transaction as BaseTransactionFullFields;
  const transfers = (transaction?.transfers || []) as BaseTransferFields[];
  const transfersBreakdown = useTransfers(transfers);

  // console.log(_transaction, loadedType);
  // console.log(transfersBreakdown.none.transfers.length);

  // console.log(transfers, transfersBreakdown);

  const [updateTransaction, { loading: isLoadingUpdateTransaction }] =
    useMutation(api.transactions.update);

  const [showDropdown, setShowDropdown] = useState<boolean>(true);
  const entries = ledgerEntries || [];
  const dispatch = useDispatch();

  const defaultValues = useMemo(
    () => getDefaultValues(transaction),
    [transaction]
  );

  const formProps = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  // only reset if in the fresh txn is loaded. so only on the initial load
  useEffect(() => {
    if (!isUsingFreshTransaction) {
      return;
    }

    // maintain the notes in between the reset
    formProps.reset({
      ...defaultValues,
      notes: transaction?.notes,
      labelUsed: transaction?.labelUsed,
    });
  }, [isUsingFreshTransaction]);

  const _resetTxnInCache = useCallback(async () => {
    try {
      const txn = await fetchFullTransaction({
        variables: { transactionId },
        fetchPolicy: "network-only",
      });

      const data = txn.data?.getTransaction;

      if (!data) {
        return;
      }

      dispatch(
        overridePrepopulatedTxn({
          txnId: data.id,
          txn: data,
        })
      );
    } catch (err) {
      // nothing
    }
  }, [fetchFullTransaction, transactionId]);

  return (
    <ActiveTransactionContext.Provider
      value={{
        transaction,
        defaultLabels: defaultLabels || null,
        transfers: transfersBreakdown,
        client: client || null,
        showAssetTransfersBuilder,
        ledgerEntries: entries ?? [],
        showDropdown,
        setShowDropdown,
        setUsedAi,
        usedAi,
        resetTxnInCache: _resetTxnInCache,
      }}
    >
      <FormProvider {...formProps}>
        <TransactionModal
          isVisible={show}
          handleHide={handleHide}
          transactionId={transactionId}
          updateTransaction={updateTransaction}
          isLoadingUpdateTransaction={
            (isLoadingTransaction && !transaction) || isLoadingUpdateTransaction
          }
        />
      </FormProvider>
    </ActiveTransactionContext.Provider>
  );
}

// Note: we put the txn modal code in here so we can access form state
const TransactionModal = ({
  transactionId,
  handleHide,
  isVisible,
  updateTransaction,
  isLoadingUpdateTransaction,
}: {
  transactionId: string;
  handleHide: () => void;
  isVisible: boolean;
  updateTransaction: any;
  isLoadingUpdateTransaction: boolean;
}) => {
  const { transaction } = useContext(ActiveTransactionContext);
  const [search, setSearchParams] = useSearchParams();
  const searchTransactionId = search.get("transactionId");
  const { background, header, medBackground, secondaryBackground, border } =
    useTheme();

  const {
    formState: { isDirty, dirtyFields },
  } = useFormContext<FormValues>();
  const dispatch = useDispatch();

  const _onHide = () => {
    if (!isEmpty(dirtyFields) || isDirty) {
      const isConfirmed = confirm(
        `Please save your transaction before you close this modal. If you do not save, your changes will be lost.`
      );
      if (isConfirmed) {
        search.delete("transactionId");
        search.set("highlightedTransactionId", transaction?.id || "");
        setSearchParams(search);
      }
    } else {
      search.delete("transactionId");
      search.set("highlightedTransactionId", transaction?.id || "");
      setSearchParams(search);
    }
  };

  useEffect(() => {
    if (!searchTransactionId) {
      handleHide();
    }
  }, [searchTransactionId]);

  const isLoading = isLoadingUpdateTransaction || !transaction;
  const isLarge = useIsLargeScreen();

  return (
    <Modal
      minW={isLarge ? "50rem" : "inherit"}
      minH="40rem"
      title={isLoading ? <div /> : <Header handleHide={handleHide} />}
      isVisible={isVisible}
      handleHide={_onHide}
      w={isLarge ? "100%" : "95%"}
      maxW={isLarge ? "55rem" : "95%"}
      footerProps={{
        visibility: isLoading ? "hidden" : "visible",
        padding: isLarge ? "1rem 2rem" : "1rem",
        borderTop: "1px solid " + border,
        background: medBackground,
        boxShadow: `0px 5px 5px 5px ${border}`, // <- needs to be outset, not inset
      }}
      trapFocus={false}
      titleHeaderProps={{
        marginTop: 0,
      }}
      headerProps={{
        padding: "0",
        marginTop: "0",
        borderBottom: "1px solid " + border,
      }}
      bodyProps={{
        padding: 0,
        maxH: isLoading ? "none" : "55vh",
        height: "100%",
        overflowY: "scroll",
      }}
      Footer={
        isLoading ? (
          <div />
        ) : (
          <TransactionModalFooter
            isLoading={isLoading}
            handleHide={handleHide}
            updateTransaction={updateTransaction}
            isLoadingUpdateTransaction={isLoadingUpdateTransaction}
          />
        )
      }
    >
      {isLoading ? (
        <Box
          w="100%"
          h="55vh"
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <Spinner color={header} />
        </Box>
      ) : (
        <TransactionDetails />
      )}
    </Modal>
  );
};

type LabelOption = TransactionTypeOption & {
  isDisabled: boolean;
};

const SaveChangesButton = ({
  isLarge,
  isLoading,
  _updateTransaction,
  _onInvalid,
  handleSubmit,
  transaction,
}: {
  isLarge: boolean;
  isLoading: boolean;
  _onInvalid: SubmitErrorHandler<FormValues>;
  _updateTransaction: (values: FormValues) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  transaction: Maybe<BaseFullTransactionFields>;
}) => {
  const { setValue, watch } = useFormContext<FormValues>();
  const labelUsed = watch("labelUsed");

  // console.log(
  //   "Label used",
  //   labelUsed,
  //   "undefined: ",
  //   labelUsed === undefined,
  //   "null: ",
  //   labelUsed === null,
  //   "empty string: ",
  //   labelUsed === ""
  // );
  const needsRecalculate = transaction?.needsReview?.needsRecalculate === true;
  const hasLabelSet = isLabeled(transaction)
    ? !!transaction?.labelUsed && !labelUsed // reset label
      ? false
      : true
    : !!labelUsed;
  const isMissingLabel = !hasLabelSet;
  const hasEntriesError =
    !transaction ||
    (transaction.needsReview !== null &&
      transaction.needsReview?.errors.some((err) => err?.startsWith("Entry")));
  const isReviewed =
    transaction &&
    hasLabelSet &&
    !transaction.isMissingBasis &&
    !hasEntriesError;

  return (
    <Popover
      trigger={needsRecalculate ? undefined : "hover"}
      placement="bottom-end"
    >
      <PopoverTrigger>
        <Box>
          <Button
            display="block"
            size="sm"
            variant="primary"
            bgColor={isReviewed ? colors.primaryGreen : undefined}
            _hover={{
              bgColor: isReviewed ? colors.primaryGreen : undefined,
            }}
            fontSize="md"
            minWidth={"100px !important"}
            disabled={isLoading}
            onClick={handleSubmit(_updateTransaction, _onInvalid)}
          >
            Save
          </Button>
        </Box>
      </PopoverTrigger>
      <PopoverContent width="15rem">
        <PopoverArrow />
        <Box w="100%" margin="0.5rem 0">
          <SecondaryText
            text={isReviewed ? "REVIEWED" : "UNREVIEWED"}
            fontWeight="bold"
            // paddingLeft="0.75rem"
            w="100%"
            textAlign="center"
          />
          <Box w="100%" display="flex" padding="0.5rem 0.75rem">
            <Text>
              <i
                className={
                  isMissingLabel ? "far fa-circle" : "fa-sharp fa-check-circle"
                }
                style={{
                  color: isMissingLabel ? undefined : colors.primaryGreen,
                }}
              />
            </Text>
            <Text>&nbsp;&nbsp;&nbsp;Label</Text>
          </Box>
          <Box w="100%" display="flex" padding="0.5rem 0.75rem">
            <Text>
              <i
                className={
                  transaction?.isMissingBasis
                    ? "far fa-circle"
                    : "fa-sharp fa-check-circle"
                }
                style={{
                  color: transaction?.isMissingBasis
                    ? undefined
                    : colors.primaryGreen,
                }}
              />
            </Text>
            <Text>
              &nbsp;&nbsp;&nbsp;Cost basis{" "}
              <Info message="To fix this warning, click into each of the red edit pens and either edit the fiat amount or save the value Awaken has set." />
            </Text>
          </Box>
          <Box w="100%" display="flex" padding="0.5rem 0.75rem">
            <Text>
              <i
                className={
                  hasEntriesError ? "far fa-circle" : "fa-sharp fa-check-circle"
                }
                style={{
                  color: hasEntriesError ? undefined : colors.primaryGreen,
                }}
              />
            </Text>
            <Text>&nbsp;&nbsp;&nbsp;Previous transactions</Text>
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

const TransactionModalFooter = ({
  isLoading,
  handleHide,
  updateTransaction,
  isLoadingUpdateTransaction,
}: {
  isLoading: boolean;
  handleHide: () => void;
  updateTransaction: any;
  isLoadingUpdateTransaction: boolean;
}) => {
  const { clientId } = useParams();
  const {
    transaction,
    defaultLabels: _defaultLabels,
    showDropdown,
    setShowDropdown,
    resetTxnInCache,
  } = useContext(ActiveTransactionContext);
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
    watch,
    setValue,
  } = useFormContext<FormValues>();
  const labelUsed = watch("labelUsed");

  const toast = useMyToast();
  const usingRule =
    transaction && !transaction.labelUsed && transaction.ruleUsed;
  const onlyHasRuleOrAutoReview =
    !transaction?.labelUsed &&
    !labelUsed &&
    !!(transaction?.ruleUsed || transaction?.autoReviewReason);
  const onlyHasAutoReview =
    !transaction?.labelUsed &&
    !transaction?.ruleUsed &&
    transaction?.autoReviewReason;
  const canEdit = onlyHasRuleOrAutoReview;

  const isGlobalRuleMode = useSelector(getIsGlobalRuleMode);
  const [search, setSearchParams] = useSearchParams();

  useEffect(() => {
    setShowDropdown(!onlyHasRuleOrAutoReview);
  }, [onlyHasRuleOrAutoReview]);

  // checkRuleUsed - don't use the `data` from this, since it may not be updated
  const [checkRuleUsed, { data: checkRuleUsedData }] = useLazyQuery<
    {
      checkRuleUsed?: CheckRuleUsedResponse;
    },
    QueryCheckRuleUsedArgs
  >(api.rules.checkRuleUsed);

  const [sendSlackNotification] = useMutation<
    {
      sendSlackNotification?: string;
    },
    MutationSendSlackNotificationArgs
  >(api.misc.sendSlackNotification);

  const [ruleToDefaultRule] = useMutation<
    {
      ruleToDefaultRule?: DefaultRule;
    },
    MutationRuleToDefaultRuleArgs
  >(api.defaultRules.ruleToDefaultRule);

  const { me } = useMe();
  const isSuperUser = me?.isSuperuser || false;

  // modal
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  useEffect(() => {
    if (transaction?.ruleUsed) {
      checkRuleUsed({
        variables: {
          ruleId: transaction.ruleUsed.id,
        },
      });
    }
  }, [transaction?.ruleUsed]);

  // Actually calls updateTransaction mutation
  const _updateTransactionHelper = async (
    values: FormValues,
    transactionId: string,
    overrideLabel: boolean
  ) => {
    const variables = {
      updates: {
        notes: values.notes,
        title: values.title,
        label: values.labelUsed,
        overrideLabel,
        globalRuleName: isGlobalRuleMode ? values.globalRuleName : "",
      },
      transactionId,
      createDefaultRule: isGlobalRuleMode,
    };

    // Optimistic UI update: hide modal immediately
    search.delete("transactionId");
    setSearchParams(search);
    handleHide();

    try {
      const result = await updateTransaction({
        variables,
      });

      void apolloClient.refetchQueries({
        include: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
          api.graph.getReplayInfo,
        ],
      });

      void resetTxnInCache();

      const message = result.data.updateTransaction.message;
      const messageStatus = result.data.updateTransaction.messageStatus;
      if (message && clientId) {
        const constraintsLink = getLink(
          clientId,
          {
            search: result.data.updateTransaction.transaction?.constraints,
          },
          true
        );
        console.log("CONSTRAINTS LINK: " + constraintsLink);
        setTimeout(() => {
          toast.show({
            message,
            status: messageStatus,
            onClick: message.includes("other transaction")
              ? () => window.open(constraintsLink, "_blank")
              : undefined,
          });
        }, 1250);
      } else {
        toast.show({
          message: "Successfully updated transaction",
          status: "success",
        });
      }
    } catch (err) {
      toast.show({
        message: (err as GraphQLError).message,
        status: "error",
      });
    }
  };

  // updateTransaction
  const _updateTransaction = async (values: FormValues) => {
    // if (isEmpty(dirtyFields) || !isDirty) {
    //   // just close
    //   search.delete("transactionId");
    //   setSearchParams(search);
    //   handleHide();
    //   return;
    // }

    if (isLoadingUpdateTransaction) return;
    if (!transaction || !clientId) return;
    try {
      // if there is no rule or if label is not changing,
      // just call updateTransaction mutation
      if (
        !transaction.ruleUsed ||
        values.labelUsed === transaction?.labelUsed
      ) {
        await _updateTransactionHelper(values, transaction.id, false);

        setTimeout(() => {
          search.delete("transactionId");
          setSearchParams(search);
          handleHide();
        }, 0);

        return;
      }

      // error: couldn't pull data for this rule
      if (checkRuleUsedData?.checkRuleUsed === undefined) return;

      // error: checkRuleUsed is for wrong rule
      if (checkRuleUsedData?.checkRuleUsed.id !== transaction.ruleUsed.id)
        return;

      // error: couldn't calculate checkRule
      const numTxnsRuleUsed = checkRuleUsedData.checkRuleUsed.numTxnsRuleUsed;
      if (numTxnsRuleUsed === undefined) return;

      // if the rule applies to just this transaction, call updateTransaction
      if (numTxnsRuleUsed === 0 || numTxnsRuleUsed === 1) {
        await _updateTransactionHelper(values, transaction.id, false);
      }
      // if the transaction applies to 2+ transactions AND they're changing the label, ask if they want to remove it
      else if (labelUsed) {
        // three options: one-off label, delete rule, cancel
        _showModal("OverrideLabelModal", {
          rule: transaction.ruleUsed,
          numTxnsRuleUsed,
          updateTransactionOverrideLabel: () =>
            _updateTransactionHelper(values, transaction.id, true),
          updateTransaction: () =>
            _updateTransactionHelper(values, transaction.id, false),
          isLoadingUpdateTransaction,
        });
      } else {
        // don't update the label used because they aint changing it
        await _updateTransactionHelper(values, transaction.id, false);
      }
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Sorry, an error occurred.",
        status: "error",
      });
    }
  };

  const _onInvalid: SubmitErrorHandler<FormValues> = (values, e) => {
    console.log(values);
  };

  const { statusTagText, infoMessage } = useMemo(() => {
    // label used
    if (transaction?.labelUsed)
      return {
        statusTagText: "",
        infoMessage: "",
      };
    // rule used
    if (transaction?.ruleUsed) {
      const ruleType = transaction.ruleUsed?.type || "";
      return {
        statusTagText: formatRuleUsed(ruleType),
        infoMessage:
          "Awaken created a pattern to automatically handle this type of transaction.",
      };
    }
    // auto reviewed
    if (transaction?.autoReviewReason) {
      return {
        statusTagText: formatAutoReviewReason(transaction.autoReviewReason),
        infoMessage: "Awaken automatically reviewed this transaction.",
      };
    }
    return {
      statusTagText: "",
      infoMessage: "",
    };
  }, [transaction]);

  const onClickIncorrect = () => {
    if (!transaction) return;
    sendSlackNotification({
      variables: {
        message: `Transaction ${transaction.txnHash} (${transaction.id}) marked as incorrect for auto-review reason ${transaction.autoReviewReason}`,
      },
    });
    toast.show({
      message: "Thanks for your feedback! We will look into this problem.",
      status: "success",
    });
  };

  // LABELS
  const { header, text, background } = useTheme();

  const { data, loading } = useQuery<Pick<Query, "getTransactionTypeOptions">>(
    api.transactions.typeOptions,
    { variables: { transactionId: transaction?.id }, skip: !transaction }
  );

  // console.log(data, _defaultLabels);

  const labelOptions: LabelOption[] = (
    data?.getTransactionTypeOptions?.labels ||
    _defaultLabels?.labels ||
    []
  )
    .filter(hasValue)
    // we don't want to create rules for internal transfer for all clients (i.e. global rule mode)
    .filter(
      (option) =>
        !isGlobalRuleMode || option.ruleType !== RuleTypeEnum.V1InternalTransfer
    )
    .map((option) => ({ ...option, isDisabled: !option.applicable }));

  const onSelectOption = (o: Maybe<TransactionTypeOption>) => {
    // if (o?.value === "v1:internal_transfer") {
    //   toast.show({
    //     message:
    //       "Only use the Wallet Transfer label if you cannot import your other wallet / exchange into Awaken",
    //     status: "info",
    //   });
    // }
    setValue("labelUsed", o?.value || null, { shouldDirty: true });
  };

  const { control } = useFormContext<FormValues>();

  const selectedOption = useMemo(() => {
    // Note: we have to do both and return label first. it is possible something is labeled AND a rule is applied.
    // we prioritize the label over the rule
    return labelOptions.find((o) => o.value === labelUsed);
  }, [labelOptions, labelUsed]);

  const hasExplorer =
    transaction?.blockExplorerName && transaction?.blockExplorerUrl
      ? true
      : false;
  const showExplorer =
    hasExplorer && (onlyHasRuleOrAutoReview ? canEdit && !showDropdown : true);
  // force recalculate if the txn is dirty and should recalc or the txn is marked as importing just in case they get in a case
  // where imports are done but dirty. shouldn't happen but just covering case so they don't have a crappy UX and don't know what the next step is
  const forceRecalculate =
    transaction?.isDirty === IsDirtyEnum.Recalculate ||
    transaction?.isImporting;

  const isLarge = useIsLargeScreen();

  const theme = useTheme();

  const createDefaultRuleFromRule = async () => {
    if (!transaction || !transaction.ruleUsed) return;
    if (transaction.ruleUsed.createdByAwaken) return;

    const label = window.prompt(
      "What's the label for this",
      transaction.ruleUsed.label
    );

    if (label === null) return; // cancel

    try {
      await ruleToDefaultRule({
        variables: {
          transactionId: transaction.id,
          label,
        },
      });

      toast.show({
        message: "Successfully created default rule",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error syncing transaction!",
      });
    }
  };

  return (
    <Box w="100%">
      <HStack
        width="100%"
        alignItems="center"
        justifyContent="flex-start"
        margin={"0.5rem 0"}
      >
        <Box
          flex={1}
          style={{
            display: "flex",
            flexDirection: isLarge ? "row" : "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {forceRecalculate && (
            <RecalculateButton message="You must press 'Recalculate' before modifying this transaction" />
          )}
          {!forceRecalculate && showDropdown && (
            <Box
              w="100%"
              display="flex"
              margin={isLarge ? "0" : "1rem"}
              alignItems="center"
            >
              <LabelSelect
                labelOptions={labelOptions}
                selectedOption={selectedOption}
                onSelectOption={onSelectOption}
                loading={loading}
              />

              {canEdit && (
                <Touchable
                  marginLeft="1rem"
                  iconName={showDropdown ? undefined : "fa-sharp fa-pen"}
                  iconPosition="right"
                  label={showDropdown ? "Cancel" : "Edit"}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              )}
            </Box>
          )}

          {!showDropdown && !forceRecalculate && onlyHasRuleOrAutoReview && (
            <Box
              display="flex"
              flexDir="column"
              justifyContent={"space-between"}
              alignItems="flex-start"
              w="100%"
            >
              <Box
                marginLeft={showDropdown ? "1rem !important" : "0"}
                display="flex"
                justifyContent={"space-between"}
                alignItems="flex-start"
              >
                <StatusTag
                  label={statusTagText}
                  type="success"
                  boxProps={{
                    border: `1px solid ${colors.positive}`,
                    marginRight: "0.5rem",
                  }}
                  infoIcon
                  hasBorder
                  infoMessage={infoMessage}
                />

                {canEdit && (
                  <Touchable
                    iconName={showDropdown ? undefined : "fa-sharp fa-pen"}
                    iconPosition="right"
                    label={showDropdown ? "Cancel" : "Edit"}
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                )}
              </Box>
              {/* {onlyHasAutoReview && !showDropdown && isLarge && (
                <Box
                  color={theme.header}
                  w="100%"
                  textAlign="left"
                  marginTop="0.2rem"
                >
                  Looks wrong? Click{" "}
                  <span
                    onClick={onClickIncorrect}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <strong>here</strong>
                  </span>{" "}
                  to tell us.
                </Box>
              )} */}
              {usingRule &&
                !transaction.ruleUsed.createdByAwaken &&
                isSuperUser && (
                  <Box w="100%" textAlign="left" marginTop="0.2rem">
                    <Text
                      fontSize="sm"
                      cursor="pointer"
                      fontWeight="bold"
                      onClick={createDefaultRuleFromRule}
                    >
                      Create default rule out of rule
                    </Text>
                  </Box>
                )}
            </Box>
          )}
        </Box>
        {isLarge && (
          <SaveChangesButton
            isLarge={isLarge}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            _onInvalid={_onInvalid}
            _updateTransaction={_updateTransaction}
            transaction={transaction}
          />
        )}
      </HStack>
      {showDropdown && (
        <TransactionFunctionHint
          formattedFunctionName={transaction?.formattedFunctionName}
        />
      )}
      {!forceRecalculate && (
        <Recommendation
          labelOptions={labelOptions}
          selectedOption={selectedOption}
          onSelectOption={onSelectOption}
          _updateTransaction={_updateTransaction}
          _onInvalid={_onInvalid}
        />
      )}

      {/* {isLarge &&
        !forceRecalculate &&
        showDropdown &&
        !!transaction?.blockExplorerUrl && (
          <Box margin="0" marginTop="0.5rem">
            <Text color={header} fontSize="sm">
              Don't remember what this transaction is?{" "}
              <a
                style={{ color: colors.primary, fontWeight: "bold" }}
                target="_blank"
                href={transaction?.blockExplorerUrl || ""}
              >
                Check it out in {transaction?.blockExplorerName}{" "}
                <i
                  style={{ marginLeft: "0.25rem" }}
                  className="fa-sharp fa-external-link-alt"
                />
              </a>
            </Text>
          </Box>
        )} */}
      {isGlobalRuleMode && (
        <Input
          name="globalRuleName"
          control={control}
          label="Protocol name (Global rule name)"
          placeholder="LooksRare (NOT LooksRare Staking)"
        />
      )}

      {!isLarge && (
        <SaveChangesButton
          isLarge={isLarge}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          _onInvalid={_onInvalid}
          _updateTransaction={_updateTransaction}
          transaction={transaction}
        />
      )}
    </Box>
  );
};

const Recommendation = ({
  labelOptions,
  selectedOption,
  onSelectOption,
  _updateTransaction,
  _onInvalid,
}: {
  labelOptions: LabelOption[];
  selectedOption?: LabelOption;
  onSelectOption: (o: Maybe<TransactionTypeOption>) => void;
  _updateTransaction: (values: FormValues) => void;
  _onInvalid: SubmitErrorHandler<FormValues>;
}) => {
  const { handleSubmit } = useFormContext<FormValues>();
  const { transaction } = useContext(ActiveTransactionContext);
  const [getOtherUserLabels, { data: otherUserLabels }] = useLazyQuery<
    {
      getOtherUserLabels?: GetOtherUserLabelsResp;
    },
    QueryGetOtherUserLabelsArgs
  >(api.rules.getOtherUserLabels);
  const isSolana = transaction?.provider === "solana";

  // modal
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  useEffect(() => {
    if (
      // only make API request if the transaction is not reviewed
      transaction &&
      transaction?.reviewStatus !== LedgerTransactionReviewStatusEnum.Reviewed
    )
      getOtherUserLabels({
        variables: {
          transactionId: transaction.id,
        },
      });
  }, [transaction]);

  const probablyWalletTransfer = useMemo(() => {
    if (!transaction) return false;
    return transaction.probablyWalletTransfer;
  }, [transaction]);

  const isLikelySpam = useMemo(() => {
    if (!transaction) return false;
    if (transaction.transfers.length !== 1) return false; // one transfer
    if (!transaction.transfers[0].toAccountId) return false; // to you
    if (transaction.transfers[0].fiatAmountCents !== 0) return false; // no price data
    const symbol: string =
      transaction.transfers[0]?.fullAsset?.symbol?.toLowerCase() || "";
    const matches =
      symbol.match(/[a-z0-9]+\.com/i) || // cant combine these for some reason without breaking the algo
      symbol.match(/[a-z0-9]+\.fi/i) ||
      symbol.match(/[a-z0-9]+\.net/i) ||
      symbol.match(/[a-z0-9]+\.org/i) ||
      symbol.match(/[a-z0-9]+\.xyz/i) ||
      [];
    return matches.length > 0;
  }, [transaction]);

  // too computationally intensive, probably slowing down the app
  const recommendation = useMemo(() => {
    return null;
    // if (selectedOption) return null;
    // if (!otherUserLabels?.getOtherUserLabels) return null;
    // if (
    //   !otherUserLabels.getOtherUserLabels.ruleType ||
    //   !otherUserLabels.getOtherUserLabels.shouldUse
    // )
    //   return null;
    // const ruleType = otherUserLabels.getOtherUserLabels.ruleType;
    // const option = labelOptions.find((o) => o.ruleType === ruleType);
    // if (!option || !option.applicable) return null;
    // return {
    //   option,
    //   numUsers: otherUserLabels.getOtherUserLabels.numUsers,
    // };
  }, [otherUserLabels, labelOptions, selectedOption, transaction]);

  const theme = useTheme();

  const onClickRecommendation = useCallback(
    (option?: Maybe<TransactionTypeOption>) => {
      if (option) {
        onSelectOption(option);
        handleSubmit(_updateTransaction, _onInvalid)();
      }
    },
    [
      recommendation,
      handleSubmit,
      onSelectOption,
      _updateTransaction,
      _onInvalid,
    ]
  );

  if (transaction?.reviewStatus === LedgerTransactionReviewStatusEnum.Reviewed)
    return null;

  if (probablyWalletTransfer) {
    // wallet transfer
    return null;
    // return (
    //   <Text color={theme.text} fontSize="sm" marginTop="0.5rem">
    //     Sent to your other wallet?{" "}
    //     <Text
    //       cursor="pointer"
    //       userSelect={"none"}
    //       display="inline"
    //       fontSize="sm"
    //       fontWeight="bold"
    //       color={colors.primary}
    //       onClick={() =>
    //         _showModal("AccountModal", {
    //           location: "wallet_transfer_hint",
    //         })
    //       }
    //     >
    //       Connect your wallet
    //     </Text>
    //   </Text>
    // );
  }

  if (isLikelySpam) {
    // spam
    return (
      <Text fontSize="sm" marginTop="0.5rem">
        {/* {recommendation.numUsers} other users labeled this transaction as{" "} */}
        This transaction looks like spam.{" "}
        <Text
          cursor="pointer"
          userSelect={"none"}
          display="inline"
          fontSize="sm"
          fontWeight="bold"
          color={colors.primary}
          onClick={() =>
            onClickRecommendation(
              labelOptions.find((o) => o.value === "v1:spam")
            )
          }
        >
          Mark as 🗑 Spam
        </Text>
      </Text>
    );
  }

  // if (recommendation) {
  //   // recommendation
  //   return (
  //     <Text fontSize="sm" marginTop="0.5rem">
  //       {/* {recommendation.numUsers} other users labeled this transaction as{" "} */}
  //       Other users labeled this transaction as{" "}
  //       <Text
  //         cursor="pointer"
  //         userSelect={"none"}
  //         display="inline"
  //         fontSize="sm"
  //         fontWeight="bold"
  //         color={colors.primary}
  //         onClick={
  //           recommendation
  //             ? () => onClickRecommendation(recommendation.option)
  //             : undefined
  //         }
  //       >
  //         {recommendation.option.label}
  //       </Text>
  //     </Text>
  //   );
  // }

  return null;
};

const TransactionDetails = () => {
  const { clientId } = useParams();
  const { usedAi, transaction, showAssetTransfersBuilder, client } = useContext(
    ActiveTransactionContext
  );
  const { header, text, background, greenBg, border, medBackground } =
    useTheme();

  const { control, watch } = useFormContext<FormValues>();
  const navigate = useNavigate();

  const { field: titleField } = useController<FormValues, "title">({
    name: "title",
    control,
  });
  const { field: notesField } = useController<FormValues, "notes">({
    name: "notes",
    control,
  });
  const [internalNotesOpen, setInternalNotesOpen] = useState(false);

  const labelUsed = watch("labelUsed");
  const isLarge = useIsLargeScreen();
  const dispatch = useDispatch();

  const { statusTagText } = useMemo(() => {
    // label used
    if (labelUsed)
      return {
        statusTagText: labelUsed,
        infoMessage: "",
      };
    // rule used
    if (transaction?.ruleUsed) {
      const ruleType = transaction.ruleUsed?.type || "";
      return {
        statusTagText: formatRuleUsed(ruleType),
        infoMessage:
          "Awaken created a pattern to automatically handle this type of transaction.",
      };
    }
    // auto reviewed
    if (transaction?.autoReviewReason) {
      return {
        statusTagText: formatAutoReviewReason(transaction.autoReviewReason),
        infoMessage: "Awaken automatically reviewed this transaction.",
      };
    }
    return {
      statusTagText: "",
      infoMessage: "",
    };
  }, [transaction, labelUsed]);

  const showLawyerModal = useMemo(() => {
    const labelOrRuleOrAutoreview = statusTagText?.toLowerCase();
    const mayBeAirdrop = labelOrRuleOrAutoreview?.includes("airdrop");
    const mayBeBridging = labelOrRuleOrAutoreview?.includes("bridging");
    const mayBeWrapping = labelOrRuleOrAutoreview?.includes("wrapping");
    const mayBeDerivativeSwap =
      labelOrRuleOrAutoreview?.includes("staking_swap") ||
      labelOrRuleOrAutoreview?.includes("staking swap");
    const isRelevantType =
      mayBeAirdrop || mayBeBridging || mayBeWrapping || mayBeDerivativeSwap;
    const income = transaction?.incomeSum || 0;
    const totalProfit = sum(
      (transaction?.transfers || []).map((t) => t.profit ?? 0)
    );

    return (
      isRelevantType &&
      (income > MIN_HIGH_INCOME || totalProfit > MIN_HIGH_CAP_GAINS)
    );
  }, [transaction, labelUsed]);

  if (!transaction) {
    return null;
  }

  const timezone = client?.timezone || "UTC";

  return (
    <Box padding="0.25rem 1rem" bg={background}>
      <Container
        bg={background}
        padding="0px"
        marginTop="0.75rem !important"
        display="block"
      >
        <Box display="flex">
          {/* <Box width="18rem">
            <Input
              {...titleField}
              control={control}
              label="Name"
              placeholder="NFT BAYC #1043 Buy"
              isDisabled
            />
          </Box> */}
          <Box flex={1}>
            {showLawyerModal && <LawyerLetter />}
            {transaction?.provider === "base" && <EchoMessage />}
            <TransactionWarning />

            <HStack
              style={{ alignItems: "center", marginBottom: 10, height: 40 }}
            >
              <FormLabel flex={1} marginBottom="0" color={header} fontSize={14}>
                Notes{" "}
                <span
                  style={{
                    color: text,
                    fontSize: 12,
                    display: "block",
                  }}
                >
                  {(notesField.value?.length || 0) > 0
                    ? `${notesField.value?.length} characters`
                    : ""}
                </span>
              </FormLabel>
              {isLarge && <AIHelper />}
            </HStack>

            <Textarea
              {...notesField}
              control={control}
              resize="vertical"
              textareaProps={{
                bg: background,
                style: {
                  color: header,
                  fontSize: 14,
                  borderColor: border,
                  padding: "0.5rem",
                },
                resize: "vertical",
                height: "auto",
                minHeight:
                  (notesField.value || "").length > 250 ? "175px" : "100px",
              }}
              placeholder="Start typing notes..."
            />

            {client?.isEnterprise && transaction.internalNotes && (
              <InternalNotes
                notes={transaction.internalNotes}
                provider={transaction.provider || ""}
              />
            )}

            {usedAi && (
              <Text
                textAlign="center"
                marginBottom="0.5rem"
                fontSize="sm"
                padding="0.5rem"
                bg={medBackground}
                border={`1px solid ${border}`}
                borderRadius={10}
                marginTop="0.5rem"
                color={text}
              >
                <i
                  className="fa-sharp fa-warning"
                  style={{ color: colors.yellow50 }}
                />{" "}
                AI can hallucinate, so check all labels and recommendations{" "}
                <Info message="We use AI to help you label, but at the end of the day you need to check to make sure. Just like humans, AI is not perfect." />
              </Text>
            )}
          </Box>
        </Box>
      </Container>

      {/* <TensorWarningMessage transaction={transaction} /> */}
      <LedgerMessage />
      {/* <Divider style={{ margin: "1rem 0" }} /> */}
      <IncomeMessage
        transaction={transaction}
        currency={transaction?.fiatCurrency || "USD"}
      />
      <Box
        padding="0"
        paddingBottom="2rem"
        overflowX={isLarge ? "auto" : "scroll"}
      >
        <TransferOverview />
        {showAssetTransfersBuilder && <Transfers />}
        <UnhideTransfers
          transactionId={transaction.id}
          hasHiddenTransfers={transaction.hasHiddenTransfers || false}
        />
        <Fees />
      </Box>
    </Box>
  );
};

const InternalNotes = ({
  notes,
  provider,
}: {
  notes: string;
  provider: string;
}) => {
  const { header, text, medBackground } = useTheme();
  const [internalNotesOpen, setInternalNotesOpen] = useState(false);

  const notesWithLinks = useMemo(() => {
    if (provider !== "base") return notes;

    // Regex to match Ethereum-style hashes (0x followed by 64 hex characters)
    const ethHashRegex = /(0x[a-fA-F0-9]{64})/g;

    return notes.replace(ethHashRegex, (match) => {
      return `[${match}](https://basescan.org/tx/${match})`;
    });
  }, [notes, provider]);

  const renderText = (text: string) => {
    if (provider !== "base") return text;

    const parts = text.split(
      /(\[0x[a-fA-F0-9]{64}\]\(https:\/\/basescan\.org\/tx\/0x[a-fA-F0-9]{64}\))/g
    );

    return parts.map((part, index) => {
      if (part.startsWith("[0x")) {
        const hash = part.match(/\[(0x[a-fA-F0-9]{64})\]/)?.[1];
        const url = part.match(/\((.*?)\)/)?.[1];
        return (
          <Link
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            color={colors.primary}
          >
            {hash}
          </Link>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <Box
      padding={3}
      borderRadius={10}
      style={{
        background: medBackground,
        cursor: "pointer",
        width: "100%",
      }}
      marginTop="0.5rem"
    >
      <HStack w="100%">
        <Text flex={1} fontSize="sm" color={text} fontWeight="medium">
          Internal Notes
        </Text>
        <i
          className={`fa-sharp fa-chevron-${internalNotesOpen ? "up" : "down"}`}
          style={{ color: text }}
          onClick={() => setInternalNotesOpen(!internalNotesOpen)}
        />
      </HStack>

      {internalNotesOpen && (
        <Text marginTop={"10px"} fontSize="sm" color={header}>
          {renderText(notesWithLinks)}
        </Text>
      )}
    </Box>
  );
};

const _includes = (_str: string, options: string[]) => {
  const str = _str.trim().toLowerCase();
  return options.some((option) => str.includes(option));
};

const IncomeMessage = ({
  transaction,
  currency,
}: {
  transaction: BaseFullTransactionFields;
  currency: Currency;
}) => {
  const toast = useMyToast();
  const theme = useTheme();
  const [updateTransaction] = useMutation(api.transactions.update);

  const { ledgerEntries } = useContext(ActiveTransactionContext);
  const hasIncomeOverride =
    !isNil(transaction.overrideIncomeCents) &&
    transaction.overrideIncomeCents >= 0;

  const autoReview = transaction?.autoReviewReason;

  const canEditIncome = new BigNumber(transaction.incomeSum || "0").gt(0);
  // transaction.labelUsed === LABELS.income ||
  // transaction.labelUsed === LABELS.staking ||
  // transaction.labelUsed === LABELS.lend ||
  // transaction.labelUsed === LABELS.unstakingWithRewards ||
  // transaction.labelUsed === LABELS.unstaking ||
  // transaction.ruleUsed?.type === LABELS.income ||
  // transaction.ruleUsed?.type === LABELS.staking ||
  // transaction.ruleUsed?.type === LABELS.lend ||
  // transaction.ruleUsed?.type === LABELS.unstakingWithRewards ||
  // transaction.ruleUsed?.type === LABELS.unstaking ||
  // autoReview?.includes(LABELS.unstakingWithRewards) ||
  // autoReview?.includes("default_rule:" + LABELS.unstaking) ||
  // autoReview?.includes("default_rule:" + LABELS.staking) ||
  // autoReview?.includes("default_rule:" + LABELS.lend) ||
  // autoReview?.includes("default_rule:" + LABELS.income);

  const incomeExpenseFormatted = useMemo(() => {
    if (!ledgerEntries.length) {
      return null;
    }
    const incomeFeeExpenses = ledgerEntries.filter(
      (entry) =>
        entry.ledgerAccount?.subType ===
          LedgerAccountSubTypeEnum.IncomeExpenseFees ||
        entry.ledgerAccount?.type === LedgerAccountTypeEnum.RewardExpense
    );
    if (!incomeFeeExpenses.length) {
      return null;
    }

    const totalIncomeExpense = incomeFeeExpenses.reduce(
      (acc, entry) => acc.plus(entry.fiatAmount),
      new BigNumber(0)
    );

    if (!totalIncomeExpense.gt(0)) {
      return null;
    }

    return D(
      totalIncomeExpense.toNumber(),
      incomeFeeExpenses[0].fiatCurrency
    ).toFormat();
  }, [ledgerEntries]);

  const _onEditIncome = async () => {
    const val = hasIncomeOverride
      ? D(transaction.overrideIncomeCents || 0).toFormat("0.00")
      : D(parseFloat(String(transaction.incomeSum)), currency).toFormat("0.00");

    const newIncomeAmount = window.prompt(
      "What would you like the income to be (USD)? We'd recommend adding a note to this transaction explaining why you are editing this value (for your record).",
      val
    );

    if (isNil(newIncomeAmount)) {
      return;
    }

    // newIncomeAmount
    const num = new BigNumber(newIncomeAmount);

    if (num.isNaN()) {
      alert("Invalid number");
      return;
    }

    try {
      const variables: MutationUpdateTransactionArgs = {
        transactionId: transaction.id,
        createDefaultRule: false,
        updates: {
          overrideLabel: false,
          overrideIncomeCents: num.multipliedBy(100).dp(0).toNumber(),
          txnHash: transaction.txnHash,
          label: transaction?.labelUsed || null,
          title: transaction?.title || "",
          notes: transaction?.notes || "",
          globalRuleName: null,
        },
      };

      await updateTransaction({
        variables,
      });

      void apolloClient.refetchQueries({
        include: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
          api.graph.getReplayInfo,
        ],
      });

      toast.show({
        message: "Updated transaction hash!",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error deleting transaction!",
      });
    }
  };

  if (!transaction.incomeSum) return <div />;
  if (new BigNumber(transaction.incomeSum).isZero()) return <div />;

  return (
    <>
      <HStack
        style={{
          marginTop: "1rem",
        }}
      >
        <i
          className="fa-sharp fa-sack-dollar"
          style={{ color: theme.header }}
        />{" "}
        {/* Changed to money bag icon */}
        <Text
          fontWeight="normal"
          fontSize="sm"
          textAlign="left"
          color={theme.header}
        >
          You earned{" "}
          <strong>
            {hasIncomeOverride
              ? D(transaction.overrideIncomeCents || 0).toFormat()
              : D(
                  parseFloat(String(transaction.incomeSum)),
                  currency
                ).toFormat()}
          </strong>{" "}
          of income on this transaction.{" "}
          <Info message="Looks wrong? Hit the Recalculate button to update this number." />{" "}
        </Text>
        {canEditIncome && (
          <Touchable
            onClick={_onEditIncome}
            label="Edit Income"
            iconName="fa-sharp fa-pen"
          />
        )}
      </HStack>
      {incomeExpenseFormatted && (
        <HStack marginTop="0.5rem" marginBottom="1rem">
          <i
            className="fa-sharp fa-hand-holding-dollar"
            style={{ color: theme.header }}
          />{" "}
          {/* Changed to hand holding money icon */}
          <Text
            fontWeight="normal"
            fontSize="sm"
            textAlign="left"
            color={theme.header}
          >
            We also automatically deducted <b>{incomeExpenseFormatted}</b> as
            income expense fees (this will offset your income).
          </Text>
        </HStack>
      )}
    </>
  );
};

const useLedgerMessage = (): {
  text: string | JSX.Element;
} | null => {
  const theme = useTheme();
  const { clientId } = useParams();
  const { transaction, ledgerEntries, transfers, client } = useContext(
    ActiveTransactionContext
  );

  const isHypeAirdrop =
    transaction?.provider === "hyperliquid" &&
    transaction?.title?.toLowerCase().includes("airdrop") &&
    (transfers?.received?.transfers || []).every(
      (t) => t.fullAsset?.symbol?.toLowerCase() === "hype"
    );

  const isPhotonTrade =
    transaction?.title?.toLowerCase()?.includes("photon buy") ||
    transaction?.title?.toLowerCase()?.includes("photon sell");

  const isCountryWithNoAirdropIncomeTax =
    client &&
    (client.country === CountryEnum.Au || client.country === CountryEnum.Ca) &&
    transaction?.title?.toLowerCase().includes("airdrop");

  const feeExpenseDeductionFormatted = useMemo(() => {
    if (!ledgerEntries.length) {
      return null;
    }
    const feeExpenses = ledgerEntries.filter(
      (entry) =>
        entry.ledgerAccount?.subType ===
        LedgerAccountSubTypeEnum.FeeExpenseDeduction
    );
    if (!feeExpenses.length) {
      return null;
    }

    const totalFeeExpense = feeExpenses.reduce(
      (acc, entry) => acc.plus(entry.fiatAmount),
      new BigNumber(0)
    );

    if (!totalFeeExpense.gt(0)) {
      return null;
    }

    return D(
      totalFeeExpense.toNumber(),
      feeExpenses[0].fiatCurrency
    ).toFormat();
  }, [ledgerEntries]);

  if (isCountryWithNoAirdropIncomeTax) {
    return {
      text: (
        <>
          In {client?.country || "Australia"}, you don't pay income tax on
          airdrops. When you receive the airdrop, it's at a $0 cost basis. When
          you sell the asset, you'll pay capital gains tax.
        </>
      ),
    };
  }

  if (isHypeAirdrop) {
    return {
      text: (
        <>
          By default, Awaken pegs the HYPE airdrop to <b>$2.00</b> per token.
          This is the most conservative approach. Some CPAs believe the FMV was
          $0, so you may want to chat with one{" "}
          <a
            style={{
              fontWeight: "bold",
              color: theme.header,
              textDecoration: "underline",
            }}
            target="_blank"
            href={`${window.location.origin}/clients/${clientId}/taxes/professionals`}
          >
            here
          </a>
          .{" "}
          <a
            style={{
              fontWeight: "bold",
              color: theme.header,
              textDecoration: "underline",
            }}
            href="https://x.com/AwakenTax/status/1876744945605746973"
            target="_blank"
          >
            Here is a survey of how some of our customers are treating it
          </a>
          . None of this is tax advice.
        </>
      ),
    };
  }

  if (feeExpenseDeductionFormatted) {
    return {
      text: `Awaken automatically deducted ${feeExpenseDeductionFormatted} from your capital gains as a fee expense deduction 🤝`,
    };
  }

  if (isPhotonTrade) {
    return {
      text: `Awaken automatically deducts fees paid to Photon off of your capital gains 🤝`,
    };
  }

  return null;
};

function LedgerMessage() {
  const theme = useTheme();

  const message = useLedgerMessage();

  if (!message) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 15,
        marginTop: 5,
        width: "100%",
      }}
    >
      <Box
        style={{
          borderRadius: 10,
          padding: "0.75rem",
          width: "100%",
          backgroundColor: theme.blueBg,
          border: "1px solid " + colors.primary,
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text color={theme.header} fontWeight="normal" fontSize="sm" w="100%">
          <i
            className="fa-sharp fa-info-circle"
            style={{
              color: colors.primary,
              fontSize: 18,
              marginRight: "0.5rem",
              position: "relative",
              top: 2,
            }}
          />{" "}
          {message.text}
        </Text>
      </Box>
    </div>
  );
}

function Header({ handleHide }: { handleHide: () => void }) {
  const { transaction, showAssetTransfersBuilder, client } = useContext(
    ActiveTransactionContext
  );
  const {
    background,
    secondaryBackground,
    medBackground,
    header,
    border,
    text,
  } = useTheme();
  const dispatch = useDispatch();
  const transactionId = transaction?.id || "";
  const [search, setSearchParams] = useSearchParams();
  const clientId = transaction?.clientId;
  const toast = useMyToast();
  const clipboard = useClipboard(transaction?.id || "");
  const [syncTransaction] = useMutation(api.transactions.sync);
  const [hideTransaction] = useMutation(api.transactions.hide);
  const [updateTransaction] = useMutation(api.transactions.update);

  const { me } = useMe();
  const [getTransactionPage, { data: getTransactionPageData }] = useLazyQuery<
    {
      getTransactionPage?: TransactionPageResponse;
    },
    QueryGetTransactionPageArgs
  >(api.transactions.getPage, {
    fetchPolicy: "network-only",
  });

  const _copyConstraints = () => {
    navigator.clipboard.writeText(transaction?.constraints || "");
    toast.show({ message: "Copied Constraints!", status: "info" });
  };

  const [getRawTransaction] = useLazyQuery(api.transactions.getRaw);

  const _consoleLogRawJson = async () => {
    try {
      if (!transaction?.id) {
        toast.show({
          message: "No transaction ID available",
          status: "error",
        });
        return;
      }

      const { data } = await getRawTransaction({
        variables: {
          transactionId: transaction.id,
        },
      });

      if (!data || !data.getTransactionRaw) {
        throw new Error("Failed to fetch raw transaction data");
      }

      // Parse the raw data
      const rawData = JSON.parse(data.getTransactionRaw);

      // Show the modal with the raw data
      dispatch(
        show("RawTransactionModal", {
          rawData,
          transactionId: transaction.id,
        })
      );

      toast.show({
        message: "Displaying raw transaction data",
        status: "success",
      });
    } catch (err) {
      console.error("Error fetching raw transaction data:", err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error fetching raw transaction data",
      });
    }
  };

  const _copyTransactionId = () => {
    navigator.clipboard.writeText(transaction?.id || "");
    toast.show({ message: "Copied Transaction ID!", status: "info" });
  };

  const _toggleDeleteTransaction = async () => {
    try {
      if (!transaction || isNil(transaction?.isHidden)) {
        return;
      }

      const variables: MutationHideTransactionArgs = {
        transactionId,
        isHidden: !transaction.isHidden,
      };

      await hideTransaction({
        variables,
        refetchQueries: [api.clients.transactions],
      });

      void apolloClient.refetchQueries({
        include: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
          api.graph.getReplayInfo,
        ],
      });

      toast.show({
        message: "Deleted Transaction!",
        status: "success",
      });

      search.delete("transactionId");
      setSearchParams(search);
      handleHide();
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error deleting transaction!",
      });
    }
  };

  const _updateTransaction = async () => {
    try {
      const newHash = window.prompt(
        "What would you like to set the transaction hash to?",
        transaction?.txnHash || ""
      );

      if (!newHash) {
        return;
      }

      const variables: MutationUpdateTransactionArgs = {
        transactionId,
        createDefaultRule: false,
        updates: {
          overrideLabel: false,
          txnHash: newHash,
          label: transaction?.labelUsed || null,
          title: transaction?.title || "",
          notes: transaction?.notes || "",
          globalRuleName: null,
        },
      };

      await updateTransaction({
        variables,
      });

      void apolloClient.refetchQueries({
        include: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
          api.graph.getReplayInfo,
        ],
      });

      toast.show({
        message: "Updated transaction hash!",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error updating transaction hash!",
      });
    }
  };

  const _setHasCostBasis = async () => {
    try {
      const variables: MutationUpdateTransactionArgs = {
        transactionId,
        createDefaultRule: false,
        updates: {
          overrideLabel: false,
          overrideIsMissingBasis: true,
          label: transaction?.labelUsed || null,
          title: transaction?.title || "",
          notes: transaction?.notes || "",
          globalRuleName: null,
        },
      };

      await updateTransaction({
        variables,
      });

      void apolloClient.refetchQueries({
        include: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
          api.graph.getReplayInfo,
        ],
      });

      toast.show({
        message: "Updated so transaction has cost basis set.",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error updating transaction hash!",
      });
    }
  };

  const _updateTransactionDate = async () => {
    try {
      if (!transaction?.createdAt) {
        toast.show({
          status: "error",
          message: "Transaction date is missing!",
        });
        return;
      }

      const newDate = window.prompt(
        "Edit the date of the transaction (ISO format)",
        new Date(transaction?.createdAt)?.toISOString() || ""
      );

      if (!newDate) {
        return;
      }

      const isValidDate = moment(newDate).isValid();

      if (!isValidDate) {
        toast.show({
          status: "error",
          message: "Invalid date format",
        });
        return;
      }

      const variables: MutationUpdateTransactionArgs = {
        transactionId,
        createDefaultRule: false,
        updates: {
          overrideLabel: false,
          createdAt: new Date(newDate),
          label: transaction?.labelUsed || null,
          title: transaction?.title || "",
          notes: transaction?.notes || "",
          globalRuleName: null,
        },
      };

      await updateTransaction({
        variables,
      });

      void apolloClient.refetchQueries({
        include: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
          api.graph.getReplayInfo,
        ],
      });

      toast.show({
        message: "Updated transaction date!",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error updating transaction date!",
      });
    }
  };

  const _hardSyncTransaction = async () => {
    try {
      await syncTransaction({
        variables: { transactionId: transaction?.id || "" },
        refetchQueries: [api.transactions.retrieve],
      });

      toast.show({
        message:
          "Synced Transaction! You will need to run 'Recalculate' to update the gain/loss.",
        status: "success",
      });

      search.delete("transactionId");
      setSearchParams(search);
      handleHide();

      // setTimeout(() => {
      //   dispatch(
      //     show("TxnDetailModal", {
      //       transactionId,
      //     })
      //   );
      // }, 1500);
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error syncing transaction!",
      });
    }
  };

  const onClickTransactionOrder = async () => {
    if (!transaction || !clientId) return;

    const pageData = await getTransactionPage({
      variables: {
        transactionId: transaction.id,
      },
    });
    const page33 = pageData.data?.getTransactionPage?.page;
    if (page33 === undefined) return;

    window.open(
      getLink(
        clientId,
        {
          page: page33,
          highlightTransactionId: transaction.id,
          includeSpam: true,
        },
        true
      ),
      "_blank"
    );
  };

  const createdAt = useCreatedAtUTC(
    transaction ?? null,
    client?.timezone || "UTC"
  );

  const isLarge = useIsLargeScreen();

  if (!transaction) {
    return null;
  }

  const timezone = client?.timezone || "UTC";
  const createdAtMoment = moment.tz(transaction.createdAt, timezone);

  // console.log(transaction.needsReview);

  return (
    <VStack
      w="100%"
      marginTop="0rem"
      bg={medBackground}
      style={{
        padding: "1.5rem 1.5rem",
        zIndex: 1_000,
      }}
    >
      <HStack
        display="flex"
        alignItems="flex-start"
        w="100%"
        paddingRight="2.5rem"
      >
        <VStack flex={1} alignItems="flex-start">
          <HStack marginBottom="0.5rem" w="100%">
            <IntegrationInfo transaction={transaction} />

            <Box
              onClick={() => {
                toast.show({
                  message: "Copied transaction link!",
                  status: "info",
                });

                navigator.clipboard.writeText(window.location.href);
              }}
              style={{
                padding: "0.5rem",
                paddingRight: "1rem",
                border: `1px solid ${border}`,
                borderRadius: 8,
                color: text,
                fontSize: 14,
                fontWeight: "500",
                fontStretch: "normal",
                cursor: "pointer",
                backgroundColor: secondaryBackground,
              }}
            >
              Copy link{" "}
              <i style={{ marginLeft: 5 }} className="fa-sharp fa-clone" />
            </Box>

            <TaxLockWarning
              client={client}
              transaction={transaction}
              hideModal={() => {
                search.delete("transactionId");
                setSearchParams(search);
              }}
            />
          </HStack>

          <Text
            // w="100%"
            noOfLines={4}
            fontWeight="semibold"
            fontSize={isLarge ? "2xl" : "md"}
            marginTop="0.3rem"
            // wrap the text
            color={header}
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              hyphens: "auto",
            }}
          >
            {(transaction?.title || "").replace(/_/g, " ")}
          </Text>

          {transaction.autoReviewReason?.startsWith("v1:default_rule") && (
            <StatusTag
              label="🧠 Labeled by Awaken AI"
              // iconName="fa-sharp fa-user-robot"
              type="info"
              infoMessage="Awaken automatically labeled this transaction."
              iconStyle={{ fontSize: 13 }}
              boxProps={{
                style: { border: "1px solid " + colors.lightBlue50 },
                margin: "0.3rem 0",
              }}
            />
          )}

          <Box
            color={colors.gray4}
            style={{
              fontSize: 16,
              fontWeight: "normal",
              width: "100%",
              marginRight: "2rem",
              marginTop: "1.25rem",
              display: "flex",
              alignItems: isLarge ? "center" : "inherit",
              flexDirection: isLarge ? "row" : "column",
            }}
          >
            <Tooltip label={timeAgo(createdAt as DateTime)}>
              <Text
                fontWeight="500"
                fontSize="sm"
                color={text}
                marginTop={isLarge ? "0" : "1rem"}
              >
                {createdAtMoment.format("MMM Do, YYYY h:mm A z")}
              </Text>
            </Tooltip>

            {isLarge && (
              <Text style={{ margin: "0 1rem", color: text }}>|</Text>
            )}

            {transaction?.txnHash && (
              <HStack
                marginLeft="0"
                marginTop={isLarge ? 0 : "10px"}
                justifyContent={"flex-start"}
              >
                <Box>
                  <Text
                    textAlign="left"
                    fontSize="sm"
                    fontWeight="500"
                    color={text}
                    isTruncated
                  >
                    {truncate(transaction.txnHash?.replace("coinbase:", ""), {
                      length: 30,
                    })}
                  </Text>
                </Box>
                <Copy
                  value={(transaction.txnHash || "").replace("coinbase:", "")}
                  iconStyle={{
                    padding: "0",
                    fontSize: "14px",
                  }}
                  // label={transaction.txnHash}
                />
              </HStack>
            )}

            {transaction?.txnHash && isLarge && (
              <Text style={{ margin: "0 1rem", color: text }}>|</Text>
            )}

            {isLarge && (
              <StatusTag
                type={
                  transaction?.isHidden
                    ? "error"
                    : GET_TXN_STATUS[
                        transaction?.status ||
                          LedgerTransactionStatusEnum.Pending
                      ]
                }
                hasBorder={true}
                // labelStyle={{
                //   color: header,
                //   fontWeight: "normal",
                // }}
                label={
                  transaction?.isHidden
                    ? "Deleted"
                    : transaction?.status || "None"
                }
              />
            )}
          </Box>
        </VStack>
      </HStack>

      <HStack
        style={{
          // hack to line up the items a lil better
          position: "relative",
          left: -11,
          marginTop: "0.5rem",
        }}
        w="100%"
        justifyContent="flex-start"
        alignItems="center"
      >
        <TransactionExplorerButton
          blockExplorerName={transaction?.blockExplorerName}
          blockExplorerUrl={transaction?.blockExplorerUrl}
          txnHash={transaction?.txnHash || ""}
          provider={transaction?.provider}
          notes={transaction?.notes}
        />

        {isLarge && (
          <>
            <Touchable
              onClick={onClickTransactionOrder}
              label="Timeline"
              padding="0.55rem 0.75rem"
              iconName="fa-sharp fa-timeline"
            />

            <EntriesPopover />

            <BalancesPopover />

            <ActionSheet
              content={{
                maxW: "225px",
              }}
              popover={{ placement: "bottom", trigger: "hover" }}
              commands={[
                {
                  label: transaction?.isHidden
                    ? "Undelete Transaction"
                    : "Delete Transaction",
                  iconName: "fa-sharp fa-trash",
                  onClick: _toggleDeleteTransaction,
                  iconColor: colors.red50,
                  color: colors.red50,
                  infoMessage:
                    "This will remove this transaction from Awaken. If you need to un-remove it, you will have to message support and let us know and we can help you.",
                },
                {
                  label: "Update Hash",
                  iconName: "fa-sharp fa-pen",
                  onClick: _updateTransaction,
                  infoMessage: "Only useful for blockchain transactions.",
                },
                {
                  label: "Edit Date",
                  iconName: "fa-sharp fa-calendar",
                  onClick: _updateTransactionDate,
                },
                {
                  label: "Copy Txn ID",
                  iconName: "fa-sharp fa-clone",
                  onClick: _copyTransactionId,
                },
                {
                  label: "Copy Constraints",
                  iconName: "fa-sharp fa-clone",
                  onClick: _copyConstraints,
                },
                me?.isSuperuser
                  ? {
                      label: "View Raw JSON",
                      iconName: "fa-sharp fa-code",
                      onClick: _consoleLogRawJson,
                    }
                  : null,
                {
                  label: "Set has cost basis",
                  iconName: "fa-sharp fa-dollar-sign",
                  onClick: _setHasCostBasis,
                  infoMessage:
                    "Try to always resolve missing cost basis. If you however cannot, this will make it so the transaction doesn't show a missing basis warning.",
                },
                {
                  label: "Sync Transaction",
                  iconName: "fa-sharp fa-refresh",
                  onClick: _hardSyncTransaction,
                  infoMessage:
                    "This will re-sync this specific transaction with Awaken's most recent import data. This is useful if you've imported a transaction that was missing transfers when you initially imported but Awaken now supports.",
                },
              ].filter(hasValue)}
            >
              <Touchable
                // super hacky can't figure out why this one is taller so whatever
                padding="0.45rem 0.75rem"
                iconName="fa-sharp fa-ellipsis-v"
                label="More"
                iconStyle={{ fontSize: 14 }}
              />
            </ActionSheet>
          </>
        )}
      </HStack>
    </VStack>
  );
}

type TransactionExplorerButtonProps = {
  blockExplorerUrl?: Maybe<string>;
  blockExplorerName?: Maybe<string>;
  txnHash: string;
  provider?: Maybe<string>;
  notes?: Maybe<string>;
};

const SOLANA_EXPLORER_STORAGE_KEY = "preferred_solana_explorer";

const SolanaExplorerButton = ({ txnHash }: { txnHash: string }) => {
  const isReward = txnHash && txnHash.includes("reward:");

  const [preferredExplorer, setPreferredExplorer] = useState(
    () => localStorage.getItem(SOLANA_EXPLORER_STORAGE_KEY) || "solscan"
  );

  const getExplorerUrl = (explorer: string) => {
    const urls = {
      solscan: `https://solscan.io/tx/${txnHash}`,
      solanafm: `https://solana.fm/tx/${txnHash}?cluster=mainnet-alpha`,
    };
    return urls[explorer as keyof typeof urls];
  };

  const handleExplorerClick = (explorer: string) => {
    setPreferredExplorer(explorer);
    localStorage.setItem(SOLANA_EXPLORER_STORAGE_KEY, explorer);
    window.open(getExplorerUrl(explorer), "_blank");
  };

  const handleDefaultClick = () => {
    window.open(getExplorerUrl(preferredExplorer), "_blank");
  };

  if (isReward) {
    return null;
  }

  return (
    <ActionSheet
      content={{
        maxW: "250px",
      }}
      popover={{ placement: "bottom-start", trigger: "hover", openDelay: 250 }}
      commands={[
        {
          label:
            "View in Solscan" +
            (preferredExplorer === "solscan" ? " (default)" : ""),
          iconImageSrc: "https://assets.awaken.tax/icons/solscan.png",
          onClick: () => handleExplorerClick("solscan"),
        },
        {
          label:
            "View in SolanaFM" +
            (preferredExplorer === "solanafm" ? " (default)" : ""),
          iconImageSrc: "https://assets.awaken.tax/icons/solanafm.png",
          onClick: () => handleExplorerClick("solanafm"),
        },
      ]}
    >
      <Touchable
        padding="0.55rem 0.75rem"
        iconName="fa-sharp fa-external-link-alt"
        label={`View in ${
          preferredExplorer === "solscan" ? "Solscan" : "SolanaFM"
        }`}
        style={{ display: "block" }}
        onClick={handleDefaultClick}
      />
    </ActionSheet>
  );
};

const TransactionExplorerButton = ({
  blockExplorerUrl,
  blockExplorerName,
  txnHash,
  provider,
  notes,
}: TransactionExplorerButtonProps) => {
  if (txnHash && provider === "coinbase") {
    const adjustedHash = txnHash.replace("coinbase:", "");

    return (
      <ActionSheet
        content={{
          maxW: "225px",
        }}
        popover={{ placement: "bottom", trigger: "hover" }}
        commands={[
          {
            label: "View in Solscan",
            iconImageSrc: "https://assets.awaken.tax/icons/solana.png",
            link: `https://solscan.io/tx/${adjustedHash}`,
          },
          // bitcoin
          {
            label: "View in Blockchair",
            iconImageSrc: "https://assets.awaken.tax/icons/btc.png",
            link: `https://blockchair.com/bitcoin/transaction/${adjustedHash}`,
          },
          {
            // eth
            label: "View in Etherscan",
            iconImageSrc: "https://assets.awaken.tax/icons/eth.png",
            link: `https://etherscan.io/tx/${adjustedHash}`,
          },
          // sui
          {
            label: "View in Suiscan",
            iconImageSrc: "https://assets.awaken.tax/icons/sui.png",
            link: `https://suiscan.xyz/mainnet/tx/${adjustedHash}`,
          },
          // base
          {
            label: "View in Basescan",
            iconImageSrc: "https://assets.awaken.tax/icons/base.png",
            link: `https://basescan.org/tx/${adjustedHash}`,
          },
        ]}
      >
        <Touchable
          padding="0.55rem 0.75rem"
          iconName="fa-sharp fa-external-link-alt"
          label={`View in block explorer`}
          style={{ display: "block" }}
        />
      </ActionSheet>
    );
  }

  if (!blockExplorerName || !blockExplorerUrl) return null;

  if (provider === "sui") {
    return <SuiExplorerButton txnHash={txnHash} />;
  }

  if (provider === "solana") {
    return <SolanaExplorerButton txnHash={txnHash} />;
  }

  if (provider === "hyperliquid") {
    const adjustedHash = (txnHash || "").split(":")[0];

    return (
      <ActionSheet
        content={{
          maxW: "225px",
        }}
        popover={{ placement: "bottom-start", trigger: "hover" }}
        commands={[
          {
            label: "View in Hyperliquid",
            iconImageSrc: "https://assets.awaken.tax/icons/hype.png",
            link: `https://app.hyperliquid.xyz/explorer/tx/${adjustedHash}`,
          },
          {
            label: "View in Hypurrscan",
            iconImageStyle: { borderRadius: 15 },
            iconImageSrc: "https://assets.awaken.tax/icons/hypurrscan-2.jpg",
            link: `https://hypurrscan.io/tx/${adjustedHash}`,
          },
        ]}
      >
        <Touchable
          padding="0.55rem 0.75rem"
          iconName="fa-sharp fa-external-link-alt"
          label={`View in explorer`}
          style={{ display: "block" }}
        />
      </ActionSheet>
    );
  }

  if (provider === "bitcoin") {
    return (
      <ActionSheet
        content={{
          maxW: "225px",
        }}
        popover={{ placement: "bottom", trigger: "hover" }}
        commands={[
          {
            label: "View in Blockchair",
            iconImageSrc: "https://assets.awaken.tax/icons/btc.png",
            link: `https://blockchair.com/bitcoin/transaction/${txnHash}`,
          },
          {
            label: "View in Mempool",
            iconImageStyle: { borderRadius: 5 },
            iconImageSrc: "https://assets.awaken.tax/icons/mempool.png",
            link: `https://mempool.space/tx/${txnHash}`,
          },
          {
            label: "View in Ordiscan",
            iconImageStyle: { borderRadius: 5 },
            iconImageSrc: "https://assets.awaken.tax/icons/ordiscan.png",
            link: `https://ordiscan.com/tx/${txnHash}`,
          },
        ]}
      >
        <Touchable
          padding="0.55rem 0.75rem"
          iconName="fa-sharp fa-external-link-alt"
          label={`View in block explorer`}
          style={{ display: "block" }}
        />
      </ActionSheet>
    );
  }

  // don't have a linked etherscan page for beacon withdrawals
  if (provider === "ethereum" && txnHash.includes("beacon-withdrawal")) {
    return null;
  }

  return (
    <Link
      href={blockExplorerUrl}
      rel="noreferrer"
      target={isMobile ? undefined : "_blank"}
      style={{ padding: 0, textDecoration: "none" }}
    >
      <Touchable
        padding="0.55rem 0.75rem"
        iconName="fa-sharp fa-external-link-alt"
        label={`View on ${blockExplorerName}`}
        style={{ display: "block" }}
      />
    </Link>
  );
};

const SCRAPING_MESSAGE = "Pulling transaction... 🌐";
const PARSING_MESSAGE = "Parsing transaction... ✍️";
const SUMMARIZING_MESSAGE = "Analyzing transaction... 🤔";

const AIHelper = () => {
  const { setUsedAi, usedAi, transaction, defaultLabels, setShowDropdown } =
    useContext(ActiveTransactionContext);

  const [summarizeTransaction] = useMutation<
    Pick<Mutation, "summarizeTransaction">
  >(api.transactions.summarize);

  const [hasUsedAi, setHasUsedAi] = useState(false);
  const formProps = useFormContext<FormValues>();
  const toast = useMyToast();
  const theme = useTheme();

  const [statusMessage, setStatusMessage] = useState(SCRAPING_MESSAGE);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const isLarge = useIsLargeScreen();
  const dispatch = useDispatch();

  const _summarize = async () => {
    try {
      if (isSummarizing) {
        return;
      }

      if (!transaction) {
        return;
      }

      setIsSummarizing(true);

      setStatusMessage(SCRAPING_MESSAGE);

      // Set up timers to change the status message
      const timer1 = setTimeout(() => setStatusMessage(PARSING_MESSAGE), 2_500);
      const timer2 = setTimeout(
        () => setStatusMessage(SUMMARIZING_MESSAGE),
        5_000
      );

      const response = await summarizeTransaction({
        variables: {
          transactionId: transaction.id,
        },
      });

      // Clear timers once the response is received
      clearTimeout(timer1);
      clearTimeout(timer2);
      setIsSummarizing(false);

      let notes = "";
      const label = response.data?.summarizeTransaction?.label;
      const description = response.data?.summarizeTransaction?.description;
      const analysis = response.data?.summarizeTransaction?.analysis;
      const rawNotes = formProps.getValues("notes");

      notes += rawNotes && !isNil(rawNotes) ? `${rawNotes}\n\n` : "";

      if ((description || analysis) && label) {
        setUsedAi(true);

        notes += [
          description ? `Description: ${description}` : null,
          analysis ? `Analysis: ${analysis}` : null,
          `Recommended: ${label}`,
        ]
          .filter(hasValue)
          .join("\n\n");
      }

      if (label) {
        const potentialLabel = (defaultLabels?.labels || []).find(
          (l) =>
            (l.label || "")
              ?.toLocaleLowerCase()
              .includes(label.toLocaleLowerCase()) ||
            l.value
              .toLocaleLowerCase()
              .includes(label?.toLocaleLowerCase() || "")
        );

        if (potentialLabel) {
          formProps.setValue("labelUsed", potentialLabel.value);
          setShowDropdown(true);
        }
      }

      // add current notes

      formProps.setValue("notes", notes);

      toast.show({
        message: "Transaction summarized! We've added it to the notes ✍️",
        status: "success",
      });

      setHasUsedAi(true);
    } catch (error) {
      console.error(error);

      toast.show({
        message:
          (error as any)?.message ||
          "Error summarizing transaction. We've been alerted and will look into the issue!",
        status: "error",
      });
    } finally {
      setIsSummarizing(false);
    }
  };

  return (
    <>
      {hasUsedAi && !isSummarizing && (
        <Touchable
          style={{
            marginTop: 10,
            marginBottom: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 10,
          }}
          onClick={() =>
            dispatch(
              show("FeedbackModal", {
                hiddenDescription: `I used Awaken's AI to summarize this transaction (${transaction?.txnHash}).`,
              })
            )
          }
          label={isLarge ? "Leave Feedback" : ""}
          iconName="fa-sharp fa-heart"
          iconStyle={{
            color: theme.header,
            animation: "pulse-big 1.5s infinite",
          }}
        />
      )}
      <HStack
        style={{
          padding: 0,
          borderRadius: 15,
          background: isSummarizing ? theme.secondaryBackground : "transparent",
        }}
      >
        {isSummarizing && (
          <HStack>
            <Spinner
              style={{
                marginLeft: 15,
                color: theme.text,
                marginRight: 5,
              }}
              size="xs"
            />
            <Text
              style={{
                color: theme.text,
                fontSize: 14,
              }}
            >
              {statusMessage}
            </Text>
          </HStack>
        )}

        <AwakenTooltip
          message="This feature is in BETA. We'll use AI to summarize this transaction and recommend a label. You'll need to check the label to make sure it actually applies."
          placement="bottom-end"
        >
          <div style={{}}>
            <Touchable
              // don't wait
              onClick={_summarize}
              style={{
                // animation: "pulse-small 1.5s infinite",
                // opacity: isSummarizing ? 0.3 : 1,
                background: colors.primary, // "linear-gradient(90deg, #ff7e5f, #feb47b)", // Gradient background
                color: theme.text,
                padding: "0.5rem 1rem",
                borderRadius: 15,
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: to add a shadow for depth
              }}
              labelStyle={{
                color: colors.white,
                fontWeight: "bold",
                fontSize: 14,
                fontStretch: "extra-expanded",
              }}
              iconStyle={{
                marginLeft: 5,
                color: colors.white,
                fontSize: 16,
              }}
              showSpinnerOnLoading={false}
              label="Ask Awaken AI"
              iconName="fa-sharp fa-wand-magic-sparkles"

              // iconName="fa-sharp fa-sparkles"
            />
          </div>
        </AwakenTooltip>
      </HStack>
    </>
  );
};

// Add this new component
const TransactionFunctionHint = ({
  formattedFunctionName,
}: {
  formattedFunctionName?: string | null;
}) => {
  const { header } = useTheme();

  if (!formattedFunctionName || formattedFunctionName.startsWith("0 X")) {
    return null;
  }

  const words = formattedFunctionName
    .split(/[\s_]+/) // Split by spaces and underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  const hasMultipleWords = words.includes(" ");

  return (
    <Text color={header} fontSize="sm" marginBottom="0.5rem">
      Hint: The transaction references <strong>"{words}"</strong>.
    </Text>
  );
};

const IntegrationInfo = ({
  transaction,
}: {
  transaction: BaseFullTransactionFields;
}) => {
  const { header, border, secondaryBackground } = useTheme();

  const isGeneral =
    transaction.provider === "general_blockchain" ||
    transaction.provider === "general_exchange";

  const providerName =
    INTEGRATIONS.find((i) => i.provider === transaction.provider)?.name ||
    "Provider";

  const firstAccount = (transaction.transfers || []).find(
    (t) => !!(t.fromAccount?.description || t.toAccount?.description)
  );

  const transactionProviderName = isGeneral
    ? firstAccount?.fromAccount?.description ||
      firstAccount?.toAccount?.description
    : providerName;

  return (
    <HStack
      style={{
        padding: "0.5rem",
        paddingRight: "1rem",
        border: `1px solid ${border}`,
        borderRadius: 8,
        backgroundColor: secondaryBackground,
      }}
    >
      {isGeneral ? (
        <i
          className="fa-sharp fa-wallet"
          style={{
            color: header,
            fontSize: 16,
          }}
        />
      ) : (
        <Image
          src={PROVIDER_TO_LOGO_URL[transaction.provider || ""] || ""}
          width="1.25rem"
          height="1.25rem"
          display="inline"
          style={{ borderRadius: 5 }}
        />
      )}
      <Text color={header} fontSize="sm" fontWeight="semibold">
        {transactionProviderName}
      </Text>
    </HStack>
  );
};

const SUI_EXPLORER_STORAGE_KEY = "preferred_sui_explorer";

const SuiExplorerButton = ({ txnHash }: { txnHash: string }) => {
  const [preferredExplorer, setPreferredExplorer] = useState(
    () => localStorage.getItem(SUI_EXPLORER_STORAGE_KEY) || "suiscan"
  );

  const getExplorerUrl = (explorer: string) => {
    const urls = {
      suiscan: `https://suiscan.xyz/mainnet/tx/${txnHash}`,
      suivision: `https://suivision.xyz/txblock/${txnHash}`,
    };
    return urls[explorer as keyof typeof urls];
  };

  const handleExplorerClick = (explorer: string) => {
    setPreferredExplorer(explorer);
    localStorage.setItem(SUI_EXPLORER_STORAGE_KEY, explorer);
    window.open(getExplorerUrl(explorer), "_blank");
  };

  const handleDefaultClick = () => {
    window.open(getExplorerUrl(preferredExplorer), "_blank");
  };

  return (
    <ActionSheet
      content={{
        maxW: "250px",
      }}
      popover={{ placement: "bottom-start", trigger: "hover", openDelay: 250 }}
      commands={[
        {
          label:
            "View in Suiscan" +
            (preferredExplorer === "suiscan" ? " (default)" : ""),
          iconImageStyle: { borderRadius: 5 },
          iconImageSrc: "https://assets.awaken.tax/icons/suiscan.png",
          onClick: () => handleExplorerClick("suiscan"),
        },
        {
          label:
            "View in Suivision" +
            (preferredExplorer === "suivision" ? " (default)" : ""),
          iconImageStyle: { borderRadius: 5 },
          iconImageSrc: "https://assets.awaken.tax/icons/suivision.png",
          onClick: () => handleExplorerClick("suivision"),
        },
      ]}
    >
      <Touchable
        padding="0.55rem 0.75rem"
        iconName="fa-sharp fa-external-link-alt"
        label={`View in ${
          preferredExplorer === "suiscan" ? "Suiscan" : "Suivision"
        }`}
        style={{ display: "block" }}
        onClick={handleDefaultClick}
      />
    </ActionSheet>
  );
};

const TaxLockWarning = ({
  client,
  transaction,
  hideModal,
}: {
  client: any;
  transaction: any;
  hideModal: () => void;
}) => {
  const { redBg } = useTheme();
  const { clientId } = useParams();
  const navigate = useNavigate();

  if (
    !client?.lockTaxesUntil ||
    !transaction ||
    client.lockTaxesUntil <= transaction.createdAt
  ) {
    return null;
  }

  return (
    <Box
      padding="0.75rem"
      backgroundColor={redBg}
      border={`1px solid ${colors.red50}`}
      borderRadius="8px"
      flex={1}
      display="flex"
      alignItems="center"
      gap="0.5rem"
    >
      <i
        className="fa-sharp fa-lock"
        style={{ color: colors.red50, fontSize: 18 }}
      />
      <Text color={colors.red50} fontSize="14px">
        Tax reports locked until{" "}
        {moment(client.lockTaxesUntil).format("MM/DD/YYYY")}. Any edits made
        will not be applied to your tax reports.{" "}
        <span
          onClick={() => {
            hideModal();
            navigate(`/clients/${clientId}/taxes`);
          }}
          style={{
            cursor: "pointer",
            color: colors.red50,
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          Unlock reports
        </span>
      </Text>
    </Box>
  );
};

export const TxnDetailModal = connectModal({
  name: "TxnDetailModal",
})(_TxnDetailModal);
