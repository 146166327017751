import {
  ApolloError,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  Box,
  Link as ChakraLink,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Progress,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isNil, maxBy } from "lodash";
import { compose } from "lodash/fp";
import moment from "moment";
import numbro from "numbro";
import { omit } from "radash";
import { useEffect, useMemo, useState } from "react";
import ReactConfetti from "react-confetti";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseClientFields, BaseUserFields } from "src/api/fragments";
import {
  ClientStatusEnum,
  CountNotDoneResponse,
  Mutation,
  NumTxnsResponse,
  Query,
  Subscription,
} from "src/api/generated/types";
import { config, CURRENT_TAX_YEAR } from "src/config";
import { Maybe } from "src/core";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useInterval } from "src/hooks/common";
import {
  useActiveSubscription,
  UseActiveSubscriptionResponse,
} from "src/hooks/useActiveSubscription";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getGainsLossesLink } from "src/modules/ledger/transactions";
import { getDrawerIsOpen, setDrawerIsOpen } from "src/redux/reducers/active";
import { getShowConfetti } from "src/redux/reducers/globalState";
import { colors, other } from "src/theme";
import { isFree } from "src/utils/free";
import { D, getEtaMessage } from "src/utils/helpers";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { THRESHOLDS } from "../modals/CheckoutModal/constants";
import { AwakenTooltip, Info } from "../styled";
import { Touchable, TouchableProps } from "../Touchable";
import { ClientSelector } from "./ClientSelector";
import { NavigationLink } from "./NavigationLink";

import { Partnerships } from "./PartnershipDiscount";
import { PrioritySelector } from "./PrioritySelector";

const WIDTH = "14rem";

export const Sidebar = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [showSidebar, setShowSidebar] = useState(true);
  const dispatch = useDispatch();
  const { me } = useMe();

  const highestPriorityLink = getGainsLossesLink({
    clientId: clientId || "",
    reviewed: false,
  });
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });

  const activeSubscription = useActiveSubscription(clientId || "");

  const [getNumTxns, { data: numTxnsData, networkStatus: numTxnsStatus }] =
    useLazyQuery<{
      getNumTxns?: NumTxnsResponse;
    }>(api.transactions.countTransactions, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

  const [countNotDone, { data: countNotDoneData }] = useLazyQuery<{
    countNotDone: CountNotDoneResponse;
  }>(api.transactions.countNotDone, {
    fetchPolicy: "cache-and-network",
  });

  const { data: spamData } = useQuery<Pick<Query, "getClientSpamAssets">>(
    api.assets.spam,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      variables: {
        clientId,
      },
    }
  );

  useEffect(() => {
    getNumTxns({
      variables: {
        clientId,
      },
    });
    countNotDone({
      variables: {
        clientId,
      },
    });
  }, [clientId]);

  const isSuperUser = me?.isSuperuser;
  const toast = useMyToast();
  const hasPortfolioEnabled = me?.hasPortfolioEnabled || false;
  const subject = "I'd like to do a feedback call about the portfolio";
  const isLarge = useIsLargeScreen();
  const isOpen = useSelector(getDrawerIsOpen);
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const onlyShowAccounts = _onlyShowAccounts(me);
  const isWhiteLabel = isWhiteLabeledDomain();
  const {
    medBackground,
    header,
    secondaryBackground,
    secondaryBackground2,
    text,
    border,
    theme,
    background,
  } = useTheme();
  const numSpamReview = spamData?.getClientSpamAssets?.needsReview?.length || 0;

  const isUpToDate =
    (countNotDoneData?.countNotDone?.dirty || 0) === 0 &&
    client?.status !== ClientStatusEnum.Importing &&
    client?.status !== ClientStatusEnum.Recalculating;

  const SidebarComponent = (
    <Box
      display="flex"
      flexDir={"column"}
      justifyContent="space-between"
      w={showSidebar ? (isLarge ? WIDTH : "100%") : "0rem"}
      h="100%"
      position="relative"
      style={{
        borderRight: `1px solid ${border}`,
        backgroundColor: background,
      }}
    >
      {isLarge && (
        <Box
          w="1.4rem"
          h="3rem"
          bgColor={secondaryBackground}
          right="-1.4rem"
          top="0.4rem"
          position="absolute"
          border={`1px solid ${border}`}
          borderRightRadius={other.borderRadius}
          cursor="pointer"
          onClick={() => setShowSidebar(!showSidebar)}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <i
            className={`fa-sharp fa-chevron-${showSidebar ? "left" : "right"}`}
            style={{ color: colors.gray3 }}
          />
        </Box>
      )}

      <Box
        w="100%"
        h="100%"
        overflow={isLarge ? "hidden" : "scroll"}
        whiteSpace="nowrap"
      >
        <VStack h="100%" w="100%">
          <ClientSelector />

          {/* top content */}
          <Box flex={1} w="100%" margin={0} marginTop="0 !important">
            <>
              <PortfolioTab clientId={clientId || ""} />
              <AccountsTab clientId={clientId || ""} />
              {/* <PortfolioTab
                  clientId={clientId || ""}
                  hasPortfolioEnabled={hasPortfolioEnabled}
                /> */}

              <NavigationLink
                link={`/clients/${clientId}/transactions`}
                label="Transactions"
                iconName="fa-sharp fa-exchange"
                extraComponent={<RecalculatingSpinner />}
                // notification={
                //   client?.status === ClientStatusEnum.Importing
                //     ? null
                //     : numTxnsData?.getNumTxns?.high
                // }
                // // extraComponent={<CogsAnimation height="2.5rem" />}
                // notificationLink={highestPriorityLink}
                // notificationMessage={
                //   <span style={{ color: text }}>
                //     You have{" "}
                //     <Link to={highestPriorityLink}>
                //       <Text
                //         fontSize="sm"
                //         display="inline"
                //         fontWeight="semibold"
                //         color={colors.primary}
                //         textDecoration="underline"
                //       >
                //         {" "}
                //         {numTxnsData?.getNumTxns?.high} unlabeled transactions
                //       </Text>
                //     </Link>{" "}
                //     with large gains/losses.
                //   </span>
                // }
              />

              {/* <NavigationLink
                  link={`/clients/${clientId}/harvest`}
                  // this way we show it as selected even at the root
                  links={[`/clients/${clientId}/harvest`]}
                  label={<>Tax Loss Harvesting</>}
                  iconName="fa-sharp fa-pumpkin"
                /> */}

              <NavigationLink
                link={`/clients/${clientId}/taxes`}
                label="Taxes"
                iconName="fa-sharp fa-file-spreadsheet"
              />

              {/* <NavigationLink
                  link={`/clients/${clientId}/awaiken`}
                  label="Awaiken"
                  iconName="fa-sharp fa-microchip-ai"
                /> */}

              {/* <NavigationLink
                  link={`/clients/${clientId}/news`}
                  label="News"
                  iconName="fa-sharp fa-newspaper"
                  extraComponent={
                    <StatusTag
                      type="warning"
                      label="Soon"
                      iconName="fa-sharp fa-sparkles"
                    />
                  }
                /> */}

              <NavigationLink
                link={`/clients/${clientId}/referrals`}
                label="Refer Friends"
                iconName="fa-sharp fa-share-alt"
                extraComponent={<BeatingHeart />}
              />

              <div
                style={{
                  margin: "0.75rem 0",
                  height: 1,
                  width: "100%",
                  background: border,
                }}
              />

              <NavigationLink
                link={`/clients/${clientId}/ai`}
                // onClick={() => alert("Coming soon")}
                label={<>Awaken AI</>}
                iconName="fa-sharp fa-microchip-ai"
              />

              {/* updates */}
              {/* <NavigationLink
                link={`/clients/${clientId}/updates`}
                label="Updates"
                iconName="fa-sharp fa-arrow-rotate-right"
              /> */}

              <NavigationLink
                link={`/clients/${clientId}/spam`}
                // onClick={() => alert("Coming soon")}
                label="Spam"
                iconName="fa-sharp fa-trash-can"
                // notification={numSpamReview > 0 ? numSpamReview : null}
                // notificationMessage={
                //   <span style={{ color: text }}>
                //     You have{" "}
                //     <Text
                //       fontSize="sm"
                //       display="inline"
                //       fontWeight="semibold"
                //       color={colors.primary}
                //       textDecoration="underline"
                //     >
                //       {" "}
                //       {numSpamReview} assets to review for spam.
                //     </Text>
                //   </span>
                // }
              />
              {/* rules */}
              <NavigationLink
                link={`/clients/${clientId}/rules`}
                label="Rules"
                iconName="fa-sharp fa-ruler"
              />

              {/* <NavigationLink
                link={`/clients/${clientId}/team`}
                label="Settings"
                iconName="fa-sharp fa-gear"
                // extraComponent={
                //   !me?.hasTwoFactorAuth ? (
                //     <StatusTag
                //       infoMessage="Please enable 2FA to keep your account secure!"
                //       type="error"
                //       label="2FA"
                //       iconName="fa-sharp fa-shield-halved"
                //     />
                //   ) : null
                // }
              /> */}

              {/* <NavigationLink
                  link={`/clients/${clientId}/opportunities`}
                  // onClick={() => alert("Coming soon")}
                  label="Opportunities"
                  iconName="fa-sharp fa-lightbulb"
                /> */}

              {/* <NavigationLink
                  link={`/clients/${clientId}/taxes/professionals`}
                  label="Tax Help"
                  iconName="fa-sharp fa-user-tie"
                  // extraComponent={<BeatingHeart />}
                /> */}
            </>

            {/* {(me?.role === "accountant" || me?.isSuperuser) && (
              <>
                {(me?.role === "accountant" || me?.isSuperuser) && (
                  <NavigationLink
                    link={`/clients/${clientId}/quotes/requests`}
                    label="Quotes"
                    iconName="fa-sharp fa-inbox"
                  />
                )}
              </>
            )} */}

            <div
              style={{
                margin: "0.75rem 0",
                height: 1,
                width: "100%",
                background: border,
              }}
            />

            {/* {!onlyShowAccounts && <DarkModeToggle />} */}
            {/* <TelegramBadge /> */}
            <Partnerships
              canDownload={activeSubscription.canDownload ?? false}
            />
          </Box>
          {/* <ImproveAwaken /> */}

          {/* {client && <VIPSection client={client} />} */}

          {me?.isSuperuser && <PrioritySelector />}

          {/* {isUpToDate && (
            <AwakenTooltip message="Your transactions have been recalculated and they are up to date.">
              <Text
                // onClick={() => dispatch(show("FeedbackModal"))}
                style={{
                  // cursor: "pointer",
                  marginBottom: 15,
                  borderRadius: 100,
                  textAlign: "left",
                  width: "100%",
                  padding: "0.3rem 1.2rem",
                  color: text,
                  // background: secondaryBackground,
                  fontSize: 14,
                  fontWeight: "500",
                  // textTransform: "uppercase",
                }}
              >
                <i
                  className="fa-sharp fa-circle-check"
                  style={{
                    color: colors.positive,
                    marginRight: 5,
                  }}
                />{" "}
                Account is up to date
              </Text>
            </AwakenTooltip>
          )} */}

          {!onlyShowAccounts && (
            <VStack
              alignItems="center"
              marginTop="0 !important"
              w="100%"
              borderRadius={0}
              borderTop={`1px solid ${border}`}
              bg={medBackground}
            >
              <CurrentTransactions activeSubscription={activeSubscription} />
            </VStack>
          )}
          {!onlyShowAccounts && (
            <UnlimitedCXSection activeSubscription={activeSubscription} />
          )}
        </VStack>
      </Box>
    </Box>
  );

  if (isLarge) return SidebarComponent;

  return (
    <Drawer
      onClose={() => setDrawerOpen(false)}
      isOpen={isOpen}
      placement="right"
    >
      <DrawerOverlay />
      <DrawerContent>{SidebarComponent}</DrawerContent>
    </Drawer>
  );
};

const BeatingHeart = () => {
  return (
    <div
      style={{
        borderRadius: 10,
        padding: "0px 10px",
        backgroundColor: colors.pink50,
        animation: "beat 2s infinite",
        display: "inline-block",
      }}
    >
      <i
        className="fa-sharp fa-heart"
        style={{
          color: colors.white,
          transformOrigin: "center",
          fontSize: 14,
          animation: "beat-icon 1s infinite",
        }}
      />
    </div>
  );
};

const NewBadge = () => {
  return (
    <div
      style={{
        borderRadius: 10,
        padding: "0px 10px",
        backgroundColor: colors.orange50,
        animation: "beat 2s infinite",
        display: "inline-block",
      }}
    >
      <i
        className="fa-sharp fa-rocket-launch"
        style={{
          color: colors.white,
          transformOrigin: "center",
          fontSize: 14,
          // animation: "beat-icon 1s infinite",
        }}
      />
    </div>
  );
};

const TelegramBadge = () => {
  const dispatch = useDispatch();

  return (
    <Box
      onClick={() => dispatch(show("TelegramModal"))}
      style={{
        marginTop: 15,
        textDecoration: "none",
        display: "block",
        padding: 5,
        cursor: "pointer",
      }}
    >
      <Box
        style={{
          borderRadius: "8px",
          width: "100%",
          background: `linear-gradient(45deg, #00a1ff 0%, #00e5ff 100%)`,
          padding: "10px 12px",
          display: "flex",
          alignItems: "center",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          transition: "transform 0.2s ease-in-out",
        }}
      >
        <i
          className="fab fa-telegram-plane"
          style={{
            fontSize: "18px",
            color: "white",
            marginRight: "10px",
          }}
        />
        <Text flex={1} color="white" fontWeight="600" fontSize="sm">
          Join our Telegram
        </Text>
        <i className="fa-sharp fa-chevron-right" style={{ color: "white" }} />
      </Box>
    </Box>
  );
};

const UnlimitedCXSection = ({
  activeSubscription,
}: {
  activeSubscription: UseActiveSubscriptionResponse;
}) => {
  const dispatch = useDispatch();
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);

  // don't show unless they have a subscription for now
  if (!activeSubscription.subscription) {
    return null;
  }

  // if (activeSubscription.hasUnlimitedCX) {
  //   return (
  //     <Box
  //       style={{
  //         marginTop: 0,
  //         width: "100%",
  //         padding: "1.75rem 0.5rem 1.75rem 0.5rem",
  //         borderTop: `1px solid ${colors.gray80}`,
  //         backgroundColor: colors.primary,
  //       }}
  //     >
  //       <Text
  //         fontSize="sm"
  //         fontWeight="semibold"
  //         color={colors.white}
  //         whiteSpace="normal"
  //       >
  //         You have unlimited customer support{" "}
  //         <i
  //           className="fa-sharp fa-sparkles"
  //           style={{ color: colors.yellow50 }}
  //         />
  //       </Text>

  //       <SmallButton
  //         // help icon
  //         marginTop="1rem"
  //         icon={
  //           // animate this to pulse with motion.div
  //           <motion.i
  //             animate={{ scale: 1 }}
  //             transition={{
  //               repeat: Infinity,
  //               repeatType: "reverse",
  //               duration: 0.5,
  //             }}
  //             style={{
  //               top: 0,
  //               position: "relative",
  //               fontSize: 16,
  //               color: colors.black,
  //             }}
  //             className="fa-sharp fa-arrow-right"
  //           />
  //         }
  //         iconPosition="right"
  //         label={"Ask us a question"}
  //         style={{
  //           color: colors.black,
  //           marginBottom: 0,
  //           border: `1px solid ${colors.lightBlue80}`,
  //         }}
  //         bg={colors.white}
  //         border={undefined}
  //         _hover={{ bg: undefined }}
  //         iconStyle={{
  //           color: colors.primary,
  //         }}
  //         labelStyle={{
  //           textAlign: "center",
  //           width: "100%",
  //           flex: 1,
  //           color: colors.black,
  //         }}
  //       />
  //     </Box>
  //   );
  // }

  return null;

  // return (
  //   <Box
  //     style={{
  //       marginTop: 0,
  //       width: "100%",
  //       padding: "2rem 0.5rem 3rem 0.5rem",
  //       borderTop: `1px solid ${colors.gray80}`,
  //       backgroundColor: colors.primary,
  //     }}
  //   >
  //     <AwakenTooltip
  //       margin="0.5rem"
  //       message="Unlock unlimited customer support help. We'll answer any questions you have on how to use the software, what to label specific transactions, figure out problems with your CSVs, and anything else you need. You'll get responses from a real person (no AI bots)."
  //     >
  //       <Text
  //         fontSize="sm"
  //         fontWeight="semibold"
  //         color={colors.white}
  //         whiteSpace="normal"
  //       >
  //         Want to purchase customer support?
  //       </Text>
  //     </AwakenTooltip>
  //     <Box marginTop="1rem">
  //       <UnlimitedCX subscription={activeSubscription.subscription} />
  //     </Box>
  //   </Box>
  // );
};

const AccountsTab = ({ clientId }: { clientId: string }) => {
  if (!clientId) return null;
  return (
    <NavigationLink
      link={`/clients/${clientId}/accounts`}
      label="Accounts"
      iconName="fa-sharp fa-wallet"
      links={[
        `/clients/${clientId}/accounts`,
        // `/clients/${clientId}/dashboard`,
        // `/clients/${clientId}`,
      ]}
    />
  );
};

const PortfolioTab = ({ clientId }: { clientId: string }) => {
  const loc = useLocation();

  if (!clientId) return null;
  return (
    <NavigationLink
      link={`/clients/${clientId}/portfolio`}
      label="Portfolio"
      isActive={loc.pathname.includes("/portfolio/")}
      iconName="fa-sharp fa-chart-pie-alt"
      links={[
        `/clients/${clientId}/portfolio`,
        `/clients/${clientId}/dashboard`,
        `/clients/${clientId}`,
      ]}
      // extraComponent={
      //   <StatusTag
      //     type="beta"
      //     label="Harvest"
      //     infoMessage="Beta: tax loss harvest before the end of the year to save on taxes."
      //     iconStyle={{ fontSize: 12 }}
      //     iconName="fa-sharp fa-carrot"
      //   />
      // }
    />
  );
};

const ImproveAwaken = () => {
  return (
    <VStack
      alignItems="flex-start"
      marginTop="0 !important"
      w="100%"
      padding="1rem"
      marginBottom="0 !important"
      borderTop={`1px solid ${colors.gray2}`}
    >
      <Text whiteSpace="normal" fontSize="sm" paddingBottom="0.2rem">
        Help us improve Awaken 🙏
      </Text>
      <FeatureRequest />
      {/* <ContactUs /> */}
    </VStack>
  );
};

const _onlyShowAccounts = (user: Maybe<BaseUserFields>) => {
  if (!user) return false;

  const isAccountant = user.role === "accountant";
  const isSuperUser = user.isSuperuser;
  const isWhiteLabel = isWhiteLabeledDomain();

  // if it isn't whitelist or the below are true they can see everything
  if (!isWhiteLabel) return false;
  if (isAccountant) return false;
  if (isSuperUser) return false;

  return true;
};

const ChecklistItem = ({
  label,
  isCompleted,
  infoMessage,
  link,
}: {
  label: string;
  isCompleted: boolean;
  infoMessage?: string;
  link: string;
}) => {
  return (
    <Link to={link} color={colors.black}>
      <HStack>
        <i
          className={
            isCompleted ? "fa-regular fa-circle-check" : "fa-regular fa-circle"
          }
          style={{
            fontSize: 20,
            color: isCompleted ? colors.green50 : colors.gray60,
          }}
        />
        <Text
          _hover={{
            textDecoration: "underline",
            color: colors.black,
          }}
          style={{ color: colors.black, fontWeight: 500, fontSize: 14 }}
          cursor="pointer"
          fontSize="sm"
        >
          {label}{" "}
          {infoMessage && (
            <Info style={{ color: colors.gray40 }} message={infoMessage} />
          )}
        </Text>
      </HStack>
    </Link>
  );
};

const FeatureRequest = () => {
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const _onClickContact = () => _showModal("FeedbackModal");

  return (
    <SmallButton
      iconName="fa-sharp fa-heart"
      label="Leave Feedback"
      onClick={_onClickContact}
    />
  );
};

const _getProgressBarClassName = (totalUsed: number, percent: number) => {
  if (totalUsed === 0) {
    return "awaken__progress_bar_blue";
  }
  if (percent > 100) {
    return "awaken__progress_bar_red";
  }
  return "awaken__progress_bar_blue";
};

const CurrentTransactions = ({
  activeSubscription,
}: {
  activeSubscription: UseActiveSubscriptionResponse;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const theme = useTheme();

  // free code doesn't count -> hacky way to give users first txns
  const hasCode = !!client?.referredByCode;
  const hasCredit =
    !!client?.availableCreditCents && client?.availableCreditCents > 0;
  const hasDiscount = client?.hasDiscountPercentage ?? false;
  const hasFreeTierMaxCeiling = !isNil(client?.freeTierMaxCeiling);

  // FIXME: hack for removing paid bar
  // if (!hasCredit) {
  //   return null;
  // }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {(hasDiscount || hasCredit || hasFreeTierMaxCeiling) && (
        <HStack>
          {hasCredit && (
            <AwakenTooltip
              maxWidth="15rem"
              message="Credit is applied automatically during checkout!"
            >
              <Box
                style={{
                  padding: "0rem 0.3rem",
                  margin: "7px 3px 0 3px",
                  borderRadius: 5,
                  // green gradient background
                  flex: 1,
                  backgroundColor: theme.secondaryBackground,
                  border: `2px solid ${theme.border}`,
                  // borderBottom: `1px solid ${colors.gray80}`,
                  color: theme.text,
                  fontWeight: "medium",
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                  }}
                >
                  {D(client?.availableCreditCents ?? 0, "USD").toFormat("$0,0")}{" "}
                  credit
                </span>
              </Box>
            </AwakenTooltip>
          )}
          {hasDiscount && (
            <AwakenTooltip
              maxWidth="15rem"
              message="This discount is applied automatically during checkout!"
            >
              <Box
                style={{
                  padding: "0rem 0.3rem",
                  margin: "7px 3px 0 3px",
                  borderRadius: 5,
                  flex: 1,
                  // green gradient background
                  backgroundColor: theme.secondaryBackground,
                  border: `2px solid ${theme.border}`,
                  // border: `1px solid ${theme.border}`,
                  // borderBottom: `1px solid ${colors.gray80}`,
                  color: theme.text,
                  fontWeight: "medium",
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                  }}
                >
                  <i
                    style={{ marginRight: 5 }}
                    className="fa-sharp fa-receipt"
                  />{" "}
                  {client?.discountPercentage}% off
                </span>
              </Box>
            </AwakenTooltip>
          )}
          {hasFreeTierMaxCeiling && (
            <AwakenTooltip
              maxWidth="15rem"
              message={`You can get a plan that has up to ${client?.freeTierMaxCeiling} transactions for free!`}
            >
              <Box
                style={{
                  padding: "0rem 0.3rem",
                  margin: "7px 3px 0 3px",
                  borderRadius: 5,
                  flex: 1,
                  // green gradient background
                  backgroundColor: theme.secondaryBackground,
                  border: `2px solid ${theme.border}`,
                  // border: `1px solid ${theme.border}`,
                  // borderBottom: `1px solid ${colors.gray80}`,
                  color: theme.text,
                  fontWeight: "medium",
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                  }}
                >
                  <i
                    style={{ marginRight: 5 }}
                    className="fa-sharp fa-exchange-alt"
                  />{" "}
                  {client?.freeTierMaxCeiling}
                </span>
              </Box>
            </AwakenTooltip>
          )}
        </HStack>
      )}

      {/* <div style={{ marginBottom: "1rem", width: "100%", textAlign: "center" }}>
        <Steps />
      </div> */}

      <div
        style={{
          width: "100%",
          padding: "0.3rem",
          paddingTop: 0,
          paddingBottom: "1.25rem",
        }}
      >
        {activeSubscription.loading ? (
          <HStack
            style={{
              padding: 15,
              paddingTop: 30,
              alignItems: "center",
            }}
          >
            <Spinner size="sm" style={{ color: theme.text }} />
            <Text fontSize="sm" style={{ color: theme.text }}>
              Loading subscription
            </Text>
          </HStack>
        ) : (
          <CurrentTaxPlan activeSubscription={activeSubscription} />
        )}
      </div>
    </div>
  );
};

const DarkModeToggle = () => {
  const { toggleDarkMode, theme } = useTheme();
  const dispatch = useDispatch();
  return (
    <div
      style={{
        marginTop: 25,
        padding: "0 15px 20px 15px",
        width: "100%",
      }}
    >
      <HStack
        style={{ marginTop: 7 }}
        onClick={() => dispatch(show("FeedbackModal"))}
        _hover={{
          cursor: "pointer",
        }}
      >
        <i
          className="fa-sharp fa-heart"
          style={{ width: 15, color: colors.gray40, fontSize: 14 }}
        />
        <Text
          style={{
            flex: 1,
            color: colors.gray40,
            fontWeight: 500,
            fontSize: 14,
          }}
          _hover={{
            textDecoration: "underline",
          }}
          fontSize="sm"
        >
          Leave Feedback
        </Text>
      </HStack>
    </div>
  );
};

const CurrentTaxPlan = ({
  activeSubscription,
}: {
  activeSubscription: UseActiveSubscriptionResponse;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const [free, setFree] = useState(isFree());

  const runConfetti = useSelector(getShowConfetti);

  const toast = useMyToast();

  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });

  const {
    totalAvailable: _totalAvailable,
    totalUsed: _totalUsed,
    hasSubscription,
    subscription,
    loading,
    discountCalculation,
  } = activeSubscription;

  const totalAvailable = _totalAvailable ?? 0;
  const totalUsed = _totalUsed ?? 0;
  const percentFilled =
    totalAvailable > 0 ? (totalUsed / totalAvailable) * 100 : 100;
  const progressBarClassName = _getProgressBarClassName(
    totalUsed,
    percentFilled
  );
  const payable = discountCalculation?.numPayableTxns ?? null;

  // hide progress for free subscriptions bc not really relevant
  const hideProgress =
    (!hasSubscription && free) ||
    (hasSubscription && subscription?.isFreeSubscription);

  const {
    background,
    text,
    header,
    theme,
    secondaryBackground,
    ternaryBackground,
  } = useTheme();

  const onSuccessFree = () => {
    _showModal("FreeSubscriptionModal");
    // toast.show({
    //   message: "You just unlocked free tax reports until March 1st!",
    //   status: "success",
    // });
  };

  // check every 5 minutes to see if still free
  useInterval(() => {
    setFree(isFree());
  }, 5 * 60 * 1000);

  const isInfinite = totalAvailable >= 1_000_000;
  const hasNotClaimedFree = client && !client.hasClaimedFreeChains;

  if (loading) {
    return (
      <Box alignItems="center" display="flex" margin="2rem 0 2rem">
        <Spinner size="sm" style={{ marginRight: 10 }} />
        <Text fontSize="sm">Loading subscription...</Text>
      </Box>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      {free && !hasSubscription && (
        <Box
          style={{
            backgroundColor: colors.gray90,
            padding: 5,
            border: `1px solid ${colors.gray70}`,
            borderRadius: 5,
            margin: "15px 0",
          }}
        >
          <Text fontSize="sm">
            Awaken is{" "}
            <b
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                textDecoration: "underline",
              }}
            >
              free
            </b>{" "}
            until March 1st!
          </Text>
        </Box>
      )}
      {!hideProgress && (
        <HStack marginTop="1rem" marginBottom="1rem">
          <Progress
            width="100%"
            value={hasSubscription ? percentFilled : 0}
            max={100}
            className={hasSubscription ? progressBarClassName : ""}
            height={2}
            borderRadius={2}
            bg={ternaryBackground}
          />
          {hasSubscription && (
            <AwakenTooltip
              message={
                percentFilled < 100
                  ? `Your subscription covers ${numbro(totalUsed).format(
                      "0,0"
                    )} transactions.`
                  : `You have more transactions than your subscription covers so you need to upgrade.`
              }
            >
              <i
                className={
                  percentFilled <= 100
                    ? "fa-sharp fa-check-circle"
                    : "fa-sharp fa-exclamation-circle"
                }
                style={{
                  color:
                    percentFilled <= 100 ? colors.positive : colors.yellow30,
                }}
              />
            </AwakenTooltip>
          )}
        </HStack>
      )}

      {hasSubscription ? (
        isInfinite ? (
          <Text
            color={text}
            marginTop="0.5rem"
            whiteSpace="normal"
            fontSize="sm"
          >
            You're subscribed to the unlimited transaction plan.
            {totalAvailable === 10_000_000 && (
              <>
                <br />
                <br />
                If you need anything, just email{" "}
                <a
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    color: colors.primary,
                  }}
                  href="mailto:andrew@awaken.tax"
                >
                  andrew@awaken.tax
                </a>
              </>
            )}
          </Text>
        ) : (
          <Text
            color={text}
            marginTop="0.5rem"
            whiteSpace="normal"
            fontSize="sm"
          >
            Used {numbro(totalUsed || 0).format("0,0")} out of{" "}
            {isInfinite ? "∞" : numbro(totalAvailable || 0).format("0,0")}{" "}
            transaction limit for {CURRENT_TAX_YEAR} ({percentFilled.toFixed(0)}
            %)
          </Text>
        )
      ) : (
        <Text
          color={text}
          marginTop="0.5rem"
          whiteSpace="normal"
          fontSize="sm"
          w="100%"
        >
          You have {numbro(totalUsed || 0).format("0,0")} transactions in{" "}
          {CURRENT_TAX_YEAR} you have to pay for.
          {/* {hasNotClaimedFree ? (
            <div
              style={{
                marginTop: 10,
                textDecoration: "underline",
                fontWeight: "bold",
                color: colors.primary,
                cursor: "pointer",
              }}
              onClick={() => dispatch(show("TweetModal"))}
            >
              UNLOCK FREE SOLANA <i className="fa-sharp fa-arrow-right"></i>
            </div>
          ) : null} */}
        </Text>
      )}

      <ReactConfetti
        style={{ zIndex: 100000 }}
        run={runConfetti}
        colors={[
          colors.yellow50,
          colors.orange50,
          colors.green50,
          colors.red50,
        ]}
        numberOfPieces={250}
        recycle={false}
      />

      {totalAvailable === 10_000_000 ? null : (
        <PurchaseButton
          onSuccessFree={onSuccessFree}
          totalAvailable={totalAvailable}
          totalUsed={totalUsed}
          hasSubscription={hasSubscription}
          subscription={subscription}
          free={free}
          client={client}
        />
      )}
    </div>
  );
};

const PurchaseButton = ({
  hasSubscription,
  free,
  onSuccessFree,
  subscription,
  client,
  totalAvailable,
  totalUsed,
}: {
  hasSubscription: boolean;
  free: boolean;
  subscription: Maybe<Subscription>;
  totalAvailable: number | null;
  totalUsed: number | null;
  onSuccessFree: () => void;
  client: Maybe<BaseClientFields>;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const [createSubscription] = useMutation<
    Pick<Mutation, "createSubscription">
  >(api.subscriptions.create);

  const freePlan = THRESHOLDS.find((t) => t.price === 0);

  const _onClaimFree = async () => {
    try {
      if (!freePlan) {
        toast.show({
          status: "error",
          message:
            "Sorry no free plan found. Contact support and we can look into this.",
        });
        return;
      }

      await createSubscription({
        variables: {
          clientId,
          txnCeiling: freePlan.max,
        },
        refetchQueries: [api.subscriptions.active, api.clients.retrieve],
      });

      toast.show({
        status: "success",
        message: `Success, you've unlocked up to ${freePlan.max} transactions for free!`,
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as ApolloError)?.message || "Error creating subscription!",
      });
    }
  };

  const toast = useMyToast();
  const isFreeSubscription =
    config.freeValidDate && subscription && subscription.isFreeSubscription;
  const theme = useTheme();
  const hasClaimedFree = client && client.hasClaimedFreeChains;

  const _unlockFreeSubscription = async () => {
    if (!clientId) return;

    onSuccessFree(); // just open the modal
    // const variables: MutationFreeSubscriptionArgs = {
    //   notes: "",
    //   clientId,
    // };

    // try {
    //   await freeSubscription({
    //     variables,
    //     refetchQueries: [api.subscriptions.active],
    //   });

    //   onSuccessFree();
    // } catch (err) {
    //   toast.show({
    //     message: (err as Error).message || "An error occurred.",
    //     status: "error",
    //   });
    // }
  };

  const _upgradePlan = async () => {
    setDrawerOpen(false);

    dispatch(show("UpgradeModal"));
  };

  const _copyLink = () => {
    if (!client) return;

    dispatch(show("ShareModal"));
  };

  const _buyTransactions = () => {
    setDrawerOpen(false);

    _showModal("CheckoutModal", { canCheckout: true });
  };

  const hasFreeSubscription =
    hasSubscription && subscription?.isFreeSubscription;

  // only show this if we are still allowing free subscriptions
  // and they have one

  if (free && hasFreeSubscription) {
    return (
      <>
        <SmallButton
          label={"Share with friends"}
          icon={
            <motion.i
              animate={{ scale: 1.1 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.5,
              }}
              style={{
                top: 0,
                position: "relative",
                fontSize: 16,
                color: colors.primary,
              }}
              className="fa-sharp fa-share"
            />
          }
          onClick={_copyLink}
          style={{
            marginTop: "1rem",
            color: colors.primary,
          }}
          bg={colors.gray80}
          border={`1px solid ${colors.gray70}`}
          _hover={{ bg: colors.gray70 }}
          labelStyle={{
            textAlign: "center",
            width: "100%",
            color: colors.black,
          }}
        />
        <AwakenTooltip
          margin="0.5rem"
          message="You will be able to download tax reports for FREE before March 1st. After that, you'll need to upgrade your subscription. Your free subscription includes unlimited wallets/exchanges and unlimited transactions!"
        >
          <HStack
            alignItems="center"
            marginTop="0.75rem"
            justifyContent="center"
          >
            <Text
              textDecor="underline"
              whiteSpace="normal"
              fontSize="xs"
              textAlign="center"
            >
              {/* party font awesome icon:  */}
              Free until{" "}
              {moment(config.freeValidDate).utc().format("MMMM Do, YYYY")}{" "}
            </Text>
            <i
              style={{
                color: colors.pink50,
                fontSize: 12,
              }}
              className="fa-sharp fa-glass-cheers"
            />
          </HStack>
        </AwakenTooltip>
      </>
    );
  }

  // otherwise if they have a non free subscription, show upgrade button
  if (hasSubscription && !subscription?.isFreeSubscription) {
    if (subscription && subscription?.transactionCeiling >= 1_000_000) {
      return null;
    }

    return (
      <>
        <SmallButton
          iconName={"fa-sharp fa-credit-card"}
          label={"Upgrade Tax Plan"}
          onClick={_upgradePlan}
          style={{
            marginTop: "1rem",
            color: undefined,
          }}
          iconStyle={{
            color: theme.text,
          }}
          bg={theme.secondaryBackground}
          border={`1px solid ${theme.border}`}
          _hover={{ bg: theme.secondaryBackground }}
          labelStyle={{
            textAlign: "center",
            width: "100%",
            color: theme.text,
            fontSize: 16,
          }}
        />
      </>
    );
  }

  if (free) {
    return (
      <SmallButton
        icon={
          // animate this to pulse with motion.div
          <motion.i
            animate={{ scale: 1.1 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.5,
            }}
            style={{
              top: 0,
              position: "relative",
              fontSize: 16,
              color: colors.white,
            }}
            className="fa-sharp fa-unlock"
          />
        }
        label={"Unlock free reports"}
        onClick={_unlockFreeSubscription}
        style={{
          marginTop: "1rem",
          color: colors.white,
        }}
        bg={colors.primary}
        border={undefined}
        _hover={{ bg: undefined }}
        labelStyle={{
          textAlign: "center",
          width: "100%",
          color: colors.white,
        }}
      />
    );
  }

  if (
    freePlan &&
    freePlan.max &&
    (totalUsed ?? 0) <= freePlan.max &&
    hasClaimedFree
  ) {
    return (
      <SmallButton
        iconName={"fa-sharp fa-gift"}
        label={"Unlock for FREE"}
        onClick={_onClaimFree}
        style={{
          marginTop: "1rem",
          color: colors.white,
        }}
        bg={`linear-gradient(45deg, ${colors.primary} 0%, ${colors.lightBlue70} 100%)`}
        border={undefined}
        _hover={{ bg: colors.lightBlue40 }}
        labelStyle={{
          textAlign: "center",
          width: "100%",
          fontSize: 16,
          color: colors.white,
        }}
      />
    );
  }

  return (
    <>
      <SmallButton
        iconName={"fa-sharp fa-unlock"}
        label={"Upgrade Tax Plan"}
        onClick={_buyTransactions}
        style={{
          marginTop: "1rem",
          color: colors.white,
        }}
        bg={`linear-gradient(45deg, ${colors.primary} 0%, ${colors.lightBlue70} 100%)`}
        border={undefined}
        _hover={{ bg: colors.lightBlue40 }}
        labelStyle={{
          textAlign: "center",
          width: "100%",
          color: colors.white,
          fontSize: 16,
        }}
      />

      {/* show an expired message if they have a free subscription */}
      {hasFreeSubscription && (
        <HStack alignItems="center" marginTop="0.5rem" justifyContent="center">
          <Text whiteSpace="normal" fontSize="xs" textAlign="center">
            {/* party font awesome icon:  */}
            Your free subscription expired.
          </Text>
        </HStack>
      )}

      <HStack
        cursor="pointer"
        onClick={() => {
          dispatch(
            show("ReferralCodeModal", {
              clientId: clientId,
            })
          );
        }}
        alignItems="center"
        marginTop="0.5rem"
        justifyContent="center"
      >
        <Text
          color={theme.text}
          whiteSpace="normal"
          fontSize="sm"
          textAlign="center"
        >
          Enter a discount code <i className="fa-sharp fa-key" />
        </Text>
      </HStack>
    </>
  );
};

const UnlimitedCX = ({
  subscription,
}: {
  subscription: Maybe<Subscription>;
}) => {
  const dispatch = useDispatch();
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);

  const _upgradeModal = () => {
    setDrawerOpen(false);
    dispatch(show("UpgradeInfoModal"));
  };

  // you need a subscription to upgrade
  if (!subscription) {
    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <SmallButton
        // help icon

        icon={
          // animate this to pulse with motion.div
          <motion.i
            animate={{ scale: 1.2 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.5,
            }}
            style={{
              top: 0,
              position: "relative",
              fontSize: 16,
              color: colors.primary,
            }}
            className="fa-sharp fa-square-up"
          />
        }
        label={"Upgrade Support"}
        onClick={_upgradeModal}
        style={{
          color: colors.black,
          marginBottom: 0,
          border: `1px solid ${colors.lightBlue80}`,
        }}
        bg={colors.white}
        border={undefined}
        _hover={{ bg: undefined }}
        iconStyle={{
          color: colors.primary,
        }}
        labelStyle={{
          textAlign: "center",
          width: "100%",
          color: colors.black,
        }}
      />
    </div>
  );
};

const ContactUs = () => {
  return (
    <a
      href="mailto:team@awaken.tax"
      rel="noreferrer"
      target={isMobile ? undefined : "_blank"}
      style={{
        color: colors.black,
        width: "100%",
      }}
    >
      <SmallButton iconName="fa-sharp fa-envelope" label="Contact Us" />
    </a>
  );
};

const SmallButton = (props: TouchableProps) => {
  return (
    <Touchable
      style={{
        position: "relative",
        cursor: "pointer",
        display: "inline-block",
        width: "100%",
        padding: "0.75rem 1rem",
        ...props.style,
      }}
      iconName={props.iconName}
      iconPosition="left"
      iconStyle={{
        color: props.style?.color,
        fontSize: 18,
      }}
      bg={colors.gray90}
      fontSize="md"
      _hover={{ bg: colors.gray80 }}
      onClick={props.onClick}
      // labelStyle={{ fontSize: 14 }}
      // iconName="fa-sharp fa-plus"
      // iconPosition="left"
      label={props.label}
      {...omit(props, ["style"])}
    />
  );
};

const SocialButtons = () => {
  return (
    <HStack
      margin="0 !important"
      w="100%"
      alignItems="center"
      justifyContent="center"
      padding="0"
      // paddingBottom="1rem"
      bg={colors.white}
    >
      {/*<Box>
        <ChakraLink
          display="block"
          rel="noreferrer"
          target={isMobile ? undefined : "_blank"}
          href={config.discordLink}
          style={{
            margin: "0.5rem",
          }}
        >
          <i
            style={{
              color: colors.gray30,
              fontSize: 15,
            }}
            className="fab fa-discord"
          ></i>
        </ChakraLink>
          </Box>*/}

      <Box>
        <ChakraLink
          display="block"
          rel="noreferrer"
          target={isMobile ? undefined : "_blank"}
          href={config.twitterLink}
          style={{
            margin: "0.5rem",
          }}
        >
          <i
            style={{
              color: colors.gray30,
              fontSize: 15,
            }}
            className="fab fa-twitter"
          ></i>
        </ChakraLink>
      </Box>

      <Box>
        <ChakraLink
          display="block"
          rel="noreferrer"
          target={isMobile ? undefined : "_blank"}
          href="mailto:team@awaken.tax"
          style={{
            margin: "0.5rem",
          }}
        >
          <i
            style={{
              color: colors.gray30,
              fontSize: 15,
            }}
            className="fa-sharp fa-envelope"
          ></i>
        </ChakraLink>
      </Box>
    </HStack>
  );
};

const VIPSection = ({ client }: { client: Maybe<BaseClientFields> }) => {
  const dispatch = useDispatch();

  const { data } = useQuery<Pick<Query, "getClientBookkeepingQuotes">>(
    api.bookkeepingQuotes.listForClient,
    {
      variables: {
        clientId: client?.id || "",
      },
    }
  );

  const quotes = data?.getClientBookkeepingQuotes ?? [];

  const newestQuote = useMemo(() => {
    return maxBy(
      quotes.filter((q) => !!q.requestYear),
      (q) => q.requestYear
    );
  }, [quotes]);

  const hasVIP = useMemo(
    () => !!newestQuote && newestQuote?.hasPaid,
    [newestQuote]
  );

  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  const _openModal = () => {
    dispatch(show("VIPQuoteRequestModal"));
  };

  if (hasVIP) {
    return (
      <div
        style={{
          width: "100%",
          marginBottom: 0,
          cursor: "pointer",
          margin: isLarge ? "0 auto" : "0 auto",
        }}
      >
        <div
          style={{
            // borderRadius: 10,
            // gold background gradient
            background: `linear-gradient(45deg, #FABC3C 0%, #FACC6B 50%, #F0AC6B 100%)`,
            // border: `1px solid ${colors.primary}`,
            padding: 12,
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <div style={{ whiteSpace: "nowrap", width: "100%" }}>
            <HStack
              style={{
                marginBottom: 5,
                alignItems: "center",
              }}
            >
              <Text
                color={colors.gray20}
                fontWeight="bold"
                fontSize="md"
                style={{
                  flex: 1,
                  fontStretch: "extra-expanded",
                }}
              >
                Subscribed to VIP
              </Text>
              <i style={{ fontSize: 18 }} className="fas fa-circle-check" />
            </HStack>
            <Text
              fontSize="sm"
              color={colors.gray20}
              style={{ marginBottom: 5 }}
              // make sure this wraps next line
              whiteSpace="normal"
            >
              You're subscribed to VIP service.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  if (newestQuote) {
    // link to the quotes page
    return (
      <Link style={{ width: "100%" }} to={`/clients/${client?.id}/quotes`}>
        <div
          style={{
            width: "100%",
            marginBottom: 0,
            cursor: "pointer",
            margin: isLarge ? "0 auto" : "0 auto",
          }}
        >
          <div
            style={{
              // borderRadius: 10,
              // gold background gradient
              background:
                theme.theme === "light"
                  ? `linear-gradient(45deg, ${colors.green90} 0%, ${colors.green90} 50%, ${colors.lightBlue90} 100%)`
                  : `linear-gradient(45deg, ${colors.green05} 0%, ${colors.green05} 50%, ${colors.lightBlue10} 100%)`,

              // border: `1px solid ${colors.primary}`,
              padding: 12,
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div style={{ whiteSpace: "nowrap", width: "100%" }}>
              <HStack style={{ marginBottom: 5, alignItems: "center" }}>
                <Text
                  color={theme.theme === "light" ? colors.gray20 : colors.white}
                  fontWeight="bold"
                  fontSize="md"
                  style={{
                    flex: 1,
                    fontStretch: "extra-expanded",
                  }}
                >
                  VIP In-progress <i style={{ marginLeft: 10 }} />
                </Text>
                <i
                  style={{
                    fontSize: 18,
                    color:
                      theme.theme === "light" ? colors.gray20 : colors.white,
                  }}
                  className="fas fa-hourglass-half"
                />
              </HStack>
              <Text
                fontSize="sm"
                color={theme.theme === "light" ? colors.gray20 : colors.white}
                style={{ marginBottom: 5 }}
                // make sure this wraps next line
                whiteSpace="normal"
              >
                We'll get back to you shortly.
              </Text>

              <Text
                style={{
                  fontWeight: "bold",
                  color: theme.theme === "light" ? colors.gray20 : colors.white,
                }}
                fontSize="sm"
              >
                See details <i className="fa-sharp fa-arrow-right" />
              </Text>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 0,
        cursor: "pointer",
        margin: isLarge ? "0 auto" : "0 auto",
      }}
      onClick={_openModal}
    >
      <div
        style={{
          // borderRadius: 10,
          // gold background gradient
          background:
            theme.theme === "light"
              ? `linear-gradient(45deg, ${colors.lightBlue80} 0%, ${colors.lightBlue80} 50%, ${colors.yellow90} 100%)`
              : `linear-gradient(45deg, ${colors.lightBlue10} 0%, ${colors.lightBlue10} 50%, ${colors.green10} 100%)`,
          // border: `1px solid ${colors.primary}`,
          padding: 12,
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <div style={{ whiteSpace: "nowrap", width: "100%" }}>
          <HStack
            style={{
              marginBottom: 5,
            }}
          >
            <Text
              color={theme.theme === "light" ? colors.gray20 : colors.white}
              fontWeight="bold"
              fontSize="md"
              style={{
                flex: 1,
                fontStretch: "extra-expanded",
              }}
            >
              VIP Service
            </Text>
            <i
              style={{
                fontSize: 18,
                color: theme.theme === "light" ? colors.gray20 : colors.white,
              }}
              className="fas fa-whale"
            />
          </HStack>
          <Text
            fontSize="sm"
            color={theme.theme === "light" ? colors.gray20 : colors.white}
            style={{ marginBottom: 5 }}
            // make sure this wraps next line
            whiteSpace="normal"
          >
            Year-round bookkeeping. Starting at $100/mo.
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: theme.theme === "light" ? colors.gray20 : colors.white,
            }}
            fontSize="sm"
          >
            Apply now <i className="fa-sharp fa-arrow-right" />
          </Text>
        </div>
      </div>
    </div>
  );
};

const RecalculatingSpinner = () => {
  const [etaMessage, setEtaMessage] = useState<string | null>("");
  const { clientId } = useParams();
  const apolloClient = useApolloClient();
  const theme = useTheme();
  const { client, refetchClient } = useClientById(clientId, {
    clientFetchPolicy: "network-only",
    onlyFetchClient: true,
  });

  const _getEtaMessage = () => {
    const recalculateEta = client?.recalculateEta;
    const message = getEtaMessage(null, recalculateEta || null);
    return message ? `(${message})` : null;
  };

  useInterval(() => {
    const message = _getEtaMessage();
    setEtaMessage(message ?? null);
  }, 1000);

  // check for a new ETA for the client's ETA
  useEffect(() => {
    let timeout: Maybe<NodeJS.Timeout> = null;

    if (client?.status === ClientStatusEnum.Recalculating) {
      console.log("[refetching]");
      // Use a single interval that handles both cases
      timeout = setInterval(
        async () => {
          void refetchClient();
        },
        client.recalculateEta ? 15000 : 3000
      ); // More frequent checks if no ETA
    }

    return () => {
      if (timeout) {
        console.log("[refetching] Clearing interval");
        clearInterval(timeout);
      }
    };
  }, [client?.status, client?.recalculateEta]);

  const firstEta = useMemo(() => {
    // remove parentheses, split by , and take the first
    const message = etaMessage?.replace(/\(|\)/g, "").split(",")[0];
    return message;
  }, [etaMessage]);

  if (!client?.status || client.status !== ClientStatusEnum.Recalculating) {
    return null;
  }

  return (
    <AwakenTooltip
      message={`Recalculating...${etaMessage ? " " + etaMessage : ""}`}
    >
      <div
        style={{
          display: "flex",
          padding: "5px",
          borderRadius: 100,
          background: colors.primary,
          fontSize: 10,
          fontWeight: "600",
          color: colors.white,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Spinner
          size="xs"
          style={{ marginRight: firstEta ? 5 : 0 }}
          color={colors.white}
        />
        {firstEta ? <>{firstEta}&nbsp;</> : ""}
      </div>
    </AwakenTooltip>
  );
};
