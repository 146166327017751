// ... existing code ...

import { useMutation } from "@apollo/client";
import { Box, HStack, Switch, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { Mutation } from "src/api/generated/types";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

export const PrioritySelector = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { client, refetchClient } = useClientById(clientId);
  const toast = useMyToast();
  const theme = useTheme();

  const [updateClient] = useMutation<Pick<Mutation, "updateClient">>(
    api.clients.update,
    {
      onCompleted: () => {
        refetchClient();
      },
      onError: (error) => {
        toast.show({
          status: "error",
          message: error.message || "Failed to update priority mode",
        });
      },
    }
  );

  const togglePriority = async () => {
    if (!client) return;

    await updateClient({
      variables: {
        clientId,
        canUsePriority: !client.canUsePriority,
      },
    });

    toast.show({
      status: "success",
      message: `Priority mode ${
        client?.canUsePriority ? "disabled" : "enabled"
      }`,
    });
  };

  return (
    <Box cursor="pointer" padding="0.75rem 1.2rem" width="100%">
      <HStack spacing={3}>
        <Text flex={1} fontSize="sm" color={theme.text}>
          Priority Mode
        </Text>

        <Switch
          colorScheme="green"
          isChecked={client?.canUsePriority || false}
          onChange={togglePriority}
        />
      </HStack>
    </Box>
  );
};
