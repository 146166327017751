import { HStack } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { CurrencyCodeEnum, IsDirtyEnum } from "src/api/generated/types";
import { StatusTagType } from "src/components/styled/StatusTag";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { formatNum, getCurrencySymbol } from "src/utils/helpers";
import { AwakenTooltip } from "../AwakenTooltip";

type PriorityInfo = {
  iconName: string;
  type: StatusTagType;
  message: string;
  infoMessage: string;
};

const INFO_FOR_PRIORITY: Record<number, PriorityInfo> = {
  3: {
    iconName: "fa-sharp fa-sack-dollar",
    type: "error",
    message: "Large",
    infoMessage: "You earned or lost a lot of money in this transaction.",
  },
  2: {
    iconName: "fa-sharp fa-coins",
    type: "warning",
    message: "Medium",
    infoMessage:
      "You earned or lost a decent amount of money in this transaction.",
  },
  1: {
    iconName: "fa-sharp fa-coin",
    type: "brown",
    message: "Small",
    infoMessage:
      "You earned or lost a small amount of money in this transaction.",
  },
  0: {
    iconName: "fa-sharp fa-question",
    type: "info",
    message: "Unknown",
    infoMessage:
      "Awaken is unsure how much money or earned or lost in this transaction.",
  },
};

const TOO_BIG_NUMBER = 1_000_000_000;

export const CapGainsTag = ({
  capPriority,
  capGainsSum,
  currency,
  isDirty,
}: {
  capPriority: Maybe<number>;
  capGainsSum: any;
  currency: CurrencyCodeEnum;
  isDirty?: Maybe<IsDirtyEnum>;
}): JSX.Element | null => {
  const info = INFO_FOR_PRIORITY[capPriority ?? 0];
  const symbol = getCurrencySymbol(currency);
  const num = new BigNumber(capGainsSum ?? 0);
  const { text } = useTheme();

  if (num.isNaN() || num.eq(0)) {
    return null;
  }

  // cap gains sum if we have one
  const dollars = Number(capGainsSum) / 100;
  const isNeg = dollars < 0;
  const cashAmount = formatNum(Math.abs(dollars), false);
  const color = isNeg ? colors.negative : colors.positive;
  const isTooBig = dollars > TOO_BIG_NUMBER;
  const uptodate = !isDirty || isDirty === IsDirtyEnum.Clean;

  if (new BigNumber(dollars).dp(2).isZero()) {
    return null;
  }

  return (
    <AwakenTooltip
      message={
        isTooBig
          ? "This transaction has an abnormally large gain/loss. We've been alerted about it. You can always email team@awaken.tax and we'd be happy to take a closer look. Thank you!"
          : uptodate
          ? `This transaction's overall ${isNeg ? "loss" : "gain"}.`
          : "This transaction's gains/losses is outdated. Please run 'Recalculate' to see the up-to-date version"
      }
    >
      <HStack opacity={isTooBig ? 0.5 : uptodate ? 1 : 0.5}>
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 15,
            color,
            marginRight: 0,
          }}
        />
        <span
          style={{
            color,
            fontWeight: "700",
            textDecoration: isTooBig
              ? "line-through"
              : uptodate
              ? undefined
              : "line-through",
            // fontSize: 16,
          }}
        >
          {isNeg ? "-" : ""}
          {symbol}
          {cashAmount}
        </span>
      </HStack>
    </AwakenTooltip>
  );

  // if (info) {
  //   return (
  //     <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
  //       <Tooltip
  //         zIndex={1401}
  //         trigger={isMobile ? "click" : "hover"}
  //         bg={colors.black}
  //         placement="top"
  //         borderRadius="0.25rem"
  //         label={info.infoMessage}
  //         padding={{ base: "0.5rem 1rem" }}
  //       >
  //         <HStack>
  //           <i className={info.iconName} style={{ fontSize: "11px" }}></i>
  //           <span>{info.message}</span>
  //           {/* <StatusTag <- too colorful
  //         iconName={info.iconName}
  //         type={info.type}
  //         label={info.message}
  //         infoMessage={info.infoMessage}
  //       ></StatusTag> */}
  //         </HStack>
  //       </Tooltip>
  //     </motion.div>
  //   );
  // }
};
