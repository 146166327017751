import BigNumber from "bignumber.js";
import { Currency } from "dinero.js";
import { isNil } from "lodash/fp";
import { useMemo } from "react";
import {
  BaseAssetFields,
  BaseFullTransactionFields,
  BaseRawTransactionAssetFields,
  PartialAssetFields,
} from "src/api/fragments";
import { AssetTypeEnum, CurrencyCodeEnum } from "src/api/generated/types";
import { DEFAULT_DECIMALS } from "src/config";
import { Maybe } from "src/core";
import {
  formatAutoReviewReason,
  formatRuleUsed,
} from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { toLuxonUTC } from "src/utils";
import { D } from "src/utils/helpers";
import { TransferType } from "./Transfers/TransferForm/utils";

export const useCreatedAtUTC = (
  transaction: Maybe<Pick<BaseFullTransactionFields, "createdAt">>,
  timezone = "UTC"
) => {
  return useMemo(
    () =>
      transaction?.createdAt
        ? toLuxonUTC(new Date(transaction?.createdAt)).setZone(timezone)
        : undefined,
    [transaction]
  );
};

export const getColor = (isInternal: boolean, type: Maybe<TransferType>) => {
  if (isInternal) {
    return {
      bg: colors.gray100,
      color: colors.gray20,
      borderColor: colors.gray80,
    };
  }
  return type === "sent"
    ? {
        bg: colors.red100,
        color: colors.red20,
        borderColor: colors.red90,
      }
    : {
        bg: colors.green100,
        color: colors.green20,
        borderColor: colors.green90,
      };
};

// if you pass the asset it appends the symbol to the name in some cases
export const formatCryptoAmount = (
  amount?: Maybe<number>,
  asset?: Maybe<PartialAssetFields>
) => {
  try {
    if (isNil(amount)) {
      return amount || "";
    }

    if (asset?.type === AssetTypeEnum.FiatCurrency) {
      const symbol = (asset.symbol || "USD") as Currency;

      // check if it's actually a valid currency code
      const values = Object.values(CurrencyCodeEnum).map((v) =>
        v.toUpperCase()
      );
      const isValidCurrencyCode = values.includes(symbol as any);

      if (!isValidCurrencyCode) {
        return amount || "";
      }

      // this isn't in cents so have to convert it
      return D(amount * 100, symbol).toFormat();
    }

    return new BigNumber(amount).dp(DEFAULT_DECIMALS).toFormat();
  } catch (e) {
    console.error(e);
    return amount || "";
  }
};

export const getTransformedRawAsset = (
  rawAsset?: Maybe<BaseRawTransactionAssetFields>
): Maybe<
  Pick<
    BaseAssetFields,
    "type" | "id" | "iconImageUrl" | "imageUrl" | "symbol" | "tokenId"
  >
> =>
  rawAsset
    ? {
        iconImageUrl: rawAsset.imageUri,
        imageUrl: rawAsset.imageUri,
        symbol: rawAsset.symbol,
        type: rawAsset.category as AssetTypeEnum,
        id: rawAsset.identifier,
        tokenId: rawAsset.tokenId,
      }
    : null;

export const getLabelRuleOrAutoReview = (
  transaction: Maybe<{
    labelUsed?: Maybe<any>;
    ruleUsed?: Maybe<{ type?: string }>;
    autoReviewReason?: Maybe<string>;
  }>
): string | null => {
  if (!transaction) return null;

  // Priority: label > rule > autoreview
  if (transaction.labelUsed) {
    return transaction.labelUsed;
  }

  if (transaction.ruleUsed) {
    const ruleType = transaction.ruleUsed.type || "";
    return formatRuleUsed(ruleType);
  }

  if (transaction.autoReviewReason) {
    return formatAutoReviewReason(transaction.autoReviewReason);
  }

  return null;
};
