import { useLazyQuery } from "@apollo/client";
import { Box, HStack } from "@chakra-ui/react";
import { useState } from "react";
import { api } from "src/api/api";
import { ProfilePicture } from "src/components/Sidebar/ClientSelector";
import { Button, Input } from "src/components/styled";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

export function SearchUser() {
  const [email, setEmail] = useState("");
  const [search, { data, loading }] = useLazyQuery(
    api.users.getClientsForEmail
  );
  const {
    text,
    header,
    background,
    medBackground,
    border,
    secondaryBackground,
  } = useTheme();

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      search({ variables: { email } });
    }
  };

  return (
    <Box minH="100vh" className="p-4" style={{ color: text, background }}>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          paddingTop: "100px",
        }}
      >
        <Box
          p={8}
          borderRadius={25}
          style={{
            backgroundColor: medBackground,
            maxWidth: "600px",
            border: `1px solid ${border}`,
            width: "100%",
          }}
        >
          <h1
            className="text-3xl font-bold mb-8 text-center"
            style={{
              color: header,
            }}
          >
            Search Users
          </h1>

          <form onSubmit={handleSearch} className="mb-8">
            <HStack maxWidth={800} w="100%" spacing={4}>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
                className="flex-1"
                containerStyle={{ flex: 1, marginBottom: 0 }}
                height="50px"
                fontSize="lg"
              />
              <Button
                type="submit"
                disabled={loading}
                isLoading={loading}
                style={{
                  background: colors.primary,
                }}
              >
                Search
              </Button>
            </HStack>
          </form>

          {data?.getClientsForEmail && (
            <div className="space-y-4">
              <h2
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  color: text,
                }}
              >
                Found {data.getClientsForEmail.length} clients
              </h2>

              {data.getClientsForEmail.map((permission: any) => (
                <a
                  target="_blank"
                  href={`${window.location.origin}/clients/${permission.client.id}`}
                  className="block"
                >
                  <div
                    key={permission.id}
                    className="border rounded-lg hover:shadow-lg transition-shadow"
                    style={{
                      padding: 10,
                      borderColor: border,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      color: text,
                      backgroundColor: secondaryBackground,
                    }}
                  >
                    {permission.client && (
                      <ProfilePicture client={permission.client} />
                    )}

                    <div style={{ flex: 1, margin: "0 10px" }}>
                      <div
                        style={{
                          color: text,
                          fontSize: 18,
                        }}
                        className="font-medium"
                      >
                        {permission.client.name}
                      </div>

                      <div
                        style={{ color: text, marginTop: 5 }}
                        className="text-sm"
                      >
                        ID: {permission.client.id}
                      </div>
                    </div>

                    <i className="fa-solid fa-chevron-right" />
                  </div>
                </a>
              ))}
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
}
