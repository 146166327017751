import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { Dictionary } from "lodash";
import { BaseTransactionFullFields } from "src/api";
import { BaseClientFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { ActiveState, ReduxState } from "../types";

// initial state
const initialState: ActiveState = {
  client: null,
  highlightedTransactionId: null,
  drawerIsOpen: false,
  prepopulatedTxns: {},
};

// actions

export const setActiveClient =
  createAction<Maybe<BaseClientFields>>("SET_ACTIVE_CLIENT");

export const setHighlightedTransactionId = createAction<Maybe<string>>(
  "SET_HIGHLIGHTED_TRANSACTION_ID"
);

export const setDrawerIsOpen = createAction<boolean>("SET_DRAWER_IS_OPEN");

export const setPrepopulatedTxns = createAction<
  Dictionary<BaseTransactionFullFields>
>("SET_PREPOPULATED_TXNS");

export const removePrepopulatedTxn = createAction<string>(
  "REMOVE_PREPOPULATED_TXN"
);

export const overridePrepopulatedTxn = createAction<{
  txnId: string;
  txn: BaseTransactionFullFields;
}>("OVERRIDE_PREPOPULATED_TXN");

// reducer
export const activeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveClient, (state, action) => {
      state.client = action.payload;
    })
    .addCase(setHighlightedTransactionId, (state, action) => {
      state.highlightedTransactionId = action.payload;
    })
    .addCase(setDrawerIsOpen, (state, action) => {
      state.drawerIsOpen = action.payload;
    })
    .addCase(setPrepopulatedTxns, (state, action) => {
      state.prepopulatedTxns = action.payload;
    })
    .addCase(removePrepopulatedTxn, (state, action) => {
      delete state.prepopulatedTxns[action.payload];
    })
    .addCase(overridePrepopulatedTxn, (state, action) => {
      state.prepopulatedTxns[action.payload.txnId] = action.payload.txn;
    });
});

// selectors
export const getActive = (state: ReduxState): ActiveState => state.active;

export const getActiveClient = createSelector(
  getActive,
  (active) => active.client
);

export const getActiveHighlightedTransactionId = createSelector(
  getActive,
  (active) => active.highlightedTransactionId
);

export const getDrawerIsOpen = createSelector(
  getActive,
  (active) => active.drawerIsOpen
);

export const getPrepopulatedTxns = createSelector(
  getActive,
  (active) => active.prepopulatedTxns
);
