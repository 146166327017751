import { Box, ButtonGroup, Heading, Text } from "@chakra-ui/react";
import { useMemo } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import {
  GetReferralCommissionsResponse,
  Mutation,
  MutationUpdateClientArgs,
  QueryGetReferralCommissionsArgs,
  ReferralCommission,
} from "src/api/generated/types";
import { OrgMemberModal } from "src/components/modals/OrgMemberModal";
import { Button, Info } from "src/components/styled";
import { Maybe } from "src/core";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { colors } from "src/theme";

import { isNil, isNumber } from "lodash";
import moment from "moment";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { BaseClientFields } from "src/api/fragments";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { trackEvent } from "src/utils/analytics";
import { D } from "src/utils/helpers";

function Referrals() {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const [updateClient] = useMutation(api.clients.update);
  const { client } = useClientById(clientId || "", {
    onlyFetchClient: true,
  });

  const isLarge = useIsLargeScreen();
  const toast = useMyToast();

  const variables = useMemo(
    (): QueryGetReferralCommissionsArgs => ({
      clientId: client?.id || "",
    }),
    [client?.id]
  );

  const { header, greenBg, theme, text, medBackground } = useTheme();

  const { data: commissionsData } = useQuery<{
    getReferralCommissions: Maybe<GetReferralCommissionsResponse>;
  }>(api.referrals.getReferralCommissions, {
    skip: !variables.clientId,
    variables: variables,
  });

  const _setPaypal = async () => {
    if (!client) return;

    // prompt them
    const username = prompt(
      "Enter your PayPal username. Crypto coming soon 👀"
    );

    if (!username) {
      toast.show({
        message: "No PayPal username entered.",
        status: "error",
      });
      return;
    }

    const variables: MutationUpdateClientArgs = {
      clientId: client.id,
      paypalUsername: username,
    };

    try {
      await updateClient({
        variables: variables,
        refetchQueries: [api.clients.retrieve, api.users.clients.getActive],
      });

      toast.show({
        message: "PayPal username updated.",
        status: "success",
      });
    } catch (err) {
      //
      toast.show({
        message: "Error updating PayPal username.",
        status: "error",
      });
    }
  };

  const paypalUsername = client?.paypalUsername ?? null;
  const dispatch = useDispatch();

  // Filter Modal
  return (
    <div
      style={{
        paddingTop: "1rem",
      }}
    >
      <OrgMemberModal />

      {/* {!paypalUsername && (
        <div
          style={{
            padding: "25px 15px",
            backgroundColor: colors.lightBlue100,
            borderRadius: 10,
          }}
        >
          <Text>
            Awaken sends payouts over PayPal (crypto coming soon). Please add
            your PayPal username.{" "}
          </Text>
          <Button
            style={{ marginTop: 15 }}
            variant="primary"
            onClick={_setPaypal}
          >
            Add PayPal username
          </Button>
        </div>
      )} */}

      {/* <FreeCreditBadge /> */}

      <ButtonGroup
        w="100%"
        display="flex"
        marginTop={0}
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Heading
          style={{
            fontStretch: "extra-expanded",
            fontWeight: "bold",
          }}
          marginTop={"1rem"}
          color={header}
          size="lg"
        >
          Referral Hub
        </Heading>
        <Box />
      </ButtonGroup>

      {/* <Warning
        message={
          <>
            Are you an influencer? Contact us at{" "}
            <a
              href="mailto:team@awaken.tax"
              style={{
                fontWeight: "bold",
                color: colors.primary,
                textDecoration: "underline",
              }}
            >
              team@awaken.tax
            </a>{" "}
            for a special offer.
          </>
        }
        color={colors.primary}
        fontWeight="500"
        border={`1px solid ${colors.primary}`}
        bg={theme === "dark" ? colors.lightBlue10 : colors.lightBlue100}
        maxW={"40rem"}
        w="100%"
        iconStyle={{ color: colors.primary }}
        iconName="fa-sharp fa-bullhorn"
        marginBottom="2rem"
        marginTop="1rem"
      /> */}

      {/* <Text style={{ color: text }} fontSize="lg" marginBottom="1rem">
        Share your referral link with friends! They'll earn $25 of Awaken
        credit, and you'll earn {client?.referralPercentage}% of their purchase
        in cash{" "}
        <Info message="Ex. if they spend $299 on a tax report, you'll make $45." />
      </Text> */}

      {/* <ListItem
        header="Community Codes"
        onClick={() => {
          dispatch(show("CommunityCodeModal"));
        }}
        backgroundColor={colors.purple50}
        subtitle={
          <>
            Part of a Discord, TG, or other community? Get a large discount code
            to share with everyone. WAGMI.
          </>
        }
        iconName="fa-sharp fa-users"
        buttonText="Apply for code"
      /> */}

      <div
        style={{
          flexDirection: isLarge ? "row" : "column",
          display: "flex",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            flex: 1,
            width: "100%",
            marginTop: isLarge ? 0 : 15,
          }}
        >
          <ShareReferral client={client} />
        </div>

        <div
          style={{
            flex: 1,
            marginLeft: isLarge ? 15 : 0,
            maxWidth: 550,
            flexDirection: isLarge ? "column" : "row",
            // add flex spacing
          }}
        >
          <ReferralEarnings
            client={client}
            commissionData={commissionsData?.getReferralCommissions ?? null}
          />

          <ReferralCommissions
            commissions={
              commissionsData?.getReferralCommissions?.commissions ?? []
            }
            numberOfReferrals={
              commissionsData?.getReferralCommissions?.referrals?.length ?? 0
            }
            client={client}
          />
        </div>
      </div>
    </div>
  );
}

const ReferralEarnings = ({
  client,
  commissionData,
}: {
  client: Maybe<BaseClientFields>;
  commissionData: Maybe<GetReferralCommissionsResponse>;
}) => {
  const paypalUsername = client?.paypalUsername ?? null;
  const isLarge = useIsLargeScreen();
  const toast = useMyToast();
  const [updateClient] = useMutation(api.clients.update);
  const [requestPayout] = useMutation(api.referrals.requestPayout);
  const { header, secondaryBackground, text, medBackground, border } =
    useTheme();

  const pending = commissionData?.totalPendingCents ?? null;
  const hasPending = !isNil(pending) && pending > 0;

  const _claimRewards = async () => {
    if (!client) return;

    try {
      toast.show({
        message:
          "We will send all referral payments on April 16th (day after tax day). If you need your payout sooner, just email team@awaken.tax and we'll get the PayPal sent to you right away!",
        status: "success",
      });

      await requestPayout({
        variables: {
          clientId: client.id,
        },
      });
    } catch (err) {
      // alert error
      toast.show({
        message: "Error requesting payout.",
        status: "error",
      });
    }
  };

  const _setPaypal = async () => {
    if (!client) return;

    // prompt them
    const username = prompt(
      "Enter your PayPal username. Crypto coming soon 👀"
    );

    if (!username) {
      toast.show({
        message: "No PayPal username entered.",
        status: "error",
      });
      return;
    }

    const variables: MutationUpdateClientArgs = {
      clientId: client.id,
      paypalUsername: username,
    };

    try {
      await updateClient({
        variables: variables,
        refetchQueries: [api.clients.retrieve, api.users.clients.getActive],
      });

      toast.show({
        message: "PayPal username updated.",
        status: "success",
      });
    } catch (err) {
      //
      toast.show({
        message: "Error updating PayPal username.",
        status: "error",
      });
    }
  };

  return (
    <Box
      style={{
        marginBottom: 15,
        border: "1px solid " + border,
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          padding: 25,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flex: 1 }}>
          <Text color={header}>Available</Text>
          <Text color={header} fontSize="2xl" fontWeight="bold">
            {commissionData?.totalAvailableCents
              ? D(commissionData?.totalAvailableCents, "USD").toFormat()
              : "$0.00"}
          </Text>

          <Button
            disabled={!commissionData?.totalAvailableCents}
            style={{
              marginTop: 10,
              height: 40,
              paddingInlineStart: 0,
              paddingInlineEnd: 0,
            }}
            padding="0 0.5rem !important"
            size="sm"
            variant="primary"
            onClick={_claimRewards}
          >
            Claim Rewards
          </Button>

          {/* <div style={{ marginTop: 5 }}>
            <AwakenTooltip message="This will automatically be paid to your PayPal account on the 1st of the month.">
              <Text style={{ color: colors.gray30 }} fontSize="sm">
                <i className="fa-sharp fa-info-circle" style={{ marginRight: 5 }} />{" "}
                Payout on {nextMonth} 1st
              </Text>
            </AwakenTooltip>
          </div> */}
        </div>

        <div style={{ flex: 1 }}>
          <Text color={header}>Total Earned</Text>
          <Text color={header} fontSize="2xl" fontWeight="bold">
            {commissionData?.totalPaidCents
              ? D(commissionData?.totalPaidCents, "USD").toFormat()
              : "$0.00"}
          </Text>
        </div>
      </div>

      <div
        style={{
          padding: 15,
          backgroundColor: secondaryBackground,
          textAlign: "center",
          borderTop: "1px solid " + border,
        }}
      >
        <Text
          style={{
            color: text,
          }}
        >
          Payouts made to your PayPal account:{" "}
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: colors.primary,
              fontWeight: "bold",
            }}
            onClick={_setPaypal}
          >
            {paypalUsername ? "@" + paypalUsername : "click to add PayPal"}
          </span>{" "}
          <Info message="Crypto payouts coming soon 👀" />.
        </Text>

        {hasPending && (
          <Text
            style={{
              marginTop: 15,
              color: text,
            }}
          >
            <b>{D(pending, "USD").toFormat()}</b> is on its way to your PayPal{" "}
            <i className="fab fa-paypal" />. It may take a day or two.
          </Text>
        )}
      </div>
    </Box>
  );
};

const ShareReferral = ({ client }: { client: Maybe<BaseClientFields> }) => {
  const value = `https://awaken.tax?ref=${client?.referralCode}`;
  const toast = useMyToast();
  const {
    header,
    medBackground,
    border,
    background,
    secondaryBackground,
    text,
  } = useTheme();
  const { me } = useMe();
  const [updateClient] = useMutation(api.clients.update);
  const [updateClientReferralCode] = useMutation(
    api.referrals.updateReferralCode
  );

  const dispatch = useDispatch();

  const [uploadFile, { loading }] = useMutation<Pick<Mutation, "uploadFile">>(
    api.uploadFile
  );

  const _copyReferralLink = () => {
    if (!client) return;

    trackEvent("Copied Referral Link");

    navigator.clipboard.writeText(value);
    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });
  };

  const _shareToTwitter = () => {
    if (!client) return;

    trackEvent("Share to Twitter Clicked");

    const referralLink = `https://twitter.com/intent/tweet?text=Join me on Awaken and get $20 of free credit! ${value}`;

    window.open(referralLink, "_blank");
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    if (!client || acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];

    // Assuming you have a function to upload to S3/storage and get URL
    // You'll need to implement or use your existing file upload utility
    try {
      // Do something with the files
      const tenYearsInSeconds = 315360000;

      const result = await uploadFile({
        variables: {
          file,
          clientId: client.id,
          expiresInSeconds: tenYearsInSeconds,
        },
      });

      const avatarUrl = result.data?.uploadFile.url || "";

      await updateClient({
        variables: {
          clientId: client.id,
          avatarImageUrl: avatarUrl,
        },
        refetchQueries: [api.clients.retrieve],
      });

      toast.show({
        message: "Avatar updated successfully",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: "Error updating avatar",
        status: "error",
      });
    }
  };

  const _editReferralCode = async () => {
    if (!client) return;

    const newCode = window.prompt(
      "Enter a new referral code:",
      client.referralCode || ""
    );

    if (!newCode) return;

    try {
      await updateClientReferralCode({
        variables: {
          clientId: client.id,
          referralCode: newCode,
        },
        refetchQueries: [api.clients.retrieve],
      });

      toast.show({
        message: "Referral code updated successfully",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Error updating referral code",
        status: "error",
      });
    }
  };

  return (
    <Box
      style={{
        // marginTop: 15,
        border: "1px solid " + border,
        borderRadius: 10,
        padding: "25px 15px",
      }}
    >
      <div style={{ marginBottom: 40 }}>
        {/* <Text
          fontWeight="bold"
          color={header}
          fontSize="lg"
          style={{
            marginBottom: 15,
          }}
        >
          Your Referral Info
        </Text> */}

        <Text color={text} fontSize="lg">
          If you're an influencer and you (genuinely) love Awaken, DM{" "}
          <a href="https://x.com/big_duca" target="_blank">
            <span
              style={{
                color: colors.primary,
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              @big_duca
            </span>
          </a>
          . We'll give you a discount if you share us on socials (in your own
          words).
          <br />
          <br />
          Everyone you share Awaken with gets{" "}
          {client?.referredDiscountPercentage
            ? `${client?.referredDiscountPercentage}% off`
            : `${D(
                client?.referredCreditCents &&
                  isNumber(client?.referredCreditCents)
                  ? client?.referredCreditCents || 0
                  : 25_00,
                "USD"
              ).toFormat("$0,0")} of Awaken credit`}{" "}
          and you earn {client?.referralPercentage ?? 15}% of their purchase in
          cash.{" "}
          <Info message="If you're an influencer, reach out to our support team and we can discuss a special affiliate deal." />
        </Text>
      </div>

      <div>
        <div
          onClick={_copyReferralLink}
          style={{
            cursor: "pointer",
          }}
        >
          {/* make a UI for a user's referral link they can copy */}
          <div
            style={{
              padding: "10px 15px",
              backgroundColor: secondaryBackground,
              borderRadius: 50,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {/* <div>
              <i className="fa-sharp fa-link" style={{ marginRight: 10 }} />
            </div> */}
            <Text
              fontSize="sm"
              style={{
                flex: 1,
                color: header,
              }}
            >
              {value}
            </Text>
            <i
              className="fa-sharp fa-edit"
              style={{
                marginLeft: 10,
                marginRight: 10,
                cursor: "pointer",
                color: header,
              }}
              onClick={(e) => {
                e.stopPropagation();
                _editReferralCode();
              }}
            />
            <i
              className="fa-sharp fa-copy"
              style={{ marginLeft: 10, cursor: "pointer", color: header }}
            />
          </div>

          <Text
            style={{ marginTop: 5, textAlign: "center", color: text }}
            fontSize="xs"
          >
            Click to copy
          </Text>
        </div>

        <div
          style={{
            marginTop: 40,
            display: "flex",
            flexDirection: "row",
          }}
        >
          {me?.isSuperuser && (
            <Button
              style={{
                flex: 1,
                marginRight: 5,
                color: header,
                // twitter blue
                backgroundColor: secondaryBackground,
              }}
              onClick={() => {
                dispatch(
                  show("AffiliateModal", {
                    client,
                  })
                );
              }}
              borderRadius={50}
            >
              Affiliate{" "}
              <i style={{ marginLeft: 5 }} className="fa-sharp fa-globe" />
            </Button>
          )}

          <Button
            style={{
              flex: 1,
              marginRight: 5,
              color: colors.white,
              // twitter blue
              backgroundColor: colors.primary,
            }}
            onClick={_copyReferralLink}
            borderRadius={50}
          >
            Ref Link{" "}
            <i className="fa-sharp fa-link" style={{ marginLeft: 10 }} />
          </Button>

          <Button
            style={{
              flex: 1,
              borderRadius: 50,
              color: colors.white,
              // twitter blue
              backgroundColor: colors.black,
              border: `1px solid ${colors.gray40}`,
            }}
            onClick={_shareToTwitter}
          >
            Share to{" "}
            <i className="fab fa-x-twitter" style={{ marginLeft: 10 }} />
          </Button>
        </div>
      </div>

      {me?.isSuperuser && (
        <div style={{ marginTop: 20, padding: "10px 15px" }}>
          <Text color={header} fontWeight="bold" marginBottom={2}>
            Update Client Avatar (Admin Only)
          </Text>
          <div
            style={{
              border: `2px dashed ${border}`,
              borderRadius: 8,
              padding: 15,
              textAlign: "center",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files) {
                  handleDrop([e.target.files[0]]);
                }
              }}
              style={{ display: "none" }}
              id="avatar-upload"
            />
            <label htmlFor="avatar-upload" style={{ cursor: "pointer" }}>
              <Text color={text}>Current avatar:</Text>
              <img
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  width: 50,
                  margin: "auto",
                  marginTop: 15,
                  height: 50,
                  borderRadius: 100,
                  objectFit: "contain",
                  border: `1px solid ${border}`,
                }}
                src={client?.avatarImageUrl || ""}
              />
              <br />
              <Text color={text}>Click or drag to upload new avatar</Text>
            </label>
          </div>
        </div>
      )}
    </Box>
  );
};

const ReferralCommissions = ({
  client,
  commissions,
  numberOfReferrals,
}: {
  client: Maybe<BaseClientFields>;
  commissions: ReferralCommission[];
  numberOfReferrals: number;
}) => {
  const { header, border, medBackground, secondaryBackground } = useTheme();

  return (
    <Box
      style={{
        border: "1px solid " + border,
        borderRadius: 10,
        padding: "10px 0",
      }}
    >
      <div
        style={{
          padding: 15,
        }}
      >
        <Text fontSize="lg" color={header} fontWeight="bold">
          Successful Referrals
        </Text>
      </div>

      {numberOfReferrals > 0 && (
        <div
          style={{
            padding: 10,
            margin: "0 15px",
            backgroundColor: secondaryBackground,
            borderRadius: 10,
          }}
        >
          <Text color={header} fontSize="sm">
            You've referred{" "}
            <b>
              {numberOfReferrals}{" "}
              {numberOfReferrals === 1 ? "person" : "people"}
            </b>
            . When they pay, your commissions will show up below.
          </Text>
        </div>
      )}

      {commissions.length === 0 ? (
        <div
          style={{
            padding: "45px 15px",
          }}
        >
          <Text color={header}>None of your referrals have paid, yet.</Text>
        </div>
      ) : (
        <div style={{ padding: "15px 0" }}>
          {commissions.map((c) => (
            <Box
              // _hover={{
              //   backgroundColor: colors.gray90,
              // }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "15px 15px",
              }}
            >
              <div
                style={{
                  backgroundColor: colors.positive,
                  color: colors.white,
                  borderRadius: 100,
                  width: 35,
                  height: 35,
                  marginRight: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: 16,
                }}
              >
                <i className="fa-sharp fa-dollar" />
              </div>

              <div
                style={{
                  flex: 1,
                }}
              >
                <Text color={header}>
                  Referral on {moment(c.createdAt).format("MMM Do, h:mma")}
                </Text>
              </div>

              <div>
                <Text
                  color={header}
                  style={{
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  +{D(c.commissionCents ?? 0, "USD").toFormat()}
                </Text>
              </div>
            </Box>
          ))}
        </div>
      )}
    </Box>
  );
};

export default Referrals;
