import { Box, HStack, List, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isEmpty } from "lodash/fp";
import { useState } from "react";
import Confetti from "react-confetti";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "src/components";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { D } from "src/utils/helpers";

export function Welcome() {
  const toast = useMyToast();
  const [runConfetti, setRunConfetti] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const amountFreeCreditCents = searchParams.get("amountFreeCreditCents");
  const credit = !isEmpty(amountFreeCreditCents)
    ? D(parseFloat(amountFreeCreditCents || "0"), "USD")
    : null;

  const referredDiscountPercentage = searchParams.get(
    "referredDiscountPercentage"
  );
  const discount = !isEmpty(referredDiscountPercentage)
    ? parseFloat(referredDiscountPercentage || "0")
    : null;

  const hasDiscount =
    (discount && discount > 0) || (credit && credit.getAmount() > 0);

  const discountOff =
    discount && discount > 0 ? `${discount}% off` : `${credit?.toFormat()} off`;

  const { me } = useMe();
  const theme = useTheme();

  const _claimTransactions = () => {
    setRunConfetti(true);

    toast.show({
      message: `Congrats, your discount applies automatically at checkout!`,
      status: "success",
    });

    setTimeout(() => {
      const hasReferralCode = searchParams.get("referralCode");
      const amountFreeCreditCents = searchParams.get("amountFreeCreditCents");
      const redirect = searchParams.get("redirect");

      navigate(
        `/marketing?redirect=${redirect}&referralCode=${
          hasReferralCode || ""
        }&amountFreeCreditCents=${amountFreeCreditCents}`
      );
      return;
    }, 5000);
  };

  return (
    <NoNavBarPageTemplate>
      <VStack
        w="100%"
        padding="2rem 0"
        flexDir="column"
        display="flex"
        minH="100vh"
        justifyContent="center"
      >
        <Confetti
          run={runConfetti}
          colors={[
            // colors.blue50,
            // colors.green50,
            // colors.purple50,
            // colors.red50,
            colors.yellow50,
            colors.orange50,
            // colors.pink50,
          ]}
          numberOfPieces={250}
          recycle={false}
        />
        <Box>
          <Box
            display="flex"
            flexDir="column"
            flex={1}
            maxWidth="35rem"
            w="100%"
            margin="auto"
            padding="2rem"
            borderRadius={other.borderRadius}
            bg={theme.background}
            border={`1px solid ${theme.border}`}
          >
            <Box width="100%">
              <Text
                color={theme.header}
                fontSize="3xl"
                style={{ fontStretch: "extra-expanded" }}
                fontWeight="700"
                marginBottom="1rem"
              >
                Welcome to Awaken!
              </Text>

              {hasDiscount && (
                <Text color={theme.text} fontSize={"18px"} marginBottom={25}>
                  You'll get{" "}
                  <span
                    style={{
                      fontWeight: "800",
                      color: colors.primary,
                      borderRadius: 100,
                      padding: 0,
                    }}
                  >
                    {discountOff}
                  </span>{" "}
                  your first purchase of Awaken.
                </Text>
              )}

              <Box
                bg={`linear-gradient(to right, ${colors.primary}, ${colors.blue80})`}
                p={4}
                borderRadius="xl"
                mb={6}
                position="relative"
              >
                <HStack spacing={-4} mb={5} mt={2} justifyContent="center">
                  {["solana", "hype", "btc", "sui"].map((chain) => (
                    <Box
                      key={chain}
                      width="48px"
                      height="48px"
                      borderRadius="full"
                      overflow="hidden"
                      border="2px solid white"
                    >
                      <img
                        src={`https://assets.awaken.tax/icons/${chain}.png`}
                        alt={`${chain} logo`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  ))}
                </HStack>
                <Text
                  color={colors.white}
                  style={{
                    fontStretch: "extra-expanded",
                    fontWeight: "900",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                  fontSize="md"
                  fontWeight="600"
                >
                  Limited Time Offer
                </Text>
                <Text
                  color={colors.gray90}
                  fontSize="md"
                  style={{
                    marginTop: 5,
                    maxWidth: 400,
                    textAlign: "center",
                  }}
                >
                  SOL, HYPE, BTC, & SUI transactions are FREE{" "}
                  <b>until April 14th</b>! Unlock it after you add your first
                  wallet.
                </Text>
              </Box>

              <List
                style={{ padding: "2rem 0", paddingTop: "1rem" }}
                marginBottom="0 !important"
              >
                <ListItem
                  iconName="fa-sharp fa-timer"
                  backgroundColor={colors.primary}
                  header="Save Hours of Time"
                  subtitle="Our smart automation makes taxes easy."
                />
                <ListItem
                  iconName="fa-sharp fa-bullseye-arrow"
                  backgroundColor={colors.primary}
                  header="Accurate Reports"
                  subtitle="Unlike competitors, Awaken understands web3."
                />
                <ListItem
                  iconName="fa-sharp fa-flag-usa"
                  header="Download Tax Reports"
                  backgroundColor={colors.primary}
                  subtitle="TurboTax, TaxAct, 8949, and more."
                />
              </List>

              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
                <Button
                  onClick={_claimTransactions}
                  padding="1.5rem 0.5rem"
                  disabled={runConfetti}
                  bg={`linear-gradient(to top right, ${colors.lightBlue40} 0%, ${colors.lightBlue60} 50%, ${colors.lightBlue80} 100%) !important`}
                  color={colors.white}
                  style={{ width: "100%" }}
                >
                  Continue{" "}
                  <i
                    style={{ marginLeft: 15 }}
                    className="fa-sharp fa-arrow-right"
                  />
                </Button>
              </motion.div>
            </Box>
          </Box>
        </Box>
      </VStack>
    </NoNavBarPageTemplate>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  const theme = useTheme();

  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 40,
          height: 40,
          backgroundColor,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 20,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.white,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} />
      </div>
      <VStack alignItems="flex-start">
        <Text color={theme.header} fontSize="lg" fontWeight="600">
          {header}
        </Text>
        <Text color={theme.text} marginTop="0 !important" fontSize="md">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};
