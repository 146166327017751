import { useMutation } from "@apollo/client";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { useClientById, useMe } from "src/hooks";
import { useActiveSubscription } from "src/hooks/useActiveSubscription";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

export const FreeTransactions = () => {
  const isLarge = useIsLargeScreen();
  const theme = useTheme();
  const { clientId } = useParams<{ clientId: string }>();
  const [claimFreeChains] = useMutation(api.clients.claimFreeChains);
  const { client } = useClientById(clientId);
  const activeSubscription = useActiveSubscription(clientId || "");
  const dispatch = useDispatch();
  const isFree = client?.hasClaimedFreeChains ?? false;
  const { me } = useMe();
  const isSuperUser = me?.isSuperuser;

  const _onShare = async () => {
    dispatch(show("TweetModal"));
  };

  if (client?.isEnterprise) {
    return null;
  }

  if (
    !isSuperUser &&
    (!activeSubscription.totalUsed || activeSubscription.hasSubscription)
  ) {
    return null;
  }

  if (isFree) {
    return (
      <div
        onClick={_onShare}
        style={{
          cursor: "pointer",
          marginBottom: isLarge ? 15 : 35,
          background: `linear-gradient(to right, ${colors.positive}, ${colors.green60})`,
          maxWidth: isLarge ? 500 : "100%",
          width: "100%",
          marginTop: 15,
          borderRadius: 15,
          padding: "10px 15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <VStack alignItems="flex-start" flex={1} marginRight={15}>
          <Text
            color={colors.white}
            style={{
              fontStretch: "extra-expanded",
              fontWeight: "700",
            }}
            fontSize="md"
          >
            You have free transactions for SOL/HYPE/BTC/SUI
          </Text>
          <Text fontSize="md" marginTop={"2px !important"} color={colors.white}>
            Please help us spread the word by sharing with your friends!
          </Text>

          <Box display="flex" alignItems="center">
            <Text
              marginTop={"0px !important"}
              fontSize="md"
              style={{
                color: colors.white,
                textDecoration: "underline",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              Click to share
            </Text>
            <OverlappingTokens />
          </Box>
        </VStack>
      </div>
    );
  }

  return (
    <div
      onClick={_onShare}
      style={{
        cursor: "pointer",
        marginBottom: isLarge ? 15 : 35,
        background: `linear-gradient(to right, ${colors.primary}, ${colors.blue80})`,
        maxWidth: isLarge ? 500 : "100%",
        width: "100%",
        marginTop: 15,
        borderRadius: 15,
        padding: "10px 15px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <VStack alignItems="flex-start" flex={1} marginRight={15}>
        <Text
          color={colors.white}
          style={{
            fontStretch: "extra-expanded",
            fontWeight: "700",
          }}
          fontSize="md"
        >
          Want a massive discount?
        </Text>
        <Text fontSize="md" marginTop={"2px !important"} color={colors.white}>
          <span
            style={{
              marginTop: 5,
              fontWeight: "bold",
            }}
          >
            Share on <i className="fa-brands fa-x-twitter" />
          </span>{" "}
          and all SOL, HYPE, BTC, & SUI wallets are <b>FREE</b>.
        </Text>

        <Box display="flex" alignItems="center">
          <Text
            marginTop={"0px !important"}
            fontSize="md"
            style={{
              color: colors.white,
              textDecoration: "underline",
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            Click to share
          </Text>
          <OverlappingTokens />
        </Box>
      </VStack>
    </div>
  );
};

function OverlappingTokens() {
  return (
    <Box position="relative" width="80px" height="24px">
      {["solana", "hype", "btc", "sui"].map((chain, index) => (
        <Box
          key={index}
          position="absolute"
          left={`${index * 15}px`}
          zIndex={4 - index}
        >
          <img
            src={`https://assets.awaken.tax/icons/${chain}.png`}
            style={{
              width: 24,
              height: 24,
              border: "1px solid white",
              borderRadius: "50%",
            }}
            alt={chain}
          />
        </Box>
      ))}
    </Box>
  );
}
