import { useMutation } from "@apollo/client";
import { Box, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { Button } from "src/components";
import WhiteBox from "src/components/styled/WhiteBox";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

const LIST_SIZE = 15;
const NEEDS_REVIEW_SIZE = 15;
const NEEDS_REVIEW_PAGE_OPTIONS = [15, 25, 50, 100];

function Updates() {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const theme = useTheme();
  const [checkForSpamReview] = useMutation(api.assets.checkForSpamReview);

  const updates: any[] = [
    // {
    //   id: "spam-detection",
    //   title: "Spam Detection",
    //   description:
    //     "Scan your wallets for potential spam assets and transactions. This helps clean up your transaction history and financial reports.",
    //   buttonText: "Run Spam Detection",
    //   icon: "fa-sharp fa-binoculars",
    //   action: async () => {
    //     try {
    //       toast.show({
    //         message:
    //           "Started checking for assets that need review. This may take a bit, so you can check back in a few minutes. You can close this tab.",
    //         status: "success",
    //       });
    //       await checkForSpamReview({
    //         variables: { clientId },
    //       });
    //     } catch (err) {
    //       toast.show({
    //         message:
    //           (err as any)?.message || "Failed to check for spam review.",
    //         status: "error",
    //       });
    //     }
    //   },
    // },
    // {
    //   id: "recalculate-transactions",
    //   title: "Recalculate Transactions",
    //   description:
    //     "Update all transaction calculations with the latest pricing data and classification rules. This ensures your reports are using the most accurate information.",
    //   buttonText: "Recalculate Transactions",
    //   icon: "fa-sharp fa-calculator",
    //   action: () => {
    //     window.location.href = `/clients/${clientId}/transactions/recalculate`;
    //   },
    // },
    // {
    //   id: "refresh-wallets",
    //   title: "Refresh Wallet Data",
    //   description:
    //     "Sync your connected wallets to fetch the latest transactions and balances. We recommend doing this periodically to keep your data current.",
    //   buttonText: "Refresh Wallets",
    //   icon: "fa-sharp fa-rotate",
    //   action: () => {
    //     window.location.href = `/clients/${clientId}/wallets`;
    //   },
    // },
    // {
    //   id: "review-spam",
    //   title: "Review Spam Assets",
    //   description:
    //     "Review assets that have been flagged as potential spam. You can confirm or reject these classifications to improve your transaction data quality.",
    //   buttonText: "Review Spam Assets",
    //   icon: "fa-sharp fa-ban",
    //   action: () => {
    //     window.location.href = `/clients/${clientId}/spam`;
    //   },
    // },
    // {
    //   id: "generate-reports",
    //   title: "Generate Tax Reports",
    //   description:
    //     "Create up-to-date tax reports based on your current transaction data. This will use all the latest classifications and calculations.",
    //   buttonText: "Generate Reports",
    //   icon: "fa-sharp fa-file-invoice",
    //   action: () => {
    //     window.location.href = `/clients/${clientId}/reports`;
    //   },
    // },
  ];

  return (
    <div>
      <HStack style={{ width: "100%", margin: "1.5rem 0 0.75rem 0" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Heading size="lg" margin="0" mr={4} color={theme.header}>
            Updates
          </Heading>
        </div>
      </HStack>

      <Text mb={6} color={theme.text} maxW="800px">
        Awaken is always getting better, but we don't want to override any of
        your information without your permission. So below you can opt-in to run
        various updates / improvements to the software.
      </Text>

      <VStack spacing={4} align="stretch">
        {!updates.length && (
          <Text color={theme.text}>
            You're up to date{" "}
            <i
              className="fa-sharp fa-check"
              style={{ color: colors.positive }}
            />
          </Text>
        )}
        {updates.map((update) => (
          <UpdateCard
            key={update.id}
            title={update.title}
            description={update.description}
            buttonText={update.buttonText}
            icon={update.icon}
            onClick={update.action}
          />
        ))}
      </VStack>
    </div>
  );
}

const UpdateCard = ({
  title,
  description,
  buttonText,
  icon,
  onClick,
}: {
  title: string;
  description: string;
  buttonText: string;
  icon: string;
  onClick: () => void;
}) => {
  const theme = useTheme();

  return (
    <WhiteBox
      style={{
        background:
          theme.theme === "light" ? theme.background : theme.medBackground,
        transition: "transform 0.2s, box-shadow 0.2s",
      }}
      padding="1.5rem"
    >
      <Flex justify="space-between" align="flex-start">
        <Box>
          <HStack mb={2} spacing={3}>
            <Box
              borderRadius="full"
              bg={colors.primary}
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <i
                className={icon}
                style={{ color: "white", fontSize: "1rem" }}
              />
            </Box>
            <Heading size="md" color={theme.header}>
              {title}
            </Heading>
          </HStack>
          <Text color={theme.text} fontSize="md" mt={2}>
            {description}
          </Text>
        </Box>
        <Button
          colorScheme="blue"
          onClick={onClick}
          rightIcon={<i className="fa-sharp fa-arrow-right" />}
        >
          {buttonText}
        </Button>
      </Flex>
    </WhiteBox>
  );
};

export default Updates;
