import { useMutation } from "@apollo/client";
import { Button, Container, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Mutation, MutationGetUpgradeLinkArgs } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Select } from "src/components/styled";
import { useMyToast } from "src/hooks";
import { useActiveSubscription } from "src/hooks/useActiveSubscription";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { THRESHOLDS } from "../CheckoutModal/constants";

type Props = InjectedProps & {
  //
  defaultMaxCeiling?: number;
};

const OPTIONS = THRESHOLDS.map((threshold) => ({
  label: threshold.labelName,
  value: threshold.max,
  price: threshold.price,
}));

function _UpgradeModal({
  handleHide,
  show: isVisible,
  defaultMaxCeiling,
}: Props) {
  const { clientId } = useParams();

  const [getUpgradeLink, { loading }] = useMutation<
    Pick<Mutation, "getUpgradeLink">
  >(api.checkouts.getUpgradeLink);

  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();

  const { subscription } = useActiveSubscription(clientId || "");

  const [checkoutLink, setCheckoutLink] = useState<string | null>(null);

  // max ceiling
  const [maxCeiling, setMaxCeiling] = useState(
    defaultMaxCeiling ?? subscription?.transactionCeiling
  );

  const selectedThreshold = OPTIONS.find(
    (threshold) => threshold.value === maxCeiling
  );

  const _onSubmit = async function () {
    if (!clientId || !maxCeiling) {
      return;
    }

    try {
      if (maxCeiling <= (subscription?.transactionCeiling ?? 0)) {
        toast.show({
          message: "You cannot downgrade your subscription.",
          status: "error",
        });
        return;
      }

      const variables: MutationGetUpgradeLinkArgs = {
        clientId: clientId,
        maxTransactionsCeiling: maxCeiling,
      };

      const response = await getUpgradeLink({
        variables: variables,
      });

      const url = response.data?.getUpgradeLink?.url;

      setCheckoutLink(url || "");

      window.open(url || "", "_blank");
    } catch (e) {
      console.error(e);

      toast.show({
        message:
          (e as any)?.message ||
          "Error upgrading subscription. Contact support.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Upgrade Subscription</>}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          marginBottom="1rem"
          onClick={_onSubmit}
        >
          Continue to Upgrade
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        {/* number inputs for monthly + upfront prices */}

        <Select
          label={`Number of transactions`}
          options={OPTIONS}
          value={selectedThreshold as any}
          selectProps={{
            autoFocus: false,
            onChange: (option: any) => {
              if (!option) return;
              setMaxCeiling(option?.value ?? 0);
            },
          }}
        />

        {checkoutLink && (
          <a href={checkoutLink} target="_blank" style={{ marginTop: 15 }}>
            <Text fontSize="md" color={colors.primary} fontWeight="bold">
              Click here to upgrade{" "}
              <i className="fa-solid fa-arrow-up-right-from-square" />
            </Text>
          </a>
        )}
      </Container>
    </Modal>
  );
}

export const UpgradeModal = connectModal({
  name: "UpgradeModal",
})(_UpgradeModal);
