import { Text } from "@chakra-ui/react";
import { sum } from "lodash";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { BaseFullTransactionFields } from "src/api/fragments";
import { ActiveTransactionContext } from "src/context";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { getLabelRuleOrAutoReview } from "../utils";

export function TransactionWarning() {
  const { transaction } = useContext(ActiveTransactionContext);
  const { yellowBg, header } = useTheme();
  const dispatch = useDispatch();
  const showIncomeWarning = _isWeirdIncomeWarning(transaction);

  if (showIncomeWarning) {
    return (
      <Text
        as="div"
        textAlign="center"
        marginBottom="1rem"
        fontSize="sm"
        padding="0.5rem"
        bg={yellowBg}
        border={`1px solid ${colors.yellow50}`}
        borderRadius={10}
        marginTop="0.5rem"
        cursor="pointer"
        onClick={() => {
          (window as any).Intercom("show");
        }}
        color={header}
      >
        <i
          className="fa-sharp fa-exclamation-triangle"
          style={{ color: colors.yellow50 }}
        />{" "}
        This income looks weirdly high, but don't worry!{" "}
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Contact support
        </span>{" "}
        and we'll see what we can do.
      </Text>
    );
  }

  return null;
}

const _isWeirdIncomeWarning = (
  transaction: BaseFullTransactionFields | null
) => {
  if (!transaction) {
    return false;
  }

  const label = getLabelRuleOrAutoReview(transaction);

  // if it has withdraw in the name, and the income cents of the txn is > $1k and the sum
  // of the assetDebit fiat amount is within 10% of the income cents

  if (
    label?.toLowerCase()?.includes("withdraw") ||
    // meteora removing liquidity
    label?.toLowerCase()?.includes("meteora remove")
  ) {
    const incomeCents = transaction.incomeSum ?? 0;
    const assetDebitFiatAmount = sum(
      transaction.transfers.map((t) => t.assetDebitFiatAmount)
    );

    // income is 90% of the asset debit
    const is90PercentOfAssetDebit = incomeCents > assetDebitFiatAmount * 0.9;

    if (incomeCents > 1000_00 && is90PercentOfAssetDebit) {
      return true;
    }
  }

  return false;
};
