import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import _, { groupBy, orderBy, truncate } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import "react-dates/initialize";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import type { MenuListProps } from "react-select";
import { components } from "react-select";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { BaseAssetFields, PartialAssetFields } from "src/api/fragments";
import { AssetTypeEnum, Query } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import {
  AwakenTooltip,
  Checkbox,
  DatePicker,
  Input,
  Option,
  Select,
} from "src/components/styled";
import { AssetIcon } from "src/components/styled/Assets";
import { Maybe } from "src/core";
import { useClientById, useMe } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import {
  AccountFilterType,
  LabelFilterType,
  useTransactionSearch,
} from "src/hooks/useTransactionSearch";
import {
  getAssetSymbolOrName,
  isUnnamedAsset,
} from "src/modules/ledger/assets";
import { colors } from "src/theme";
import { INTEGRATIONS } from "../AccountModal/constants";
import { FormValues, getDefaultValues, schema } from "./form";

type Props = InjectedProps & {
  clientId: Maybe<string>;
};

const REVIEWED_OPTIONS: Option[] = [
  {
    value: "true",
    label: "Reviewed",
  },
  {
    value: "false",
    label: "Unreviewed",
  },
];

const GAIN_LOSS_FILTER: Option[] = [
  {
    value: "only_gains",
    label: "Only transactions with an overall gain 🟢",
  },
  {
    value: "only_losses",
    label: "Only transactions with an overall loss 🔴",
  },
];

const ASSET_TYPES: Option[] = [
  {
    value: "nft",
    label: "NFTs",
  },
  {
    value: "fungible_token",
    label: "Tokens",
  },
];

const defaultValuesUseEffectCheck = (defaultValues: FormValues) =>
  JSON.stringify({
    ...defaultValues,
    accounts:
      defaultValues.accounts ?? []
        ? (defaultValues.accounts || []).map((a) => ({
            value: a.value,
            data: a.data,
            label: "", // remove JSX.Element label
          }))
        : null,
    providers: (defaultValues.providers ?? []).map((p) => ({
      value: p.value,
      data: p.data,
      label: "", // remove JSX.Element label
    })),
  });

function _FilterTxnsModal({ handleHide, show: isVisible, clientId }: Props) {
  const { accounts, assets, getAssets } = useClientById(clientId);
  const { filters, updateFilters } = useTransactionSearch();
  const { me } = useMe("cache-first");
  const { data } = useQuery<Pick<Query, "getLabels">>(api.rules.getLabels, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    getAssets();
  }, []);

  // build a list of options from the accounts / wallets
  const accountOptions = useMemo(
    () =>
      accounts.map((a) => ({
        account: a,
        value: a.id,
        name: a.description,
        label: a.description,
      })),
    [JSON.stringify(accounts, null)]
  );

  const providerOptions = useMemo(
    () =>
      INTEGRATIONS.map((i) => ({
        value: i.provider,
        label: i.name,
        logoUrl: i.logoUrl,
      })),
    []
  );

  const assetOptions = useMemo(() => {
    const sortedAssets = _.sortBy(
      assets.map((a) => ({
        ...a,
        key: _getAssetKey(a),
      })),
      (a) => a.key
    );

    const realAssetsByKey = groupBy(sortedAssets, (a) => a.key);

    return orderBy(
      [
        ...Object.entries(realAssetsByKey).map(([key, assets]) => ({
          value: key,
          isUnnamedAsset: isUnnamedAsset(assets[0]), // deprecated field
          label: getAssetSymbolOrName(assets[0]),
          assets: assets,
        })),
      ],
      // this way we have priced assets show up FIRST when filtering through. ex. looking up "USDC"
      // when there are a ton of spam usdc but they aren't labeled, we want priced to be at the top
      (a) => a.assets.some((a) => a.coinGeckoTokenId),
      "desc"
    ).filter((a) => !!a.label);
  }, [JSON.stringify(assets)]);

  const labelOptions = data?.getLabels ?? [];

  const defaultValues = useMemo(() => {
    const accountIds = new Set(filters.accountIds ?? []);
    const providers = new Set(filters.providers ?? []);
    const labels = new Set(filters.labels ?? []);
    // FIXME: this is rlly asset keys. it isn't a specific match on ID atm it is by the coingecko or identifier
    const assetIds = new Set(filters.assetIds ?? []);

    const selectedAccountOptions =
      accountIds.size > 0
        ? accountOptions.filter((o) => accountIds.has(o.value))
        : null;

    const selectedProviderOptions =
      providers.size > 0
        ? providerOptions.filter((o) => providers.has(o.value))
        : null;

    const selectedAssetOptions =
      assetIds.size > 0
        ? assetOptions.filter((a) => {
            const aIds = a.assets.map((a) => a.id);
            return aIds.some((id) => assetIds.has(id));
          })
        : filters.assetSymbolOrName
        ? assetOptions.filter((a) => a.label === filters.assetSymbolOrName)
        : null;

    const selectedLabelOptions =
      labels.size > 0 ? labelOptions.filter((a) => labels.has(a.value)) : null;

    const startDate = filters.startDate
      ? new Date(filters.startDate).toISOString()
      : null;

    const endDate = filters.endDate
      ? new Date(filters.endDate).toISOString()
      : null;

    // remove any " or ' from the search
    const search = (filters.search || "")?.replace(/['"]/g, "");

    return getDefaultValues({
      search: search,
      startDate,
      accounts: selectedAccountOptions,
      providers: selectedProviderOptions,
      endDate,
      providerFilterMode: filters.providerFilterMode,
      labelFilterMode: filters.labelFilterMode,
      isMissingBasis: filters.isMissingBasis ?? null,
      onlyShowDeleted: filters.onlyShowDeleted ?? null,
      showOnlyEditedTransactions: filters.showOnlyEditedTransactions ?? null,
      isNegativeBalance: filters.isNegativeBalance ?? null,
      hasIncome: filters.hasIncome ?? null,
      hasNotes: filters.hasNotes ?? null,
      assets: selectedAssetOptions || null,
      assetType: ASSET_TYPES.find((o) => o.value === filters.assetType) || null,
      reviewed:
        REVIEWED_OPTIONS.find((o) => o.value === filters.reviewed) || null,
      gainLossFilter:
        GAIN_LOSS_FILTER.find((o) => o.value === filters.gainLossFilter) ||
        null,
      labels: selectedLabelOptions,
    });
  }, [accountOptions, assetOptions, labelOptions, JSON.stringify(filters)]); // Note: do not add the filters as a dependency

  const formProps = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting },
    control,
    reset,
    handleSubmit,
    setValue,
  } = formProps;

  const onSubmit = (values: FormValues) => {
    // console.log(values);

    // remove any spaces or ' or " from the string
    const search = values.search?.trim() || "";
    // .replace(/['"]/g, ""); <- do not do this! breaks constraints
    const assetIds = (values.assets || [])
      .map((a) => a.assets.map((a) => a.id))
      .flat();

    const newFilters = {
      startDate: values.startDate,
      endDate: values.endDate,
      accountIds: (values.accounts || []).map((a) => a.value),
      labels: (values.labels || []).map((a) => a.value),
      labelFilterMode: values.labelFilterMode ?? null,
      providers: (values?.providers || []).map((p) => p.value),
      providerFilterMode: values.providerFilterMode ?? null,
      search: search,
      assetIds,
      assetType: values.assetType?.value ?? null,
      assetSymbolOrName: null,
      reviewed: values.reviewed?.value ?? null,
      gainLossFilter: values.gainLossFilter?.value ?? null,
      isMissingBasis: values.isMissingBasis === true ? true : null,
      onlyShowDeleted: values.onlyShowDeleted === true ? true : null,
      showOnlyEditedTransactions:
        values.showOnlyEditedTransactions === true ? true : null,
      isNegativeBalance: values.isNegativeBalance === true ? true : null,
      hasIncome: values.hasIncome === true ? true : null,
      hasNotes: values.hasNotes === true ? true : null,
      page: 0,
      includeSpam: assetIds.length > 0 || !!search ? true : null,
    };

    // console.log(newFilters);

    // this updates the url
    updateFilters(newFilters);

    handleHide();
  };

  const theme = useTheme();

  // Note: don't change this stringify part. it was causing a bug where the default values were updated
  // and then it just would remove stuff from the url. super weird. whatever.
  useEffect(
    () => reset(defaultValues),
    [defaultValuesUseEffectCheck(defaultValues)]
  );

  const isSuperUser = me?.isSuperuser || false;

  return (
    <FormProvider {...formProps}>
      {/* other referenced modals */}
      <Modal
        title="Search Transactions"
        headerProps={{
          padding: "1rem",
          marginBottom: 0,
        }}
        titleHeaderProps={{
          style: {
            fontSize: 18,
            marginBottom: 0,
          },
        }}
        bodyProps={{
          style: {
            padding: "1rem",
            maxHeight: "80vh",
            overflowY: "scroll",
          },
        }}
        isVisible={isVisible}
        handleHide={handleHide}
        w="95%"
        maxW="700px"
        footerProps={{
          style: {
            padding: "1rem",
          },
        }}
        Footer={
          <Button
            onClick={handleSubmit(onSubmit, (e) => console.log(e))}
            width="100%"
            variant="primary"
            fontSize="sm"
            isLoading={isSubmitting}
            style={{ position: "relative" }}
          >
            Search{" "}
            <i
              style={{ fontSize: 18, position: "absolute", top: 10, right: 25 }}
              className="fa-sharp fa-arrow-right"
            />
          </Button>
        }
      >
        <Box>
          <Input
            labelIconName="fa-sharp fa-search"
            label="Search (across most of transaction fields)"
            name="search"
            placeholder="Name / Hash / Contract / Function Name / Notes"
            control={control}
          />

          <HStack alignItems="flex-start" w="100%">
            <Flex flex={1}>
              <Select
                label="Status"
                labelIconName="fa-sharp fa-check"
                options={REVIEWED_OPTIONS}
                name="reviewed"
                placeholder=""
                control={control}
                selectProps={{ isClearable: true }}
                containerStyle={{ width: "100%" }}
              />
            </Flex>

            <Flex flex={1}>
              <LabelFilterSelect labelOptions={labelOptions} />
            </Flex>
          </HStack>

          <HStack alignItems="flex-start" w="100%">
            <Flex flex={1} flexGrow={1}>
              <Select
                label="Account"
                labelIconName="fa-sharp fa-wallet"
                options={accountOptions}
                name="accounts"
                containerStyle={{ width: "100%" }}
                placeholder=""
                control={control}
                selectProps={{
                  isMulti: true,
                  isClearable: false,
                  styles: {
                    input: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    multiValue: (base, state) => ({
                      ...base,
                      minWidth: "inherit",
                      color: theme.header,
                      backgroundColor: theme.secondaryBackground,
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: theme.background,
                      borderColor: theme.border,
                      border: "none",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: theme.text,
                    }),
                    indicatorSeparator: (p) => ({ ...p, display: "none" }),
                    singleValue: (p) => ({ ...p, color: theme.header }),
                    container: (container) => ({
                      ...container,
                      backgroundColor: theme.background,
                      borderRadius: "5px",
                      border: "1px solid " + theme.border,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  },
                  components: {
                    MultiValueLabel: ({ innerProps, data }) => {
                      const theme = useTheme();
                      return (
                        <Box
                          {...innerProps}
                          _hover={{ bg: theme.secondaryBackground }}
                        >
                          <Tooltip
                            label={(data as any).account?.description || ""}
                          >
                            <Text
                              fontSize="xs"
                              color={theme.text}
                              style={{
                                maxWidth: 200,
                              }}
                            >
                              {(data as any).account?.description || ""}
                            </Text>
                          </Tooltip>
                        </Box>
                      );
                    },
                    Option: ({ innerRef, innerProps, label, data }) => {
                      const theme = useTheme();
                      return (
                        <Tooltip
                          openDelay={500}
                          label={(data as any).account?.description || ""}
                        >
                          <HStack
                            w="100%"
                            padding="0.75rem 0.5rem"
                            ref={innerRef}
                            {...innerProps}
                            _hover={{
                              bg: theme.secondaryBackground,
                              cursor: "pointer",
                            }}
                          >
                            <Image
                              style={{ borderRadius: 3 }}
                              src={(data as any).account?.iconImageUrl}
                              w="1rem"
                              h="1rem"
                              margin="0 0.5rem"
                            />
                            <div style={{ fontSize: 14, color: theme.text }}>
                              {truncate(
                                (data as any).account?.description || "",
                                {
                                  length: 25,
                                }
                              )}
                            </div>
                          </HStack>
                        </Tooltip>
                      );
                    },
                  },
                }}
              />
            </Flex>
            <Flex flex={1}>
              <Box position="relative" width="100%">
                <ProviderFilterSelect providerOptions={providerOptions} />
              </Box>
            </Flex>
          </HStack>

          <HStack alignItems="flex-start" w="100%">
            <Flex flex={1}>
              <AssetSelector options={assetOptions} />
            </Flex>

            <Flex flex={1}>
              <Select
                label="Asset Types"
                labelIconName="fa-sharp fa-filter"
                options={ASSET_TYPES}
                name="assetType"
                containerStyle={{
                  width: "100%",
                }}
                control={control}
                placeholder=""
                selectProps={{
                  isClearable: true,
                }}
              />
            </Flex>
          </HStack>

          <HStack>
            <Flex flex={1}>
              <DatePicker
                label="Start Date"
                labelIconName="fa-sharp fa-calendar"
                control={control}
                containerStyle={{ width: "100%" }}
                name="startDate"
                showTimeSelect={false}
              />

              <Box style={{ margin: "0 0.5rem" }}>
                <i
                  style={{
                    position: "relative",
                    top: 40,
                    fontSize: 14,
                    color: colors.gray40,
                  }}
                  className="fa-sharp fa-chevron-right"
                />
              </Box>

              <DatePicker
                label="End Date"
                labelIconName="fa-sharp fa-calendar"
                control={control}
                containerStyle={{
                  width: "100%",
                  backgroundColor: theme.background,
                }}
                name="endDate"
                showTimeSelect={false}
              />
            </Flex>

            <Flex flex={1}>
              <Select
                label="Gain/loss filter"
                labelIconName="fa-sharp fa-balance-scale-left"
                options={GAIN_LOSS_FILTER}
                name="gainLossFilter"
                placeholder=""
                control={control}
                selectProps={{ isClearable: true }}
                containerStyle={{ width: "100%" }}
              />
            </Flex>
          </HStack>

          <Divider style={{ margin: "1rem 0" }} />

          <HStack
            style={{
              marginBottom: "0.5rem",
              alignItems: "flex-start",
            }}
          >
            <Checkbox
              containerStyle={{ flex: 1 }}
              label="Show transactions with income"
              labelIconName="fa-sharp fa-sack-dollar"
              name="hasIncome"
              placeholder="Only show transactions with income"
              control={control}
            />

            <Checkbox
              containerStyle={{ flex: 1 }}
              label="Show transactions with notes"
              labelIconName="fa-sharp fa-sticky-note"
              name="hasNotes"
              placeholder="Only show transactions with notes"
              control={control}
            />
          </HStack>

          <HStack
            style={{
              marginBottom: "1rem",
              alignItems: "flex-start",
            }}
          >
            <Checkbox
              containerStyle={{ flex: 1 }}
              label="Missing purchase price"
              labelIconName="fa-sharp fa-dollar-sign"
              name="isMissingBasis"
              placeholder="Only show transactions with no price"
              control={control}
            />

            <Checkbox
              containerStyle={{ flex: 1 }}
              label="Negative balance"
              labelIconName="fa-sharp fa-balance-scale-left"
              name="isNegativeBalance"
              infoMessage="This is useful for figuring out where transactions may be missing that lead to missing basis. Ex. if your SOL balance dipped negative, it means there is likely a transaction before that which may be missing."
              placeholder="Only where the balance is negative"
              control={control}
            />
          </HStack>

          <HStack
            style={{
              marginBottom: "1rem",
              alignItems: "flex-start",
            }}
          >
            <Checkbox
              containerStyle={{ flex: 1 }}
              label="Show deleted transactions"
              labelIconName="fa-sharp fa-trash"
              name="onlyShowDeleted"
              placeholder="Only show deleted transactions"
              control={control}
            />

            <Checkbox
              containerStyle={{ flex: 1 }}
              label="Show edited transactions"
              labelIconName="fa-sharp fa-pencil"
              name="showOnlyEditedTransactions"
              placeholder="Any transactions you manually edited."
              control={control}
            />
          </HStack>
        </Box>
      </Modal>
    </FormProvider>
  );
}

const _getAssetKey = (asset: PartialAssetFields): string => {
  if (asset.type === AssetTypeEnum.FiatCurrency) {
    return asset.symbol || asset.id;
  }
  if (asset.type === AssetTypeEnum.Nft) {
    return `${asset.contractAddress}-${asset.tokenId || ""}`;
  }
  if (asset.type === AssetTypeEnum.FungibleToken) {
    return asset.symbol || asset.id;
  }
  return asset.id;
};

const AssetsImage = ({ assets }: { assets: BaseAssetFields[] }) => {
  // make it so we switch through these random assets every 2.5 seconds and render using the <AssetIcon
  const [assetIndex, setAssetIndex] = useState(0);
  // const [asset, setAsset] = useState(assets[0]);

  // useEffect(() => {
  //   const images = assets.map((a) => getAssetUrl(a));
  //   // if every image is the same, return
  //   if (images.every((i) => i === images[0])) return;
  //   // only if all assets are NFT
  //   if (assets.every((a) => a.type === AssetTypeEnum.Nft)) return;
  //   // if only one asset, return
  //   if (assets.length === 1) return;

  //   const interval = setInterval(() => {
  //     // Delay setting the new asset and resetting the fade to create a fade effect
  //     setTimeout(() => {
  //       // Calculate the next asset index, looping back to 0 if needed
  //       const nextIndex = (assetIndex + 1) % assets.length;
  //       setAssetIndex(nextIndex);

  //       // Set the corresponding asset from the assets array
  //       setAsset(assets[nextIndex]);
  //     }, 500); // Delay for 0.5 seconds for the fade effect
  //   }, 2500); // Interval of 2.5 seconds in milliseconds

  //   // Cleanup the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, [assetIndex, assets]);

  const asset = assets[0];

  if (!asset) {
    return null;
  }

  return <AssetIcon textStyle={{ fontSize: 8 }} size={20} asset={asset} />;
};

const MenuList = ({ children, ...props }: MenuListProps) => {
  const theme = useTheme();
  return (
    <components.MenuList {...props}>
      <div style={{ color: theme.text }}>{children}</div>
    </components.MenuList>
  );
};

const AssetOption = React.memo(
  ({ innerRef, innerProps, data }: any) => {
    const theme = useTheme();
    return (
      <HStack
        w="100%"
        padding="0.75rem 0.5rem"
        ref={innerRef}
        {...innerProps}
        _hover={{
          bg: theme.secondaryBackground,
          cursor: "pointer",
        }}
      >
        <AssetsImage assets={data.assets || []} />
        <Text fontSize="sm" color={theme.text}>
          {data.label}
        </Text>
      </HStack>
    );
  },
  (prev, next) => prev.data.value === next.data.value
);

const AssetSelector = React.memo(
  ({ options: options }: { options: any[] }) => {
    const { control } = useFormContext<FormValues>();
    const theme = useTheme();

    const selectProps = useMemo(
      () => ({
        isClearable: true,
        isMulti: true,
        components: {
          MenuList,
          Option: AssetOption,
          MultiValueLabel: ({ innerProps, data }: any) => (
            <HStack padding="5px 15px" {...innerProps}>
              <AssetsImage assets={data?.assets || []} />
              <Text fontSize="sm">{data.label || ""}</Text>
            </HStack>
          ),
        },
        styles: {
          input: (base: any) => ({
            ...base,
            color: theme.header,
          }),
          multiValue: (base: any, state: any) => ({
            ...base,
            minWidth: "inherit",
            color: theme.header,
            background: theme.secondaryBackground,
          }),
          multiValueLabel: (base: any) => ({
            ...base,
            color: theme.header,
          }),
          control: (base: any) => ({
            ...base,
            backgroundColor: theme.background,
            borderColor: theme.border,
            color: theme.header,
            border: "none",
          }),
          placeholder: (base: any) => ({
            ...base,
            color: theme.text,
          }),
          indicatorSeparator: (p: any) => ({ ...p, display: "none" }),
          singleValue: (p: any) => ({ ...p, color: theme.header }),
          container: (container: any) => ({
            ...container,
            backgroundColor: theme.background,
            borderRadius: "5px",
            border: "1px solid " + theme.border,
          }),
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        },
      }),
      [theme]
    );

    return (
      <Select
        label="Assets"
        labelIconName="fa-sharp fa-coins"
        options={options}
        name="assets"
        control={control}
        placeholder=""
        containerStyle={{
          width: "100%",
        }}
        selectProps={selectProps}
      />
    );
  },
  (prevProps, nextProps) => {
    // Deep compare the options arrays
    return (
      JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options)
    );
  }
);

// New component for provider filter
const ProviderFilterSelect = ({
  providerOptions,
}: {
  providerOptions: Option[];
}) => {
  const theme = useTheme();
  const { watch, setValue, control } = useFormContext<FormValues>();
  const providerFilterMode =
    watch("providerFilterMode") || AccountFilterType.Include;

  const setProviderFilterMode = (mode: AccountFilterType) => {
    setValue("providerFilterMode", mode);
  };

  return (
    <Select
      formLabelStyle={{
        marginRight: 0,
        marginBottom: "3px",
      }}
      label={
        <HStack alignItems="center">
          <i className="fas fa-exchange" style={{ color: theme.text }}></i>
          <Text flex={1} fontSize="sm" mr={2}>
            Chain or exchange
          </Text>
          <Flex
            bg={theme.secondaryBackground}
            borderRadius="md"
            p="0"
            alignItems="center"
          >
            <AwakenTooltip label="Only show transactions from the selected chains or exchanges">
              <Button
                size="xs"
                variant={
                  providerFilterMode === AccountFilterType.Include
                    ? "solid"
                    : "ghost"
                }
                onClick={() => setProviderFilterMode(AccountFilterType.Include)}
                mr={1}
                bg={
                  providerFilterMode === AccountFilterType.Include
                    ? colors.primary
                    : "transparent"
                }
                borderRadius={100}
                _active={{
                  bg:
                    providerFilterMode === AccountFilterType.Exclude
                      ? colors.primary
                      : theme.medBackground,
                }}
                color={
                  providerFilterMode === AccountFilterType.Include
                    ? "white"
                    : theme.text
                }
                _hover={{
                  bg:
                    providerFilterMode === AccountFilterType.Include
                      ? colors.primary
                      : theme.secondaryBackground,
                }}
              >
                Only
              </Button>
            </AwakenTooltip>
            <AwakenTooltip label="Only show transactions that are NOT the selected chains or exchanges">
              <Button
                size="xs"
                variant={
                  providerFilterMode === AccountFilterType.Exclude
                    ? "solid"
                    : "ghost"
                }
                borderRadius={100}
                onClick={() => setProviderFilterMode(AccountFilterType.Exclude)}
                bg={
                  providerFilterMode === AccountFilterType.Exclude
                    ? colors.primary
                    : "transparent"
                }
                _active={{
                  bg:
                    providerFilterMode === AccountFilterType.Exclude
                      ? colors.primary
                      : theme.medBackground,
                }}
                color={
                  providerFilterMode === AccountFilterType.Exclude
                    ? "white"
                    : theme.text
                }
                _hover={{
                  bg:
                    providerFilterMode === AccountFilterType.Exclude
                      ? colors.primary
                      : theme.secondaryBackground,
                }}
              >
                Not
              </Button>
            </AwakenTooltip>
          </Flex>
        </HStack>
      }
      options={providerOptions}
      name="providers"
      placeholder=""
      control={control}
      selectProps={{
        isClearable: true,
        isMulti: true,
        styles: {
          input: (base) => ({
            color: theme.header,
          }),
          multiValue: (base, state) => ({
            ...base,
            minWidth: "inherit",
            color: theme.header,
            background: theme.secondaryBackground,
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: theme.header,
          }),
          control: (base) => ({
            ...base,
            backgroundColor: theme.background,
            borderColor: theme.border,
            border: "none",
          }),
          placeholder: (base) => ({
            ...base,
            color: theme.text,
          }),
          indicatorSeparator: (p) => ({ ...p, display: "none" }),
          singleValue: (p) => ({ ...p, color: theme.header }),
          container: (container) => ({
            ...container,
            backgroundColor: theme.background,
            borderRadius: "5px",
            border: "1px solid " + theme.border,
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        },
        components: {
          Option: ({ innerRef, innerProps, label, data }) => {
            const theme = useTheme();
            return (
              <HStack
                w="100%"
                padding="0.75rem 0.5rem"
                ref={innerRef}
                {...innerProps}
                _hover={{
                  bg: theme.secondaryBackground,
                  cursor: "pointer",
                }}
              >
                {(data as any)?.logoUrl ? (
                  <Image
                    style={{ borderRadius: 3 }}
                    src={(data as any).logoUrl}
                    w="1rem"
                    h="1rem"
                    margin="0 0.5rem"
                  />
                ) : (
                  <Box
                    style={{
                      width: "1rem",
                      height: "1rem",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "revert",
                      alignItems: "center",
                      borderRadius: 5,
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                    bg={theme.secondaryBackground}
                  >
                    {(data as any).label?.charAt(0).toUpperCase()}
                  </Box>
                )}

                <div style={{ fontSize: 14, color: theme.text }}>
                  {(data as any).label}
                </div>
              </HStack>
            );
          },
        },
      }}
      containerStyle={{ width: "100%" }}
    />
  );
};

// New component for label filter
const LabelFilterSelect = ({ labelOptions }: { labelOptions: Option[] }) => {
  const theme = useTheme();
  const { watch, setValue, control } = useFormContext<FormValues>();
  const labelFilterMode = watch("labelFilterMode") || LabelFilterType.Include;

  const setLabelFilterMode = (mode: LabelFilterType) => {
    setValue("labelFilterMode", mode);
  };

  return (
    <Select
      formLabelStyle={{
        marginBottom: "3px",
        marginRight: 0,
      }}
      label={
        <HStack alignItems="center">
          <i className="fa-sharp fa-tags" style={{ color: theme.text }}></i>
          <Text flex={1} fontSize="sm" mr={2}>
            Labels
          </Text>
          <Flex
            bg={theme.secondaryBackground}
            borderRadius="md"
            p="0"
            alignItems="center"
          >
            <AwakenTooltip label="Only show transactions with the selected labels">
              <Button
                borderRadius={100}
                size="xs"
                variant={
                  labelFilterMode === LabelFilterType.Include
                    ? "solid"
                    : "ghost"
                }
                onClick={() => setLabelFilterMode(LabelFilterType.Include)}
                mr={1}
                _active={{
                  bg:
                    labelFilterMode === LabelFilterType.Include
                      ? colors.primary
                      : theme.medBackground,
                }}
                bg={
                  labelFilterMode === LabelFilterType.Include
                    ? colors.primary
                    : "transparent"
                }
                color={
                  labelFilterMode === LabelFilterType.Include
                    ? "white"
                    : theme.text
                }
                _hover={{
                  bg:
                    labelFilterMode === LabelFilterType.Include
                      ? colors.primary
                      : theme.secondaryBackground,
                }}
              >
                Only
              </Button>
            </AwakenTooltip>
            <AwakenTooltip label="Only show transactions that do NOT have the selected labels">
              <Button
                size="xs"
                borderRadius={100}
                variant={
                  labelFilterMode === LabelFilterType.Exclude
                    ? "solid"
                    : "ghost"
                }
                onClick={() => setLabelFilterMode(LabelFilterType.Exclude)}
                bg={
                  labelFilterMode === LabelFilterType.Exclude
                    ? colors.primary
                    : "transparent"
                }
                color={
                  labelFilterMode === LabelFilterType.Exclude
                    ? "white"
                    : theme.text
                }
                _active={{
                  bg:
                    labelFilterMode === LabelFilterType.Include
                      ? colors.primary
                      : theme.medBackground,
                }}
                _hover={{
                  bg:
                    labelFilterMode === LabelFilterType.Exclude
                      ? colors.primary
                      : theme.secondaryBackground,
                }}
              >
                Not
              </Button>
            </AwakenTooltip>
          </Flex>
        </HStack>
      }
      options={labelOptions}
      name="labels"
      placeholder=""
      control={control}
      selectProps={{
        isMulti: true,
        styles: {
          multiValue: (base, state) => ({
            ...base,
            minWidth: "inherit",
            backgroundColor: theme.secondaryBackground,
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: theme.header,
          }),
          control: (base) => ({
            ...base,
            backgroundColor: theme.background,
            borderColor: theme.border,
            color: theme.header,
            border: "none",
          }),
          placeholder: (base) => ({
            ...base,
            color: theme.text,
          }),
          indicatorSeparator: (p) => ({ ...p, display: "none" }),
          singleValue: (p) => ({ ...p, color: theme.header }),
          container: (container) => ({
            ...container,
            backgroundColor: theme.background,
            borderRadius: "5px",
            border: "1px solid " + theme.border,
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          input: (base) => ({
            ...base,
            color: theme.header,
          }),
        },
      }}
      containerStyle={{ width: "100%" }}
    />
  );
};

export const FilterTxnsModal = connectModal({
  name: "FilterTxnsModal",
})(_FilterTxnsModal);
