import { useLazyQuery } from "@apollo/client";
import { Box, Container, HStack, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { BaseAssetFields } from "src/api/fragments";
import { Modal } from "src/components/Modal";
import { AwakenTooltip, Button } from "src/components/styled";
import { AssetIcon } from "src/components/styled/Assets";
import { Input } from "src/components/styled/Form/Input";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

type Props = InjectedProps & {
  onSelect: (assets: BaseAssetFields[]) => void;
  canOnlySelectOne?: boolean;
  provider?: string;
};

function _SearchAssetModal({
  handleHide,
  show: isVisible,
  canOnlySelectOne,
  onSelect,
  provider,
}: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const [selectedAssets, setSelectedAssets] = useState<BaseAssetFields[]>([]);

  const searchParams = useMemo(() => {
    if (search.length >= 2) {
      return {
        clientId: clientId || "",
        search: search.length >= 2 ? search : null,
        hideSpamAssets: true,
        provider,
      };
    }
    return null;
  }, [search, provider]);

  const [executeSearch, { data: searchData, loading: searchLoading }] =
    useLazyQuery(api.assets.searchClientAssets);

  const debouncedSearch = useMemo(
    () =>
      debounce((params: typeof searchParams) => {
        if (params) {
          executeSearch({ variables: params, fetchPolicy: "network-only" });
        }
      }, 300),
    [executeSearch]
  );

  useEffect(() => {
    if (searchParams) {
      debouncedSearch(searchParams);
    }
  }, [searchParams, debouncedSearch]);

  const searchResults = (searchData?.searchClientAssets ||
    []) as BaseAssetFields[];

  const handleAssetClick = (asset: BaseAssetFields) => {
    if (canOnlySelectOne) {
      onSelect([asset]);
      handleHide();
    } else {
      if (!selectedAssets.find((a) => a.id === asset.id)) {
        setSelectedAssets([...selectedAssets, asset]);
      }
    }
  };

  const submitSelectedAssets = () => {
    onSelect(selectedAssets);
    handleHide();
  };

  const removeAsset = (assetId: string) => {
    setSelectedAssets(selectedAssets.filter((a) => a.id !== assetId));
  };

  return (
    <Modal
      title={
        <HStack>
          <Text
            color={theme.header}
            fontSize="3xl"
            margin={0}
            fontWeight={"bold"}
          >
            Search Assets
          </Text>
        </HStack>
      }
      isVisible={isVisible}
      handleHide={handleHide}
    >
      <Container padding="0px" marginTop="10px !important">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          label="Search Assets"
          placeholder="Search by name or symbol..."
        />

        {selectedAssets.length > 0 && (
          <Box mt={2}>
            <HStack
              sx={{ gap: "8px" }} // This adds both horizontal and vertical spacing
              spacing={2}
              alignItems="flex-start"
              flexWrap="wrap"
            >
              {selectedAssets.map((asset) => (
                <Box
                  key={asset.id}
                  bg={theme.secondaryBackground}
                  px={2}
                  py={1}
                  borderRadius="md"
                  display="flex"
                  alignItems="center"
                >
                  <AssetIcon
                    asset={asset}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                    }}
                  />
                  <Text color={theme.text} fontSize="sm">
                    {asset.symbol}
                  </Text>
                  <Box
                    ml={2}
                    cursor="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeAsset(asset.id);
                    }}
                  >
                    <i
                      className="fas fa-times"
                      style={{ fontSize: "12px", color: theme.text }}
                    />
                  </Box>
                </Box>
              ))}
            </HStack>
          </Box>
        )}

        {searchLoading && <Text color={theme.header}>Searching...</Text>}

        {searchResults.length > 0 && (
          <Box mt={4}>
            <Text color={theme.header} fontWeight="bold" mb={2}>
              Assets
            </Text>
            {searchResults.map((result) => (
              <Box
                key={result.id}
                variant="outline"
                width="100%"
                mb={2}
                _hover={{
                  bg: theme.secondaryBackground,
                }}
                style={{
                  padding: 10,
                  border: `1px solid ${
                    selectedAssets.find((a) => a.id === result.id)
                      ? colors.positive
                      : theme.border
                  }`,
                  borderRadius: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleAssetClick(result);
                }}
              >
                <HStack width="100%">
                  <AssetIcon
                    asset={result}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                  <Text
                    color={theme.header}
                    flex={1}
                    style={{ textAlign: "left" }}
                  >
                    {result.name}{" "}
                    {result.coinGeckoTokenId && (
                      <AwakenTooltip message="This asset has pricing data on Coingecko.">
                        <i
                          className="fa-sharp fa-check-circle"
                          style={{ color: colors.positive }}
                        />
                      </AwakenTooltip>
                    )}
                  </Text>
                  <Text color={theme.text}>{result.symbol}</Text>
                </HStack>
              </Box>
            ))}
          </Box>
        )}

        <br />

        {selectedAssets.length > 0 && !canOnlySelectOne && (
          <Button
            variant="primary"
            style={{
              marginBottom: 25,
              width: "100%",
            }}
            onClick={submitSelectedAssets}
          >
            Select Assets
          </Button>
        )}

        <br />
      </Container>
    </Modal>
  );
}

export const SearchAssetModal = connectModal({
  name: "SearchAssetModal",
})(_SearchAssetModal);
