import {
  Alert,
  Box,
  Divider,
  Heading,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { Maybe } from "graphql/jsutils/Maybe";
import { compose } from "lodash/fp";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { TransactionTypeOption } from "src/api/generated/types";
import SecondaryText from "src/components/styled/SecondaryText";
import { colors, other } from "src/theme";

// help images
import { BaseFullTransactionFields } from "src/api/fragments";
import LendBad from "src/assets/awaken/labels/lend/bad.png";
import LendCaution from "src/assets/awaken/labels/lend/caution.png";
import LendGood from "src/assets/awaken/labels/lend/good.png";
import LendBorrowBad from "src/assets/awaken/labels/lendBorrow/bad.png";
import LendBorrowGood from "src/assets/awaken/labels/lendBorrow/good.png";
import LendSwapBad from "src/assets/awaken/labels/lendSwap/bad.png";
import LendSwapGood from "src/assets/awaken/labels/lendSwap/good.png";
import StakingBad from "src/assets/awaken/labels/staking/bad.png";
import StakingCaution from "src/assets/awaken/labels/staking/caution.png";
import StakingGood from "src/assets/awaken/labels/staking/good.png";
import StakingSwapBad from "src/assets/awaken/labels/stakingSwap/bad.png";
import StakingSwapCaution from "src/assets/awaken/labels/stakingSwap/caution.png";
import StakingSwapGood from "src/assets/awaken/labels/stakingSwap/good.png";
import UnstakingBad from "src/assets/awaken/labels/unstaking/bad.png";
import UnstakingGood from "src/assets/awaken/labels/unstaking/good.png";
import UnstakingSwapCaution from "src/assets/awaken/labels/unstakingSwap/caution.png";
import UnstakingSwapGood from "src/assets/awaken/labels/unstakingSwap/good.png";
import { AwakenTooltip } from "src/components/styled";
import { useTheme } from "src/hooks/useTheme";
import {
  hasTransferReceived,
  hasTransferSent,
} from "src/modules/ledger/transfers";
import Helpers from "src/utils/helpers";
import { isDeposit } from "src/utils/labels";

type HelpImage = {
  src: string;
  text: string;
};

type HelpImagesInfo = {
  [key: string]: {
    good?: HelpImage;
    caution?: HelpImage;
    bad?: HelpImage;
  };
};

const HELP_IMAGES: HelpImagesInfo = {
  "v1:staking": {
    good: {
      src: StakingGood,
      text: "You gave tokens to a staking pool and didn't receive anything back.",
    },
    caution: {
      src: StakingCaution,
      text: "You gave tokens to a staking pool and received REWARDS back.",
    },
    bad: {
      src: StakingBad,
      text: "You gave tokens to a staking pool and received a STAKED TOKEN back.",
    },
  },
  "v1:deposit_liquidity": {
    good: {
      src: StakingGood,
      text: "You deposited tokens to a liquidity pool and didn't receive anything back.",
    },
    caution: {
      src: StakingCaution,
      text: "You deposited tokens to a liquidity pool and received REWARDS back.",
    },
    bad: {
      src: StakingBad,
      text: "You deposited tokens to a liquidity pool and received a token that represents your position back (ex. UNI-v2).",
    },
  },
  "v1:unstaking_with_rewards": {
    good: {
      src: UnstakingGood,
      text: "You withdrew tokens from a staking pool, optionally along with rewards.",
    },
    bad: {
      src: UnstakingBad,
      text: "You gave a staked token in exchange for your tokens. That's an Unstaking Swap.",
    },
  },

  "v1:withdraw_liquidity": {
    good: {
      src: UnstakingGood,
      text: "You withdrew tokens from a liquidity pool, optionally along with rewards.",
    },
    bad: {
      src: UnstakingBad,
      text: "You sold LP tokens (UNI-v2) for your original tokens. That's a Remove Liquidity.",
    },
  },
  "v1:staking_swap": {
    good: {
      src: StakingSwapGood,
      text: "You gave a token and received an equal number of STAKED TOKENS in return.",
    },
    caution: {
      src: StakingSwapCaution,
      text: "You gave a token and received an unequal number of STAKED TOKENS in return.",
    },
    bad: {
      src: StakingSwapBad,
      text: "You gave a token and received REWARDS in return. This is a Staking Deposit.",
    },
  },
  "v1:unstaking_swap": {
    good: {
      src: UnstakingSwapGood,
      text: "You gave your staked token back, and received an equal amount of the ORIGINAL token back.",
    },
    caution: {
      src: UnstakingSwapCaution,
      text: "You gave your staked token back, and received an unequal number of the ORIGINAL token back.",
    },
  },
  "v1:lend": {
    good: {
      src: LendGood,
      text: "You lent a token and received nothing in return",
    },
    caution: {
      src: LendCaution,
      text: "You lent your tokens and received INCOME back.",
    },
    bad: {
      src: LendBad,
      text: "You lent your tokens and received a borrowed position back.",
    },
  },
  "v1:lend_swap": {
    good: {
      src: LendSwapGood,
      text: "You lent your tokens and received a token representing that position.",
    },
    bad: {
      src: LendSwapBad,
      text: "You lent your tokens and received INCOME back.",
    },
  },
  "v1:lend_borrow": {
    good: {
      src: LendBorrowGood,
      text: "You lent your tokens and received a borrowed position back.",
    },
    bad: {
      src: LendBorrowBad,
      text: "You lent your tokens and received INCOME back.",
    },
  },
};

// checks transfers
const couldBeStakingOrUnstakingSwap = (
  transaction?: BaseFullTransactionFields
) => {
  if (!transaction || transaction.transfers.length !== 2) return false;
  const sent = transaction.transfers.filter(hasTransferSent);
  const received = transaction.transfers.filter(hasTransferReceived);
  if (
    sent.length !== 1 ||
    received.length !== 1 ||
    sent[0].id === received[0].id
  )
    return false;
  if (sent[0].value !== received[0].value) return false;
  return true;
};

export const LabelInfo = ({
  o,
  loading,
  handleHide,
  onSelectOption,
  transaction,
  isSelected,
}: {
  o: TransactionTypeOption;
  loading: boolean;
  onSelectOption: (o: Maybe<any>) => void;
  handleHide: () => void;
  transaction?: BaseFullTransactionFields;
  isSelected: boolean;
}) => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const theme = useTheme();

  const _onSubmit = (o: any) => {
    const regularSelect = () => {
      onSelectOption(o as any);
      handleHide();
    };

    // if (isInternalTransfer(o.value)) {
    //   _showModal("WalletTransferWarningModal", {
    //     regularSelect,
    //     handleHideLabelsModal: handleHide,
    //     transaction,
    //   });
    // } else
    if (
      (o.value === "v1:staking" || o.value === "v1:deposit_liquidity") &&
      couldBeStakingOrUnstakingSwap(transaction)
    ) {
      _showModal("StakingExactWarningModal", {
        regularSelect,
        type: "STAKING",
      });
    } else if (
      (o.value === "v1:unstaking_with_rewards" ||
        o.value === "v1:withdraw_liquidity") &&
      couldBeStakingOrUnstakingSwap(transaction)
    ) {
      _showModal("StakingExactWarningModal", {
        regularSelect,
        type: "UNSTAKING",
      });
    } else if (isDeposit(o.value)) {
      const reason = o.value.split(":")[1] || "";
      const parsedReason = reason.split("_").map(Helpers.capitalize).join(" ");

      _showModal("UnstakingWarningModal", {
        regularSelect,
        transaction,
        parsedReason,
      });
    } else {
      regularSelect();
    }
  };

  const unclickable = loading || !o.applicable;
  const showCaution = !unclickable && o.applicable && !o.applicableCautious;
  const showHighlight = !unclickable && o.keywordMatches;
  const hasHelpImages = HELP_IMAGES[o.ruleType || ""]?.good;

  return (
    <Popover openDelay={500} placement="bottom" trigger="hover">
      <PopoverTrigger>
        <Text
          cursor="pointer"
          fontWeight="500"
          color={theme.header}
          opacity={unclickable && !isSelected ? 0.33 : 1}
        >
          <HStack
            onClick={() => _onSubmit(o)}
            _hover={{
              backgroundColor: showHighlight
                ? undefined
                : theme.secondaryBackground,
              opacity: showHighlight ? 1 : 0.7,
            }}
            borderRadius={other.borderRadius}
            padding="0.5rem 3px"
            marginBottom="0.1rem"
            cursor={"pointer"}
            userSelect="none"
            bgColor={
              isSelected
                ? theme.greenBg
                : showHighlight
                ? theme.theme === "light"
                  ? colors.yellow80
                  : colors.yellow50
                : undefined
            }
          >
            <Box width="100%">
              <Text
                fontSize="sm"
                fontWeight={600}
                color={
                  showHighlight
                    ? theme.theme === "light"
                      ? theme.header
                      : colors.black
                    : unclickable && !isSelected
                    ? theme.theme === "light"
                      ? colors.gray4
                      : colors.gray50
                    : theme.header
                }
              >
                {o.label}{" "}
                {o.helpGuideUrl && (
                  <a
                    target="_blank"
                    style={{
                      display: "inline-block",
                      marginLeft: 5,
                      borderRadius: 100,
                      backgroundColor: theme.blueBg,
                      padding: "0.25rem 0.5rem",
                    }}
                    href={o.helpGuideUrl}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Text
                      fontSize="xs"
                      color={colors.primary}
                      fontWeight="bold"
                    >
                      Guide <i className="fa-solid fa-arrow-up-right" />
                    </Text>
                  </a>
                )}
              </Text>

              {showHighlight && !showCaution && (
                <SecondaryText
                  text={"👆 This might be it"}
                  fontWeight="normal"
                  color={colors.primary}
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                />
              )}

              {showCaution && (
                <SecondaryText
                  text={"⚠️ Use carefully"}
                  fontWeight="normal"
                  color={showHighlight ? colors.gray10 : undefined}
                />
              )}
            </Box>

            {isSelected && (
              <AwakenTooltip message="This is the currently selected label.">
                <i
                  className="fa-sharp fa-check-circle"
                  style={{ color: colors.positive, marginRight: 0 }}
                />
              </AwakenTooltip>
            )}
          </HStack>
        </Text>
      </PopoverTrigger>
      <PopoverContent
        overflowY="scroll"
        style={{
          width: hasHelpImages ? "40rem" : undefined,
        }}
        bg={theme.background}
        borderColor={theme.border}
        maxH="25rem"
      >
        <PopoverArrow bg={theme.background} />
        <Box>
          {o.infoMessage && (
            <>
              <Text
                padding="0.75rem 1rem"
                flex={1}
                fontSize="sm"
                fontWeight="normal"
                color={theme.text}
              >
                {o.infoMessage}
              </Text>
            </>
          )}

          {hasHelpImages && (
            <>
              <Divider
                style={{
                  borderColor: theme.secondaryBackground,
                  margin: "15px 0",
                }}
              />

              <Alert status="success" bg={theme.background}>
                <Box>
                  <Heading
                    size="md"
                    marginTop={0}
                    paddingTop={0}
                    color={colors.positive}
                  >
                    <i
                      className="fa-solid fa-check-circle"
                      style={{ color: colors.positive }}
                    />{" "}
                    Correct
                  </Heading>
                  <Text fontSize="sm" margin="0.5rem 0" color={theme.text}>
                    {HELP_IMAGES[o.ruleType || ""].good?.text}
                  </Text>
                  <Image
                    borderRadius={15}
                    width="100%"
                    maxHeight="10rem"
                    objectFit={"cover"}
                    objectPosition="left"
                    bg={theme.secondaryBackground}
                    src={HELP_IMAGES[o.ruleType || ""].good?.src}
                  />
                </Box>
              </Alert>
              <Divider
                style={{
                  borderColor: theme.secondaryBackground,
                  margin: "15px 0",
                }}
              />
            </>
          )}

          {HELP_IMAGES[o.ruleType || ""]?.caution && (
            <>
              <Alert status="success" bg={theme.background}>
                <Box>
                  <Heading
                    size="md"
                    marginTop={0}
                    paddingTop={0}
                    color={colors.positive}
                  >
                    <i
                      className="fa-solid fa-check-circle"
                      style={{ color: colors.positive }}
                    />{" "}
                    Correct{" "}
                  </Heading>
                  <Text fontSize="sm" margin="0.5rem 0" color={theme.text}>
                    {HELP_IMAGES[o.ruleType || ""].caution?.text}
                  </Text>
                  <Image
                    width="100%"
                    borderRadius={15}
                    maxHeight="10rem"
                    objectFit={"cover"}
                    bg={theme.secondaryBackground}
                    src={HELP_IMAGES[o.ruleType || ""].caution?.src}
                  />
                </Box>
              </Alert>
              <Divider
                style={{
                  borderColor: theme.secondaryBackground,
                  margin: "15px 0",
                }}
              />
            </>
          )}

          {HELP_IMAGES[o.ruleType || ""]?.bad && (
            <Alert status="error" bg={theme.background}>
              <Box>
                <Heading
                  size="md"
                  marginTop={0}
                  paddingTop={0}
                  color={colors.negative}
                >
                  <i
                    className="fa-solid fa-times"
                    style={{ color: colors.negative }}
                  />{" "}
                  Incorrect{" "}
                </Heading>
                <Text fontSize="sm" margin="0.5rem 0" color={theme.text}>
                  {HELP_IMAGES[o.ruleType || ""].bad?.text}
                </Text>
                <Image
                  objectFit={"cover"}
                  width="100%"
                  borderRadius={15}
                  maxHeight="10rem"
                  bg={theme.secondaryBackground}
                  src={HELP_IMAGES[o.ruleType || ""].bad?.src}
                />
              </Box>
            </Alert>
          )}
        </Box>
      </PopoverContent>
    </Popover>
  );
};
