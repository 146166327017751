import {
  Box,
  Heading,
  HStack,
  Image,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import WhiteBox from "src/components/styled/WhiteBox";
import { useTheme } from "src/hooks/useTheme";

import WhiteLogo from "src/assets/awaken/logos/white-logo.png";

import { useQuery } from "@apollo/client";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { Button } from "src/components";
import { CheckoutModal } from "src/components/modals/CheckoutModal";
import StatusTag from "src/components/styled/StatusTag";
import { colors } from "src/theme";
import {
  _getSignupLinkWithRef,
  HeaderContainer,
} from "src/views/consumer/TradingLanding";
import { Company, Query } from "../../../api/generated/types";

export const Builders = ({ isStatic }: { isStatic?: boolean }) => {
  const { header, background, text } = useTheme();
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const dispatch = useDispatch();
  const { data: companyData } = useQuery<Pick<Query, "getFreeCompanies">>(
    api.misc.getFreeCompanies
  );

  const _showModal = (modal: string) => dispatch(show(modal));

  const companies = companyData?.getFreeCompanies ?? [];

  return (
    <Box bg={colors.black} minH="100vh" style={{ paddingTop: 20 }}>
      {isStatic && <CheckoutModal />}
      {isStatic && (
        <HeaderContainer
          isBlurred={true}
          position="fixed"
          top="0"
          width="100%"
          zIndex="100"
        >
          {/* <FreeBanner /> */}
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={
              !isStatic
                ? undefined
                : isLargerThan900
                ? "0.75rem 6rem"
                : "0.75rem 3%"
            }
          >
            <Link to="/">
              <Box flexShrink={0}>
                <Image src={WhiteLogo} h="2.5rem" />
              </Box>
            </Link>
            <HStack display="flex" justifyContent={"flex-end"}>
              {isLargerThan900 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/integrations"
                    style={{ padding: "5px 20px", textAlign: "center" }}
                  >
                    <Text color={colors.white} width="auto" fontSize="md">
                      Integrations
                    </Text>
                  </Link>

                  <a
                    href="https://awaken.tax/blog"
                    target="_blank"
                    style={{ padding: "5px 20px", textAlign: "center" }}
                  >
                    <Text color={colors.white} width="auto" fontSize="md">
                      Blog
                    </Text>
                  </a>

                  <a
                    onClick={() => _showModal("CheckoutModal")}
                    style={{ padding: "5px 20px", textAlign: "center" }}
                  >
                    <Text color={colors.white} width="auto" fontSize="md">
                      Pricing
                    </Text>
                  </a>
                </div>
              )}
              {/* {!isMobile && (
                <a
                  href="https://blog.awaken.tax"
                  style={{ margin: "0 2rem", flex: 1 }}
                >
                  <Text color={colors.white} width="auto" fontSize="md">
                    Blog
                  </Text>
                </a>
              )} */}
              <Link
                to="/login"
                style={{
                  padding: "5px 20px",
                  textAlign: "center",
                  marginLeft: 0,
                }}
              >
                <Text color={colors.white} width="auto" fontSize="md">
                  Sign In
                </Text>
              </Link>
              {!isMobile && <Box w={"1rem"} />}
              <Link to={_getSignupLinkWithRef()}>
                <Button
                  style={{ color: colors.black }}
                  bg={colors.white}
                  fontSize="md"
                >
                  Sign up
                </Button>
              </Link>
            </HStack>
          </Box>
        </HeaderContainer>
      )}
      <HStack
        style={{
          marginTop: isStatic ? 100 : 0,
          // padding: isStatic ? "1rem 1.5rem" : "0 3%",
        }}
        padding={
          !isStatic ? "0" : isLargerThan900 ? "0.75rem 6rem" : "0.75rem 3%"
        }
      >
        <VStack flex={1} style={{ alignItems: "flex-start" }}>
          <Heading
            color={colors.white}
            flex={1}
            size="lg"
            margin="0"
            paddingTop="0"
            fontWeight="bold"
            style={{
              fontStretch: "extra-expanded",
            }}
          >
            Awaken is FREE for Crypto Builders{" "}
            <i className="fa-solid fa-hammer"></i>
          </Heading>

          <Text
            style={{ marginTop: 15, color: colors.gray80, maxWidth: 1000 }}
            fontSize="lg"
          >
            First of all, thank you for helping build the future of crypto! As a
            small token of appreciation, we're making it so you can use Awaken
            100% free this year. Here is{" "}
            <a
              href="https://help.awaken.tax/en/articles/10650535-free-crypto-taxes-for-builders"
              target="_blank"
              style={{
                color: colors.primary,
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              a guide
            </a>{" "}
            on how to unlock it after you signup.
            <br />
            <br />
            If you think your company should be listed,{" "}
            <a
              style={{
                fontWeight: "bold",
                color: colors.primary,
                textDecoration: "underline",
              }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfWcQ6zhfnhS53-AHFi5PnMRtozYtCq8d0VFe8zPjTFo2ma8Q/viewform?usp=sharing"
            >
              apply here
            </a>
            .
          </Text>
        </VStack>
      </HStack>

      <Box
        w="100%"
        display="grid"
        gridTemplateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
        style={{ paddingBottom: 100 }}
        padding={
          !isStatic ? "0" : isLargerThan900 ? "0.75rem 6rem" : "0.75rem 3%"
        }
        gap={2}
      >
        {(companies || [])?.map((company) => (
          <CryptoCompany key={company.name} info={company} />
        ))}
      </Box>
    </Box>
  );
};

const CryptoCompany = ({ info }: { info: Company }) => {
  const { text, header, yellowBg, secondaryBackground } = useTheme();
  return (
    <Link to="/dashboard">
      <WhiteBox
        style={{
          display: "flex",
          padding: 15,
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid " + colors.gray20,
        }}
        bg={colors.gray05}
        cursor="pointer"
        width="100%"
        position="relative"
      >
        <Image
          src={info.imageUrl}
          w="4rem"
          borderRadius={15}
          h="4rem"
          objectFit="contain"
          marginRight="1rem"
          marginTop="0.4rem"
        />

        <Box style={{ flex: 1 }}>
          <Text
            fontWeight="semibold"
            fontSize="lg"
            color={colors.white}
            margin="0rem 0 0.4rem 0"
          >
            {info.name}
          </Text>

          <StatusTag
            type="info"
            overrideTheme="dark"
            infoMessage="You'll have to verify your company email after signing up to unlock your discount."
            label={info.emails.map((a) => `@${a}`).join(", ")}
          />
        </Box>
      </WhiteBox>
    </Link>
  );
};
