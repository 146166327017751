import { useLazyQuery } from "@apollo/client";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Modal } from "src/components/Modal";
import { Button } from "src/components/styled/Button";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

const _AwakenProModal = ({ handleHide, show: isVisible }: InjectedProps) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { text, header, background } = useTheme();
  const dispatch = useDispatch();
  const toast = useMyToast();
  const [isLoading, setIsLoading] = useState(false);
  const [getAuthToken] = useLazyQuery(api.users.getAuthToken);

  const handleGoToPro = async () => {
    try {
      setIsLoading(true);

      const tokenResponse = await getAuthToken({
        variables: {
          clientId: clientId || "",
        },
      });

      const token = tokenResponse?.data?.getAuthToken;

      if (!token) {
        toast.show({
          status: "error",
          message: "Failed to get auth token",
        });
        return;
      }

      const fullPath = window.location.href;
      // redirect to the same URL with authToken set
      window.location.href = `https://awaken.pro${window.location.pathname}?authToken=${token}`;
    } catch (error) {
      toast.show({
        status: "error",
        message:
          (error as any)?.message ||
          "Failed to redirect to awaken.pro. Please try again or message support.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal title="Awaken Pro" isVisible={isVisible} handleHide={handleHide}>
      <VStack spacing={4} align="stretch">
        <Box
          borderRadius="lg"
          overflow="hidden"
          position="relative"
          height="150px"
          mb={2}
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            backgroundImage={`url(${require("src/assets/accrue/images/awaken_pro.png")})`}
            backgroundSize="cover"
            backgroundPosition="center"
            zIndex={0}
          />
        </Box>

        <Text fontSize="md" color={text}>
          This is the EXACT same Awaken, but on a new domain: awaken.pro. We
          want to be your hub for everything onchain from tax to portfolio
          tracking and more.{" "}
          <i
            className="fa-sharp fa-crown"
            style={{
              color: "#FFD700",
            }}
          ></i>
        </Text>

        <Box pt={4}>
          <Button
            w="100%"
            style={{
              background: `linear-gradient(45deg, ${colors.primary} 0%, ${colors.lightBlue80} 100%)`,
            }}
            color={colors.white}
            fontSize="16px"
            height="55px"
            padding="20px"
            borderRadius="8px"
            margin="5px 0"
            isLoading={isLoading}
            onClick={handleGoToPro}
          >
            Go to awaken.pro{" "}
            <i
              style={{ marginLeft: 10 }}
              className="fa-solid fa-arrow-right"
            ></i>
          </Button>
        </Box>

        <br />
        <br />
      </VStack>
    </Modal>
  );
};

export const AwakenProModal = connectModal({
  name: "AwakenProModal",
})(_AwakenProModal);
