import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { compose, groupBy } from "lodash/fp";
import { fork } from "radash";
import { useEffect, useMemo, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { show } from "redux-modal";
import { api, apolloClient } from "src/api";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
  BaseClientFields,
  BaseUserFields,
} from "src/api/fragments";
import {
  ImportTypeEnum,
  Maybe,
  Mutation,
  Query,
} from "src/api/generated/types";
import { ActionSheet, AwakenTooltip, Button, Info } from "src/components";
import { Touchable } from "src/components/Touchable";
import { Warning } from "src/components/Warning";
import { hasValue } from "src/core";
import { useClientById, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { AccountsGroupedRow } from "./AccountsGrouped";
import { AccountsContext } from "./context";
import {
  AccountGroupInfo,
  EnhancedAccountGroups,
  groupAccountsByType,
  shouldReconnect,
} from "./utils";

import BigNumber from "bignumber.js";
import { isNil, sum } from "lodash";
import moment from "moment";
import numbro from "numbro";
import CoinbaseLogo from "src/assets/awaken/logos/coinbase.png";
import { AddAccount } from "src/components/Accounts";
import { config } from "src/config";
import { SyncAllRecent } from "../Transactions";
import { FreeTransactions } from "./FreeTransactions";

const MAX_ACCOUNTS_ALLOWED_FOR_POLLING = 250;
const ACCOUNTS_PER_PAGE = 12; // we have grid of 2 or 3. so this way it's either 6 or 4 rows

// Note: CONTINUOUS SYNC MAX -> search for this across mono repo
export const MAX_NUM_TXNS_FOR_CONTINUOUS_SYNC = 30_000;

function Accounts() {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const dispatch = useDispatch();
  const showModal = compose(dispatch, show);
  const theme = useTheme();
  const [params] = useSearchParams();
  const triggerAccountModal = params.get("showAddAccountModal") === "true";
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const [accountsToGroup, setAccountsToGroup] = useState<BaseAccountFields[]>(
    []
  );
  const [groupModeOn, setGroupModeOn] = useState<boolean>(false);
  const { header, background, text } = useTheme();
  // every 5 seconds -> if have a lot of accounts tho we change this
  const [pollInterval, setPollInterval] = useState<number | null>(5000);

  const { accounts, isLoadingAccounts } = useClientById(clientId, {
    // poll accounts every 5 seconds for updates
    pollAccountsMs: pollInterval,
    accountFetchPolicy: "cache-first",
    skipFetchAssetsOnLoad: true,
  });

  // don't auto refresh if they have too many accounts
  useEffect(() => {
    if (!accounts.length) return;
    if (accounts.length > MAX_ACCOUNTS_ALLOWED_FOR_POLLING) {
      setPollInterval(null);
      return;
    }
  }, [accounts.length]);

  const [deleteClient] = useMutation(api.clients.delete);
  const [updateOutOfDate] = useMutation(api.accounts.updateOutOfDate);

  const { data: meData, refetch: refetchMe } = useQuery<Pick<Query, "me">>(
    api.users.me,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: clientData, refetch: refetchClient } = useQuery<
    Pick<Query, "getClientById">
  >(api.clients.retrieve, {
    variables: {
      clientId,
    },
    fetchPolicy: "cache-and-network",
    skip: !clientId,
  });

  const me = useMemo(() => meData?.me || null, [meData]);

  useEffect(() => void refetchMe(), []);
  useEffect(() => void refetchClient(), [clientId]);

  useEffect(() => {
    if (!triggerAccountModal) return;
    showModal("AccountModal", {
      location: "Accounts",
    });
  }, [triggerAccountModal]);

  const isSuperUser = me?.isSuperuser || false;

  const _deleteClient = async () => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await deleteClient({
          variables: { clientId },
          refetchQueries: [api.clients.list],
        });
        navigate("/dashboard");
      } catch (err) {
        toast.show({
          status: "error",
          message: (err as Error).message || "Couldn't delete client.",
        });
      }
    }
  };

  const _updateOutOfDate = async () => {
    try {
      await updateOutOfDate({
        variables: { clientId },
        refetchQueries: [api.clients.list],
      });

      toast.show({
        status: "success",
        message:
          "Successfully started update for all accounts. This may take a few minutes.",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: (err as Error).message || "Couldn't delete client.",
      });
    }
  };

  const _onLeaveFeedback = () => showModal("FeedbackModal");

  const [normalAccounts, virtualAccounts] = useMemo(() => {
    const [normalAccounts, virtualAccounts] = fork(
      accounts.filter(hasValue),
      (a) => a?.importType !== ImportTypeEnum.VirtualAccount
    );
    const normalAccountsByWallet = groupBy(
      (a) =>
        a.accountGroupId || (!isNil(a.walletAddress) ? a.walletAddress : a.id),
      normalAccounts
    );

    return [Object.values(normalAccountsByWallet), virtualAccounts];
  }, [accounts]);

  const _onAddWallet = () => {
    showModal("AccountModal", {
      location: "Accounts",
    });
  };

  const [createGroup] = useMutation<Pick<Mutation, "createAccountGroup">>(
    api.accounts.createGroup
  );
  const [updateAccount] = useMutation<Pick<Mutation, "updateAccount">>(
    api.accounts.update
  );

  const _mergeAccounts = () => {
    setGroupModeOn(true);
  };

  const _saveGroup = async () => {
    // create
    if (!accountsToGroup.length) {
      toast.show({
        status: "error",
        message:
          "No accounts selected. Please click an account below to select it.",
      });
      return;
    }

    if (accountsToGroup.length === 1) {
      toast.show({
        status: "error",
        message: "You need to select at least 2 accounts to group them.",
      });
      return;
    }

    try {
      const group = await createGroup({
        variables: {
          clientId,
          name: accountsToGroup[0].name,
        },
      });

      const groupId = group.data?.createAccountGroup.id;

      if (!groupId) {
        toast.show({
          status: "error",
          message: "Couldn't save group.",
        });
        return;
      }

      // update accounts
      for (const account of accountsToGroup) {
        await updateAccount({
          variables: {
            accountId: account.id,
            accountGroupId: groupId,
          },
        });
      }

      setAccountsToGroup([]);
      setGroupModeOn(false);

      // refetch the accounts (they will be grouped)
      apolloClient.refetchQueries({
        include: [api.clients.accounts],
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: "Couldn't save group.",
      });
    }
  };

  const isLarge = useIsLargeScreen();
  const accountsToReconnect = Object.values(normalAccounts)
    .flat()
    .filter((a) => shouldReconnect(a));

  const groupedAccounts = useMemo(() => {
    return groupAccountsByType(normalAccounts);
  }, [normalAccounts]);

  const hasAccounts = normalAccounts.length > 0;
  const totalTxns = useMemo(() => {
    const totalTxns = sum(
      normalAccounts
        .flat()
        .map((a: BaseAccountWithCurrentJobFields): number =>
          new BigNumber(a.numberOfTransactions || 0).toNumber()
        )
    );

    return totalTxns;
  }, [normalAccounts]);

  // only run this if we are loading accounts and the number of accounts has changed
  if (isLoadingAccounts) {
    return (
      <Box
        w="100%"
        minH="100vh"
        display="flex"
        bg={theme.background}
        flexDir="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Spinner color={theme.header} />
      </Box>
    );
  }

  // console.log(
  //   "Created At: ",
  //   me?.createdAt,
  //   "\n",
  //   config.coinbaseWalletDiscountDate,
  //   "\n",
  //   me?.createdAt,
  //   config.coinbaseWalletDiscountDate.getTime()
  // );

  // console.log(magicEdenData);

  return (
    <AccountsContext.Provider
      value={{
        client: client,
        accountsToGroup: accountsToGroup,
        setAccounts: setAccountsToGroup,
        groupModeOn: groupModeOn,
        setGroupModeOn: setGroupModeOn,
        isEligibleForMagicEden: false,
        magicEdenAccountIds: [],
      }}
    >
      <Box
        maxWidth="inherit"
        bg={background}
        minH={isLarge ? undefined : "100vh"}
        // overflowY="auto"
        // height="100vh"
        position="relative"
        padding="1rem 0"
      >
        {/* <FreeCreditBadge /> */}

        <HStack alignItems="center" padding="0.5rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            {hasAccounts && (
              <Heading color={header} margin="0" size="lg">
                Accounts
              </Heading>
            )}

            {hasAccounts && isLarge && <SyncAllRecent />}
          </Flex>

          {isBrowser &&
            hasAccounts &&
            (groupModeOn ? (
              <>
                <Touchable
                  label="Cancel"
                  // two squares being combined
                  iconName="fa-sharp fa-times"
                  onClick={() => {
                    setGroupModeOn(false);
                    setAccountsToGroup([]);
                  }}
                />

                <Button variant="primary" size="sm" onClick={_saveGroup}>
                  Save Group ({accountsToGroup.length})
                </Button>
              </>
            ) : isLarge ? (
              <AwakenTooltip
                openDelay={0}
                message="Warning: please read this entire thing before grouping accounts! Grouping accounts will treat all of the transactions/assets for all accounts in the group so it is effectively one account. Do not group random wallets together (ex. grouping Solana wallets or grouping Solana with your EVM etc...). This is not allowed under the IRS as it goes against per wallet accounting. Some valid use cases of this are for Bitcoin wallets (you have multiple public keys that are from the same xpub) or if you have a wallet and added a supplemental CSV that you want to be grouped with it. Again: do NOT group two unrelated wallets together. This will make your taxes inaccurate."
              >
                <div style={{ display: "flex" }}>
                  <Touchable
                    label={isLarge ? "Group Accounts" : "Group"}
                    // two squares being combined
                    iconName="fa-sharp fa-merge"
                    onClick={_mergeAccounts}
                  />
                </div>
              </AwakenTooltip>
            ) : null)}

          {hasAccounts && me?.isSuperuser && (
            <ActionSheet
              content={{
                maxW: "250px",
              }}
              popover={{ placement: "bottom-end" }}
              commands={[
                {
                  label: "Update Accounts",
                  iconName: "fa-sharp fa-circle-bolt",
                  iconColor: header,
                  color: header,
                  onClick: _updateOutOfDate,
                },
                isSuperUser
                  ? {
                      label: "Delete Client",
                      iconName: "fa-sharp fa-trash",
                      hasDivider: true,
                      iconColor: colors.red,
                      color: colors.red,
                      onClick: _deleteClient,
                    }
                  : null,
              ].filter(hasValue)}
            >
              <Touchable label="More" iconName="fa-sharp fa-ellipsis-v" />
            </ActionSheet>
          )}

          {hasAccounts && (
            <Button
              style={{ minWidth: 70, marginLeft: 20 }}
              variant="primary"
              size="sm"
              onClick={_onAddWallet}
            >
              {isLarge ? "Add Account" : "Add"}{" "}
              <i
                style={{ marginLeft: 5 }}
                className="fa-solid fa-plus-circle"
              />
            </Button>
          )}
        </HStack>

        {totalTxns > MAX_NUM_TXNS_FOR_CONTINUOUS_SYNC && (
          <Box
            style={{
              marginTop: 15,
              backgroundColor: theme.secondaryBackground,
              padding: 15,
              border: `1px solid ${theme.border}`,
              borderRadius: 15,
            }}
          >
            <Text color={text} fontSize="sm">
              <i className="fa-solid fa-info-circle" /> You have{" "}
              <b>{totalTxns} transactions</b>, which means we will{" "}
              <span style={{ textDecoration: "underline" }}>not</span>{" "}
              automatically sync your wallets. You can sync your wallets
              manually by pressing the "Sync all" button above, or by clicking
              on the three dots next to a wallet and clicking "Sync New
              Transactions".
              <div
                style={{
                  marginTop: 15,
                  fontSize: 14,
                }}
              >
                Last synced at:{" "}
                <b>
                  {moment(client?.lastSyncedAt).format("h:mma, MM/DD/YYYY")}{" "}
                  <i className="fa-solid fa-clock" />
                </b>
              </div>
            </Text>
          </Box>
        )}

        {/* just removing for now. especially as we go more year round don't rlly need this alert here. could be incorporated into the tax flow / check list */}

        {groupModeOn && (
          <Text color={text} fontSize="md" style={{ marginBottom: 25 }}>
            Click on the accounts below to group them{" "}
            <Info message="Grouped accounts will be treated as a single account for tax purposes. This is useful if you want to have multiple accounts (ex. multiple wallets or a wallet + a CSV) be merged into one." />
          </Text>
        )}

        {accountsToReconnect.length > 0 && (
          <ReconnectAccountsWarning accounts={accountsToReconnect} />
        )}
        {/* <DashboardWarning accounts={accounts} /> */}

        {hasAccounts && <FreeTransactions />}

        <Grid
          marginTop={{ base: "0rem", md: "1rem" }}
          templateColumns={{
            sm: "repeat(1, 1fr)",
            // lg: "repeat(2, 1fr)",
            // "2xl": "repeat(3, 1fr)",
          }}
          alignItems="center"
          columnGap={2}
          rowGap={2}
        >
          <GroupList groups={groupedAccounts} />
          {/* TODO: OLD -> remove later {normalAccounts.map((accounts, i) => (
              <GridItem
                h="100%"
                w="100%"
                colSpan={1}
                key={i}
                // hacky
                maxW={isLarge ? "100%" : "93%"}
              >
                <AccountsGroupedRow accounts={accounts} />
              </GridItem>
            ))} */}
        </Grid>

        {!hasAccounts && (
          <Box
            colSpan={1}
            paddingTop={isLarge ? "300px" : "100px"}
            w="100%"
            margin="auto"
            textAlign="center"
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text color={text} fontSize="md" style={{ marginBottom: 25 }}>
              You don't have any accounts yet. Let's add your first one!
            </Text>

            <AddAccount
              numAccounts={normalAccounts.length}
              location="Accounts"
            />
          </Box>
        )}
        {/* {virtualAccounts.length > 0 && (
          <Box marginTop="5rem">
            <Flex flex={1} alignItems="center" display="flex" flexDir="row">
              <Heading margin="0" size="md">
                External Positions{" "}
                <Info
                  style={{ fontSize: 16 }}
                  message="These include staking/lending positions."
                />
              </Heading>
            </Flex>
            <Grid
              marginTop="1rem"
              templateColumns={{
                sm: "repeat(1, 1fr)",
                lg: "repeat(2, 1fr)",
                "2xl": "repeat(3, 1fr)",
              }}
              alignItems="flex-start"
              columnGap={2}
              rowGap={2}
            >
              {virtualAccounts.map((a) => (
                <GridItem colSpan={1} key={a.id}>
                  <AccountRow
                    account={a}
                    key={a.id}
                    portfolio={portfolioWithAssetsByAccountId[a.id] || null}
                  />
                </GridItem>
              ))}
            </Grid>
          </Box>
        )} */}
      </Box>

      <Box>
        <ButtonGroup>
          <ClaimCoinbaseDiscount me={me} client={client} />
        </ButtonGroup>
      </Box>

      {hasAccounts && (
        <VStack
          style={{
            color: colors.gray4,
            top: "2rem",
            position: "relative",
            margin: "1rem 0",
          }}
          alignItems="flex-start"
        >
          <ReactRouterLink
            to="/legal/privacy"
            style={{
              color: colors.gray4,

              alignItems: "flex-start",
              display: "block",
            }}
          >
            <Text fontSize="sm">
              Your privacy is our <u>priority.</u>
            </Text>
          </ReactRouterLink>
          <Text
            onClick={_onLeaveFeedback}
            fontSize="sm"
            style={{ cursor: "pointer" }}
            target={isMobile ? undefined : "_blank"}
          >
            <u>
              Leave feedback{" "}
              <i
                style={{
                  color: colors.red50,
                  animation: "pulse-big 1.5s infinite",
                }}
                className="fa-sharp fa-heart"
              />
            </u>
          </Text>
        </VStack>
      )}
    </AccountsContext.Provider>
  );
}

const ReconnectAccountsWarning = ({
  accounts,
}: {
  accounts: BaseAccountWithCurrentJobFields[];
}) => {
  // if (!accounts.length) return null;

  return (
    <Warning
      margin="1rem 0"
      bg={colors.red100}
      fontWeight="semibold"
      border={`1px solid ${colors.red50}`}
      color={colors.black}
      // connect icon
      iconName="fa-sharp fa-plug"
      iconStyle={{
        marginRight: 5,
        color: colors.red50,
        fontSize: 18,
      }}
      w="100%"
      message={
        <>
          Some of your accounts ({accounts.map((a) => a.description).join(", ")}
          ) need to be reconnected. Please click the "Reconnect" buttons below
          👇
        </>
      }
    />
  );
};

const ClaimCoinbaseDiscount = ({
  me,
  client,
}: {
  me: Maybe<Pick<BaseUserFields, "createdAt">>;
  client: Maybe<Pick<BaseClientFields, "createdAt" | "coinbaseWalletInfo">>;
}) => {
  const dispatch = useDispatch();
  const showModal = compose(dispatch, show);

  const showCoinbaseDiscountModal = () => {
    if (!client) return;
    showModal("CoinbaseDiscountModal", {
      coinbaseWalletInfo: client?.coinbaseWalletInfo || null,
    });
  };

  if (!me?.createdAt || !client?.createdAt) {
    return null;
  }

  if (
    new Date(me.createdAt).getTime() <
    config.coinbaseWalletDiscountDate.getTime()
  ) {
    return null;
  }

  if (
    new Date(client.createdAt).getTime() <
    config.coinbaseWalletDiscountDate.getTime()
  ) {
    return null;
  }

  return null; // for now just don't show it

  return (
    <Button
      onClick={showCoinbaseDiscountModal}
      variant="outline"
      colorScheme="gray"
      marginTop="1rem"
      leftIcon={
        client?.coinbaseWalletInfo?.confirmed ? (
          <i
            className="fa-sharp fa-check-circle"
            style={{
              color: colors.white,
            }}
          />
        ) : (
          <Image src={CoinbaseLogo} w="1rem" h="1rem" />
        )
      }
      bgColor="#0052ff"
      color={colors.white}
      _hover={{
        bgColor: "#0052ff",
        color: colors.white,
      }}
    >
      Claim{client?.coinbaseWalletInfo?.confirmed ? "ed" : ""} Wallet Discount
    </Button>
  );
};

const GroupSection = ({ section: _section }: { section: AccountGroupInfo }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  const filteredAccounts = useMemo(() => {
    // First apply the search filter
    const filtered = _section.accounts.filter((accountGroup) =>
      accountGroup.some(
        (account) =>
          account.description
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          account.walletAddress
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
      )
    );

    // Then sort as before
    return filtered.sort((a, b) => {
      // const aIsSyncing = a.find((a) => a.status === AccountStatusEnum.Syncing);
      // const bIsSyncing = b.find((a) => a.status === AccountStatusEnum.Syncing);
      // if (aIsSyncing) return -1;
      // if (bIsSyncing) return 1;

      const aTotalTxns = a.reduce(
        (acc, curr) => acc + (curr.numberOfTransactions || 0),
        0
      );
      const bTotalTxns = b.reduce(
        (acc, curr) => acc + (curr.numberOfTransactions || 0),
        0
      );
      return bTotalTxns - aTotalTxns;
    });
  }, [_section.accounts, searchQuery]);

  // Set accounts per page to 2
  const totalPages = Math.ceil(filteredAccounts.length / ACCOUNTS_PER_PAGE);
  const hasMultiplePages =
    Math.ceil(_section.accounts.length / ACCOUNTS_PER_PAGE) > 1;

  // Get current page's accounts
  const currentPageAccounts = useMemo(() => {
    if (showAll) {
      return filteredAccounts;
    }
    const startIndex = currentPage * ACCOUNTS_PER_PAGE;
    return filteredAccounts.slice(startIndex, startIndex + ACCOUNTS_PER_PAGE);
  }, [currentPage, filteredAccounts, showAll]);

  const handlePageChange = (p: { selected: number }) => {
    setCurrentPage(p.selected);
  };

  const MultiAccountSearch = useMemo(() => {
    return (
      <HStack spacing={2}>
        <Box mr={2} maxW={200}>
          <InputGroup size="sm">
            <InputLeftElement>
              <i className="fa-sharp fa-search" style={{ color: theme.text }} />
            </InputLeftElement>
            <Input
              placeholder={`Search ${_section.label?.toLowerCase()} accts...`}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(0); // Reset to first page when searching
              }}
              bg={theme.secondaryBackground}
              color={theme.text}
              borderRadius={20}
              focusBorderColor={theme.border}
              border={`1px solid ${theme.border} !important`}
            />
          </InputGroup>
        </Box>
        <Button
          size="xs"
          onClick={() => handlePageChange({ selected: currentPage - 1 })}
          isDisabled={currentPage === 0 || showAll || !filteredAccounts.length}
          bg={theme.secondaryBackground}
          color={theme.text}
          _hover={{
            bg: theme.secondaryBackground2,
          }}
        >
          <i className="fa-sharp fa-chevron-left" />
        </Button>
        <Button
          size="xs"
          onClick={() => handlePageChange({ selected: currentPage + 1 })}
          isDisabled={
            currentPage === totalPages - 1 ||
            showAll ||
            !filteredAccounts.length
          }
          bg={theme.secondaryBackground}
          color={theme.text}
          _hover={{
            bg: theme.secondaryBackground2,
          }}
        >
          <i className="fa-sharp fa-chevron-right" />
        </Button>
        <Button
          size="xs"
          onClick={() => {
            setShowAll(!showAll);
          }}
          bg={theme.secondaryBackground}
          color={theme.text}
          _hover={{
            bg: theme.secondaryBackground2,
          }}
        >
          {showAll ? "Show less" : "Show all"}
        </Button>
      </HStack>
    );
  }, [
    _section.label,
    currentPage,
    totalPages,
    showAll,
    filteredAccounts,
    searchQuery,
    theme,
  ]);

  return (
    <Box
      className="mb-4"
      bg={"transparent"}
      style={{
        borderRadius: 10,
        padding: "10px 0",
        width: "100%",
      }}
    >
      <div
        className="flex items-center justify-between cursor-pointer"
        style={{
          marginBottom: 25,
        }}
      >
        <div className="flex-1">
          <HStack>
            {_section.iconImageUrl && (
              <img
                src={_section.iconImageUrl}
                style={{
                  border: `1px solid ${theme.secondaryBackground}`,
                  width: 20,
                  height: 20,
                  borderRadius: 100,
                }}
              />
            )}
            <h3
              style={{
                color: theme.header,
                cursor: "pointer",
              }}
              onClick={() => setIsOpen(!isOpen)}
              className="text-lg font-semibold"
            >
              {_section.label}{" "}
              {isOpen ? (
                <i
                  style={{
                    marginLeft: 5,
                    fontSize: 14,
                  }}
                  className="fa-solid fa-chevron-up"
                />
              ) : (
                <i
                  style={{
                    marginLeft: 5,
                    fontSize: 14,
                  }}
                  className="fa-solid fa-chevron-down"
                />
              )}
            </h3>
          </HStack>
          <HStack spacing={4} className="mt-2">
            {_section.accountCount > 0 && (
              <HStack
                spacing={2}
                style={{
                  color: theme.text,
                }}
              >
                <i
                  style={{ fontSize: 12, color: theme.text }}
                  className="fa-sharp fa-wallet"
                />
                <Text
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  {numbro(_section.accountCount).format("0,0")}{" "}
                  {isLarge ? "accounts" : "accts"}
                </Text>
              </HStack>
            )}
            {_section.transactionCount > 0 && (
              <HStack
                spacing={2}
                style={{
                  color: theme.text,
                }}
              >
                <i
                  style={{ fontSize: 12, color: theme.text }}
                  className="fa-sharp fa-exchange"
                />
                <Text
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  {numbro(_section.transactionCount).format("0,0")}{" "}
                  {isLarge ? "transactions" : "txns"}
                </Text>
              </HStack>
            )}
            {_section.syncingCount > 0 && (
              <HStack
                spacing={2}
                style={{
                  color: theme.text,
                  fontSize: "0.875rem",
                }}
              >
                <i
                  style={{ fontSize: 12, color: theme.text }}
                  className="fa-sharp fa-sync fa-spin"
                />
                <Text
                  style={{
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  {numbro(_section.syncingCount).format("0,0")} syncing
                </Text>
              </HStack>
            )}
            {_section.failedCount > 0 && (
              <AwakenTooltip
                message={
                  <>
                    {_section.failedCount} accounts failed to sync. You can
                    click in to re-sync them below.
                  </>
                }
              >
                <HStack
                  spacing={2}
                  style={{
                    color: colors.red50,
                    fontSize: "0.875rem",
                  }}
                >
                  <i
                    style={{ fontSize: 12, color: colors.red50 }}
                    className="fa-sharp fa-exclamation-triangle"
                  />
                  <Text
                    style={{
                      fontWeight: "400",
                      fontSize: 14,
                    }}
                  >
                    {numbro(_section.failedCount).format("0,0")} failed
                  </Text>
                </HStack>
              </AwakenTooltip>
            )}
            <Flex flex={1} />
            {hasMultiplePages && isLarge && MultiAccountSearch}
          </HStack>
        </div>
      </div>

      {!isLarge && hasMultiplePages && (
        <div style={{ marginBottom: 25 }}>{MultiAccountSearch}</div>
      )}

      {isOpen && (
        <>
          <Grid
            marginTop={{ base: "0rem", md: "1rem" }}
            templateColumns={{
              sm: "repeat(1, 1fr)",
              xl: "repeat(2, 1fr)",
              "2xl": "repeat(3, 1fr)",
            }}
            alignItems="start"
            columnGap={4}
            rowGap={4}
            width="100%"
          >
            {currentPageAccounts.map((accounts, i) => (
              <GridItem maxW="93vw" key={i} w="100%">
                <AccountsGroupedRow accounts={accounts} />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

const GroupList = ({ groups }: { groups: EnhancedAccountGroups }) => {
  const sections = useMemo(() => {
    return Object.entries(groups).map(([_key, section]) => section);
  }, [groups]);

  // sort so one with most txns is at the top. and remove any with no accounts
  const sortedSections = sections
    .sort((a, b) => b.transactionCount - a.transactionCount)
    .filter((s) => s.accounts.length > 0);

  return (
    <div className="space-y-4 w-full">
      {sortedSections.map((section) => (
        <GroupSection key={section.label} section={section} />
      ))}
    </div>
  );
};

export default Accounts;
