import { useMutation } from "@apollo/client";
import { Box, Container, Text, VStack } from "@chakra-ui/react";
import { isNil } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { MutationUpdateClientArgs } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Button, Input } from "src/components/styled";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { Touchable } from "../Touchable";
import StatusTag from "../styled/StatusTag";

type Props = InjectedProps & {
  onSuccess?: () => Promise<void>;
  defaultLockDate?: Maybe<Date>;
};

function _LockTaxesModal(props: Props) {
  const { handleHide, show: isVisible, defaultLockDate } = props;

  console.log(defaultLockDate);
  const [lockDate, setLockDate] = useState<null | string>(
    defaultLockDate
      ? moment(defaultLockDate).format("MM/DD/YYYY")
      : moment().format("MM/DD/YYYY")
  );
  const toast = useMyToast();
  const theme = useTheme();
  const { clientId } = useParams();

  const [updateClient] = useMutation(api.clients.update);

  const _onSubmit = async function () {
    try {
      if (!clientId) {
        toast.show({
          message: "Client not found",
          status: "error",
        });
        return;
      }

      if (isNil(lockDate)) {
        toast.show({
          message: "Please select a date",
          status: "error",
        });
        return;
      }

      // Convert date string to Date object
      const date = moment(lockDate).utc().toDate();

      const mutations: MutationUpdateClientArgs = {
        clientId,
        lockTaxesUntil: date,
      };

      await updateClient({
        variables: mutations,
        refetchQueries: [api.clients.retrieve],
      });

      toast.show({
        message:
          "Successfully locked transactions before " +
          moment(date).format("MM/DD/YYYY"),
        status: "success",
      });

      handleHide();

      if (props.onSuccess) {
        return await props.onSuccess();
      }
    } catch (err) {
      console.error(err);
      toast.show({
        message: (err as any)?.message || "Failed to lock transactions",
        status: "error",
      });
    }
  };

  const _unlock = async () => {
    try {
      if (!clientId) {
        toast.show({
          message: "Client not found",
          status: "error",
        });
        return;
      }

      const mutations: MutationUpdateClientArgs = {
        clientId,
        lockTaxesUntil: null,
      };

      await updateClient({
        variables: mutations,
        refetchQueries: [api.clients.retrieve],
      });

      toast.show({
        message: "Successfully unlocked transactions",
        status: "success",
      });

      handleHide();

      if (props.onSuccess) {
        return await props.onSuccess();
      }
    } catch (err) {
      console.error(err);
      toast.show({
        message: (err as any)?.message || "Failed to lock transactions",
        status: "error",
      });
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      handleHide={handleHide}
      minH="35vh"
      Footer={
        <VStack style={{ width: "100%", marginBottom: 10 }}>
          <Button
            width="100%"
            variant="primary"
            onClick={_onSubmit}
            style={{ marginBottom: "1rem" }}
          >
            Lock tax reports until {moment(lockDate).format("MM/DD/YYYY")}
          </Button>

          {/* if there is a default lock date, also have a button to unlock all */}
          {props.defaultLockDate && (
            <Touchable onClick={_unlock} label="Unlock all tax reports" />
          )}
        </VStack>
      }
    >
      <Container
        padding="3rem 0 1rem 0"
        style={{
          alignItems: "center",
          textAlign: "center",
        }}
        marginTop="0px !important"
      >
        <Text
          color={theme.header}
          fontSize="xl"
          fontWeight="bold"
          marginBottom="0"
          style={{ textAlign: "left" }}
        >
          Lock Tax Reports{" "}
          <StatusTag
            type="beta"
            label="Beta"
            infoMessage="This feature is new so it is in beta. Please use with caution."
          />
        </Text>

        <Box style={{ marginTop: 25 }}>
          <Text
            style={{
              marginBottom: 20,
              textAlign: "left",
            }}
            color={theme.text}
          >
            Before using this feature, please read{" "}
            <a
              href="https://help.awaken.tax/en/articles/10889180-locking-your-tax-reports"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: colors.primary,
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              our tutorial <i className="fa fa-external-link" />
            </a>
          </Text>

          <Text
            style={{
              marginBottom: 20,
              textAlign: "left",
            }}
            color={theme.text}
          >
            Any edits made to transactions before this date will not be applied
            and your numbers will be locked. You can still edit information and
            it will affect transactions after this date.
          </Text>

          <Text
            style={{
              marginBottom: 10,
              textAlign: "left",
              fontWeight: "bold",
            }}
            color={theme.text}
          >
            Lock transactions before ({lockDate}):
          </Text>

          <Input
            type="date"
            value={lockDate || ""}
            onChange={(e) => setLockDate(e.target.value)}
            autoFocus
            placeholder="Select date"
          />

          <br />
          <Text
            style={{
              marginTop: 5,
              textAlign: "left",
              fontSize: "14px",
            }}
            color={theme.text}
          >
            Note: This means any edits made to transactions on or before this
            date will not affect tax calculations for that period.
          </Text>
        </Box>
      </Container>
    </Modal>
  );
}

export const LockTaxesModal = connectModal({
  name: "LockTaxesModal",
})(_LockTaxesModal);
