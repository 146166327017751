import { Box, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { isNil, truncate } from "lodash";
import numbro from "numbro";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AssetTypeEnum,
  CurrencyCodeEnum,
  PortfolioAssetPosition,
} from "src/api/generated/types";
import SmallLogo from "src/assets/awaken/logos/awaken-black.jpeg";
import { AwakenTooltip } from "src/components";
import { AssetIcon } from "src/components/styled/Assets";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import Helpers, { D, formatNum } from "src/utils/helpers";
import truncateMiddle from "truncate-middle";
import { AssetKeyInfoContext } from "../utils";

const NAME_FLEX = 3;
const VALUE_FLEX = 2;

export const AccountPosition = ({
  position,
  clientId,
  currency,
  isLast,
}: {
  position: PortfolioAssetPosition;
  clientId: string;
  currency: CurrencyCodeEnum;
  isLast: boolean;
}) => {
  const { symbol, iconImageUrl, assetKeyV2 } = useContext(AssetKeyInfoContext);
  const currentValue = position.fiatAmountCents;
  const toast = useMyToast();
  const theme = useTheme();

  const name = position.name;

  return (
    <Link
      to={`/clients/${clientId}/transactions?accountIds=${position.accountId}&assetKeyV2=${assetKeyV2}`}
    >
      <HStack
        style={{
          justifyContent: "space-between",
          borderBottom: isLast ? "none" : `1px solid ${theme.border}`,
        }}
        opacity={1}
        // borderRadius={7}
        // borderBottom="none !important"
        width="100%"
        padding="0.5rem 0.75rem"
      >
        <div style={{ marginRight: 15, position: "relative" }}>
          <AssetIcon
            asset={{
              iconImageUrl: iconImageUrl,
              symbol: symbol,
              type: AssetTypeEnum.FungibleToken,
            }}
            size={35}
          />

          <img
            src={position.logoUrl || SmallLogo}
            style={{
              width: "1.25rem",
              height: "1.25rem",
              borderRadius: "100%",
              bottom: -5,
              border: `1px solid ${theme.border}`,
              backgroundColor: theme.border,
              right: -5,
              borderRight: "100%",
              position: "absolute",
            }}
          />
        </div>

        <Box
          display="flex"
          style={{
            flex: NAME_FLEX,
            alignItems: "flex-start",
          }}
          marginLeft="0 !important"
          alignItems="center"
          position="relative"
        >
          <VStack marginLeft="0 !important" w="100%" alignItems="flex-start">
            <HStack w="100%">
              <VStack alignItems="flex-start" w="100%">
                <HStack w="100%">
                  <AwakenTooltip message={name} openDelay={200}>
                    <Text
                      isTruncated
                      margin="0 !important"
                      fontSize="md"
                      fontWeight="500"
                      color={theme.header}
                      _hover={{
                        textDecoration: "underline",
                        color: colors.primary,
                      }}
                    >
                      {truncateMiddle(name, 8, 8, "..")}
                    </Text>
                  </AwakenTooltip>
                </HStack>

                <HStack marginTop="2px !important">
                  {position.descriptionImageUrl && (
                    <img
                      src={position.descriptionImageUrl}
                      style={{
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "100%",
                      }}
                    />
                  )}
                  <Text margin="0" fontSize="sm" color={theme.header}>
                    {truncate(
                      position.description ||
                        Helpers.capitalize(position.provider) ||
                        "",
                      { length: 20 }
                    )}
                    {!isNil(position.positionDescription)
                      ? ` • ${position.positionDescription}`
                      : ""}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          </VStack>
        </Box>

        <VStack
          style={{
            flex: VALUE_FLEX,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginLeft: 0,
          }}
        >
          <CurrentValue breakdown={position} currency={currency} />

          <Tooltip
            label={`${numbro(position.amount ?? 0).format(
              "0,000.[00000000]"
            )} ${symbol}`}
            openDelay={500}
          >
            <Text
              margin="0"
              marginTop="2px !important"
              isTruncated
              fontSize="sm"
              isNumeric
              color={theme.header}
            >
              {formatNum(position.amount ?? 0, false, "0,0.[0000]") + " "}
              {truncate(symbol || "", { length: 8 })}
            </Text>
          </Tooltip>
        </VStack>

        <i
          style={{ marginLeft: 10, fontSize: 12, color: theme.text }}
          className="fa-solid fa-chevron-right"
        />
      </HStack>
    </Link>
  );
};

const CurrentValue = ({
  breakdown,
  currency,
}: {
  breakdown: PortfolioAssetPosition;
  currency: CurrencyCodeEnum;
}) => {
  const { clientId } = useParams<{ clientId: string }>();

  const currentValue = breakdown.fiatAmountCents;
  const theme = useTheme();

  return (
    <Box>
      <Text
        fontSize="md"
        isNumeric
        color={theme.header}
        style={{
          fontWeight: "500",
        }}
      >
        {D(currentValue || 0, currency).toFormat()}
      </Text>
      {/* <Text
          fontSize="sm"
          marginTop={0}
          isTruncated
          // fontStyle="italic"
          style={{ color: colors.gray30 }}
        >
          {D(breakdown.currentTokenPriceFiatAmount || 0, currency).toFormat()}/
          {isNFT
            ? singular(tokenName)
            : asset?.symbol?.toUpperCase() || singular(tokenName)}
        </Text> */}
    </Box>
  );
};
