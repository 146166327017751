import { useMutation } from "@apollo/client";
import { Grid, GridItem, Heading, HStack, Text } from "@chakra-ui/react";
import _ from "lodash";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { connectModal, InjectedProps, show } from "redux-modal";
import { api } from "src/api";
import { BaseAssetFields, BaseFullTransactionFields } from "src/api/fragments";
import { TransactionTypeOption } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Option } from "src/components/styled";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { LabelInfo } from "./LabelInfo";

const DEBOUNCE_MS = 200;

type Props = InjectedProps & {
  asset: Maybe<BaseAssetFields>;
  onCreate?: (x: any) => any;
  labelOptions: TransactionTypeOption[];
  selectedOption: Option | undefined;
  onSelectOption: (o: Maybe<TransactionTypeOption>) => void;
  loading: boolean;
  transaction?: BaseFullTransactionFields;
};

function _LabelsModal({
  handleHide,
  show: isVisible,
  asset,
  onCreate,
  selectedOption,
  labelOptions,
  onSelectOption,
  loading,
  transaction,
}: Props) {
  const isSol = transaction?.provider === "solana";
  const [leaveFeedback] = useMutation(api.contact.feedback);
  const toast = useMyToast();
  const dispatch = useDispatch();
  const theme = useTheme();

  const _reportTxnCannotLabel = async () => {
    const feedback = [
      `Please provide details: \n`,
      `\n\n\n------------------------------\n`,
      `Info (do not edit this info):`,
      `Provider: ${transaction?.provider}`,
      `URL: ${window.location.href}`,
    ].join("\n");

    // const variables: MutationSubmitFeedbackArgs = {
    //   feedback: [
    //     `I cannot label my transaction`,
    //     `Provider: ${transaction?.provider}`,
    //     `URL: ${window.location.href}`,
    //   ].join("\n"),
    // };

    dispatch(
      show("FeedbackModal", {
        startingFeedback: feedback,
      })
    );

    // try {
    //   await leaveFeedback({
    //     variables,
    //   });

    //   toast.show({
    //     message: "Success, we have received your report!",
    //     status: "success",
    //   });
    // } catch (err) {
    //   if (err instanceof ApolloError) {
    //     console.log(err);
    //     toast.show({
    //       message: "There was an error submitting your feedback.",
    //       status: "error",
    //     });
    //   }
    // }
  };

  const categories = useMemo(
    () => (!labelOptions ? [] : _.uniq(labelOptions.map((o) => o.category))),
    [labelOptions]
  );
  const optionsByCategory = useMemo(
    () => _.groupBy(labelOptions, (o: TransactionTypeOption) => o.category),
    [labelOptions]
  );
  const isLarge = useIsLargeScreen();

  return (
    <Modal
      headerProps={{ paddingBottom: 0 }}
      // title={
      //   <>
      //     <Box
      //       style={{
      //         backgroundColor: colors.lightBlue100,
      //         borderRadius: 10,
      //         fontSize: 14,
      //         padding: 15,
      //         color: colors.black,
      //         fontWeight: "normal",
      //         marginBottom: "1rem",
      //         marginTop: "1.5rem",
      //         border: `1px solid ${colors.primary}`,
      //       }}
      //     >
      //       If you cannot select a specific label for this transaction,{" "}
      //       <span
      //         onClick={_reportTxnCannotLabel}
      //         style={{
      //           fontWeight: "bold",
      //           color: colors.primary,
      //           cursor: "pointer",
      //         }}
      //       >
      //         click here
      //       </span>{" "}
      //       and we'll look into it!
      //       <Info
      //         message={
      //           "Awaken's labeling system is strict to make sure we create the correct taxable events for your transactions. This means that sometimes if a transaction looks different from what we expect, the option may be grayed out. Just click this button though, and we'll look into this!"
      //         }
      //       />
      //     </Box>
      //   </>
      // }
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <div
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: theme.medBackground,
            color: theme.header,
            padding: "0.5rem",
            borderRadius: 100,
          }}
        >
          If you want to learn more about the taxes for each of these labels,{" "}
          <a
            style={{
              color: colors.primary,
              textDecoration: "underline",
              fontSize: 14,
              fontWeight: "bold",
            }}
            href="https://help.awaken.tax/en/articles/10453755-label-taxation-documentation"
          >
            view our documentation.
          </a>
        </div>
      }
      minW={isLarge ? "50rem" : undefined}
      w={!isLarge ? "95%" : undefined}
      minH="50vh"
      footerProps={{
        paddingTop: 0,
      }}
      bodyProps={{
        style: {
          padding: isLarge ? "0rem 2rem 1rem 2rem" : "1rem",
        },
      }}
      maxW="1000px"
    >
      <Grid
        paddingBottom="0"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        marginTop="2rem"
        // alignContent="flex-start"
      >
        {!categories
          ? null
          : categories.map((category) => {
              const options = optionsByCategory[category];
              if (!options || options.length === 0) return null;
              return (
                <GridItem
                  marginBottom="1rem"
                  colSpan={1}
                  position="relative"
                  key={category}
                  paddingRight="20px"
                >
                  <HStack
                    marginTop="0.5rem"
                    paddingLeft="3px"
                    alignItems="center"
                    paddingRight="15px"
                    marginBottom="10px"
                  >
                    <Heading
                      color={theme.text}
                      size="sm"
                      style={{
                        fontWeight: "700",
                        fontSize: 12,
                        marginTop: 0,
                        textTransform: "uppercase",
                        fontStretch: "extra-expanded",
                        marginBottom: 0,
                      }}
                    >
                      {category?.toLowerCase()?.includes("liquidity")
                        ? "Liquidity"
                        : category}
                    </Heading>

                    {category?.toLowerCase()?.includes("liquidity") && (
                      <a
                        target="_blank"
                        style={{
                          borderRadius: 100,
                          backgroundColor: theme.blueBg,
                          padding: "0.25rem 0.5rem",
                        }}
                        href="https://help.awaken.tax/en/articles/10835423-how-to-handle-liquidity-providing"
                      >
                        <Text
                          fontSize="xs"
                          color={colors.primary}
                          fontWeight="bold"
                        >
                          Help Guide{" "}
                          <i className="fa-solid fa-arrow-up-right" />
                        </Text>
                      </a>
                    )}
                  </HStack>

                  {options.map((o) => (
                    <LabelInfo
                      o={o}
                      key={o.value}
                      loading={loading}
                      handleHide={handleHide}
                      onSelectOption={onSelectOption}
                      transaction={transaction}
                      isSelected={selectedOption?.value === o.value}
                    />
                  ))}
                </GridItem>
              );
            })}
      </Grid>
    </Modal>
  );
}

export const LabelsModal = connectModal({
  name: "LabelsModal",
})(_LabelsModal);
