import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Container,
  Divider,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import confetti from "canvas-confetti";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Company, Query } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

type Props = InjectedProps;

function _BuilderDiscountModal({ handleHide, show: isVisible }: Props) {
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [matchedCompany, setMatchedCompany] = useState<any>(null);
  const { clientId } = useParams();

  const toast = useMyToast();
  const theme = useTheme();

  // Query to get free companies
  const { data: freeCompaniesData } = useQuery<Pick<Query, "getFreeCompanies">>(
    api.misc.getFreeCompanies,
    { fetchPolicy: "cache-and-network" }
  );

  const freeCompanies = freeCompaniesData?.getFreeCompanies ?? [];

  // Mutations
  const [sendVerification, { loading: sendingVerification }] = useMutation(
    api.users.sendVerification
  );
  const [verifyCompany, { loading: verifying }] = useMutation(api.users.verify);

  const handleSendVerification = async () => {
    if (!email) {
      return toast.show({
        message: "Please enter an email address",
        status: "error",
      });
    }

    // Check if email domain matches any free companies
    const domain = email.split("@")[1];
    const matchingCompany = freeCompanies.find(
      (company: Company) =>
        !!company.emails.find((e) => e.toLowerCase() === domain.toLowerCase())
    );

    setMatchedCompany(matchingCompany);

    try {
      await sendVerification({
        variables: { email },
      });

      setShowCodeInput(true);
      toast.show({
        message: "Verification email sent!",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      return toast.show({
        message: "Please enter the verification code",
        status: "error",
      });
    }

    try {
      await verifyCompany({
        variables: {
          code: verificationCode,
          clientId,
        },
      });

      // Trigger confetti animation
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });

      toast.show({
        message: "You've unlocked Awaken for free!",
        status: "success",
      });

      handleHide();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title="Builders Get Awaken FREE"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          marginTop={25}
          variant="primary"
          marginBottom={25}
          isLoading={showCodeInput ? verifying : sendingVerification}
          onClick={showCodeInput ? handleVerifyCode : handleSendVerification}
        >
          {showCodeInput ? "Verify Code" : "Send Verification"}
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <VStack spacing={4} align="stretch">
          <Text style={{ color: theme.text }} fontSize="lg" fontWeight="500">
            Thank you for helping bring crypto to the world! We want to show our
            appreciation by giving you Awaken 100% free this year.{" "}
            <Link
              to="/builders"
              onClick={() => handleHide()}
              style={{
                color: colors.primary,
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              See supported companies
            </Link>
            .
          </Text>

          <br />

          <Divider
            style={{
              borderColor: theme.border,
            }}
          />

          {!showCodeInput ? (
            <>
              <Text
                style={{ color: theme.text }}
                fontSize="sm"
                fontWeight="500"
                mb="-2"
              >
                What's your company email?
              </Text>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your company email..."
                style={{ color: theme.header }}
              />
            </>
          ) : (
            <>
              {matchedCompany && (
                <HStack>
                  <Text style={{ color: theme.header }} fontSize="md">
                    {matchedCompany.name}
                    {matchedCompany.imageUrl && (
                      <img
                        src={matchedCompany.imageUrl}
                        alt={matchedCompany.name}
                        style={{
                          width: 20,
                          height: 20,
                          marginLeft: 5,
                          marginRight: 5,
                          display: "inline-block",
                          borderRadius: 100,
                          border: `1px solid ${theme.border}`,
                        }}
                      />
                    )}
                    is eligible!
                  </Text>
                </HStack>
              )}

              <Text style={{ color: theme.text }} fontSize="md">
                We just emailed you a verification code (you may need to check
                your spam folder). Paste that below to unlock free Awaken.
              </Text>

              <Input
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Code..."
                style={{ color: theme.header }}
              />
            </>
          )}
        </VStack>
      </Container>
    </Modal>
  );
}

export const BuilderDiscountModal = connectModal({
  name: "BuilderDiscountModal",
})(_BuilderDiscountModal);
