import { Box, BoxProps, Text } from "@chakra-ui/react";
import { compose } from "lodash/fp";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setHasBanner } from "src/redux/reducers/globalState";
import { colors } from "src/theme";
import { Touchable } from "../Touchable";

type BannerProps = BoxProps & {
  message: string | JSX.Element;
  dismissKey?: string;
};

export const BANNER_HEIGHT_PX = "35";
export const BANNER_HEIGHT = `${BANNER_HEIGHT_PX}px`;

const Banner = ({
  message,
  bgColor,
  color,
  dismissKey,
  ...other
}: BannerProps) => {
  const dispatch = useDispatch();
  const _setHasBanner = compose(dispatch, setHasBanner);
  const value = dismissKey ? localStorage.getItem(dismissKey) : null;
  const [isHidden, setHidden] = useState(!!dismissKey && value === "hidden");

  const _onHide = () => {
    if (dismissKey) {
      localStorage.setItem(dismissKey, "hidden");
      setHidden(true);
      _setHasBanner(false);
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <Box
      w="100%"
      h={message ? BANNER_HEIGHT : "0px"}
      bgColor={bgColor || colors.black}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      fontWeight="semibold"
      transition="0.3s ease-in-out height"
      {...other}
    >
      {typeof message === "string" ? (
        <Text fontSize="sm" color={color || colors.white}>
          {message}
        </Text>
      ) : (
        <>{message}</>
      )}
      {dismissKey && (
        <Touchable
          style={{
            position: "absolute",
            right: 15,
            top: 7,
          }}
          _hover={{
            bg: colors.green50,
          }}
          iconStyle={{ color: colors.white }}
          onClick={_onHide}
          iconName="fa-sharp fa-times"
        />
      )}
    </Box>
  );
};

export default Banner;
