import { Box, Container, HStack, Image, Text } from "@chakra-ui/react";
import { compose } from "lodash/fp";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps, show } from "redux-modal";
import { AccountTypeEnum } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Button, Select } from "src/components/styled";
import { OptionWithData } from "src/components/styled/Form/types";
import YoutubeLink from "src/components/YoutubeLink";
import { Maybe } from "src/core";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { EmojiWalletName } from "..";
import { useHandleVezgoLink } from "../AccountModal/AccountsWorkflow";
import {
  APPLICABLE_INTEGRATIONS,
  AwakenCSVLink,
} from "../AccountModal/constants";
import { IntegrationAction } from "../AccountModal/types";
import { DEFAULT_EMOJIS } from "../WalletAccountModal/defaultEmojis";
import { WalletHeaderImage } from "../WalletAccountModal/WalletHeaderImage";

type Props = InjectedProps & {
  walletName: string;
  walletLogoUrl: string;
  allowedIntegrationProviders?: Set<string>;
  onSuccess?: () => void;
};

const TUTORIALS: Record<string, string> = {
  "phantom wallet": "https://youtu.be/nxyVN5NkvZk",
  "ledger wallet": "https://youtu.be/x6bh2i_-TzQ?si=TGw0fmPFd8_7Ie30&t=120",
  "metamask wallet": "https://youtu.be/vgI_Qm7OJYM?si=sFFUI1zyX46305nW&t=141",
  "coinbase wallet": "https://youtu.be/urNmPGL1-aA?si=xSJoqGdSanhQxKTL&t=115",
  // "trust wallet": "https://youtu.be/EWOg3MTleVg?si=uYyCAoalAY2-T0dd&t=116",
};

function _WalletModal({
  handleHide,
  show: isVisible,
  onSuccess,
  walletName,
  walletLogoUrl,
  allowedIntegrationProviders,
}: Props) {
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const isLarge = useIsLargeScreen();
  const [chain, setChain] = useState<Maybe<OptionWithData>>(null);
  const { clientId } = useParams();

  // console.log(walletName);
  const youtubeVideo = TUTORIALS[(walletName || "")?.toLowerCase()];
  const [label, setLabel] = useState("");
  const [isOpenEmoji, setOpenEmoji] = useState(false);
  const [defaultEmoji, defaultName] =
    DEFAULT_EMOJIS[Math.floor(Math.random() * DEFAULT_EMOJIS.length)];
  const [emoji, setEmoji] = useState(defaultEmoji);
  const theme = useTheme();
  const vezgoLink = useHandleVezgoLink(clientId);

  const _onAddWallet = async () => {
    if (!chain) return;

    const data = chain.data;

    const vezgoOption = data.options.find(
      (a) => a.action === IntegrationAction.VezgoLink
    );

    if (vezgoOption) {
      await vezgoLink(data, vezgoOption);
      return;
    }

    const hasAddressSupport = data.options.find(
      (a) => a.action === IntegrationAction.TriggerModal
    );

    if (hasAddressSupport) {
      _showModal("WalletAccountModal", {
        provider: chain?.value,
        onSuccess: () => {
          handleHide();
          if (onSuccess) {
            onSuccess();
          }
        },
        integration: chain.data,
        label,
        emoji,
      });
      return;
    }

    const awakenOption = data.options.find(
      (a) => a.action === IntegrationAction.AwakenCsvFileUpload
    );

    _showModal("AwakenCSVUploadModal", {
      integration: data,
      mode: "new_account",
      provider: data.provider,
      accountId: null,
      option: awakenOption,
      message: data.awakenCsvModalDescription || (
        <AwakenCSVLink chain={data.provider} />
      ),
      onSuccess: () => {
        handleHide();
        if (onSuccess) {
          onSuccess();
        }
      },
    });
  };

  return (
    <Modal
      title={
        <>
          <WalletHeaderImage logoUrl={walletLogoUrl} />
          Add Wallet
        </>
      }
      isVisible={isVisible}
      handleHide={handleHide}
      w={isLarge ? undefined : "95%"}
      overflow="visible"
      Footer={
        <Box w="100%">
          <Button
            width="100%"
            variant="primary"
            cursor="pointer"
            onClick={_onAddWallet}
          >
            Add Blockchain
          </Button>
        </Box>
      }
      headerProps={{
        style: {
          padding: isLarge ? "2rem 2rem 1rem 2rem" : "1rem",
        },
      }}
      footerProps={{
        style: {
          padding: isLarge ? "2rem" : "1rem",
        },
      }}
      bodyProps={{
        style: {
          padding: isLarge ? "0 2rem" : "1rem",
        },
      }}
    >
      <Container padding="0px" marginTop="0px !important" paddingBottom="2rem">
        {/* <Input
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          label="Wallet Address"
          // subtitle={messageForWalletInput}
          infoMessage="Your wallet address. For ETH/Polygon, this starts with 0x."
          placeholder={"0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326"}
        /> */}
        <Text color={theme.text} marginBottom="1rem">
          If your wallet supports multiple chains, you'll have to upload each of
          them.
        </Text>

        {youtubeVideo && <YoutubeLink youtubeVideo={youtubeVideo || ""} />}

        <EmojiWalletName
          label={label}
          setLabel={setLabel}
          emoji={emoji}
          setEmoji={setEmoji}
          isOpenEmoji={isOpenEmoji}
          setOpenEmoji={setOpenEmoji}
          defaultName={defaultName}
        />
        <br />
        <Select
          label="Blockchain"
          value={chain}
          options={APPLICABLE_INTEGRATIONS.filter(
            (i) => i.type === AccountTypeEnum.Wallet
          )
            .filter((i) =>
              allowedIntegrationProviders
                ? allowedIntegrationProviders.has(i.provider)
                : true
            )
            .map((integration) => ({
              value: integration.provider,
              label: integration.name,
              data: integration,
            }))}
          selectProps={{
            onChange: (o) => setChain(o as OptionWithData),
            components: {
              Option: ({ innerRef, innerProps, label, data }) => {
                return (
                  <HStack
                    w="100%"
                    padding="0.75rem 0.5rem"
                    ref={innerRef}
                    {...innerProps}
                    _hover={{
                      bg: theme.secondaryBackground,
                      cursor: "pointer",
                    }}
                  >
                    {
                      <Image
                        style={{ borderRadius: 3 }}
                        src={(data as any).data.logoUrl}
                        w="1rem"
                        h="1rem"
                        margin="0 0.5rem"
                      />
                    }

                    <div style={{ fontSize: 14, color: theme.text }}>
                      {(data as any).label}
                    </div>
                  </HStack>
                );
              },
            },
          }}
        />
      </Container>
    </Modal>
  );
}

export const WalletModal = connectModal({
  name: "WalletModal",
})(_WalletModal);
