import { useMutation } from "@apollo/client";
import { Button, Container, HStack, Text } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { OptionProps } from "react-select";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import {
  Mutation,
  MutationUpdateAssetPricingKeyArgs,
} from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Option } from "src/components/styled";
import { Input } from "src/components/styled/Form/Input";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { IntegrationProviderInfo } from "../AccountModal/types";

type Props = InjectedProps & {
  coingeckoTokenId: string;
  assetKey: string;
  onSave?: (newKey: string) => any;
};

function _AssetPriceModal({
  handleHide,
  show: isVisible,
  coingeckoTokenId,
  assetKey,
  onSave,
}: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const theme = useTheme();

  const [coinGeckoTokenId, setCoingeckoId] = useState(coingeckoTokenId || "");

  // GQL
  const [updatePricingKey, { loading: loadingUpdate }] = useMutation<
    Pick<Mutation, "updateAssetPricingKey">,
    MutationUpdateAssetPricingKeyArgs
  >(api.assets.updatePricingKey);

  const _update = useCallback(async () => {
    const variables: MutationUpdateAssetPricingKeyArgs = {
      clientId: clientId || "",
      assetKey: assetKey,
      coingeckoTokenId: coinGeckoTokenId,
    };

    const response = await updatePricingKey({
      variables,
      refetchQueries: [api.clients.assetOptions],
    });

    const key = response?.data?.updateAssetPricingKey || "";

    handleHide();

    if (onSave) {
      onSave(key);
    }

    toast.show({
      message: "Pricing updated",
      status: "success",
    });
  }, [clientId, assetKey, coinGeckoTokenId]);

  const _onSubmit = useCallback(
    async (e?: any) => {
      try {
        e?.preventDefault();
        await _update();
      } catch (err) {
        toast.show({
          message: (err as Error).message || "An error occurred.",
          status: "error",
        });
      }
    },
    [_update]
  );

  return (
    <Modal
      title={
        <HStack>
          <Text fontSize="3xl" margin={0} fontWeight={"bold"}>
            Asset Price
          </Text>
        </HStack>
      }
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          type="submit"
          variant="primary"
          onClick={_onSubmit}
          isLoading={loadingUpdate}
          disabled={loadingUpdate}
        >
          Save Price
        </Button>
      }
    >
      <form onSubmit={_onSubmit}>
        <Container padding="0px" marginTop="0px !important">
          <Text
            style={{
              color: theme.text,
            }}
          >
            This is the coingecko price of the asset. If this looks wrong, check
            out{" "}
            <a
              style={{
                color: colors.primary,
                fontWeight: "bold",
                textDecoration: "underline",
              }}
              target="_blank"
              href="https://help.awaken.tax/en/articles/10626542-how-do-i-change-the-pricing-of-an-asset"
            >
              this guide
            </a>
            . If you have questions,{" "}
            <span
              onClick={() => {
                (window as any).Intercom("show");
              }}
              style={{
                cursor: "pointer",
                color: colors.primary,
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              contact support
            </span>
            .
          </Text>

          <br />

          <Input
            value={coinGeckoTokenId}
            onChange={(e) => setCoingeckoId(e.target.value)}
            label="Coingecko Token ID"
            subtitle={
              <>
                Find the asset on Coingecko, and copy the "API ID" field. <br />
                <a
                  style={{ color: colors.primary, fontWeight: "bold" }}
                  href={
                    coinGeckoTokenId
                      ? `https://coingecko.com/en/coins/${coinGeckoTokenId}`
                      : "https://coingecko.com"
                  }
                  target="_blank"
                >
                  Open Coingecko <i className="fas fa-external-link-alt" />
                </a>
              </>
            }
            placeholder=""
          />
        </Container>
      </form>
    </Modal>
  );
}

const CustomOption = ({
  innerRef,
  innerProps,
  data,
}: OptionProps<Option & { integration: IntegrationProviderInfo }>) => {
  const theme = useTheme();

  return (
    <div ref={innerRef} {...innerProps}>
      <HStack
        margin="0"
        bg={theme.background}
        cursor="pointer"
        padding="0.5rem 1rem"
        _hover={{
          bg: theme.secondaryBackground,
        }}
      >
        <Text color={theme.text} marginRight="1rem !important" flex={1}>
          {data.integration?.name}
        </Text>
        <img
          style={{
            width: "2rem",
            height: "auto",
            borderRadius: "100%",
          }}
          src={data.integration?.logoUrl || ""}
          alt={data.integration?.name}
        />
      </HStack>
    </div>
  );
};

export const AssetPriceModal = connectModal({
  name: "AssetPriceModal",
})(_AssetPriceModal);
