import { HStack } from "@chakra-ui/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api, apolloClient } from "src/api";
import { ActionSheet } from "src/components/styled/ActionSheet/ActionSheet";
import { Touchable } from "src/components/Touchable";
import { useMyToast } from "src/hooks";
import { useAssetMutations } from "src/hooks/useAssetMutations";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { AssetKeyInfoContext } from "./utils";

export const ReportAsset = () => {
  const { clientId } = useParams();
  const { symbol, assetKeyV2, coingeckoId } = useContext(AssetKeyInfoContext);
  const isLarge = useIsLargeScreen();

  const toast = useMyToast();
  const { handleMarkAsSpam, handleMarkAsWorthless } = useAssetMutations();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _markAsSpam = async () => {
    try {
      if (!assetKeyV2) {
        return;
      }

      await handleMarkAsSpam(assetKeyV2);

      void apolloClient.refetchQueries({
        include: [api.portfolio.getAssetPositions],
      });

      navigate(`/clients/${clientId}/portfolio`);
    } catch (error) {
      toast.show({
        message: "Failed to mark as spam",
        status: "error",
      });
    }
  };

  const reportCommands = [
    {
      label: "Price is wrong",
      iconName: "fa-solid fa-dollar-sign",
      onClick: () => {
        dispatch(
          show("AssetPriceModal", {
            coingeckoTokenId: coingeckoId,
            assetKey: assetKeyV2,
            onSave: (newKey: string) => {
              navigate(`/clients/${clientId}/portfolio/${newKey}`);
            },
          })
        );
      },
    },
    {
      label: "Mark as Spam",
      iconName: "fa-solid fa-trash",
      onClick: _markAsSpam,
    },
    {
      label: "Contact Support",
      iconName: "fa-solid fa-comments",
      onClick: () => {
        // open intercom on the window
        (window as any).Intercom("show");
      },
    },
  ];

  return (
    <HStack>
      <ActionSheet
        popover={{
          placement: "bottom-end",
        }}
        content={{
          maxWidth: 200,
        }}
        commands={reportCommands}
      >
        <Touchable
          iconName="fa-solid fa-flag"
          label={isLarge ? "Report" : ""}
        />
      </ActionSheet>
    </HStack>
  );
};
