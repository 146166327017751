import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { Currency } from "dinero.js";
import { motion } from "framer-motion";
import { isEqual, isNil, orderBy, sortBy, uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import {
  CountNotDoneResponse,
  RecalculateSummary,
  RecalculateSummaryBreakdown,
} from "src/api/generated/types";
import { Option, Select } from "src/components";
import WhiteBox from "src/components/styled/WhiteBox";
import { CURRENT_TAX_YEAR_OPTION } from "src/config";
import { useClientById, useMe, useMyToast, useRecalculate } from "src/hooks";
import { useActiveSubscription } from "src/hooks/useActiveSubscription";
import { useTheme } from "src/hooks/useTheme";
import {
  formatSummaryDate,
  getBreakdownYear,
  getDiffLink,
  getRelevantBreakdown,
} from "src/modules/jobs";
import { colors, other } from "src/theme";
import { D, isLoadingGQL } from "src/utils/helpers";
import Loading from "src/views/Loading";

// don't change, it works
const SELECTED_YEAR_DEFAULT_VALUE = undefined;

function History() {
  const { clientId } = useParams();
  const [select1, setSelect1] = useState("");
  const [select2, setSelect2] = useState("");
  const toast = useMyToast();
  const [selectedYear, setSelectedYear] = useState<Option | undefined>(
    SELECTED_YEAR_DEFAULT_VALUE
  );
  const { client } = useClientById(clientId || "");
  const { me } = useMe();

  const { hasSubscription } = useActiveSubscription(clientId || "");

  const [
    countNotDone,
    { data: countNotDoneData, networkStatus: countNotDoneNetworkStatus },
  ] = useLazyQuery<{
    countNotDone: CountNotDoneResponse;
  }>(api.transactions.countNotDone, {
    fetchPolicy: "cache-and-network",
  });

  const [
    getRecalculateSummaries,
    { data: summariesData, networkStatus: summariesNetworkStatus, error },
  ] = useLazyQuery<{
    getRecalculateSummaries: Array<RecalculateSummary>;
  }>(api.summaries.list, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!clientId) return;
    getRecalculateSummaries({
      variables: {
        clientId,
      },
    });
    countNotDone({
      variables: {
        clientId,
      },
    });
  }, [clientId]);

  const {
    summaries: _summaries,
    summariesByYear: _summariesByYear,
    yearOptions,
  } = useMemo((): {
    summaries: Array<RecalculateSummary>;
    summariesByYear: Record<string, Array<RecalculateSummary>>;
    yearOptions: Array<Option>;
  } => {
    const summaries = summariesData?.getRecalculateSummaries || [];

    // years
    const allBreakdownYears = summaries.map((s: RecalculateSummary) =>
      s.breakdowns.map((b: RecalculateSummaryBreakdown) =>
        String(getBreakdownYear(b))
      )
    );
    const uniqBreakdownYears = uniq(allBreakdownYears.flat());
    const sortedYears = sortBy(uniqBreakdownYears.map((year) => parseInt(year)))
      .reverse()
      .map((s) => s.toString());

    const summaryByYearObj: Record<string, RecalculateSummary[]> = {};

    for (const summary of summaries) {
      const breakdowns = summary.breakdowns;
      for (const breakdown of breakdowns) {
        const year = getBreakdownYear(breakdown);
        if (!summaryByYearObj[year]) {
          summaryByYearObj[year] = [];
        }
        summaryByYearObj[year].push(summary);
      }
    }

    return {
      summaries,
      summariesByYear: (Object.entries(summaryByYearObj) || {}).reduce(
        (acc, [k, v]) => {
          return {
            ...acc,
            [k]: orderBy(v, (s) => new Date(s.createdAt), "desc"),
          };
        },
        {}
      ),
      yearOptions: sortedYears.map((y) => ({ value: y, label: y })),
    };
  }, [summariesData]);

  const theme = useTheme();

  useEffect(() => {
    // already has a value
    if (selectedYear !== SELECTED_YEAR_DEFAULT_VALUE) return;
    else if (
      // set it to current year if it exists
      yearOptions.some((o) => isEqual(o, CURRENT_TAX_YEAR_OPTION))
    )
      setSelectedYear(CURRENT_TAX_YEAR_OPTION);
    else if (
      // set it to the last year if it exists
      yearOptions.length > 0
    )
      setSelectedYear(yearOptions[yearOptions.length - 1]);
  }, [yearOptions, selectedYear]);

  function onClickCompare(id: string) {
    const isSelected1 = select1 === id;
    const isSelected2 = select2 === id;

    if (isSelected1 && isSelected2) {
      // bug that should never happen
      setSelect1("");
      setSelect2("");
    } else if (isSelected1) {
      setSelect1("");
    } else if (isSelected2) {
      setSelect2("");
    } else if (!select1) {
      setSelect1(id);
      if (_summaries.length === 1)
        toast.show({
          message:
            "You need to have run Recalculate twice to look at the changes between them.",
          status: "error",
        });
    } else if (!select2) {
      setSelect2(id);
      window.open(
        getDiffLink(clientId || "", select1, id, selectedYear?.value || ""),
        "_blank"
      );
    }
  }

  const relevantSummaries = useMemo(
    (): RecalculateSummary[] =>
      _summariesByYear[selectedYear?.value || ""] || [],
    [selectedYear?.value, _summariesByYear]
  );

  const Summaries = relevantSummaries.map((summary, i) => {
    const isSelected1 = select1 === summary.id;
    const isSelected2 = select2 === summary.id;
    const isSelected = isSelected1 || isSelected2;
    const breakdowns = summary.breakdowns;
    const relevantBreakdown = getRelevantBreakdown(
      breakdowns,
      selectedYear?.value || "" // use backup just in case
    );

    const capGainsTotal =
      relevantBreakdown && !isNil(relevantBreakdown?.capGainsTotalCents)
        ? D(
            new BigNumber(relevantBreakdown.capGainsTotalCents).toNumber(),
            (client?.currency || "USD") as Currency
          )
        : null;
    const income =
      relevantBreakdown && !isNil(relevantBreakdown?.netIncomeCents)
        ? D(
            new BigNumber(relevantBreakdown.netIncomeCents).toNumber(),
            (client?.currency || "USD") as Currency
          )
        : null;
    const futures =
      relevantBreakdown && !isNil(relevantBreakdown?.futuresNetCents)
        ? D(
            new BigNumber(relevantBreakdown.futuresNetCents).toNumber(),
            (client?.currency || "USD") as Currency
          )
        : null;

    return (
      <Tr
        bgColor={
          isSelected
            ? theme.theme === "dark"
              ? colors.lightBlue10
              : colors.lightBlue90
            : undefined
        }
        onClick={() => onClickCompare(summary.id)}
        cursor="pointer"
        w="100%"
        overflowX="scroll"
        transition="0.1s ease-in-out"
        _hover={{
          bgColor: isSelected ? undefined : theme.secondaryBackground,
          transition: "0.2s ease-in-out",
        }}
      >
        <Td borderBottom={`1px solid ${theme.border} !important`}>
          <Text color={theme.text}>
            {formatSummaryDate(summary.createdAt)}
            {summary.usedSpeedup && me?.isSuperuser ? (
              <i
                className="fa-sharp fa-fast-forward"
                style={{
                  marginLeft: "1rem",
                  color: theme.text,
                }}
              />
            ) : (
              ""
            )}
          </Text>
        </Td>
        <Td borderBottom={`1px solid ${theme.border} !important`}>
          <Center>
            <Text color={theme.text}>{capGainsTotal?.toFormat()}</Text>
          </Center>
        </Td>
        <Td borderBottom={`1px solid ${theme.border} !important`}>
          <Center>
            <Text color={theme.text}>{income?.toFormat()}</Text>
          </Center>
        </Td>
        <Td borderBottom={`1px solid ${theme.border} !important`}>
          <Center>
            <Text color={theme.text}>{futures?.toFormat()}</Text>
          </Center>
        </Td>
        <Td borderBottom={`1px solid ${theme.border} !important`}>
          <Center>
            <Button variant={isSelected ? "secondary" : "primary"}>
              {isSelected ? "Unselect" : "Select for comparison"}
            </Button>
          </Center>
        </Td>
      </Tr>
    );
  });

  if (
    isLoadingGQL(summariesNetworkStatus) ||
    isLoadingGQL(countNotDoneNetworkStatus)
  )
    return <Loading />;

  // if (error) {
  //   return <Text>{error.message}</Text>;
  // }

  // separate from is isLoadingGQL. At this point we know the request didn't fail
  if (!countNotDoneData?.countNotDone) {
    return <Loading />;
  }

  if (!hasSubscription && !me?.isSuperuser) {
    return (
      <Box>
        <Text color={theme.text}>
          You need to have a subscription to access this page.
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <HStack>
        <Heading style={{ color: theme.header }} flex={1} size="lg">
          Recalculate History
        </Heading>
        <Select
          placeholder="Select Year"
          value={selectedYear}
          options={yearOptions}
          selectProps={{
            isDisabled: !summariesData || !!error,
            onChange: (o) => setSelectedYear(o as Option),
          }}
        />
      </HStack>
      <Text color={theme.text}>
        This page shows the history of your Recalculate jobs. You can see how
        your modifications to transactions changed your tax reports, in detail.
      </Text>
      <WhiteBox padding="0">
        <TableContainer>
          <Table
            variant="simple"
            border={`1px solid ${theme.border} !important`}
          >
            <Thead>
              <Tr>
                <Th
                  color={theme.text}
                  borderBottom={`1px solid ${theme.border} !important`}
                >
                  Date
                </Th>
                <Th
                  color={theme.text}
                  borderBottom={`1px solid ${theme.border} !important`}
                >
                  <Center>Gains/Losses</Center>
                </Th>
                <Th
                  color={theme.text}
                  borderBottom={`1px solid ${theme.border} !important`}
                >
                  <Center>Income</Center>
                </Th>
                <Th
                  color={theme.text}
                  borderBottom={`1px solid ${theme.border} !important`}
                >
                  <Center>Futures</Center>
                </Th>
                <Th
                  color={theme.text}
                  borderBottom={`1px solid ${theme.border} !important`}
                >
                  <Center>Compare</Center>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <PendingRecalculateSummary
                countNotDone={countNotDoneData.countNotDone}
              />
              {/* <Tr>
                      <Td>
                        <Heading size="md">For {year} tax reports</Heading>
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr> */}
              {Summaries}
            </Tbody>
          </Table>
        </TableContainer>
      </WhiteBox>
    </Box>
  );
}

type PendingRecalculateSummary = {
  countNotDone: CountNotDoneResponse;
};

const PendingRecalculateSummary = ({
  countNotDone,
}: {
  countNotDone?: CountNotDoneResponse;
}) => {
  const theme = useTheme();
  const recalculate = useRecalculate();

  if (!countNotDone || (countNotDone.dirty === 0 && !countNotDone.isDirty))
    return null;

  return (
    <Tr
      bgColor={colors.red90}
      onClick={() => recalculate()}
      cursor="pointer"
      w="100%"
      borderBottom={other.boxBorder}
    >
      <Td>
        <Text color={theme.text}>
          You have pending changes. Click Recalculate&nbsp;&nbsp;👉
        </Text>
      </Td>
      {[1, 2, 3].map((i) => (
        <Td key={i}>
          <Text>
            <Center color={theme.text}>?</Center>
          </Text>
        </Td>
      ))}
      <Td>
        <Center>
          <motion.div
            animate={{ scale: 1.05 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 1,
            }}
          >
            <Button variant="primary">Recalculate</Button>
          </motion.div>
        </Center>
      </Td>
    </Tr>
  );
};

export default History;
