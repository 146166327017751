import {
  Box,
  BoxProps,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTheme } from "src/hooks/useTheme";
import { Command, CommandProps } from "./Command";

type ActionSheetProps = {
  commands: CommandProps[];
  popover?: PopoverProps;
  content?: PopoverContentProps;
  children?: JSX.Element | JSX.Element[];
  closeAfterClick?: boolean;
  boxStyle?: BoxProps;
};

export const ActionSheet = ({
  popover,
  content,
  children,
  commands,
  boxStyle,
  closeAfterClick: _closeAfterClick,
}: ActionSheetProps) => {
  const [isOpen, setOpen] = useState(false);
  const closeAfterClick = _closeAfterClick ?? false;
  const { background, border } = useTheme();

  // this is a curried function for the command click, and the purpose of it
  // is so that we can automatically close the popover on success of the click
  //
  // code looks a bit confusing, but all it does is close the popover on success clicks
  const _onClick = async (c: Pick<CommandProps, "onClick">) => {
    if (!closeAfterClick) setOpen(false);
    if (c.onClick) await c.onClick();
    if (closeAfterClick) setOpen(false);
  };

  return (
    <Popover
      placement="bottom"
      isOpen={popover?.isOpen ?? isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...popover}
    >
      <PopoverTrigger>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(true);
          }}
        >
          {children}
        </div>
      </PopoverTrigger>
      <PopoverContent
        bg={background}
        borderRadius={0}
        border={`none`}
        {...content}
      >
        <PopoverArrow
          bg={background}
          bgColor={background}
          boxShadow={
            // use the border
            `0 0 0 1px ${border} !important`
          }
        />
        <Box
          bg={background}
          padding="0.25rem 0"
          border={
            // use the border
            `1px solid ${border}`
          }
          borderRadius={5}
          {...boxStyle}
        >
          {commands.map((c, i) => (
            <Command
              {...c}
              onClick={async (e) => {
                await _onClick(c);
              }}
              key={i}
            />
          ))}
        </Box>
      </PopoverContent>
    </Popover>
  );
};
