const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8080";

const isDev = (process.env.NODE_ENV || "") === "development";

export const MIN_HIGH_INCOME = 10000_00;
export const MIN_HIGH_CAP_GAINS = 10000_00;
export const MIN_TOTAL_HIGH_INCOME = 250_000;
export const MIN_TOTAL_HIGH_CAP_GAINS = 500_000;

export const config = {
  env: process.env.NODE_ENV || "local",
  apiPortfolioUrl: process.env.REACT_APP_API_PORTFOLIO_URL || apiUrl,
  apiUrl: apiUrl,
  // march 1st 2024 UTC
  // put this to UTC no timezone
  freeValidDate: new Date("2024-03-01T16:00:00.000Z"),
  cxEmail: "team@awaken.tax",
  discordLink:
    process.env.REACT_APP_DISCORD_INVITE_LINK ||
    "https://discord.gg/7PgvKYkrBv",
  twitterLink: "https://twitter.com/AwakenTax",
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || "",
  stripe: {
    publicKey:
      process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
      "pk_test_51LNNLQFnjnDlXiOCii4pZCve9ReK572CR8oK56Kerqx70bBxMvtre5ubzbZPEOufA21g18egS5mnNg2gnQ4wb9nu00YF29Vmol",
  },
  recaptcha: {
    token: process.env.REACT_APP_RECAPTCHA_TOKEN || "",
    v2Token: process.env.REACT_APP_RECAPTCHA_TOKEN_V2 || "",
    v3Token: process.env.REACT_APP_RECAPTCHA_TOKEN_V3 || "",
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
  },
  redux: {
    //isDev ||
    logger: process.env.REACT_APP_REDUX_LOGGER === "true",
  },
  hatchfi: {
    clientId: process.env.REACT_APP_HATCHFI_CLIENT_ID || "",
  },
  logrocket: process.env.REACT_APP_LOGROCKET_PROJECT_ID || "onwpfq/awaken-tax",
  pusher: {
    key: process.env.REACT_APP_PUSHER_KEY || "c74fd7e531876e3b5018",
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || "us3",
  },
  coinbaseWalletDiscountDate: new Date("2023-03-15"), // MAKE SURE TO UPDATE BACKEND IF YOU CHANGE THIS
};

export const isProduction = config.env === "production";

export * from "./constants";
