import { PublicKey } from "@solana/web3.js";
import { BaseLedgerAccountFields } from "src/api/fragments";
import {
  AccountProviderEnum,
  LedgerAccountClassificationEnum,
  LedgerAccountSubTypeEnum,
  LedgerAccountTypeEnum,
} from "src/api/generated/types";
import { Maybe } from "src/core";

export const EVM_PROVIDERS = new Set(
  [
    AccountProviderEnum.Ethereum,
    AccountProviderEnum.Bsc,
    AccountProviderEnum.Polygon,
    AccountProviderEnum.Fantom,
    AccountProviderEnum.Avalanche,
    AccountProviderEnum.Arbitrum,
    AccountProviderEnum.Optimism,
    AccountProviderEnum.Base,
    AccountProviderEnum.Gnosis,
    AccountProviderEnum.Blast,
    AccountProviderEnum.Unichain,
    AccountProviderEnum.Apechain,
    AccountProviderEnum.Pulsechain,
    AccountProviderEnum.Hyperliquid,
    AccountProviderEnum.Sonic,
    AccountProviderEnum.Abstract,
  ].map((t) => t.toLowerCase())
);

export const ALLOWED_EVM_PROVIDERS = new Set(
  [
    AccountProviderEnum.Ethereum,
    AccountProviderEnum.Bsc,
    AccountProviderEnum.Polygon,
    AccountProviderEnum.Fantom,
    AccountProviderEnum.Avalanche,
    AccountProviderEnum.Arbitrum,
    AccountProviderEnum.Optimism,
    AccountProviderEnum.Base,
    AccountProviderEnum.Blast,
  ].map((t) => t.toLowerCase())
);

export const isValidEVMAddress = (address: string): boolean => {
  // we'll allow ens
  if (address.includes(".eth")) {
    return true;
  }

  // Ethereum address regex pattern
  const evmAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;

  // Check if the address matches the pattern
  return evmAddressPattern.test(address);
};

export const isValidSolanaAddress = (address: string): boolean => {
  try {
    new PublicKey(address);
    return true;
  } catch (err) {
    return false;
  }
};

export const _isAsset = (a?: Maybe<BaseLedgerAccountFields>) =>
  a?.classification === LedgerAccountClassificationEnum.Asset;

export const _isInvestment = (a?: Maybe<BaseLedgerAccountFields>) =>
  a?.classification === LedgerAccountClassificationEnum.Revenue &&
  a?.subType === LedgerAccountSubTypeEnum.Investments;

// just the root account for income should show asset for now
export const _isRootIncomeAccount = (a?: Maybe<BaseLedgerAccountFields>) =>
  a?.classification === LedgerAccountClassificationEnum.Revenue &&
  a?.type === LedgerAccountTypeEnum.Income &&
  a?.subType === null &&
  a?.level === 1;

export const _isCOGS = (a?: Maybe<BaseLedgerAccountFields>) =>
  a?.classification === LedgerAccountClassificationEnum.Expense &&
  a?.type === LedgerAccountTypeEnum.CostOfGoodsSold;

export const shouldUseAsset = (a?: Maybe<BaseLedgerAccountFields>): boolean => {
  return _isAsset(a) || _isInvestment(a) || _isCOGS(a);
};

export const shouldUseAmount = (
  a?: Maybe<BaseLedgerAccountFields>
): boolean => {
  return _isAsset(a);
};
