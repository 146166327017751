import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { Box, Divider, useToast } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { sleep } from "radash";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { connectModal, InjectedProps, show } from "redux-modal";
import { api, apolloClient } from "src/api";
import { Modal } from "src/components/Modal";
import { MyToast } from "src/components/MyToast";
import { Touchable } from "src/components/Touchable";
import { useTheme } from "src/hooks/useTheme";
import {
  getIsGlobalRuleMode,
  setGlobalRuleMode,
} from "src/redux/reducers/globalState";

const _AdminModal = ({ handleHide, show: isVisible }: InjectedProps) => {
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useToast();
  const { header, border } = useTheme();
  const isGlobalRuleMode = useSelector(getIsGlobalRuleMode);
  const _setGlobalRuleMode = (value: boolean) =>
    dispatch(setGlobalRuleMode(value));

  const [getSubscriptions] = useLazyQuery(api.subscriptions.list);
  const [giveCredit] = useMutation(api.clients.giveCredit);
  const [adjustSubscriptionTier] = useMutation(
    api.clients.adjustSubscriptionTier
  );

  const _giveCredit = async () => {
    try {
      await sleep(250);

      const response = await getSubscriptions({
        variables: { clientId },
      });

      const subs = (response?.data?.getSubscriptions || []).filter(
        (s: any) => s.transactionCeiling > 25
      );

      if (subs.length > 0) {
        const confirmed = window.confirm(
          "This client already have a subscription. Do you still want to give them credit?"
        );
        if (!confirmed) return;
      }

      const amountOfCredit = window.prompt(
        "Enter the amount of credit to give",
        "25"
      );

      if (!amountOfCredit) return;

      const amount = new BigNumber(amountOfCredit).multipliedBy(100).dp(0);

      if (amount.isNaN()) {
        alert("Invalid amount");
        return;
      }

      await giveCredit({
        variables: { clientId: clientId, newCreditCents: amount.toNumber() },
        refetchQueries: [api.clients.retrieve],
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        return alert(err.message);
      }
      return alert("An error occurred.");
    }
  };

  const _upgradeSubscription = async () => {
    try {
      await sleep(250);

      const newCeiling = window.prompt(
        "How much should their new tier be?",
        "1500"
      );

      if (!newCeiling) return;

      const amount = new BigNumber(newCeiling).dp(0);

      if (amount.isNaN()) {
        alert("Invalid amount");
        return;
      }

      await adjustSubscriptionTier({
        variables: { clientId: clientId, newCeiling: amount.toNumber() },
      });

      await apolloClient.refetchQueries({
        include: [api.clients.retrieve, api.subscriptions.active],
      });

      toast({
        position: "top",
        render: () => (
          <MyToast
            message={"Successfully upgraded subscription!"}
            status="success"
          />
        ),
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        return alert(err.message);
      }
      return alert("An error occurred.");
    }
  };

  return (
    <Modal
      trapFocus={false}
      title="Admin Controls"
      isVisible={isVisible}
      handleHide={handleHide}
      bodyProps={{
        padding: 0,
        overflowY: "auto", // Changed from "scroll" to "auto"
      }}
    >
      <Box
        mb={4}
        fontSize="14px"
        color={header}
        p={4}
        bg="whiteAlpha.100"
        borderRadius="md"
      >
        {[
          { key: "⌘ + P", action: "Open Admin Modal" },
          { key: "⌘ + K", action: "Search Users" },
          { key: "⌘ + J", action: "View Jobs" },
          { key: "⌘ + E", action: "Copy Email" },
          { key: "⌘ + I", action: "Copy Client ID" },
        ].map(({ key, action }) => (
          <div
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "2px 0",
            }}
          >
            <code
              style={{
                background: "whiteAlpha.200",
                padding: "2px 6px",
                borderRadius: "4px",
                fontFamily: "monospace",
                marginRight: "8px",
              }}
            >
              {key}
            </code>
            <span>{action}</span>
          </div>
        ))}
      </Box>
      <Divider my={2} borderColor={border} />

      <Touchable
        style={{
          margin: "5px 0",
          borderRadius: "8px",
          padding: "8px",
          fontSize: "16px",
        }}
        labelStyle={{
          fontSize: 16,
        }}
        onClick={() => {
          _setGlobalRuleMode(!isGlobalRuleMode);
          handleHide();
        }}
        iconName={isGlobalRuleMode ? "fal fa-globe" : "fa-sharp fa-globe"}
        iconPosition="left"
        label={`${isGlobalRuleMode ? "Exit" : "Enter"} Global Rule Mode`}
      />

      <Touchable
        labelStyle={{
          fontSize: 16,
        }}
        style={{
          margin: "5px 0",
          borderRadius: "8px",
          padding: "8px",
          fontSize: "16px",
        }}
        onClick={_giveCredit}
        iconName="fa-sharp fa-gift"
        iconPosition="left"
        label={`Give credit`}
      />

      <Touchable
        style={{
          margin: "5px 0",
          borderRadius: "8px",
          padding: "8px",
          fontSize: "16px",
        }}
        labelStyle={{
          fontSize: 16,
        }}
        onClick={_upgradeSubscription}
        iconName="fa-sharp fa-arrow-up-right-from-square"
        iconPosition="left"
        label={`Upgrade subscription`}
      />

      <Touchable
        labelStyle={{
          fontSize: 16,
        }}
        style={{
          margin: "5px 0",
          borderRadius: "8px",
          padding: "8px",
          fontSize: "16px",
        }}
        onClick={() => {
          dispatch(show("BuyLinkModal"));
          handleHide();
        }}
        iconName="fa-sharp fa-file-invoice-dollar"
        iconPosition="left"
        label={`Generate payment link`}
      />

      <Touchable
        labelStyle={{
          fontSize: 16,
        }}
        style={{
          margin: "5px 0",
          borderRadius: "8px",
          padding: "8px",
          fontSize: "16px",
        }}
        onClick={() => {
          dispatch(show("RefundModal"));
          handleHide();
        }}
        iconName="fa-sharp fa-money-bill-transfer"
        iconPosition="left"
        label={`Refund client`}
      />

      <Divider my={2} borderColor={border} />

      <Link to={`/admin/search`} onClick={() => handleHide()}>
        <Touchable
          labelStyle={{
            fontSize: 16,
          }}
          style={{
            margin: "5px 0",
            borderRadius: "8px",
            padding: "8px",
            fontSize: "16px",
          }}
          iconName="fa-sharp fa-magnifying-glass"
          iconPosition="left"
          label={`Search for user`}
        />
      </Link>

      <Link to={`/clients/${clientId}/jobs`} onClick={() => handleHide()}>
        <Touchable
          labelStyle={{
            fontSize: 16,
          }}
          style={{
            margin: "5px 0",
            borderRadius: "8px",
            padding: "8px",
            fontSize: "16px",
          }}
          iconName="fa-sharp fa-cogs"
          iconPosition="left"
          label="Jobs"
        />
      </Link>

      <Link to={`/clients/${clientId}/default-rules`}>
        <Touchable
          labelStyle={{
            fontSize: 16,
          }}
          style={{
            margin: "5px 0",
            borderRadius: "8px",
            padding: "8px",
            fontSize: "16px",
          }}
          iconName="fa-sharp fa-ruler"
          iconPosition="left"
          label="Global Rules"
        />
      </Link>
    </Modal>
  );
};

export const AdminModal = connectModal({
  name: "AdminModal",
})(_AdminModal);
